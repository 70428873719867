<template>
  <v-card style="border-radius: 1em;">
    <v-img v-if="posto.url_imagem_posto" width="700" :src="posto.url_imagem_posto" class="mb-3"></v-img>
      <v-card v-else color="grey" height="300" class="rounded-0 mb-3">
          <v-layout justify-center align-center fill-height>
            <div class="title white--text pa-4 text-xs-center">Sem Imagem</div>
          </v-layout>
      </v-card>
    <v-card-text class="mb-4">
      <v-card-title class="pb-0">
          <h2 v-if="postoSelecionado" style="width: 100%; text-align: center;">{{ postoSelecionado.nome_fantasia }}</h2>
      </v-card-title>
      <v-layout row class="mt-4">
      <v-flex xs5>
        <v-card-text v-if="combustiveis.length > 0">
          <v-list>
          <v-list-item two-line v-for="(item, index) in combustiveis" :key="index">
            <v-list-item-action>
              <v-tooltip :color="item.combustivel.color" top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <svg-filler
                      :path="item.combustivel.icone ? require(`@/assets/combs/${item.combustivel.icone}.svg`) : require(`@/assets/combs/gas.svg`)"
                      width="40px"
                      height="40px"
                      :fill="item.combustivel.color"
                    />
                  </span>
                </template>
                <span>{{item.combustivel.nome}}</span>
              </v-tooltip>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title style="font-size: 0.9em;">{{item.combustivel.nome}}</v-list-item-title>
              <v-list-item-subtitle>{{ item.preco | currency({ fractionCount: 2 }) }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        </v-card-text>
        <v-card-text v-else>
          Sem combustíveis cadastrados
        </v-card-text>
      </v-flex>
      <v-flex xs7 v-if="postoSelecionado">
        <v-card-text style="font-size: 1.3em;" v-if="postoSelecionado.email">Email: <a  v-bind:href="'mailto:' + postoSelecionado.email">{{ postoSelecionado.email }}</a></v-card-text>
        <v-card-text style="font-size: 1.3em;" v-if="postoSelecionado.telefone">Telefone: <a v-bind:href="'tel:' + postoSelecionado.telefone">{{ postoSelecionado.telefone }}</a></v-card-text>
        <v-card-text style="font-size: 1.3em;" v-if="endereco">Endereço: {{ endereco.logradouro }}, bairro {{ endereco.bairro }}, número {{ endereco.numero }} — {{ endereco.cidade }} ({{ endereco.estado }}) — CEP {{ endereco.cep  }}</v-card-text>
      </v-flex>
      </v-layout>
      </v-card-text>
    </v-card>
</template>

<script>
export default {
  data () {
    return {
      postoSelecionado: null,
      endereco: null,
      combustiveis: []
    }
  },
  props: ['posto'],
  computed: {
    empresa () {
      return this.$store.getters.empresa
    }
  },
  beforeMount () {
    if (this.posto) {
      this.pegarEndereco()
      this.pegarPosto()
      this.pegarItens()
    }
  },
  methods: {
    corrigirPreco (valor) {
      var temCasaDecimal = valor.toString().replaceAll('.', ',').split(',')[1] != null
      if (temCasaDecimal && valor.toString().replaceAll('.', ',').split(',')[1].length > 2) {
        var casasDecimais = valor.toString().replaceAll('.', ',').split(',')[1]
        var filtrando = casasDecimais.substring(0, 2)
        return parseFloat([valor.toString().split('.')[0], filtrando].join('.')).toFixed(2)
      }
      return valor.toFixed(2)
    },
    pegarEndereco () {
      this.$store.dispatch('pegarEndereco', this.posto.cnpj).then(endereco => {
        this.endereco = endereco
      })
    },
    pegarItens () {
      if (!this.empresa) return
      const data = {
        empresa: this.empresa.cnpj,
        categoria__referencia: 'combustivel',
        posto__cnpj: this.posto.cnpj
      }

      this.$store.dispatch('pegarItens', data).then(json => {
        this.combustiveis = json
      })
    },
    pegarPosto () {
      if (!this.postoSelecionado) {
        this.$store.dispatch('pegarPosto', this.posto.cnpj).then(posto => {
          this.postoSelecionado = posto
        })
      }
    }
  }
}
</script>

<style>

</style>
