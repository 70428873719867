<template>
  <v-container fluid fill-height grid-list-md>
    <v-layout row wrap justify-center>
      <v-flex xs12>
        <v-toolbar
          color="#eee"
          class="elevation-0 secondary--text hidden-print-only"
          style="background: transparent"
          tabs
        >
          <v-toolbar-title>Editar dados da empresa</v-toolbar-title>
        </v-toolbar>
      </v-flex>
      <v-flex xs11>
        <v-tabs class="hidden-print-only" v-model="tabs" grow>
          <v-tab class="font-weight-medium green--text text--darken-1">Informações</v-tab>
          <v-tab class="font-weight-medium green--text text--darken-1">Imagens</v-tab>
          <v-tab class="font-weight-medium green--text text--darken-1">Geo Localização</v-tab>
          <v-tab class="font-weight-medium green--text text--darken-1">Horarios</v-tab>
        </v-tabs>
        <v-card class="elevation-0" :loading="loading">
          <v-tabs-items v-model="tabs">
            <v-tab-item>
              <v-card-text class="pt-0 pb-0">
                <v-container grid-list-lg fluid>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-layout row wrap justify-center>
                      <v-flex
                        xs12
                        class="headline secondary--text text-xs-center"
                      >Dados cadastrais da empresa</v-flex>
                      <v-flex xs12 md4>
                        <v-text-field
                          label="Nome fantasia"
                          v-model="empresa_model.nome_fantasia"
                          outline
                          required
                          :rules="[
                            (v) => !!v || 'O nome fantasia é obrigatório'
                          ]"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md4>
                        <v-text-field
                          label="Razão Social"
                          v-model="empresa_model.razao_social"
                          outline
                          required
                          :rules="[
                            (v) => !!v || 'A razão social é obrigatória'
                          ]"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md4>
                        <v-text-field
                          v-model="empresa_model.email"
                          label="Email de notificações"
                          hint="Insira o email que receberá as notificações da empresa"
                          outline
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md4>
                        <v-text-field
                            label="Número de WhatsApp"
                            v-model="empresa_model.contato1"
                            placeholder="(12) 9 3456-7891"
                            v-mask="!carregando ? '(##) # ####-####' : '###########'"
                            :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md4>
                        <v-text-field
                            label="Número de telefone (Fixo)"
                            v-model="empresa_model.contato2"
                            placeholder="(12) 3456-7891"
                            v-mask="!carregando ? '(##) ####-####' : '##########'"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md4>
                        <v-text-field
                            label="Número de celular"
                            v-model="empresa_model.contato3"
                            placeholder="(12) 9 3456-7891"
                            v-mask="!carregando ? '(##) # ####-####' : '###########'"
                        ></v-text-field>
                      </v-flex>
                      <v-flex
                        xs12
                        class="headline secondary--text text-xs-center pt-0"
                      >Endereço da empresa</v-flex>

                      <v-flex xs6 md3 lg2>
                        <v-text-field
                          v-model="empresa_model.endereco.cep"
                          maxlength="9"
                          label="CEP"
                          v-mask="!carregando ? '#####-###' : '########'"
                          required
                          @keyup="searchCep()"
                          :rules="[
                            (v) => !!v || 'O CEP é obrigatório'
                          ]"
                          outline
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs6 md5 lg4>
                        <v-text-field
                          v-model="empresa_model.endereco.cidade"
                          label="Cidade"
                          required
                          outline
                          :rules="[
                            (v) => !!v || 'A cidade é obrigatória'
                          ]"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md4>
                        <v-text-field
                          v-model="empresa_model.endereco.logradouro"
                          label="Logradouro"
                          outline
                          required
                          :rules="[
                        (v) => !!v || 'O logradouro é obrigatório']"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs6 md2>
                        <v-text-field
                          v-model="empresa_model.endereco.numero"
                          label="Nº"
                          mask="#########"
                          hint="Digite 0 para sem número"
                          outline
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs6 md3>
                        <v-text-field
                          v-model="empresa_model.endereco.bairro"
                          label="Bairro"
                          outline
                          required
                          :rules="[
                            (v) => !!v || 'O bairro é obrigatório'
                          ]"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs8 md5>
                        <v-text-field
                          v-model="empresa_model.endereco.complemento"
                          label="Complemento"
                          counter="50"
                          maxlength="50"
                          outline
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs4 md2>
                        <v-autocomplete
                          v-model="empresa_model.endereco.estado"
                          label="UF"
                          outline
                          required
                          :rules="[
                            (v) => !!v || 'Insira uma UF válida'
                          ]"
                          clearable
                          :items="estados"
                        ></v-autocomplete>
                      </v-flex>
                      <template v-if="empresa_model.tipo_empresa === 4 || empresa_model.tipo_empresa === 5 || empresa_model.tipo_empresa === 6">
                        <v-flex xs6 align-self-center>
                          <v-select
                            v-model="empresa_model.categoria"
                            :items="categorias"
                            label="Categoria de Atuação"
                          />
                        </v-flex>
                        <v-flex xs6 align-self-center>
                          <v-select
                            v-model="empresa_model.subcategorias"
                            :items="subcategorias.filter(sc => {
                              const categoriaCorrespondente = sc.categoria === empresa_model.categoria || empresa_model.categoria === 9
                              const servicoCorrespondente = (empresa_model.tipo_empresa === 5 && sc.servico || empresa_model.tipo_empresa === 4 && !sc.servico) || (empresa_model.tipo_empresa === 6)
                              return categoriaCorrespondente && servicoCorrespondente
                            }
                            )"
                            label="Subcategoria de Atuação"
                            chips
                            multiple
                          />
                        </v-flex>
                      </template>
                    </v-layout>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions style="flex-direction: column;">
                <v-btn
                  text
                  color="primary"
                  @click="dialogMudarTipo = true"
                  block
                  :loading="loading"
                  class="mb-2"
                >
                  Solicitar mudança de tipo
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="alterarempresa"
                  block
                  :loading="loading"
                >
                  Salvar Informações
                </v-btn>
              </v-card-actions>
            </v-tab-item>
            <v-tab-item>
              <v-card-text class="pt-0">
                <v-container grid-list-md fluid>
                  <v-layout row wrap fill-height>
                    <v-flex xs6 class="text-xs-center">
                      <h3 class="headline secondary--text" style="width: 100%; text-align: center;">Imagem de capa</h3>
                      <v-flex xs12 v-if="empresa && !empresa.url_imagem_posto && !croppingCapa" align-self-center>
                        <v-card style="height: 17em" class="mt-4" hover @click="$refs.capaInput.click()">
                          <v-layout justify-center align-center fill-height column>
                            <v-icon color="gray" size="150">image</v-icon>
                            <h3>Sem Imagem</h3>
                          </v-layout>
                        </v-card>
                      </v-flex>
                      <v-layout justify-center align-center fill-height>
                        <v-slide-y-transition>
                          <v-flex xs11 v-show="croppingCapa">
                            <vue-croppie
                              ref="croppieCapaRef"
                              :enableExif="true"
                              :enforceBoundary="true"
                              :enableResize="false"
                              :enableOrientation="false"
                              :mouseWheelZoom="true"
                              :showZoomer="false"
                              :boundary="{ width: 500, height: 250 }"
                              :viewport="{ width: 400, height: 200}"
                            ></vue-croppie>
                            <v-btn
                              text
                              @click="recortarCapa"
                              block
                              color="grey darken-2"
                            >Recortar imagem</v-btn>
                          </v-flex>
                        </v-slide-y-transition>
                        <v-slide-y-transition>
                          <v-flex xs10 v-show="!croppingCapa">
                            <v-img v-if="empresa && empresa.url_imagem_posto" :src="empresa.url_imagem_posto" contains @click="$refs.capaInput.click()" style="cursor: pointer;"></v-img>
                          </v-flex>
                        </v-slide-y-transition>
                      </v-layout>
                    </v-flex>
                    <v-flex xs6 class="text-xs-center">
                      <h3 class="headline secondary--text" style="width: 100%; text-align: center;">Imagem do logo</h3>
                      <v-layout justify-center>
                        <v-flex xs6 v-if="empresa && !empresa.url_logo && !croppingLogo">
                          <v-card style="height: 17em" class="mt-4" hover @click="$refs.logoInput.click()">
                            <v-layout justify-center align-center fill-height column>
                              <v-icon color="gray" size="150">image</v-icon>
                              <h3>Sem Imagem</h3>
                            </v-layout>
                          </v-card>
                        </v-flex>
                      </v-layout>
                      <v-layout justify-center align-center fill-height>
                        <v-slide-y-transition>
                          <v-flex xs8 v-show="croppingLogo">
                            <vue-croppie
                              ref="croppieRef"
                              :enableExif="true"
                              :enforceBoundary="true"
                              :enableResize="false"
                              :enableOrientation="false"
                              :mouseWheelZoom="true"
                              :showZoomer="false"
                              :boundary="{ width: 400, height: 400 }"
                              :viewport="{ width: 200, height: 200, type : 'circle' }"
                            ></vue-croppie>
                            <v-btn
                              text
                              @click="recortarLogo"
                              color="grey darken-2"
                              block
                            >Recortar imagem</v-btn>
                          </v-flex>
                        </v-slide-y-transition>
                        <v-slide-y-transition>
                          <v-flex xs5 v-show="!croppingLogo">
                            <v-img v-if="empresa && empresa.url_logo" :src="empresa.url_logo" contains @click="$refs.logoInput.click()" style="cursor: pointer;"></v-img>
                          </v-flex>
                        </v-slide-y-transition>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions class="justify-space-around">
                <v-btn text color="primary" @click="$refs.capaInput.click()">
                  Carregar imagem de capa
                  <input
                    type="file"
                    style="display:none"
                    accept="image/*"
                    ref="capaInput"
                    @input="carregarCapa"
                  />
                </v-btn>
                <v-btn text color="primary" @click="$refs.logoInput.click()">
                  Carregar logo empresa
                  <input
                    type="file"
                    style="display:none"
                    accept="image/*"
                    ref="logoInput"
                    @input="carregarLogo"
                  />
                </v-btn>
              </v-card-actions>
            </v-tab-item>
            <v-tab-item>
              <edit-geo-position @info="$store('setSuccess', 'Teste')"></edit-geo-position>
            </v-tab-item>
            <v-tab-item>
              <v-card-text class="pt-0 pb-0">
                <v-container grid-list-lg fluid>
                  <v-layout row wrap justify-center>
                    <v-flex
                      xs10
                      class="headline secondary--text text-xs-center"
                    >Horários de funcionamento da empresa</v-flex>
                    <v-flex xs2 class="justify-center">
                    <v-btn outlined rounded @click="() => toogle24horas()">
                      <v-switch class="mt-3" v-model="empresa_model.empresa24horas">
                      <template v-slot:label>
                        empresa 24 horas
                      </template>
                    </v-switch>
                    </v-btn>
                  </v-flex>
                    <template v-for="dia in dias">
                      <v-flex xs6 sm6 md3 :key="dia.text">
                        <v-layout row wrap justify-start>
                          <v-flex xs12 class="font-weight-medium primary--text subheading">
                            {{dia.text}}
                            <v-spacer></v-spacer>
                            <span
                              class="font-weight-light green--text"
                              v-if="dia.cadastrado"
                            >Cadastrado</span>
                            <span class="font-weight-light red--text" v-else>Cadastro pendente</span>
                          </v-flex>
                          <v-flex xs5>
                            <v-text-field label="Entrada" v-mask="'##:##'" v-model="dia.hora_abertura"></v-text-field>
                          </v-flex>
                          <v-flex xs5>
                            <v-text-field label="Saída"  v-mask="'##:##'" v-model="dia.hora_fechamento"></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </template>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  text
                  block
                  color="primary"
                  @click="cadastrarHorarios()"
                  :loading="loading"
                >SALVAR HORÁRIOS</v-btn>
              </v-card-actions>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialogMudarTipo" v-if="dialogMudarTipo" width="50em">
      <mudar-tipo
        @fechar="dialogMudarTipo = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import EditGeoPosition from '@/components/cadastros/EditGeoPosition'
import MudarTipo from '@/components/dialogs/MudarTipo'
import { mask } from 'vue-the-mask'

export default {
  components: { EditGeoPosition, MudarTipo },
  directives: {
    mask
  },
  data: () => ({
    carregando: false,
    retaguarda_adquirente: null,
    retaguarda_abastecimento: null,
    tabs: null,
    croppingCapa: false,
    croppingLogo: false,
    cropped: null,
    croppedCapa: null,
    valid: true,
    coordenadas: {
      latitude: null,
      longitude: null
    },
    dialogMudarTipo: false,
    empresaEndereco: null,
    domingoCadastrado: false,
    segundaCadastrado: false,
    tercaCadastrado: false,
    quartaCadastrado: false,
    quintaCadastrado: false,
    sextaCadastrado: false,
    sabadoCadastrado: false,
    categorias: [
      { text: 'Alimentação e Bebidas', value: 1 },
      { text: 'Automóveis e Peças Automotivas', value: 2 },
      { text: 'Construção e Imóveis', value: 3 },
      { text: 'Design e Criatividade', value: 4 },
      { text: 'Moda e Estilo', value: 5 },
      { text: 'Saúde e Bem-Estar', value: 6 },
      { text: 'Beleza e Estética', value: 7 },
      { text: 'Educação e Treinamento', value: 8 },
      { text: 'Outra', value: 9 }
    ],
    subcategorias: [
      // Alimentação e Bebidas
      { text: 'Restaurante', value: 1, categoria: 1, servico: false },
      { text: 'Lanchonete', value: 2, categoria: 1, servico: false },
      { text: 'Pizzaria', value: 3, categoria: 1, servico: false },
      { text: 'Bar', value: 4, categoria: 1, servico: false },
      { text: 'Buffet', value: 5, categoria: 1, servico: true },
      { text: 'Supermercado', value: 6, categoria: 1, servico: false },
      { text: 'Venda de Água Mineral', value: 7, categoria: 1, servico: false },
      { text: 'Padaria', value: 8, categoria: 1, servico: false },

      // Automóveis e Peças Automotivas
      { text: 'Auto Peças', value: 9, categoria: 2, servico: false },
      { text: 'Oficina', value: 10, categoria: 2, servico: true },
      { text: 'Concessionária', value: 11, categoria: 2, servico: false },
      { text: 'Transportadora', value: 12, categoria: 2, servico: true },
      { text: 'Lava Jato', value: 13, categoria: 2, servico: true },
      { text: 'Locação', value: 14, categoria: 2, servico: true },
      { text: 'Auto Escola', value: 15, categoria: 2, servico: true },
      { text: 'Equipadora', value: 16, categoria: 2, servico: true },

      // Construção e Imóveis
      { text: 'Material de Construção', value: 17, categoria: 3, servico: false },
      { text: 'Construtora', value: 18, categoria: 3, servico: true },
      { text: 'Metalurgica', value: 19, categoria: 3, servico: true },
      { text: 'Madeireira', value: 20, categoria: 3, servico: false },
      { text: 'Portão Automático', value: 21, categoria: 3, servico: false },
      { text: 'Vidraçarias', value: 22, categoria: 3, servico: true },
      { text: 'Engenharia', value: 23, categoria: 3, servico: true },
      { text: 'Serralharia', value: 24, categoria: 3, servico: true },
      { text: 'Corretagem e Consultoria Imobiliária', value: 25, categoria: 3, servico: true },
      { text: 'Desenvolvimento Imobiliário', value: 26, categoria: 3, servico: true },
      { text: 'Gestão de Propriedades', value: 27, categoria: 3, servico: true },
      { text: 'Construção Residencial', value: 28, categoria: 3, servico: true },
      { text: 'Construção Comercial', value: 29, categoria: 3, servico: true },
      { text: 'Serviços de Manutenção Predial', value: 30, categoria: 3, servico: true },
      { text: 'Investimento Imobiliário', value: 31, categoria: 3, servico: true },
      { text: 'Avaliação de Propriedades', value: 32, categoria: 3, servico: true },
      { text: 'Empreendimentos Sustentáveis', value: 33, categoria: 3, servico: false },
      { text: 'Arquitetura e Design de Interiores', value: 34, categoria: 3, servico: true },

      // Design e Criatividade
      { text: 'Design Gráfico', value: 35, categoria: 4, servico: true },
      { text: 'Design de Produto', value: 36, categoria: 4, servico: true },
      { text: 'Design de Interiores', value: 37, categoria: 4, servico: true },
      { text: 'Design de Moda', value: 38, categoria: 4, servico: true },
      { text: 'Arquitetura', value: 39, categoria: 4, servico: true },
      { text: 'Design de Mídia', value: 40, categoria: 4, servico: true },
      { text: 'Ilustração e Arte Gráfica', value: 41, categoria: 4, servico: true },
      { text: 'Web Design e Desenvolvimento', value: 42, categoria: 4, servico: true },

      // Moda e Estilo
      { text: 'Roupa Feminina', value: 42, categoria: 5, servico: false },
      { text: 'Roupa Unissex', value: 43, categoria: 5, servico: false },
      { text: 'Roupa Masculina', value: 44, categoria: 5, servico: false },
      { text: 'Roupa Infantil', value: 45, categoria: 5, servico: false },
      { text: 'Ótica', value: 46, categoria: 5, servico: false },
      { text: 'Pronta Entrega', value: 47, categoria: 5, servico: false },
      { text: 'Calçados e Bolsas', value: 48, categoria: 5, servico: false },
      { text: 'Conserto de Roupa', value: 49, categoria: 5, servico: false },
      { text: 'Atelier', value: 50, categoria: 5, servico: true },
      { text: 'Acessórios', value: 51, categoria: 5, servico: false },
      { text: 'Armarinho', value: 52, categoria: 5, servico: false }, // verificar
      { text: 'Roupas Esportivas e Ativewear', value: 53, categoria: 5, servico: false },

      // Saúde e Bem-Estar
      { text: 'Clínica', value: 53, categoria: 6, servico: true }, // verificar
      { text: 'Farmácia', value: 54, categoria: 6, servico: false },
      { text: 'Plano de Saúde', value: 55, categoria: 6, servico: false },
      { text: 'Serviços Médicos', value: 56, categoria: 6, servico: true },
      { text: 'Serviços Odontológico', value: 57, categoria: 6, servico: true },
      { text: 'Laboratório', value: 58, categoria: 6, servico: true },
      { text: 'Posto de Saúde', value: 59, categoria: 6, servico: true },
      { text: 'Nutrição', value: 60, categoria: 6, servico: true },
      { text: 'Farmácia de Manipulação', value: 61, categoria: 6, servico: false },
      { text: 'Fitness', value: 62, categoria: 6, servico: true },

      // Beleza e Estética
      { text: 'Salão de Beleza', value: 63, categoria: 7, servico: true },
      { text: 'Depilação', value: 64, categoria: 7, servico: true },
      { text: 'Perfumaria', value: 65, categoria: 7, servico: false },
      { text: 'Cosméticos', value: 66, categoria: 7, servico: false },
      { text: 'Massagem', value: 67, categoria: 7, servico: true },
      { text: 'Barbearia', value: 68, categoria: 7, servico: true },
      { text: 'Tatuagem', value: 69, categoria: 7, servico: true },
      { text: 'Piercing', value: 70, categoria: 7, servico: true },
      { text: 'Esmalteria', value: 71, categoria: 7, servico: false },
      { text: 'Bronzeamento', value: 72, categoria: 7, servico: true },
      { text: 'Maquiagem', value: 73, categoria: 7, servico: false },
      { text: 'Cuidados com a Pele', value: 74, categoria: 7, servico: true },
      { text: 'Manicure e Pedicure', value: 75, categoria: 7, servico: true },
      { text: 'Spas de Beleza', value: 76, categoria: 7, servico: true },
      { text: 'Cirurgia Plástica Estética', value: 77, categoria: 7, servico: true },
      { text: 'Design de Sobrancelhas e Cílios', value: 77, categoria: 7, servico: true },

      // Educação e Treinamento
      { text: 'Instituições Educacional', value: 78, categoria: 8, servico: true },
      { text: 'Treinamento Corporativo', value: 79, categoria: 8, servico: true },
      { text: 'Educação à Distância', value: 79, categoria: 8, servico: true },
      { text: 'Cursos de Idiomas', value: 80, categoria: 8, servico: true },
      { text: 'Cursos Técnicos', value: 81, categoria: 8, servico: true },
      { text: 'Educação Especial', value: 82, categoria: 8, servico: true },
      { text: 'Faculdade', value: 83, categoria: 8, servico: true },
      { text: 'Livrarias', value: 84, categoria: 8, servico: false },
      { text: 'Transporte Escolar', value: 85, categoria: 8, servico: true },
      { text: 'Escola Ensino Fundamental', value: 86, categoria: 8, servico: true },
      { text: 'Escola Ensino Infantil', value: 87, categoria: 8, servico: true },
      { text: 'Escola Ensino Médio', value: 88, categoria: 8, servico: true },

      // Outros
      { text: 'Lavanderia', value: 89, categoria: 9, servico: true },
      { text: 'Serigrafia', value: 90, categoria: 9, servico: true },
      { text: 'Assistencia Tecnica', value: 91, categoria: 9, servico: true },
      { text: 'Contabilidade', value: 92, categoria: 9, servico: true },
      { text: 'Associação', value: 93, categoria: 9, servico: true },
      { text: 'Eletrica', value: 94, categoria: 9, servico: true },
      { text: 'Eletrônica', value: 95, categoria: 9, servico: true },
      { text: 'Sistema de Segurança', value: 96, categoria: 9, servico: true },
      { text: 'Venda de Gás de Cozinha', value: 97, categoria: 9, servico: false }
    ],
    logo: {
      url_logo: '1'
    },
    imagem: {
      url_imagem: '1'
    },
    dias: [
      {
        dia_semana: 'domingo',
        hora_abertura: '',
        hora_fechamento: '',
        text: 'Domingo',
        cadastrado: false
      },
      {
        dia_semana: 'segunda',
        hora_abertura: '',
        hora_fechamento: '',
        text: 'Segunda',
        cadastrado: false
      },
      {
        dia_semana: 'terca',
        hora_abertura: '',
        hora_fechamento: '',
        text: 'Terça',
        cadastrado: false
      },
      {
        dia_semana: 'quarta',
        hora_abertura: '',
        hora_fechamento: '',
        text: 'Quarta',
        cadastrado: false
      },
      {
        dia_semana: 'quinta',
        hora_abertura: '',
        hora_fechamento: '',
        text: 'Quinta',
        cadastrado: false
      },
      {
        dia_semana: 'sexta',
        hora_abertura: '',
        hora_fechamento: '',
        text: 'Sexta',
        cadastrado: false
      },
      {
        dia_semana: 'sabado',
        hora_abertura: '',
        hora_fechamento: '',
        text: 'Sábado',
        cadastrado: false
      }
    ],
    cropper: false,
    croppersrc: null,
    dia: null,
    empresa_model: {
      razao_social: null,
      nome_fantasia: null,
      email: null,
      endereco: {
        cep: null,
        logradouro: null,
        numero: null,
        bairro: null,
        cidade: null,
        estado: null
      },
      latitude: null,
      longitude: null,
      empresa24horas: false,
      categoria: 1,
      subcategorias: []
    }
  }),
  computed: {
    headers () {
      return this.$store.getters.headers
    },
    empresa () {
      return this.$store.getters.empresa
    },
    loading () {
      return this.$store.getters.loading
    },
    estados () {
      return this.$store.getters.estados
    }
  },
  watch: {
    empresa (val) {
      if (val !== null && val !== undefined) {
        this.detalharempresa()
      }
    }
  },
  methods: {
    toogle24horas () {
      this.$store
        .dispatch('toogle24horas')
    },
    buscarHorarioFuncionamento () {
      this.$store.dispatch('horarioFuncionamento', {
        cnpj: this.empresa.cnpj,
        method: 'GET'
      })
        .then(result => {
          for (var i = 0; i < result.length; i++) {
            const index = this.dias.findIndex(dia => {
              return dia.dia_semana === result[i].dia_semana
            })
            if (index !== -1) {
              this.dias[index] = {
                ...result[i],
                text: this.dias[index].text,
                cadastrado: true
              }
            }
          }
        })
    },
    carregarLogo (event) {
      if (event.target.files && event.target.files[0]) {
        const imagem = event.target.files[0]
        const reader = new FileReader()
        reader.onloadend = e => {
          this.croppingLogo = true
          this.$refs.croppieRef.bind({
            url: reader.result
          })
          this.$nextTick(() => {
            this.$refs.croppieRef.setZoom(0.1)
          })
        }
        reader.readAsDataURL(imagem)
      }
    },
    carregarCapa (event) {
      if (event.target.files && event.target.files[0]) {
        const imagem = event.target.files[0]
        const reader = new FileReader()
        reader.onloadend = e => {
          this.croppingCapa = true
          this.$refs.croppieCapaRef.bind({
            url: reader.result
          })
          this.$nextTick(() => {
            this.$refs.croppieCapaRef.setZoom(0.1)
          })
        }
        reader.readAsDataURL(imagem)
      }
    },
    recortarLogo () {
      const options = {
        type: 'base64',
        format: 'png',
        circle: true
      }
      this.$refs.croppieRef.result(options, output => {
        this.cropped = output
        this.croppingLogo = false
        this.inserirImagem(output, 'logo')
      }).then(() => {
        this.$store.dispatch('escolherEmpresa', this.empresa)
      })
    },
    recortarCapa () {
      const options = {
        type: 'base64',
        format: 'png'
      }
      this.$refs.croppieCapaRef.result(options, output => {
        this.croppedCapa = output
        this.croppingCapa = false
        this.inserirImagem(output, 'capa')
      })
    },
    async inserirImagem (imagem, text) {
      const cnpj = this.empresa_model.cnpj
      let url
      await this.$store.dispatch('inserirFirebase', {
        prefixo: `empresa_${text}`,
        id: cnpj,
        arquivo: imagem,
        local: 'empresas',
        tipo: 'png'
      }).then(downloadUrl => {
        url = downloadUrl
      })
      const data = { cnpj: cnpj }
      if (text === 'logo') {
        data.url_logo = url
      } else {
        data.url_imagem_posto = url
      }
      await this.$store.dispatch('atualizarImagemEmpresa', data).then(() => {
        this.$store.dispatch('setSuccess', 'As informações da empresa foram alteradas com sucesso')
        this.$store.dispatch('escolherEmpresa', this.empresa)
      })
    },
    detalharempresa () {
      this.$store.dispatch('detalharEmpresa').then(json => {
        this.empresaEndereco = json
        this.montarDados()
        this.buscarHorarioFuncionamento()
      })
    },
    cadastrarHorarios () {
      var erro = false

      this.dias.forEach(dia => {
        if ((dia.hora_fechamento === '' && dia.hora_abertura !== '') || (dia.hora_fechamento !== '' && dia.hora_abertura === '')) {
          erro = true
        }

        if (
          dia.hora_abertura.length === 4 &&
          dia.hora_fechamento.length === 4
        ) {
          dia.hora_abertura = [
            dia.hora_abertura.slice(0, 2),
            dia.hora_abertura.slice(2, 4)
          ].join(':')
          dia.hora_fechamento = [
            dia.hora_fechamento.slice(0, 2),
            dia.hora_fechamento.slice(2, 4)
          ].join(':')
          dia.cnpj = this.empresa.cnpj
          dia.method = 'POST'
          this.$store.dispatch('horarioFuncionamento', dia).then(() => {
            dia.cadastrado = true
            this.$forceUpdate()
          })
        } else if (
          dia.hora_abertura.length > 4 &&
          dia.hora_fechamento.length > 4
        ) {
          dia.hora_abertura = [
            dia.hora_abertura.split(':')[0],
            dia.hora_abertura.split(':')[1]
          ].join(':')
          dia.hora_fechamento = [
            dia.hora_fechamento.split(':')[0],
            dia.hora_fechamento.split(':')[1]
          ].join(':')
          dia.cnpj = this.empresa.cnpj
          dia.method = 'POST'
          this.$store.dispatch('horarioFuncionamento', dia).then(() => {
            dia.cadastrado = true
            this.$forceUpdate()
          })
        } else if (dia.hora_abertura === '' && dia.hora_fechamento === '' && dia.cadastrado) {
          dia.cnpj = this.empresa.cnpj
          dia.method = 'POST'
          this.$store.dispatch('horarioFuncionamento', dia).then(() => {
            dia.cadastrado = false
            this.$forceUpdate()
          })
        }
      })

      if (erro) {
        this.$store.commit('setError', 'Desculpe, não foi possível atualizar os dados')
      } else {
        this.$store.commit('setSuccess', 'Horário de funcionamento atualizado com sucesso')
      }
    },
    async searchCep () {
      this.carregando = true
      const cep = this.empresa_model.endereco.cep.replace(/[^\d]+/g, '')
      if (!cep || cep.length !== 8) {
        this.carregando = false
        return
      }
      await fetch(`https://viacep.com.br/ws/${cep}/json/`, {
        method: 'get',
        mode: 'cors',
        headers: {
          Accept: 'application/json'
        }
      })
        .then(res => res.json())
        .then(json => {
          this.empresa_model.endereco.logradouro = json.logradouro
          this.empresa_model.endereco.bairro = json.bairro
          this.empresa_model.endereco.cidade = json.localidade
          this.empresa_model.endereco.estado = json.uf
        })
      this.carregando = false
    },
    async abrirGeoCordenadas () {
      this.geoPositionQuestion = false
      await this.$emit('atualizarGeoPosition')
      try {
        await this.$store.dispatch('pegarCoordenadas', {
          logradouro: this.empresa_model.logradouro,
          numero: this.empresa_model.numero,
          bairro: this.empresa_model.bairro,
          cidade: this.empresa_model.cidade,
          estado: this.empresa_model.uf
        }).then(json => {
          this.res = json
          this.coordenadas.latitude = json.latitude
          this.coordenadas.longitude = json.longitude
        })
      } catch {
        this.$store.dispatch('setError', 'Não foi possível pegar coordenadas para esse endereço.')
      }
      this.$store.dispatch('cadastroPostoByClient', {
        empresa: this.empresa_model
      })
    },
    alterarempresa () {
      if (this.$refs.form.validate()) {
        this.carregando = true
        if (this.empresa_model.endereco.cep) this.empresa_model.endereco.cep = this.empresa_model.endereco.cep.replace(/[^\d]+/g, '')
        if (this.empresa_model.contato1) this.empresa_model.contato1 = this.empresa_model.contato1.replace(/[^\d]+/g, '')
        if (this.empresa_model.contato2) this.empresa_model.contato2 = this.empresa_model.contato2.replace(/[^\d]+/g, '')
        if (this.empresa_model.contato3) this.empresa_model.contato3 = this.empresa_model.contato3.replace(/[^\d]+/g, '')
        this.$store.dispatch('editarEmpresa', this.empresa_model)
          .then(() => {
            this.$store.dispatch('escolherEmpresa', this.empresa_model)
            this.carregando = false
          })
      }
    },
    montarDados () {
      this.empresa_model = { ...this.empresaEndereco }
      this.empresa_model.endereco = { ...this.empresaEndereco.endereco }
    }
  },
  mounted () {
    if (this.empresa) {
      if (!this.empresaEndereco) {
        this.detalharempresa()
      }
    }
  }
}
</script>
