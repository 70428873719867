<template>
  <v-stepper v-model="step">
    <v-stepper-header>
      <v-stepper-step :complete="step > 1" step="1">Solictiar aumento do saldo</v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="step > 2" step="2" v-if="proposta.modelo_faturamento === 'prepago'">Anexar comprovante</v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3" v-if="proposta.modelo_faturamento === 'prepago'">Pagamento cartão</v-stepper-step>

    </v-stepper-header>
    <v-stepper-items>
      <!-----------------tela 1------------------------->
      <v-stepper-content step="1" class="pa-0">
      <v-card>
          <v-toolbar color="green" dark>
              <v-toolbar-title>Aumentar saldo da proposta</v-toolbar-title>
          </v-toolbar>
          <v-card-text>

              <div v-if="proposta.modelo_faturamento === 'prepago' && meios_de_recebimento.length == 0">
                <div class="headline">Escolha um das opções para pagamento: </div>
                <v-flex xs12 class="text-xs-left pt-2"> Posto não informou os meios de pagamento! </v-flex>
              </div>
              <div v-else-if="proposta.modelo_faturamento === 'prepago' && meios_de_recebimento.length > 0">
                <v-form
                  ref="formRecarga"
                  v-model="valid"
                  lazy-validation
                >
                  <v-select
                    v-model="select"
                    :items="meios_de_recebimento"
                    label="Forma de pagamento"
                    item-text="descricao"
                    required
                    return-object
                  ></v-select>
                  <v-flex xs12 class="text-xs-left pt-2">
                      <v-text-field
                        prefix="R$"
                        label="Informe o valor da recarga"
                        v-money="money"
                        :rules="[valorRules.required]"
                        v-model.lazy="valor"
                        hint="Valor precisa ser maior do que 0"
                        counter
                        />
                  </v-flex>
              </v-form>
            </div>
            <div v-else>
              <v-flex xs12 class="text-xs-left pt-2">
                  <v-text-field
                    prefix="R$"
                    label="Informe o valor da recarga"
                    v-money="money"
                    :rules="[valorRules.required]"
                    v-model.lazy="valor"
                    hint="Valor precisa ser maior do que 0"
                    counter
                    />
                </v-flex>
            </div>
          </v-card-text>
          <v-card-actions>
              <v-btn color="red" @click="$emit('fechar')">Fechar</v-btn>
                <v-spacer></v-spacer>
              <v-btn color="primary" @click="passarTela()">{{ proposta.modelo_faturamento === 'prepago' ? 'Próximo Passo' : 'Solicitar' }}</v-btn>
            </v-card-actions>
          </v-card>
      </v-stepper-content>
      <!-----------------fim tela 1------------------------->
      <v-stepper-content step="2" class="pa-0" v-if="proposta.modelo_faturamento == 'prepago'">
        <v-card>
        <v-card-text>
            <v-layout row wrap justify-space-around fill-height>

                  <v-flex
                        row
                        wrap
                        align-center
                        justify-center
                        pb-5
                        pt-5>
                        <h4 style="width: 100%; text-align: center; padding-buttom: 5px;" v-if="select.chave_pix">Chave PIX: {{select.chave_pix}}</h4>
                        <h4 style="width: 100%; text-align: center; padding-buttom: 5px;" v-if="select.cod_boleto">Código do Boleto: {{select.cod_boleto}}</h4>
                        <h4 style="width: 100%; text-align: center; padding-buttom: 5px;" v-if="select.banco">{{select.banco}}</h4>
                        <h5 style="width: 100%; text-align: center; padding-buttom: 5px;" v-if="select.agencia">Agencia: {{select.agencia}} </h5>
                        <h5 style="width: 100%; text-align: center; padding-buttom: 5px;" v-if="select.conta">Conta: {{select.conta}}</h5>
                        <h5 style="width: 100%; text-align: center; padding-buttom: 5px;" v-if="select.tipo_conta">{{tipos_conta[select.tipo_conta - 1].descricao }}</h5>
                        <!-- <h4 style="width: 100%; text-align: center; padding-buttom: 5px;" v-if="select.tipo == 4">Aceita cartão de crédito</h4>
                        <h4 style="width: 100%; text-align: center; padding-buttom: 5px;" v-if="select.tipo == 5">Aceita cartão de débito</h4> -->
                      <template
                        v-if="
                          !recarga_credito.comprovante_pagamento ||
                          imagemAnexada ||
                          arquivoAnexado
                        "
                      >
                        <div>
                          <img
                            v-if="imagemAnexada"
                            :src="imagemAnexada"
                            alt="imagem do comprovante"
                            width="300"
                            @click="$refs.imageInput.click()"
                          />
                          <object
                            v-if="arquivoAnexado"
                            width="300"
                            height="300"
                            type="application/pdf"
                            :data="arquivoAnexado"
                            @click="$refs.imageInput.click()"
                          ></object>
                          <v-card
                            v-if="!imagemAnexada && !arquivoAnexado"
                            color="grey"
                            width="300"
                            height="300"
                            @click="$refs.imageInput.click()"
                          >
                            <v-layout justify-center align-center fill-height>
                              <div
                                class="title white--text pa-4 text-xs-center"
                              >
                                Clique para adicionar Comprovante
                              </div>
                            </v-layout>
                          </v-card>
                        </div>
                      </template>
                      <template v-else>
                        <div v-if="!imagemAnexada && !arquivoAnexado">
                          <object
                            v-if="
                              this.recarga_credito.comprovante_pagamento.indexOf(
                                'png'
                              ) === -1
                            "
                            width="400"
                            height="400"
                            type="application/pdf"
                            :data="fatura.comprovante_pagamento"
                            @click="$refs.imageInput.click()"
                          ></object>
                          <img
                            v-else
                            alt="imagem do comprovante"
                            :src="this.fatura.comprovante_pagamento"
                            width="300"
                            @click="$refs.imageInput.click()"
                          />
                        </div>
                      </template>

                      <input
                        type="file"
                        style="display: none"
                        accept="image/pdf*"
                        ref="imageInput"
                        @input="anexar"
                      />
                  </v-flex>
            </v-layout>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!imagemAnexada && !arquivoAnexado"
              class="mb-5"
              color="primary"
              @click="$refs.imageInput.click()"
              >Anexar</v-btn
              >
              <v-btn v-else class="mb-3" color="primary" @click="inserir()"
              >Confirmar</v-btn
            >
            <v-spacer></v-spacer>
        </v-card-actions>
        </v-card>
      </v-stepper-content>
      <v-stepper-content step="3" class="pa-0" v-if="proposta.modelo_faturamento == 'prepago'">
        <v-card>
          <v-container fluid grid-list-md>
                <v-slide-y-transition>
                  <v-layout column>
                    <v-flex
                      class="text-xs-center headline gray--text font-weight-medium"
                      >

                      <v-form
                        ref="formCreditCard"
                        v-model="valid"
                        lazy-validation>
                        <v-layout row wrap>
                            <v-row>
                            <v-col cols="15" md="12">
                                <v-select
                                  :items="desserts"
                                  :rules="[rules.required]"
                                  item-text="final"
                                  label="Final do cartão"
                                  item-value="id_cartao"
                                  v-model="selectCartao"
                                  required
                            ></v-select>
                            </v-col>
                            <v-col cols="15" md="12">
                            <v-text-field
                                v-model="cvv"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.required, rules.min]"
                                :type="show1 ? 'text' : 'password'"
                                name="input-10-1"
                                label="CVV"
                                hint="Pelo menos 3 caracteres"
                                counter
                                @click:append="show1 = !show1"
                              ></v-text-field>
                              </v-col>
                              </v-row>
                            </v-layout>
                        </v-form>
                    </v-flex>
                  </v-layout>
                </v-slide-y-transition>
              </v-container>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn
                  color="red darken-1"
                  text
                  @click="cancelar()"
                  >Cancelar Recarga</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="finalizarCompra()"
                  >Confirmar Recarga</v-btn
                >
              </v-card-actions>
        </v-card>
      </v-stepper-content>
      <v-stepper-content step="4" class="pa-0">
            <v-card>
              <v-container fluid grid-list-md>
                <v-slide-y-transition>
                  <v-layout column>
                  <v-flex v-if="select.tipo == 4"
                      xs8
                      class="text-xs-center subheading green--text font-weight-regular"
                    >
                      Recarga aprovada! Via cartão de crédito
                    </v-flex>
                    <v-flex v-else-if="select.tipo == 5"
                      xs8
                      class="text-xs-center subheading red--text font-weight-regular"
                    >
                      Recarga via cartão de dédito não disponível!
                    </v-flex>
                    <v-flex v-else
                      xs8
                      class="text-xs-center subheading red--text font-weight-regular"
                    >
                      {{ proposta.modelo_faturamento === 'prepago' ? 'Aguarde o posto aprovar o pagamento' : 'Aguarde o posto aceitar sua solicitação' }}
                    </v-flex>
                  </v-layout>
                </v-slide-y-transition>
              </v-container>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn
                  color="red darken-1"
                  text
                  @click="cancelar()"
                  >Fechar</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>

import { VMoney } from 'v-money'
import { postoDao, pagamentoDao } from '@/api'

export default {
  props: ['proposta'],
  data () {
    return {
      step: 1,
      indoFinalizar: {
        valor_final: null,
        id: null
      },
      mostrar: false,
      valid: false,
      tipos_conta: [
        { descricao: 'Conta Corrente' },
        { descricao: 'Conta Poupança' },
        { descricao: 'Conta Conjunta' },
        { descricao: 'Conta Salário' }
      ],
      selectOpcoes: null,
      aumento_com_cartao: null,
      valor: null,
      valor_formatado: null,
      arquivoAnexado: null,
      imagemAnexada: null,
      recarga_credito: {
        comprovante_pagamento: null
      },
      meios_de_recebimento: [],
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        masked: false
      },
      select: {
        agencia: null,
        banco: null,
        chave_pix: null,
        cod_boleto: null,
        conta: null,
        tipo: null,
        tipos_conta: null
      },
      selectCartao: null,
      cvv: null,
      desserts: [],
      valorRules: {
        required: value => !!value || 'Obrigatório.',
        min: v => v <= 0.0 || 'Valor precisa ser maior do que 0'
      },
      rules: {
        select: [(v) => !!v || 'Item is required']
      },
      show1: false,
      infoPgto: {
        url_pagamento: null,
        id_compra: null,
        user_agent: null,
        nome: null,
        email: null,
        cpf: null,
        data_nascimento: null
      },
      endereco_cobranca: {
        cep: null,
        rua: null,
        num: null,
        complemento: null,
        cidade: null,
        estado: null,
        pais: null,
        bairro: null,
        numero: null
      }
    }
  },
  watch: {
    step (val) {
      if (this.step === 3) {
        this.getCartao()
      }
    },
    valor (val) {
      if (val !== null || val !== undefined) {
        this.valor_formatado = this.valor.split('.').join('')
        this.valor_formatado = this.valor_formatado.split(',').join('.')
        this.valor_formatado = Number(this.valor_formatado)
      }
    }
  },
  beforeMount () {
    if (this.proposta) {
      this.pegarMeiosRebebimento()
    }
  },
  computed: {
    headers () {
      return this.$store.getters.headers
    },
    recarga () {
      return this.$store.getters.recarga
    },
    empresa () {
      return this.$store.getters.empresa
    },
    pessoa () {
      return this.$store.getters.pessoa
    },
    cartoes () {
      return this.$store.getters.cartoes
    },
    hash () {
      return this.$store.getters.hash
    },
    cartaoId () {
      return this.$store.getters.cartao
    },
    infoCompra () {
      let cnpj
      let idRede
      if (this.proposta.posto) {
        cnpj = this.proposta.posto.cnpj
      } else {
        idRede = this.proposta.rede.id_rede
      }
      return {
        empresa: this.empresa.cnpj,
        cnpj_posto: cnpj,
        id_rede: idRede,
        // MerchantId: this.proposta.posto.MerchantId,
        // MerchantKey: this.proposta.posto.MerchantKey,
        id: this.proposta.id,
        valor_compra: parseFloat(this.valor.replace(/,/, '.'))
      }
    },
    infoCompraFinalizar () {
      return {
        url: this.infoPgto.url_pagamento,
        id_compra: this.infoPgto.id_compra,
        id_cartao: this.selectCartao,
        cvv: this.cvv,
        cartao: this.hash,
        user_agent: this.infoPgto.user_agent
      }
    },
    cartao () {
      const cartao = this.$store.getters.cartao
      if (cartao && typeof cartao === 'object') {
        return cartao
      } else if (cartao && typeof cartao === 'string') {
        const idCartao = Number(cartao.split('-')[0])
        return {
          id_cartao: idCartao,
          hash_code: cartao.split('-')[1]
        }
      }
      return this.$store.getters.cartao
    }
  },
  methods: {
    pegarMeiosRebebimento () {
      const posto = this.proposta.posto == null ? this.proposta.rede.matriz : this.proposta.posto
      postoDao.pegarMeioRecebimento(this.headers, posto.cnpj).then(response => {
        if (!response.ok) {
          this.meios_de_recebimento = []
          return false
        } else {
          return response.json()
        }
      }).then(json => {
        this.meios_de_recebimento = json
        return true
      })
    },
    cancelar () {
      this.clearInput()
      this.$emit('fechar')
    },
    solicitarAumentoSaldo () {
      const data = {
        valor_recarga: parseFloat(this.valor),
        proposta: this.proposta.id,
        ativo: true,
        status: this.aumento_com_cartao
      }
      this.$store.dispatch('solicitarAumentoSaldo', data).then(() => {
        this.$emit('carregar')
      })
    },
    passarTela () {
      this.changeValor()
      if (this.proposta.modelo_faturamento === 'prepago' && this.valor > 0 && this.select.tipo != null) {
        if (this.proposta.modelo_faturamento === 'prepago' && this.select.tipo === 4) {
          this.getCartao()
          this.realizarCompra()
          this.step = 3
        } else if (this.proposta.modelo_faturamento === 'prepago' && this.select.tipo === 5) {
          this.step = 4
        } else if (this.proposta.modelo_faturamento === 'prepago' && this.select.tipo !== 4) {
          this.solicitarAumentoSaldo()
          this.step = 2
        } else if (this.proposta.modelo_faturamento !== 'prepago' && this.select.tipo !== 4) {
          this.solicitarAumentoSaldo()
        } else {
          this.step = 4
        }
      } else if (this.proposta.modelo_faturamento === 'pospago' && this.valor > 0) {
        this.solicitarAumentoSaldo()
        this.step = 4
      } else {
        this.$emit('error', 'Para prosseguir preencha todas as informações!')
      }
    },
    anexar (event) {
      this.arquivoAnexado = null
      this.imagemAnexada = null
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onload = event => {
        const arquivo = event.target.result
        if (file.type.indexOf('image/') !== -1) {
          this.imagemAnexada = arquivo
        } else {
          this.arquivoAnexado = arquivo
        }
      }
      if (file) {
        reader.readAsDataURL(file)
      }
    },
    inserir () {
      this.$store.dispatch('inserirArquivoFirebaseRecarga', {
        prefixo: 'comprovante',
        id: this.recarga.id,
        local: 'propostas',
        arquivo: this.imagemAnexada ? this.imagemAnexada : this.arquivoAnexado,
        tipo: this.imagemAnexada ? 'png' : 'pdf'
      }).then(downloadURL => {
        this.$store.dispatch('inserirComprovanteRecarga', {
          comprovante_pagamento: downloadURL,
          id: this.recarga.id
        })
      }).then(() => {
        this.$emit('success', 'Comprovante inserido com sucesso.')
      }).catch(() => {
        this.$emit('error', 'Não foi possível inserir seu comprovante')
      })
      this.step = 4
    },
    getCartao () {
      this.$store.dispatch('getCartao')
        .then(json => {
          this.desserts = json
        })
        .catch(error => {
          this.$emit('error', error)
        })
    },
    getFormaPagamento () {
      const cnpj = this.proposta.posto ? this.proposta.posto.cnpj : this.proposta.rede.matriz.cnpj
      pagamentoDao
        .formaDepagamento(cnpj)
        .then(response => {
          return response.json()
        })
        .then(result => {
          this.infoPgto.url_pagamento = result[0].forma_pagamento.url
        })
    },
    carregarDados () {
      this.infoPgto.user_agent = navigator.userAgent
      this.endereco_cobranca.cep = this.empresa.endereco.cep
      this.endereco_cobranca.rua = this.empresa.endereco.logradouro
      this.endereco_cobranca.num = this.empresa.endereco.numero
      this.endereco_cobranca.numero = this.empresa.endereco.numero
      this.endereco_cobranca.complemento = this.empresa.endereco.complemento
      this.endereco_cobranca.cidade = this.empresa.endereco.cidade
      this.endereco_cobranca.estado = this.empresa.endereco.estado
      this.endereco_cobranca.pais = this.empresa.endereco.pais
      this.endereco_cobranca.bairro = this.empresa.endereco.bairro
    },
    realizarCompra () {
      this.carregarDados()
      this.getFormaPagamento()
      this.$store
        .dispatch('realizarCompra', this.infoCompra, this.headers)
        .then(json => {
          this.infoPgto.id_compra = json.id_compra
        })
        .catch(error => {
          this.$emit('error', error.message)
          this.cancelar()
        })
    },
    finalizarCompra () {
      this.$store
        .dispatch('pagarCartao', this.infoCompraFinalizar)
        .then(json => {
          this.step = 4
          this.proceed = true
          this.aumento_com_cartao = true
          this.changeValor()
          this.solicitarAumentoSaldo()
        })
        .catch(error => {
          this.$emit('error', error.error)
          this.cancelar()
        })
    },
    clearInput () {
      this.valor = null
      this.cvv = null
      this.selectCartao = null
    },
    changeValor () {
      if (this.valor) {
        this.valor = this.valor.split('.').join('')
        this.valor = this.valor.split(',').join('.')
      }
    }
  },
  directives: { money: VMoney }
}
</script>
