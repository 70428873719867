<template>
  <aviso-bloqueio v-if="empresa && (!empresa.ativo || ![4, 5, 6].includes(empresa.tipo_empresa))"/>
  <v-container fluid fill-height v-else>
    <v-layout row wrap>
      <v-flex sm12>
        <v-toolbar
          color="#eee"
          class="elevation-0 secondary--text mb-4"
          style="background: transparent" >
          <v-toolbar-title>Meus Funcionários</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn fab x-small color='info' v-on="on" @click="dialog = true">
                <v-icon>add</v-icon>
              </v-btn>
            </template>
            <span>Adicionar Funcionário</span>
          </v-tooltip>
        </v-toolbar>
        <v-data-table
          hide-default-footer
          :hide-default-header="!funcionarios || !funcionarios.length"
          :items="funcionarios"
          :headers="[
            {
              align: 'left',
              sortable: false
            },
            {
              text: 'Nome',
              align: 'left',
              sortable: false,
              value: 'nome'
            },
            {
              text: 'CPF',
              align: 'left',
              sortable: false,
              value: 'cpf'
            },
            {
              text: 'Matrícula',
              align: 'left',
              sortable: false,
              value: 'matricula'
            },
            {
              text: 'Gerente',
              align: 'center',
              sortable: false
            },
            {
              text: 'Preços',
              align: 'center',
              sortable: false
            },
            {
              text: 'Admissão',
              align: 'center',
              sortable: false
            },
            {
              text: 'Cashback',
              align: 'center',
              sortable: false
            },
            {
              text: 'Acesso',
              align: 'center',
              sortable: false
            },
            {
              align: 'right',
              sortable: false
            }
          ]"
          class="elevation-1"
          :loading="loading"
        >
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              Desculpe, não encontramos nenhum dado!
            </v-alert>
          </template>
          <v-alert v-slot:no-results :value="true" color="error" icon="warning">
              Desculpe, não encontramos nenhum dado!
          </v-alert>
          <template v-slot:item="props">
            <tr>
              <td style="width: 10px">
                <v-avatar
                  color="grey"
                  size="30">
                  <img v-if="props.item.pessoa && props.item.pessoa.url_imagem_perfil" :src="props.item.pessoa.url_imagem_perfil" alt="trevor">
                  <v-icon dark v-else>face</v-icon>
                </v-avatar>
              </td>
              <td>{{ props.item.pessoa ? props.item.pessoa.nome : props.item.nome }}</td>
              <td class="text-xs-left">{{ props.item.pessoa ? props.item.pessoa.cpf : props.item.cpf  }}</td>
              <td class="text-xs-left">{{ props.item.matricula  }}</td>
              <td class="text-xs-center" style="width: 50px">
                <v-layout align-center justify-center>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                       <v-switch
                        class="lean-input"
                        v-on="on"
                        value
                        @click.native.stop="() => ativarGerente(props.item, props.index)"
                        :input-value="props.item.gerente"
                        readonly
                      >
                    </v-switch>
                    </template>
                    Habilite os gerentes
                  </v-tooltip>
                </v-layout>
              </td>
              <td class="text-xs-center" style="width: 50px">
                <v-layout align-center justify-center>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                       <v-switch
                        class="lean-input"
                        v-on="on"
                        value
                        @click.stop="() => ativarControlePrecos(props.item, props.index)"
                        :input-value="props.item.controle_precos"
                        readonly
                      ></v-switch>
                    </template>
                    Permite controlar preços
                  </v-tooltip>
                </v-layout>
              </td>
              <td class="text-xs-center" style="width: 50px">
                <v-layout align-center justify-center>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                       <v-switch
                        readonly
                        class="lean-input"
                        v-on="on"
                        value
                        @click.stop="() => ativarControleAdmissao(props.item, props.index)"
                        :input-value="props.item.controle_frentistas">
                      </v-switch>
                    </template>
                    Permite controlar adminssão de funcionários
                  </v-tooltip>
                </v-layout>
              </td>
              <td class="text-xs-center" style="width: 50px">
                <v-layout align-center justify-center>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                       <v-switch
                          class="lean-input"
                          v-on="on"
                          :input-value="props.item.controle_cashposto"
                          @click.native.stop="() => ativarControleCashback(props.item)"
                          readonly
                        >
                        </v-switch>
                    </template>
                    Permite venda de Cashback para clientes
                  </v-tooltip>
                </v-layout>
              </td>
              <td class="text-xs-center" style="width: 30px">
                <v-layout align-center justify-center>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                       <v-checkbox
                       class="lean-input mt-3 mb-0"
                        v-on="on"
                        value
                        @click.stop="() => !props.item.pessoa ? liberarAcesso(props.item, props.index) : false"
                        :input-value="!!props.item.pessoa"
                        readonly
                      ></v-checkbox>
                    </template>
                    {{ !props.item.pessoa ? 'Conceder acesso a este funcionário' : 'Este funcionário já possui acesso' }}
                  </v-tooltip>
                </v-layout>
              </td>
              <td
                class="text-xs-right"
                style="
                  justify-content: space-around;
                  flex-direction: row;
                  display: flex;
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                      text
                      color="info"
                      @click="
                        funcionario = props.item,
                        dialogEditarFuncionario = true
                      "
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar Informações</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon text color="info" @click="detalhesFuncionario(props.item)">
                      <v-icon>assignment_ind</v-icon>
                    </v-btn>
                  </template>
                  <span>Visualizar Informações</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" width="600" :fullscreen="$vuetify.breakpoint.xs">
      <cad-funcionario @complete="funcionarioSalvo"></cad-funcionario>
    </v-dialog>
    <v-dialog :value="dialogLiberarAcesso" width="400" :fullscreen="$vuetify.breakpoint.xs">
      <liberar-acesso-funcionario :funcionario="funcionarioLiberarAcesso" :empresa="empresa" @complete="funcionarioSalvo"></liberar-acesso-funcionario>
    </v-dialog>
    <v-dialog v-if="funcionario" v-model="dialogDetalheFuncionario" width="400" :fullscreen="$vuetify.breakpoint.xs">
      <v-card color="lighten-3" class="primary--text pe-3">
        <v-card-text>
          <v-layout>
            <v-flex xs3 class="pt-4 pl-4">
              <v-layout align-center justify-center column >
                <v-avatar
                  color="grey"
                  size="60">
                    <img v-if="funcionario.pessoa && funcionario.pessoa.url_imagem_perfil" :src="funcionario.pessoa.url_imagem_perfil" alt="trevor">
                    <v-icon dark v-else>face</v-icon>
                </v-avatar>
              </v-layout>
            </v-flex>
            <v-flex xs9>
              <v-card-title class="text-xs-left">
                <v-layout column justify-start>
                  <div class="headline">{{ funcionario.pessoa ? funcionario.pessoa.nome : funcionario.nome }} {{ funcionario.pessoa ? funcionario.pessoa.sobrenome : funcionario.sobrenome }}</div>
                  <div>CPF: {{ funcionario.pessoa ? funcionario.pessoa.cpf : funcionario.cpf }}</div>
                  <div>Mat.: {{ funcionario.matricula }}</div>
                </v-layout>
              </v-card-title>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-list two-line v-if="funcionario.pessoa">
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="grey">phone</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ funcionario.pessoa.telefone }}</v-list-item-title>
              <v-list-item-subtitle>Telefone principal</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn color="info" icon text>
                <v-icon>chat</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider inset></v-divider>
          <v-list-item>
            <v-list-item-action>
              <v-icon color="grey">mail</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ funcionario.pessoa.email }}</v-list-item-title>
              <v-list-item-subtitle>Email pessoal</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider inset></v-divider>
        </v-list>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn icon text color="red" @click.prevent="() => removerFuncionario(funcionario)">
            <v-icon>delete_forever</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogEditarFuncionario"
      v-if="dialogEditarFuncionario"
      width="35em"
    >
      <editar-funcionario
        :funcionario="funcionario"
        @fechar="
          dialogEditarFuncionario = false,
          funcionario = undefined
        "
        @carregar="carregarFuncionarios"
      />
    </v-dialog>
    <v-dialog
      v-model="dialogAutenticacao"
      v-if="dialogAutenticacao"
      width="35em"
    >
      <autenticacao-por-sms
        :funcionario="funcionario"
        @fechar="dialogAutenticacao = false"
        @callDialog="autenticado = true, ativarControleCashback()"
      />
    </v-dialog>
  </v-container>
</template>

<script>

import CadFuncionario from '@/components/cadastros/CadFuncionario'
import LiberarAcessoFuncionario from '@/components/cadastros/LiberarAcessoFuncionario'
import EditarFuncionario from '@/components/dialogs/EditarFuncionario'
import AutenticacaoPorSms from '@/components/dialogs/SmartLock'
import AvisoBloqueio from '@/components/AvisoBloqueio'

export default {
  components: {
    CadFuncionario,
    LiberarAcessoFuncionario,
    EditarFuncionario,
    AutenticacaoPorSms,
    AvisoBloqueio
  },
  data () {
    return {
      dialogDetalheFuncionario: false,
      dialogEditarFuncionario: false,
      dialogAutenticacao: false,
      autenticado: false,
      dialog: false,
      funcionarioLiberarAcesso: null,
      totalItems: 0,
      funcionario: null,
      list_Usuarios: []
    }
  },
  computed: {
    dialogLiberarAcesso () {
      return !!this.funcionarioLiberarAcesso
    },
    empresa () {
      return this.$store.getters.empresa
    },
    funcionarios () {
      return this.$store.getters.frentistas
    },
    loading () {
      return this.$store.getters.loading
    }
  },
  beforeMount () {
    if (this.empresa) {
      this.carregarFuncionarios()
    }
  },
  watch: {
    empresa (val) {
      if (val) {
        this.carregarFuncionarios()
      }
    },
    dialogDetalheFuncionario (val) {
      if (val === false) {
        this.funcionario = null
      }
    }
  },
  methods: {
    detalhesFuncionario (funcionario) {
      this.funcionario = funcionario
      this.dialogDetalheFuncionario = true
    },
    carregarFuncionarios () {
      this.$store.dispatch('frentistas', {
        empresa: this.empresa.cnpj,
        tudo: true
      })
    },
    fecharDialogFuncionario () {
      this.dialog = false
    },
    funcionarioSalvo () {
      this.carregarFuncionarios()
      this.funcionarioLiberarAcesso = null
      this.fecharDialogFuncionario()
    },
    removerFuncionario (funcionario) {
      this.$store.dispatch('removerFrentista', {
        id: funcionario.id_frentista
      }).then(() => {
        this.dialogDetalheFuncionario = false
        this.carregarFuncionarios()
      })
    },
    ativarGerente (funcionario) {
      if (!funcionario.pessoa) {
        this.$store.dispatch('setError', 'Para ser gerente, o funcionário precisar ter acesso concedido.')
        return
      }
      this.$store.dispatch('editarFrentista', {
        id_frentista: funcionario.id_frentista,
        empresa: this.empresa.cnpj,
        gerente: !funcionario.gerente
      }).catch((e) => {
        alert(e.message)
      })
    },
    ativarControlePrecos (funcionario) {
      if (!funcionario.pessoa) {
        this.$store.dispatch('setError', 'Para ter controle sobre os preços, o funcionário precisar ter acesso concedido.')
        return
      }
      this.$store.dispatch('editarFrentista', {
        id_frentista: funcionario.id_frentista,
        empresa: this.empresa.cnpj,
        controle_precos: !funcionario.controle_precos
      }).catch((e) => {
        alert(e.message)
      })
    },
    ativarControleAdmissao (funcionario) {
      if (!funcionario.pessoa) {
        this.$store.dispatch('setError', 'Para ter controle de admissão, o funcionário precisar ter acesso concedido.')
        return
      }
      this.$store.dispatch('editarFrentista', {
        id_frentista: funcionario.id_frentista,
        empresa: this.empresa.cnpj,
        controle_frentistas: !funcionario.controle_frentistas
      }).catch((e) => {
        alert(e.message)
      })
    },
    liberarAcesso (funcionario) {
      this.funcionarioLiberarAcesso = funcionario
    },
    ativarControleCashback (funcionario) {
      if (this.autenticado) {
        this.$store.dispatch('editarFrentista', {
          id_frentista: this.funcionario.id_frentista,
          empresa: this.empresa.cnpj,
          controle_cashposto: !this.funcionario.controle_cashposto
        }).then(() => {
          this.funcionario = null
          this.dialogAutenticacao = false
          this.autenticado = false
          this.carregarFuncionarios()
        })
      } else {
        this.funcionario = funcionario
        this.dialogAutenticacao = true
      }
    }
  }
}
</script>
<style>
  .lean-input, .lean-input .v-input--selection-controls__input {
    margin-right: 0;
    text-align: center;
  }
</style>
