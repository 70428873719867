import settings from '@/settings'

// Pega redes ativos
const custoPorPosto = (headers, data) => {
  return fetch(`${settings.apetrusApi}/dashboard/custoporpostos/?cnpj=${data}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

export default {
  custoPorPosto
}
