import settings from '@/settings'

const detalharEmpresa = (cnpjPosto, headers) => {
  return fetch(`${settings.apetrusApi}/empresas/?cnpj=${cnpjPosto}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const minhasEmpresas = (headers, data) => {
  let url = `${settings.apetrusApi}/empresas/minhasempresas/`
  if (data) {
    Object.keys(data).forEach((filter) => {
      url = `${url}${url[url.length - 1] === '/' ? '?' : '&'}${filter}=${data[filter]}`
    })
  }
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const inserirByClient = (posto, headers) => {
  return fetch(`${settings.apetrusApi}/empresas/inserir_pelo_cliente/`, {
    method: 'POST',
    body: JSON.stringify(posto),
    mode: 'cors',
    headers: headers
  })
}

const geolocateEmpresa = (empresa, headers) => {
  return fetch(`${settings.apetrusApi}/empresas/atualizarlocalizacao/`, {
    method: 'PUT',
    body: JSON.stringify(empresa),
    mode: 'cors',
    headers: headers
  })
}

const editarEmpresa = (cnpj, empresa, headers) => {
  return fetch(`${settings.apetrusApi}/empresas/editarempresa/?cnpj=${cnpj}`, {
    method: 'PUT',
    body: JSON.stringify(empresa),
    mode: 'cors',
    headers: headers
  })
}

const aceitarTermos = (cnpj, headers) => {
  return fetch(`${settings.apetrusApi}/empresas/aceitartermos/?cnpj=${cnpj}`, {
    method: 'PUT',
    mode: 'cors',
    headers: headers
  })
}

const atualizarImagem = (cnpj, data, headers) => {
  return fetch(`${settings.apetrusApi}/empresas/atualizar/${cnpj}/`, {
    method: 'PUT',
    mode: 'cors',
    body: JSON.stringify(data),
    headers: headers
  })
}

const relatorioPropostas = (filtros, cnpj, headers, pagination) => {
  const keys = Object.keys(filtros)
  let url = `${settings.apetrusApi}/empresas/relatoriopropostas/?page=${pagination.page}&empresa=${cnpj}`
  for (let i = 0; i < keys.length; i++) {
    url = `${url}&${keys[i]}=${filtros[keys[i]]}`
  }
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const cadastrarpin = (cnpj, pin, headers) => {
  return fetch(`${settings.apetrusApi}/empresas/smartlock/`, {
    method: 'POST',
    body: JSON.stringify({
      pin, cnpj
    }),
    mode: 'cors',
    headers: headers
  })
}

const autenticarPin = (cnpj, pin, headers) => {
  return fetch(`${settings.apetrusApi}/empresas/smartlock/`, {
    method: 'PUT',
    body: JSON.stringify({
      cnpj, pin
    }),
    mode: 'cors',
    headers: headers
  })
}

const cadastrarPin = (data, headers) => {
  // Cadastrar e atualiza PIN
  return fetch(`${settings.apetrusApi}/empresas/smartlock/`, {
    method: 'POST',
    body: JSON.stringify(
      data
    ),
    mode: 'cors',
    headers: headers
  })
}

const ativarPin = (cnpj, headers) => {
  // Ativar ou desativar smartlock
  return fetch(`${settings.apetrusApi}/empresas/smartlock/`, {
    method: 'PATCH',
    body: JSON.stringify({ cnpj }),
    mode: 'cors',
    headers: headers
  })
}

const criarCarteiraVirtual = (cnpj, headers) => {
  return fetch(`${settings.apetrusApi}/carteiravirtual/criar/${cnpj}/?is_posto=${false}`, {
    method: 'POST',
    body: JSON.stringify({ cnpj: cnpj }),
    mode: 'cors',
    headers: headers
  })
}

const pegarCarteiraVirtual = (cnpj, headers) => {
  return fetch(`${settings.apetrusApi}/carteiravirtual/${cnpj}/?is_posto=${false}`, {
    method: 'GET',
    cache: 'default',
    mode: 'cors',
    headers: headers
  })
}

const pegarHistoricoDaCarteiraVirtual = (headers, filtros) => {
  let url = `${settings.apetrusApi}/carteiravirtual/historico/${filtros.id_carteira}/`
  if (filtros) {
    Object.keys(filtros).forEach((filter) => {
      url = `${url}${url[url.length - 1] === '/' ? '?' : '&'}${filter}=${filtros[filter]}`
    })
  }
  return fetch(url, {
    method: 'GET',
    cache: 'default',
    mode: 'cors',
    headers: headers
  })
}

const transferirSaldo = (payload, headers) => {
  return fetch(`${settings.apetrusApi}/empresas/transferirsaldo/${payload.cnpj_pagador}/`, {
    method: 'POST',
    body: JSON.stringify({ is_posto: payload.is_posto, cpf: payload.cpf, valor: payload.valor, cnpj_receptor: payload.cnpj_receptor }),
    mode: 'cors',
    headers: headers
  })
}

const empresasAtivas = (headers, filter) => {
  let url = `${settings.apetrusApi}/empresas/todasAtivas/`
  if (filter) {
    const keys = Object.keys(filter)
    for (let i = 0; i < keys.length; i++) {
      url = `${url}${url[url.length - 1] === '/' ? '?' : '&'}${keys[i]}=${filter[keys[i]]}`
    }
  }
  return fetch(url, {
    method: 'GET',
    cache: 'default',
    mode: 'cors',
    headers: headers
  })
}

const verificacaoVeiculo = (headers, cnpj) => {
  return fetch(`${settings.apetrusApi}/empresas/${cnpj}/verificacao/veiculos/`, {
    method: 'PATCH',
    mode: 'cors',
    headers: headers
  })
}

const verificacaoFuncionario = (headers, cnpj) => {
  return fetch(`${settings.apetrusApi}/empresas/${cnpj}/verificacao/funcionarios/`, {
    method: 'PATCH',
    mode: 'cors',
    headers: headers
  })
}

const solicitarRetiradaDeSaldo = (headers, cnpj, payload) => {
  return fetch(`${settings.apetrusApi}/empresas/retiradadesaldo/${cnpj}/`, {
    method: 'POST',
    body: JSON.stringify({ valor: payload }),
    mode: 'cors',
    headers: headers
  })
}

const cadastrarChavePix = (headers, payload) => {
  return fetch(`${settings.apetrusApi}/empresas/editarpix/`, {
    method: 'PUT',
    body: JSON.stringify({
      cnpj: payload.cnpj,
      tipo_chave_pix: payload.tipo_chave_pix,
      chave_pix: payload.chave_pix
    }),
    mode: 'cors',
    headers: headers
  })
}

const cadastrarFilial = (headers, payload) => {
  return fetch(`${settings.apetrusApi}/empresas/filial/`, {
    method: 'POST',
    body: JSON.stringify(payload),
    mode: 'cors',
    headers: headers
  })
}

const listarfiliais = (headers, filter) => {
  const keys = Object.keys(filter)
  let url = `${settings.apetrusApi}/empresas/listarfiliais/?`
  for (let i = 0; i < keys.length; i++) {
    url = `${url}${url[url.length - 1] === '?' ? '' : '&'}${keys[i]}=${filter[keys[i]]}`
  }
  return fetch(url, {
    method: 'GET',
    cache: 'default',
    mode: 'cors',
    headers: headers
  })
}

const update24horas = (empresa, headers) => {
  return fetch(`${settings.apetrusApi}/empresas/atualizar_empresa_24_horas/?empresa=${empresa}`, {
    method: 'PUT',
    mode: 'cors',
    headers: headers
  })
}

const redeSocial = (headers, payload) => {
  const data = {
    method: payload.method || 'GET',
    mode: 'cors',
    headers: headers
  }
  if (payload.method === 'GET') data.cache = 'default'
  else data.body = JSON.stringify(payload)
  return fetch(`${settings.apetrusApi}/empresas/redesocial/${payload.cnpj}/`, data)
}

const horarioFuncionamento = (headers, payload) => {
  const data = {
    method: payload.method || 'GET',
    mode: 'cors',
    headers: headers
  }
  if (payload.method === 'GET') data.cache = 'default'
  else data.body = JSON.stringify(payload)
  return fetch(`${settings.apetrusApi}/empresas/horariofuncionamento/${payload.cnpj}/`, data)
}

const detalhar = (headers, cnpj) => {
  return fetch(`${settings.apetrusApi}/empresas/detalhar/${cnpj}/`, {
    method: 'GET',
    cache: 'default',
    mode: 'cors',
    headers: headers
  })
}

export default {
  detalharEmpresa,
  minhasEmpresas,
  inserirByClient,
  geolocateEmpresa,
  aceitarTermos,
  editarEmpresa,
  atualizarImagem,
  cadastrarpin,
  autenticarPin,
  relatorioPropostas,
  cadastrarPin,
  ativarPin,
  criarCarteiraVirtual,
  pegarCarteiraVirtual,
  pegarHistoricoDaCarteiraVirtual,
  transferirSaldo,
  empresasAtivas,
  verificacaoVeiculo,
  verificacaoFuncionario,
  solicitarRetiradaDeSaldo,
  cadastrarChavePix,
  cadastrarFilial,
  listarfiliais,
  redeSocial,
  horarioFuncionamento,
  update24horas,
  detalhar
}
