<template>
  <v-card>
    <v-card-title class="justify-center">Notificações de Novos Veículos</v-card-title>
    <v-card-text>
      <v-layout wrap justify-space-around>
        <v-flex xs9>
          <v-switch
            slot="activator"
            class="ml-4"
            v-model="notificacoesAtiva"
            color="info"
            label="Receber Notificações de Novos Veículos cadastrados"
          >
          </v-switch>
        </v-flex>
        <template v-if="notificacoesAtiva">
          <v-layout justify-end>
            <v-btn icon color="info" @click="adicionar = !adicionar"><v-icon>add</v-icon></v-btn>
          </v-layout>
          <v-card v-if="adicionar" class="mb-4" elevation="4">
            <v-card-text class="pt-4 mt-4">
              <v-layout wrap justify-space-around>
                <v-flex xs5>
                  <v-text-field
                    label="Marca"
                    v-model="marca"
                  ></v-text-field>
                </v-flex>
                <v-flex xs5>
                  <v-text-field
                    label="Modelo"
                    v-model="modelo"
                  ></v-text-field>
                </v-flex>
                <v-flex xs5>
                  <v-text-field
                    label="Ano do veículo"
                    v-model="ano"
                  ></v-text-field>
                </v-flex>
                <v-flex xs5>
                  <v-text-field
                    label="Preço do veículo"
                    v-money="money"
                    v-model.lazy="preco"
                    prefix="R$"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-btn block color="info">Cadastrar</v-btn>
            </v-card-actions>
          </v-card>
          <v-card style="width: 100%">
            <v-list style="width: 100%" class="mb-0">
              <v-list-item v-if="veiculos.length == 0">
                <v-list-item-content>
                  <v-list-item-title style="width: 100%; text-align: center;">Sem veículos cadastrados</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-for="(v, index) in veiculos" :key="index">
                <v-list-item-icon>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" :color="formatarCor(v.cor)">{{getIcon(v.tipo_veiculo)}}</v-icon>
                    </template>
                    Cor {{v.cor_texto}}
                  </v-tooltip>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{v.modelo_marca}}</v-list-item-title>
                  <v-list-item-subtitle>Ano: {{v.ano_fabricacao}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        icon
                        @click="veiculoSelecionado = undefined, carregarVeiculos(false)"
                      >
                        <v-icon>close</v-icon>
                      </v-btn>
                    </template>
                    <span>Remover Veículo</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </template>
      </v-layout>
    </v-card-text>
    <v-card-actions>
      <v-layout column>
        <v-btn block class="mb-3" color="info">Salvar</v-btn>
        <v-btn block>Fechar</v-btn>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import { VMoney } from 'v-money'
export default {
  directives: { money: VMoney },
  data: () => ({
    notificacoesAtiva: false,
    veiculos: [],
    adicionar: false,
    preco: '0,00',
    modelo: '',
    marca: '',
    ano: '',
    money: {
      decimal: ',',
      thousands: '.',
      precision: 2,
      masked: false
    }
  }),
  methods: {
    carregarVeiculos () {},
    getIcon (tipo) {
      if (tipo && typeof tipo === 'object') tipo = tipo.denominacao
      if (tipo === 'Passageiro' || tipo === 'Automóvel' || tipo === 'Outros') return 'airport_shuttle'
      else if (tipo === 'Caminhão') return 'local_shipping'
      else if (tipo === 'Caminhão trator') return 'agriculture'
      else if (tipo === 'Caminhonete') return 'agriculture'
      else if (tipo === 'Ciclomotor' || tipo === 'Motoneta' || tipo === 'Motocicleta' || tipo === 'Triciclo') return 'two_wheeler'
      else if (tipo === 'Micro-ônibus' || tipo === 'Ônibus') return 'directions_bus'
      return 'airport_shuttle'
    },
    formatarCor (cor) {
      if (cor === null) return ''
      if (cor === undefined) return ''
      if (cor.length === 6) return `#${cor}`
      if (cor.length === 7) return cor
      if (cor.length === 9) return cor.slice(0, 7)
      else {
        let teste = parseInt(cor)
        teste = teste.toString(16)
        return `#${teste.slice(2, 8)}`
      }
    }
  }
}
</script>
