<template>
  <v-card>
    <v-toolbar
      dense
      dark
      color="primary"
    >
      <v-toolbar-title>
        Aumentar crédito do funionário
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
    <div class="my-1 subtitle-1 black--text">{{ credito.funcionario ? `Nome: ${credito.funcionario.nome_email}` : `Placa: ${credito.veiculo}` }} </div>
    <div class="my-1 subtitle-1 black--text">Crédito Atual: {{credito.valor - credito.valor_usado}} </div>
            <v-text-field
              class="mt-3 mb-3"
              v-model.lazy="valor"
              outline
              label="Valor do aumento"
              prefix="R$"
              hide-details
              v-money="money"
            ></v-text-field>
    </v-card-text>
    <v-card-actions align-content-space-between>
      <v-btn color="grey" text @click="fechar">Cancelar</v-btn>
      <v-spacer></v-spacer>
      <v-btn :disabled="valor < 1 " color="green darken-1" text @click="aumentarCredito">Confirmar</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>

import { VMoney } from 'v-money'

export default {
  props: ['credito'],
  data () {
    return {
      valor: 0.00,
      error: null,
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        masked: false
      }
    }
  },
  methods: {
    aumentarCredito () {
      if (this.valor) {
        this.valor = this.valor.split('.').join('')
        this.valor = this.valor.split(',').join('.')
      }

      this.$store.dispatch('aumentarCreditoFuncionario', {
        credito: this.credito,
        valor_aumentar: this.valor
      }).then(() => {
        this.fechar()
      })
    },
    fechar () {
      this.valor = 0.00
      this.error = null
      this.$emit('carregar')
      this.$emit('fechar')
    }
  },
  directives: { money: VMoney }
}
</script>
