<template>
  <v-container fluid class="pa-0" fill-height>
    <div v-if="error" class="form-error">
      <v-dialog width="400px" persistent v-if="error" :value="hasError">
        <v-card>
          <v-card-text class="error--text headline font-weight-medium text-xs-center">
            <span>{{ error.message }}</span>
          </v-card-text>
          <v-card-actions>
            <v-btn block text color="secondary" @click="fecharDialogError">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <template>
      <v-layout column fill-height>
        <v-img
          :src="require('@/assets/background.svg')"
          cover
          fill-height
          style="height: 200px"
          position="top right"
        >
          <v-layout column fill-height>
            <template v-if="true">
              <v-layout column fill-height align-center>
                <center>
                  <img class="logo pr-4" :src="require('@/assets/logomarca.png')" alt="Apetrus logomarca" />
                </center>
                <v-alert
                  v-if="user"
                  v-model="alert"
                  type="warning"
                  >
                    Se não verificou o email, verifique e <strong>recarregue</strong> a página, por favor!
                </v-alert>
                <div
                  style="color:#074d6e"
                  class="font-weight-medium display-1 pr-4"
                >Apetrus  Empresa</div>
                <br />
                <template v-if="!user">
                  <v-flex class="">
                    <v-form class="form" ref="form" v-model="valid" lazy-validation>
                      <v-dialog width="400px" persistent v-if="error" :value="hasError">
                        <v-card>
                          <v-card-text
                            class="error--text headline font-weight-medium text-xs-center"
                          >
                            <span>{{ error.message }}</span>
                          </v-card-text>
                          <v-card-actions>
                            <v-btn block text color="secondary" @click="fecharDialogError">OK</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    <div class="pl-16">
                      <v-text-field
                        v-model="login.email"
                        filled
                        label="E-mail"
                        class="input-login"
                        >
                      </v-text-field>

                      <v-text-field
                        v-model="login.password"
                        filled
                        label="Senha"
                        type="password"
                        class="input-login"
                      ></v-text-field>
                    </div>
                      <div class="cadastro mb-3 pl-16">
                        <span>
                          Esqueceu sua senha?
                          <a @click="sendResetMail = true">Alterar</a>
                        </span>
                      </div>

                      <div class="mb-3 pl-15">
                        <v-layout row align-space-around>
                          <v-btn
                            block
                            rounded
                            :loading="loading"
                            :disabled="loading"
                            color="secondary"
                            class="btnLogin"
                            @click="fazerLogin"
                          >Entrar</v-btn>

                          <v-spacer></v-spacer>

                          <v-btn
                            block
                            text
                            :loading="loading"
                            :disabled="loading"
                            color="secondary"
                            class="white--text btnLogin"
                            @click="dialogCad=true"
                          >Cadastre-se</v-btn>
                        </v-layout>
                      </div>
                    </v-form>
                  </v-flex>
                </template>
                <template v-else>
                  <v-flex class="text-xs-left">
                    <h1>Seguir como:</h1>
                    <v-chip color="primary" dark close @click:close="logout()">
                      <v-avatar>
                        <img v-if="user.image" :src="user.image" alt="trevor" />
                        <v-icon v-else>account_circle</v-icon>
                      </v-avatar>
                      {{ user.email }}
                    </v-chip>
                  </v-flex>
                  <v-flex class="pa-10">
                    <v-btn
                      block
                      rounded
                      v-if="!isUserVerified"
                      color="error"
                      dark
                      @click="resendEmail"
                    >Usuário não verificado, reenviar e-mail!</v-btn>
                    <v-btn
                      v-else-if="isNotPessoa"
                      block
                      rounded
                      width="350px"
                      :loading="loading"
                      :disabled="loading"
                      color="error"
                      class="white--text btnLogin"
                      @click="dialogCad=true"
                    >Por favor, Finalize seu cadastro aqui</v-btn>
                    <v-btn
                      v-else
                      block
                      rounded
                      width="250px"
                      :loading="loading"
                      :disabled="loading"
                      color="success"
                      class="white--text btnLogin"
                      @click="$router.push(lastPage ? lastPage : '/home')"
                    >Continue</v-btn>
                  </v-flex>
                </template>
              </v-layout>
            </template>
          </v-layout>
        </v-img>
      </v-layout>
    </template>

    <!----------------------dialog cadastro-------------------------->
    <v-dialog
      v-model="dialogCad"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card width="100%" height="100%">
        <iframe
          :src="url_cadastro"
          frameborder="0"
          :style="{width: '100%', height: '100%'}"
          ref="frameCad"
        ></iframe>
      </v-card>
    </v-dialog>
    <v-dialog v-model="sendResetMail" width="350">
      <reset-password v-on:fechar="sendResetMail = false" v-on:sucesso="fecharDialogReset()"></reset-password>
    </v-dialog>
    <v-snackbar v-model="snackbar" color="info" :multi-line="true" :timeout="4000" :vertical="true">
      {{snackbar_text}}
      <v-btn text @click="snackbar = false">Fechar</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import ResetPassword from '@/components/dialogs/SendResetPassword'
import settings from '@/settings'

export default {
  components: { ResetPassword },
  data: () => ({
    sendResetMail: false,
    snackbar: false,
    snackbar_text: null,
    dialogCad: false,
    loginErro: false,
    errorMessage: '',
    url_cadastro: null,
    valid: true,
    alert: true,
    login: {
      email: '',
      password: '',
      tipo: ''
    },
    emailRules: [
      v => !!v || 'Insira seu E-mail!'
    ],
    passwordRules: [
      v => !!v || 'Insira sua senha!'
    ]
  }),
  created () {
    setTimeout(() => {
      this.alert = false
    }, 5000)
    window.addEventListener('message', e => {
      if (e.data === 'closing_cad') {
        this.dialogCad = false
      }
    }, false)
  },
  mounted () {
    this.url_cadastro = settings.urlCadastroUsuario
  },
  computed: {
    hasError () {
      return !!this.$store.getters.error
    },
    loading () {
      return this.$store.getters.loading
    },
    user () {
      return this.$store.getters.user
    },
    error () {
      return this.$store.getters.error
    },
    isUserVerified () {
      return this.user.emailVerified
    },
    isNotPessoa () {
      return this.$store.getters.isNotPessoa
    },
    lastPage () {
      return this.$store.getters.lastPage
    }
  },
  methods: {
    fecharDialog () {
      this.dialogCad = false
      window.location.reload()
    },
    fecharDialogError () {
      this.$store.dispatch('setError', null)
    },
    onDismissed () {
      this.$store.dispatch('clearError')
    },
    fecharDialogReset () {
      this.sendResetMail = false
      this.snackbar_text = 'Link enviado com sucesso, verifique seu e-mail'
      this.snackbar = true
    },
    fazerLogin () {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('signUserIn', { email: this.login.email, password: this.login.password })
      }
    },
    logout () {
      this.$store.dispatch('signUserOut')
    },
    resendEmail () {
      this.$store.dispatch('sendVerifyEmail').then(() => {
        this.snackbar_text = 'Link de verificação enviado, verifique seu e-mail'
        this.snackbar = true
      })
    }
  }
}
</script>
<style>
.banner {
  width: 100%;
}
.logo {
  width: 20%;
}

.form {
  width: 80%;
  z-index: 2;
  position: relative;
}

.m-auto {
  margin: auto;
}

.curva {
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
}

.onda {
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
}

.tablet {
  width: 100%;
  z-index: 2;
}

.imgCurva {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
}

.imgOnda {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
}

.imgTablet {
  width: 80%;
}

.cadastro * {
  font-size: 90%;
}

.logopequeno {
  width: 7%;
}

.form-error {
  z-index: 2;
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
}

.form-flex:first-child,
.form-error:first-child,
.button-over {
  z-index: 2;
}

@media screen and (min-width: 300px) {
  .imgOnda {
    width: 100%;
  }
  .input-login{
      width: 300px;
  }
  .form {
    width: 80%;
  }
}

@media screen and (min-width: 800px) {
  .input-login{
      width: 380px;
      padding-left: 100px;
  }
}
</style>
