<template>
  <v-card>
      <v-card-title class="justify-center">
        <h2 class="text-center">
          {{ step === 1 ? 'CADASTRAR NOVA EMPRESA' : 'Insira agora o logo da sua empresa.' }}
        </h2>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-stepper v-model="step" style="box-shadow: none; height: 100%;" class="mb-4">
            <v-stepper-items>
              <v-stepper-content step="1" style="box-shadow: none; height: 100%;">
                <v-layout wrap justify-space-between>
                  <v-flex
                    xs12
                    class="ma-0 pa-0"
                  >
                    <v-text-field
                      class="mt-1"
                      label="Nome fantásia"
                      v-model="empresa_model.nome_fantasia"
                      required
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    xs12
                    class="ma-0 pa-0"
                  >
                    <v-text-field
                      class="mt-1"
                      :rules="razaoRules"
                      label="Razão Social"
                      v-model="empresa_model.razao_social"
                      hint="Insira o nome oficial do CNPJ"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    xs5
                    class="ma-0 pa-0"
                  >
                    <v-text-field
                      outlined
                      v-model.lazy="empresa_model.cnpj"
                      label="CNPJ"
                      :rules="[ () => validarMatematicamenteCNPJ() || 'Esse CNPJ não é valido']"
                      required
                      hint="Digite apenas os números"
                      v-mask="'##.###.###/####-##'"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs5 class="ma-0 pa-0">
                    <v-text-field
                      outlined
                      @keyup="searchCep()"
                      v-model="empresa_model.cep"
                      v-mask="'#####-###'"
                      maxlength="9"
                      label="Cep"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs5 class="ma-0 pa-0">
                    <v-text-field
                      outlined
                      v-model="empresa_model.logradouro"
                      label="Logradouro"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2 class="ma-0 pa-0">
                    <v-text-field
                      outlined
                      v-model="empresa_model.numero"
                      label="Nº"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs4 class="ma-0 pa-0">
                    <v-text-field
                      outlined
                      v-model="empresa_model.complemento"
                      label="Complemento"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs5 class="ma-0 pa-0">
                    <v-text-field
                      outlined
                      v-model="empresa_model.bairro"
                      label="Bairro"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs4 class="ma-0 pa-0">
                    <v-text-field
                      outlined
                      v-model="empresa_model.cidade"
                      label="Cidade"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2 class="ma-0 pa-0">
                    <v-autocomplete
                      outlined
                      v-model="empresa_model.uf"
                      label="UF"
                      :items="estados"
                      required
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs12 align-self-center class="ma-0 pa-0">
                    <v-select
                      outlined
                      v-model="empresa_model.tipo_empresa"
                      :items="[
                        { text: 'Controle de Frota', value: 1, icone: 'airport_shuttle', cor: 'info' },
                        { text: 'Revenda de veículos', value: 2, icone: 'directions_car', cor: 'success' },
                        { text: 'Automação', value: 3, icone: 'change_circle', cor: 'gray' },
                        { text: 'Venda de Produtos', value: 4, icone: 'inventory_2', cor: 'yellow' },
                        { text: 'Prestação de Serviços', value: 5, icone: 'engineering', cor: 'primary'  },
                        { text: 'Venda de Produtos e Serviços', value: 6, icone: 'currency_exchange', cor: 'secondary'  },
                        { text: 'Instituição Beneficente', value: 7, icone: 'volunteer_activism', cor: 'error'  },
                        { text: 'Integradora', value: 8, icone: 'integration_instructions', cor: 'secondary'  }
                      ]"
                      label="Tipo de empresa"
                    >
                    <template  v-slot:item="props">
                        <v-icon :color=" props.item.cor" class="mr-3">{{ props.item.icone }}</v-icon>
                        <span>{{ props.item.text }}</span>
                    </template>
                    </v-select>
                  </v-flex>
                  <template v-if="empresa_model.tipo_empresa === 4 || empresa_model.tipo_empresa === 5 || empresa_model.tipo_empresa === 6">
                    <v-flex xs12 align-self-center class="ma-0 pa-0">
                      <v-select
                        v-model="empresa_model.categoria"
                        :items="categorias"
                        label="Categoria de Atuação"
                        outlined
                      />
                    </v-flex>
                    <v-flex xs12 align-self-center class="ma-0 pa-0">
                      <v-select
                        outlined
                        v-model="empresa_model.subcategorias"
                        :items="subcategorias.filter(sc => {
                          const categoriaCorrespondente = sc.categoria === empresa_model.categoria || empresa_model.categoria === 9
                          const servicoCorrespondente = (empresa_model.tipo_empresa === 5 && sc.servico || empresa_model.tipo_empresa === 4 && !sc.servico) || (empresa_model.tipo_empresa === 6)
                          return categoriaCorrespondente && servicoCorrespondente
                        }
                        )"
                        label="Subcategoria de Atuação"
                        chips
                        multiple
                      />
                    </v-flex>
                  </template>
                </v-layout>
                </v-stepper-content>
                <v-stepper-content step="2">
                  <v-container grid-list-md>
                      <v-layout column>
                          <v-flex align-self-center>
                            <v-img
                              height="250"
                              width="250"
                              :src="cropped ? cropped : ''"
                              @click="cropped ? carregarImagem = true : $refs.imageInput.click()"
                              style="cursor: pointer"
                            >
                              <v-card v-if="!cropped" color="grey" height="100%" width="100%">
                                <v-layout justify-center align-center fill-height>
                                  <div class="title white--text pa-4 text-xs-center">Sem imagem</div>
                                </v-layout>
                              </v-card>
                            </v-img>
                          </v-flex>
                          <v-flex class="text-xs-center mt-3">
                            <v-btn
                              text
                              block
                              @click="$refs.imageInput.click()"
                            >
                              <v-spacer></v-spacer>
                              <template v-if="nome_imagem">{{nome_imagem}}</template>
                              <template v-else>CARREGAR IMAGEM</template>
                              <v-spacer></v-spacer>
                              <v-icon>cloud_upload</v-icon>
                              <input
                                type="file"
                                style="display:none"
                                accept="image/*"
                                ref="imageInput"
                                @input="carregarImagem"
                              />
                            </v-btn>
                          </v-flex>
                      </v-layout>
                  </v-container>
                </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-form>
      </v-card-text>
      <v-footer absolute color="transparent">
        <v-layout justify-space-around>
          <v-flex xs6 class="mb-1">
            <v-btn
              text
              color="primary"
              @click="fechar()"
              block
            >
              Cancelar
            </v-btn>
          </v-flex>
          <v-divider vertical></v-divider>
          <v-flex xs6 class="mb-1">
            <v-btn
              text
              color="primary"
              @click="btnConfirm"
              block
            >
              Salvar
            </v-btn>
          </v-flex>
        </v-layout>
      </v-footer>
  </v-card>
</template>

<script>
import { mask } from 'vue-the-mask'

export default {
  directives: {
    mask
  },
  data () {
    return {
      step: 1,
      valid: true,
      categorias: [
        { text: 'Alimentação e Bebidas', value: 1 },
        { text: 'Automóveis e Peças Automotivas', value: 2 },
        { text: 'Construção e Imóveis', value: 3 },
        { text: 'Design e Criatividade', value: 4 },
        { text: 'Moda e Estilo', value: 5 },
        { text: 'Saúde e Bem-Estar', value: 6 },
        { text: 'Beleza e Estética', value: 7 },
        { text: 'Educação e Treinamento', value: 8 },
        { text: 'Outra', value: 9 }
      ],
      subcategorias: [
        // Alimentação e Bebidas
        { text: 'Restaurante', value: 1, categoria: 1, servico: false },
        { text: 'Lanchonete', value: 2, categoria: 1, servico: false },
        { text: 'Pizzaria', value: 3, categoria: 1, servico: false },
        { text: 'Bar', value: 4, categoria: 1, servico: false },
        { text: 'Buffet', value: 5, categoria: 1, servico: true },
        { text: 'Supermercado', value: 6, categoria: 1, servico: false },
        { text: 'Venda de Água Mineral', value: 7, categoria: 1, servico: false },
        { text: 'Padaria', value: 8, categoria: 1, servico: false },

        // Automóveis e Peças Automotivas
        { text: 'Auto Peças', value: 9, categoria: 2, servico: false },
        { text: 'Oficina', value: 10, categoria: 2, servico: true },
        { text: 'Concessionária', value: 11, categoria: 2, servico: false },
        { text: 'Transportadora', value: 12, categoria: 2, servico: true },
        { text: 'Lava Jato', value: 13, categoria: 2, servico: true },
        { text: 'Locação', value: 14, categoria: 2, servico: true },
        { text: 'Auto Escola', value: 15, categoria: 2, servico: true },
        { text: 'Equipadora', value: 16, categoria: 2, servico: true },

        // Construção e Imóveis
        { text: 'Material de Construção', value: 17, categoria: 3, servico: false },
        { text: 'Construtora', value: 18, categoria: 3, servico: true },
        { text: 'Metalurgica', value: 19, categoria: 3, servico: true },
        { text: 'Madeireira', value: 20, categoria: 3, servico: false },
        { text: 'Portão Automático', value: 21, categoria: 3, servico: false },
        { text: 'Vidraçarias', value: 22, categoria: 3, servico: true },
        { text: 'Engenharia', value: 23, categoria: 3, servico: true },
        { text: 'Serralharia', value: 24, categoria: 3, servico: true },
        { text: 'Corretagem e Consultoria Imobiliária', value: 25, categoria: 3, servico: true },
        { text: 'Desenvolvimento Imobiliário', value: 26, categoria: 3, servico: true },
        { text: 'Gestão de Propriedades', value: 27, categoria: 3, servico: true },
        { text: 'Construção Residencial', value: 28, categoria: 3, servico: true },
        { text: 'Construção Comercial', value: 29, categoria: 3, servico: true },
        { text: 'Serviços de Manutenção Predial', value: 30, categoria: 3, servico: true },
        { text: 'Investimento Imobiliário', value: 31, categoria: 3, servico: true },
        { text: 'Avaliação de Propriedades', value: 32, categoria: 3, servico: true },
        { text: 'Empreendimentos Sustentáveis', value: 33, categoria: 3, servico: false },
        { text: 'Arquitetura e Design de Interiores', value: 34, categoria: 3, servico: true },

        // Design e Criatividade
        { text: 'Design Gráfico', value: 35, categoria: 4, servico: true },
        { text: 'Design de Produto', value: 36, categoria: 4, servico: true },
        { text: 'Design de Interiores', value: 37, categoria: 4, servico: true },
        { text: 'Design de Moda', value: 38, categoria: 4, servico: true },
        { text: 'Arquitetura', value: 39, categoria: 4, servico: true },
        { text: 'Design de Mídia', value: 40, categoria: 4, servico: true },
        { text: 'Ilustração e Arte Gráfica', value: 41, categoria: 4, servico: true },
        { text: 'Web Design e Desenvolvimento', value: 42, categoria: 4, servico: true },

        // Moda e Estilo
        { text: 'Roupa Feminina', value: 42, categoria: 5, servico: false },
        { text: 'Roupa Unissex', value: 43, categoria: 5, servico: false },
        { text: 'Roupa Masculina', value: 44, categoria: 5, servico: false },
        { text: 'Roupa Infantil', value: 45, categoria: 5, servico: false },
        { text: 'Ótica', value: 46, categoria: 5, servico: false },
        { text: 'Pronta Entrega', value: 47, categoria: 5, servico: false },
        { text: 'Calçados e Bolsas', value: 48, categoria: 5, servico: false },
        { text: 'Conserto de Roupa', value: 49, categoria: 5, servico: false },
        { text: 'Atelier', value: 50, categoria: 5, servico: true },
        { text: 'Acessórios', value: 51, categoria: 5, servico: false },
        { text: 'Armarinho', value: 52, categoria: 5, servico: false }, // verificar
        { text: 'Roupas Esportivas e Ativewear', value: 53, categoria: 5, servico: false },

        // Saúde e Bem-Estar
        { text: 'Clínica', value: 53, categoria: 6, servico: true }, // verificar
        { text: 'Farmácia', value: 54, categoria: 6, servico: false },
        { text: 'Plano de Saúde', value: 55, categoria: 6, servico: false },
        { text: 'Serviços Médicos', value: 56, categoria: 6, servico: true },
        { text: 'Serviços Odontológico', value: 57, categoria: 6, servico: true },
        { text: 'Laboratório', value: 58, categoria: 6, servico: true },
        { text: 'Posto de Saúde', value: 59, categoria: 6, servico: true },
        { text: 'Nutrição', value: 60, categoria: 6, servico: true },
        { text: 'Farmácia de Manipulação', value: 61, categoria: 6, servico: false },
        { text: 'Fitness', value: 62, categoria: 6, servico: true },

        // Beleza e Estética
        { text: 'Salão de Beleza', value: 63, categoria: 7, servico: true },
        { text: 'Depilação', value: 64, categoria: 7, servico: true },
        { text: 'Perfumaria', value: 65, categoria: 7, servico: false },
        { text: 'Cosméticos', value: 66, categoria: 7, servico: false },
        { text: 'Massagem', value: 67, categoria: 7, servico: true },
        { text: 'Barbearia', value: 68, categoria: 7, servico: true },
        { text: 'Tatuagem', value: 69, categoria: 7, servico: true },
        { text: 'Piercing', value: 70, categoria: 7, servico: true },
        { text: 'Esmalteria', value: 71, categoria: 7, servico: false },
        { text: 'Bronzeamento', value: 72, categoria: 7, servico: true },
        { text: 'Maquiagem', value: 73, categoria: 7, servico: false },
        { text: 'Cuidados com a Pele', value: 74, categoria: 7, servico: true },
        { text: 'Manicure e Pedicure', value: 75, categoria: 7, servico: true },
        { text: 'Spas de Beleza', value: 76, categoria: 7, servico: true },
        { text: 'Cirurgia Plástica Estética', value: 77, categoria: 7, servico: true },
        { text: 'Design de Sobrancelhas e Cílios', value: 77, categoria: 7, servico: true },

        // Educação e Treinamento
        { text: 'Instituições Educacional', value: 78, categoria: 8, servico: true },
        { text: 'Treinamento Corporativo', value: 79, categoria: 8, servico: true },
        { text: 'Educação à Distância', value: 79, categoria: 8, servico: true },
        { text: 'Cursos de Idiomas', value: 80, categoria: 8, servico: true },
        { text: 'Cursos Técnicos', value: 81, categoria: 8, servico: true },
        { text: 'Educação Especial', value: 82, categoria: 8, servico: true },
        { text: 'Faculdade', value: 83, categoria: 8, servico: true },
        { text: 'Livrarias', value: 84, categoria: 8, servico: false },
        { text: 'Transporte Escolar', value: 85, categoria: 8, servico: true },
        { text: 'Escola Ensino Fundamental', value: 86, categoria: 8, servico: true },
        { text: 'Escola Ensino Infantil', value: 87, categoria: 8, servico: true },
        { text: 'Escola Ensino Médio', value: 88, categoria: 8, servico: true },

        // Outros
        { text: 'Lavanderia', value: 89, categoria: 9, servico: true },
        { text: 'Serigrafia', value: 90, categoria: 9, servico: true },
        { text: 'Assistencia Tecnica', value: 91, categoria: 9, servico: true },
        { text: 'Contabilidade', value: 92, categoria: 9, servico: true },
        { text: 'Associação', value: 93, categoria: 9, servico: true },
        { text: 'Eletrica', value: 94, categoria: 9, servico: true },
        { text: 'Eletrônica', value: 95, categoria: 9, servico: true },
        { text: 'Sistema de Segurança', value: 96, categoria: 9, servico: true },
        { text: 'Venda de Gás de Cozinha', value: 97, categoria: 9, servico: false }
      ],
      empresa_model: {
        checkbox: false,
        razao_social: '',
        cnpj: '',
        nome_fantasia: '',
        cep: '',
        logradouro: '',
        numero: '',
        bairro: '',
        cidade: '',
        uf: '',
        contato1: '',
        contato2: '',
        contato3: '',
        rep_nome: '',
        rep_sobrenome: '',
        rep_cpf: '',
        rep_contato: '',
        email: '',
        latitude: null,
        longitude: null,
        tipo_empresa: 1,
        categoria: 1,
        subcategorias: []
      },
      razaoRules: [
        v => !!v || 'Insira Razão Social!'
      ],
      cnpjRules: [
        v => !!v || 'Insira Cnpj!'
      ],
      empresa: null,
      cropped: null,
      nome_imagem: null
    }
  },
  methods: {
    btnConfirm () {
      if (this.step === 1) {
        this.salvarEmpresa()
      } else this.inserirImagem()
    },
    recortarLogo () {
      const options = {
        type: 'base64',
        format: 'png',
        circle: true
      }
      this.$refs.croppieRef.result(options, output => {
        this.cropped = output
        this.croppingLogo = false
      })
    },
    carregarImagem (event) {
      this.imagem_convenio = null
      const imagem = event.target.files[0]
      const reader = new FileReader()
      reader.onload = event => {
        const image = new Image()
        image.src = event.target.result
        const self = this
        image.onload = function (e) {
          var height = this.height
          var width = this.width
          if (height > 300 || width > 300) {
            const canvas = document.createElement('canvas')
            const maxWidth = 300
            const scaleSize = maxWidth / width
            canvas.width = maxWidth
            canvas.height = height * scaleSize
            if (canvas.height > 300) {
              const scaleSize = maxWidth / canvas.height
              canvas.height = maxWidth
              canvas.width = canvas.height * scaleSize
            }
            const ctx = canvas.getContext('2d')
            ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height)
            const srcEncoded = ctx.canvas.toDataURL(e.target, 'image/jpeg')
            self.cropped = srcEncoded
            self.nome_imagem = imagem.name
            return true
          }
          self.cropped = event.target.result
          self.nome_imagem = imagem.name
          return true
        }
      }
      if (imagem) {
        reader.readAsDataURL(imagem)
      }
    },
    async inserirImagem () {
      const cnpj = await this.empresa_model.cnpj.replace(/[^\d]+/g, '')
      let url
      await this.$store.dispatch('inserirFirebase', {
        prefixo: 'empresa',
        id: cnpj,
        arquivo: this.cropped,
        local: 'empresas',
        tipo: 'png'
      }).then(downloadUrl => {
        url = downloadUrl
      })

      await this.$store.dispatch('atualizarImagemEmpresa', { url_logo: url, cnpj: cnpj }).then(() => {
        this.$emit('carregar')
        this.fechar()
      })

      this.$store.dispatch('setSuccess', 'Empresa inserida com sucesso')
    },
    validarMatematicamenteCNPJ () {
      if (this.empresa_model && this.empresa_model.cnpj && this.empresa_model.cnpj.length === 18) {
        var digito1 = null
        var digito2 = null
        var indiceDeCalculo = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
        digito1 = this.calculoDv(indiceDeCalculo, 2)
        indiceDeCalculo = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
        digito2 = this.calculoDv(indiceDeCalculo, 1)
        const cnpj = this.empresa_model.cnpj.replace(/[^\d]+/g, '')
        if (digito1 > 9) {
          if (parseInt(cnpj.substring(12, 13)) === 0 && digito2 === parseInt(cnpj.substring(13))) {
            return true
          }
        } else {
          if (digito1 === parseInt(cnpj.substring(12, 13)) || digito2 === parseInt(cnpj.substring(13))) {
            return true
          }
        }
      }
    },
    calculoDv (indiceDeCalculo, dig) {
      var somaCnpj = 0
      var i = 0
      const cnpj = this.empresa_model.cnpj.replace(/[^\d]+/g, '')
      while (i < cnpj.length - dig) {
        somaCnpj = somaCnpj + cnpj.substring(i, i + 1) * indiceDeCalculo[i]
        i++
      }
      return (somaCnpj * 10) % 11
    },
    searchCep () {
      if (!this.empresa_model.cep || this.empresa_model.cep.length < 8) return
      fetch(`https://viacep.com.br/ws/${this.empresa_model.cep.replace(/[^\d]+/g, '')}/json/`,
        {
          method: 'get',
          mode: 'cors',
          headers: {
            Accept: 'application/json'
          }
        })
        .then(res => res.json())
        .then(json => {
          this.empresa_model.logradouro = json.logradouro
          this.empresa_model.bairro = json.bairro
          this.empresa_model.cidade = json.localidade
          this.empresa_model.uf = json.uf
        })
    },
    async salvarEmpresa () {
      if (this.$refs.form.validate()) {
        try {
          await this.$store.dispatch('pegarCoordenadas', {
            logradouro: this.empresa_model.logradouro,
            numero: this.empresa_model.numero,
            bairro: this.empresa_model.bairro,
            cidade: this.empresa_model.cidade,
            estado: this.empresa_model.uf
          }).then(json => {
            this.empresa_model.latitude = json.latitude
            this.empresa_model.longitude = json.longitude
          })
        } catch {
          this.$store.dispatch('setError', 'Endereço não encontrado.')
        }
        this.empresa_model.cnpj = await this.empresa_model.cnpj.replace(/[^\d]+/g, '')
        this.empresa_model.cep = await this.empresa_model.cep.replace(/[^\d]+/g, '')
        this.$store.dispatch('InserirNovaEmpresa', { empresa: this.empresa_model }).then(() => {
          this.step++
          this.$emit('carregar')
        })
      } else {
        this.$store.dispatch('setError', 'Formulário inválido.')
      }
    },
    fechar () {
      this.$refs.form.reset()
      this.step = 1
      this.cropped = null
      this.$emit('fechar')
    }
  },
  computed: {
    estados () {
      return this.$store.getters.estados
    }
  }
}
</script>
