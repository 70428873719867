<template>
    <v-card style="border-radius: 1em;">
        <v-toolbar color="primary">
          <v-toolbar-title style="width: 100%">
            <h2 class="headline white--text" style="text-align: center;">
              {{ titulo() }}
            </h2>
          </v-toolbar-title>
        </v-toolbar>
        <v-stepper v-model="step" style="border-shadow: none;">
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-container grid-list-lg>
                  <v-layout column wrap>
                      <v-flex xs12 align-self-center>
                          <v-text-field
                          class="ma-0 pa-0"
                          v-model="pin"
                          label="Digite o seu codigo"
                          outlined
                          :type="show ? 'text' : 'password'"
                          :append-icon="show ? 'visibility' : 'visibility_off'"
                          :rules="[v => !!v || 'Insira um código válido']"
                          required
                          :min="4"
                          v-on:keyup.enter="autenticar"
                          @click:append="show = !show"
                          autocomplete="off"
                        />
                        <v-flex class="ma-0 pa-0" style="justify-content: center; display: flex;">
                          <v-btn small rounded color="#f6b555" class="white--text" @click="step++">Cadastrar SmartLock</v-btn>
                        </v-flex>
                      </v-flex>
                  </v-layout>
              </v-container>
              <v-btn class="mt-3 mb-2" block rounded @click="autenticar" color="primary" :loading="loading">
                  AUTENTICAR
              </v-btn>
            </v-stepper-content>
            <v-stepper-content step="2">
                <v-card-text class="subtitle-2 text-xs-center secondary--text">
                    <div class="subheading grey--text text--darken-2 font-weight-medium">
                        Enviaremos um SMS de confirmação
                    </div>
                </v-card-text>
                <v-container grid-list-lg class="pt-0 pb-0">
                  <v-form v-model="valid" ref="form" lazy-validation>
                    <v-layout row wrap>
                      <v-flex xs12 class="pb-0">
                          <v-text-field
                              v-model="telefone"
                              outlined
                              clearable
                              single-line
                              class="centered-input headline font-weight-medium"
                              v-mask="'(##) #####-####'"
                              required
                              placeholder="Digite seu telefone"
                              autocomplete="off"
                              :rules="[
                                t => (
                                  !t ||
                                  t.replace(/[^a-zA-Z0-9]/g, '').length < 11 ||
                                  pessoa.telefone.endsWith(t.replace(/[^a-zA-Z0-9]/g, ''))
                                ) || 'O número inserido não é igual ao usado em seu cadastro'
                              ]"
                          ></v-text-field>
                      </v-flex>
                      <v-flex xs12 id="captcha-container" class="pa-0"></v-flex>
                    </v-layout>
                  </v-form>
                </v-container>
                <v-layout class="mt-2">
                  <v-btn text color="grey darken-2" @click="voltarTela">
                    VOLTAR
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn :disabled="continuarDesativado" text color="green" @click="continuarTela()" :loading="loading">
                      CONTINUAR
                  </v-btn>
                </v-layout>
            </v-stepper-content>
            <v-stepper-content step="3" class="pa-0">
                <v-card-text class="subtitle-2 text-xs-center secondary--text">
                    Um SMS foi enviado ao seu dispositivo
                    <div class="subheading grey--text text--darken-2 font-weight-medium">
                        Confirme-o para concluir
                    </div>
                </v-card-text>
                <v-container grid-list-lg class="pt-0 pb-0">
                    <v-layout row wrap>
                        <v-flex xs12>
                            <v-text-field
                                v-model="codigo"
                                outlined
                                clearable
                                single-line
                                class="centered-input headline font-weight-medium"
                                v-mask="'#########'"
                                hide-details
                                :type="show ? 'text' : 'password'"
                                :append-icon="show ? 'visibility' : 'visibility_off'"
                                :rules="[v => !!v || 'Insira um código válido']"
                                @click:append="show = !show"
                                required
                                autocomplete="off"
                                v-on:keyup.enter="continuarTela"
                            ></v-text-field>
                        </v-flex>
                    </v-layout>
                </v-container>
                <v-layout class="pa-4">
                  <v-btn text color="grey darken-2" @click="voltarTela">
                    VOLTAR
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn :disabled="continuarDesativado" text color="green" @click="continuarTela" :loading="loading">
                      CONTINUAR
                  </v-btn>
                </v-layout>
            </v-stepper-content>
            <v-stepper-content step="4">
              <v-container grid-list-lg class="pt-0">
                <v-layout row wrap>
                    <v-flex xs12>
                        <label for="codigo">Senha</label>
                        <v-text-field
                        v-model="pin"
                        label="Digite o seu codigo"
                        outlined
                        single-line
                        :type="show ? 'text' : 'password'"
                        :append-icon="show ? 'visibility' : 'visibility_off'"
                        @click:append="show = !show"
                        required
                        :min="4"
                        :rules="[v => !!v || 'Insira um código válido']"
                        autocomplete="off"
                      />
                    </v-flex>
                    <v-flex xs12>
                        <label>Confirme sua senha</label>
                        <v-text-field
                        v-model="pin_confirmacao"
                        single-line
                        label="Confirme o seu codigo"
                        :type="show2 ? 'text' : 'password'"
                        :append-icon="show2 ? 'visibility' : 'visibility_off'"
                        @click:append="show2 = !show2"
                        outlined
                        required
                        :min="4"
                        :rules="[v => !!v || 'Insira um código válido' || v !== pin]"
                        autocomplete="off"
                      />
                    </v-flex>
                </v-layout>
              </v-container>
              <v-layout>
                  <v-btn text color="grey darken-2" @click="voltarTela">
                    VOLTAR
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn :disabled="continuarDesativado" text color="green" @click="continuarTela()" :loading="loading">
                      CONTINUAR
                  </v-btn>
                </v-layout>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
    </v-card>
</template>

<script>
import { mask } from 'vue-the-mask'

export default {
  directives: {
    mask
  },
  data () {
    return {
      show: false,
      show2: false,
      codigo: null,
      step: 1,
      telefone: '',
      pin_confirmacao: '',
      pin: '',
      valid: false
    }
  },
  computed: {
    pessoa () {
      return this.$store.getters.pessoa
    },
    loading () {
      return this.$store.getters.loading
    },
    continuarDesativado () {
      if (this.telefone === null || this.telefone === undefined) {
        return false
      }
      const tel = this.telefone.replace(/[^a-zA-Z0-9]/g, '')
      switch (this.step) {
        case 2:
          if (this.pessoa.telefone.startsWith('+55')) {
            return tel !== this.pessoa.telefone.substr(3, 11)
          } else {
            return tel !== this.pessoa.telefone
          }
      }
      return false
    }
  },
  methods: {
    titulo () {
      switch (this.step) {
        case 1:
          return 'Insira seu SmartLock'
        case 2:
          return 'Confirme seu número'
        case 3:
          return 'Insira o código'
        case 4:
          return 'Cadastre seu SmartLock'
      }
    },
    fechar () {
      this.limparCampos()
      this.step = 1
      this.$emit('fechar')
    },
    limparCampos () {
      this.telefone = null
      this.codigo = null
      this.show = false
      this.show2 = false
      this.pin = ''
      this.pin_confirmacao = ''
    },
    autenticar () {
      this.$store.dispatch('pinAutenticar', this.pin).then(() => {
        this.limparCampos()
        this.step = 1
        this.$emit('autenticado')
      })
    },
    voltarTela () {
      this.limparCampos()
      if (this.step === 4) {
        this.step = 1
        return
      }
      this.step--
    },
    continuarTela () {
      switch (this.step) {
        case 2:
          this.$store.dispatch('sendSmsVerification', 'captcha-container').then(() => {
            this.step++
          }).catch(() => {
            this.$store.dispatch('setError', 'Desculpe, não foi possível enviar o seu código, tente novamente mais tarde.')
          })
          break
        case 3:
          this.$store.dispatch('confirmSmsVerification', this.codigo).then(() => {
            this.step = 4
            this.limparCampos()
          }).catch(() => {
            this.$store.dispatch('setError', 'Desculpe, mas não foi possível confirmar seu código, por favor, tente novamente.')
            this.step = 3
          })
          break
        case 4:
          if (this.pin !== this.pin_confirmacao) {
            this.$store.dispatch('setError', 'Os códigos digitados são diferentes')
          }
          this.$store.dispatch('cadastrarPin', { pin: this.pin }).then(() => {
            this.limparCampos()
            this.step = 1
          })
      }
    }
  }
}
</script>
