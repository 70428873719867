<template>
    <v-card>
        <v-card-title class="justify-center">
            <h2>{{ itemSelecionado ? 'EDITAR ITEM' : 'CADASTRAR ITEM' }}</h2>
        </v-card-title>
        <v-alert v-show="alerta" :type="tipo" style="width: 100%;" class="pa-2" :value="true">
            <h4>{{alerta}}</h4>
        </v-alert>
        <v-divider v-show="alerta"></v-divider>
        <v-card-text>
            <v-stepper
              v-model="step"
              class="pa-0 ma-0"
              style="box-shadow: none;"
            >
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-layout wrap justify-space-between>
                      <v-flex xs12>
                          <v-autocomplete
                              label="Categoria"
                              outline
                              :items="categorias"
                              item-text="nome"
                              return-object
                              v-model="item.categoria"
                          >
                              <template  v-slot:item="props">
                                  <v-icon :color=" props.item.cor" class="mr-3">{{ props.item.icone }}</v-icon>
                                  <span>{{ props.item.nome }}</span>
                              </template>
                          </v-autocomplete>
                      </v-flex>
                      <v-flex xs12>
                          <v-select
                              label="Tipos de Item"
                              outline
                              :items="
                                servicos ?
                                [
                                  { text: 'Serviço', value: 'S' },
                                ]
                                :
                                [
                                  { text: 'Produto Físico', value: 'P' },
                                  { text: 'Produto Digital', value: 'D' },
                                ]"
                              v-model="item.tipo"
                          ></v-select>
                      </v-flex>
                      <v-flex xs12>
                          <v-text-field
                              label="Preço"
                              outline
                              v-money="money"
                              v-model="item.preco"
                              prefix="R$"
                          ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                          <v-text-field
                              label="Nome"
                              outline
                              v-model="item.nome"
                              maxlength="100"
                              counter="100"
                          ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                          <v-textarea
                              label="Descrição"
                              outline
                              v-model="item.descricao"
                              maxlength="255"
                              counter="255"
                          ></v-textarea>
                      </v-flex>
                      <v-flex xs6>
                        <v-switch
                          slot="activator"
                          v-model="tem_limite_qtd"
                          color="primary"
                          :disabled="!item.categoria"
                        >
                          <template v-slot:label>
                              Tem controle de quantidade
                          </template>
                        </v-switch>
                        <v-slide-x-transition>
                          <v-text-field
                              v-if="tem_limite_qtd"
                              label="Quantidade Disponível"
                              outline
                              v-model="item.quantidade_disponivel"
                              type="number"
                          ></v-text-field>
                        </v-slide-x-transition>
                      </v-flex>
                        <v-flex xs6>
                        <v-switch
                          slot="activator"
                          v-model="item.is_anuncio"
                          color="primary"
                        >
                          <template v-slot:label>
                              Mostrar como anúncio
                          </template>
                        </v-switch>
                      </v-flex>

                  </v-layout>
                </v-stepper-content>
                <v-stepper-content step="2">
                  <v-toolbar color="transparent" elevation="0">
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          color="info"
                          v-on="on"
                          @click="imagens.push(undefined)"
                          icon
                        >
                          <v-icon>add</v-icon>
                        </v-btn>
                      </template>
                      <h4 class="info--text">Adicionar Nova Imagem</h4>
                    </v-tooltip>
                  </v-toolbar>
                  <input
                      type="file"
                      style="display:none"
                      accept="image/*"
                      ref="imagemInput"
                      @input="carregarImagem"
                  />
                  <v-flex xs12 v-show="cropping">
                    <vue-croppie
                      ref="croppie"
                      :enableExif="true"
                      :enforceBoundary="true"
                      :enableResize="false"
                      :enableOrientation="false"
                      :mouseWheelZoom="true"
                      :showZoomer="false"
                      :boundary="{ width: croppie_width + 100, height: croppie_height + 100 }"
                      :viewport="{ width: croppie_width, height: croppie_height }"
                    ></vue-croppie>
                    <v-btn
                      text
                      @click="recortarImagem()"
                      block
                      class="mt-3"
                    >
                      Recortar imagem
                      </v-btn>
                  </v-flex>
                  <v-stepper
                    v-model="step_image"
                    style="box-shadow: none;"
                    v-if="!cropping"
                  >
                    <v-stepper-items style="box-shadow: none;">
                      <v-stepper-content
                        v-for="(item, index) in imagens"
                        :key="index"
                        :step="Number(index) + 1"
                        class="ma-0 pa-0"
                        style="box-shadow: none;"
                      >
                        <v-layout
                          justify-center
                          column
                          style="justify-content: center; align-items: center;"
                        >
                          <img
                            v-if="item"
                            :src="item"
                            @click="removerImagem(item, index)"
                            class="ml-3 mr-3 mt-2"
                            width="600"
                            slot="activator"
                          />
                          <v-progress-circular
                            v-else-if="loading && !item"
                            indeterminate
                            :size="50"
                            color="info"
                          ></v-progress-circular>
                          <v-tooltip
                            bottom
                            v-else-if="!cropping"
                          >
                            <template v-slot:activator="{ on }">
                              <v-card
                                hover
                                class="ml-3 mr-3 mt-2 mb-2"
                                width="600"
                                v-on="on"
                                @click="$refs.imagemInput.click(), indexSelecionado = index"
                                :loading="loading"
                              >
                                <v-layout justify-center column>
                                  <v-icon size="15em">image</v-icon>
                                </v-layout>
                              </v-card>
                            </template>
                            <h4>Clique para inserir uma imagem</h4>
                          </v-tooltip>
                        </v-layout>
                        <v-flex
                          xs12
                          style="
                            justify-content: center;
                            align-items: center;
                            display: flex;
                          "
                        >
                          <v-btn
                            class="mt-3 mb-3"
                            color="primary"
                            @click="$refs.imagemInput.click(), indexSelecionado = index"
                            large
                          >
                            Inserir imagem
                          </v-btn>
                        </v-flex>
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
                  <v-layout wrap justify-space-between>
                    <v-btn
                      icon
                      color="info"
                      class="ma-3"
                      @click="step_image--"
                      :disabled="step_image === 1"
                    >
                      <v-icon>arrow_back</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      color="info"
                      class="ma-3"
                      @click="step_image++"
                      :disabled="imagens.length === step_image"
                    >
                      <v-icon>arrow_forward</v-icon>
                    </v-btn>
                  </v-layout>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-card-actions>
          <v-flex>
            <v-btn block color="success" @click="cadastrarItem" class="mb-1" v-if="step == 1">
              <v-icon class="mr-1">save</v-icon>
              {{ itemSelecionado ? 'Editar' : 'Cadastrar' }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn block @click="$emit('fechar')" class="mt-1">
              Fechar
            </v-btn>
          </v-flex>
        </v-card-actions>
    </v-card>
</template>

<script>
import { VMoney } from 'v-money'

export default {
  directives: { money: VMoney },
  props: ['itemSelecionado', 'servicos'],
  data: () => ({
    step: 1,
    categorias: [],
    imagem: undefined,
    imagem_temp: undefined,
    imagens: [undefined],
    croppie_width: 400,
    croppie_height: 400,
    step_image: 1,
    indexSelecionado: undefined,
    cropping: false,
    alerta: undefined,
    tipo: 'error',
    money: {
      decimal: ',',
      thousands: '.',
      prefix: '',
      precision: 2,
      masked: false
    },
    tem_limite_qtd: false,
    item: {
      categoria: null,
      nome: null,
      preco: null,
      descricao: null,
      imagens: [undefined],
      tipo: 'P',
      quantidade_disponivel: null,
      is_anuncio: false,
      combustivel: null,
      empresa: null,
      is_conveniencia: false
    }
  }),
  watch: {
    tem_limite_qtd (val) {
      if (!val) this.item.quantidade_disponivel = null
    }
  },
  beforeMount () {
    if (this.servicos) {
      this.item.tipo = 'S'
    }
    this.pegarCategorias()
    if (this.itemSelecionado) {
      this.item = {
        categoria: this.itemSelecionado.categoria.id,
        ...this.itemSelecionado
      }
      this.imagens = this.item.imagens.length === 0 ? [undefined] : this.item.imagens
      this.tem_limite_qtd = !!this.item.quantidade_disponivel
    }
  },
  computed: {
    empresa () {
      return this.$store.getters.empresa
    },
    loading () {
      return this.$store.getters.loading
    }
  },
  methods: {
    pegarCategorias () {
      this.$store.dispatch('pegarCategorias').then(json => {
        this.categorias = json.filter(c => c.referencia !== 'combustivel')
      })
    },
    formatarValor (valor) {
      if (!valor) return 0
      return Number(valor.replaceAll('.', '').replace(',', '.'))
    },
    activeAlert (text, type) {
      this.alerta = text
      this.tipo = type
      const interval = setInterval(() => {
        this.alerta = undefined
        clearInterval(interval)
      }, 5000)
    },
    cadastrarItem () {
      if (!this.item.categoria) {
        this.activeAlert('Você precisa selecionar uma categoria', 'error')
        return
      }
      if (!this.item.tipo) {
        this.activeAlert('Você precisa selecionar um tipo', 'error')
        return
      }
      if (!this.item.nome) {
        this.activeAlert('Você precisa digitar um nome para o item.', 'error')
        return
      }
      if (this.formatarValor(this.item.preco) <= 0) {
        this.activeAlert('O preço do item precisa ser superior a zero.', 'error')
        return
      }
      const data = {
        method: this.item.id ? 'PUT' : 'POST',
        body: {
          ...this.item,
          preco: this.formatarValor(this.item.preco),
          empresa: this.empresa.cnpj,
          categoria: this.item.categoria.id,
          imagens: this.imagens.filter((i) => i !== undefined)
        }
      }
      if (this.item.id) {
        data.id = this.item.id
      }
      return this.$store.dispatch('itens', data).then(json => {
        this.$emit('success', 'Item cadastrado com sucesso')
        this.$emit('carregar')
        this.item = json
        this.step = 2
        this.imagens = json.imagens.length === 0 ? [undefined] : json.imagens
        if (json.categoria) this.item.categoria = json.categoria.id
      }).catch(() => {
        this.activeAlert('Não foi possível cadastrar item.', 'error')
      })
    },
    carregarImagem (event) {
      this.imagem = null
      this.imagem_temp = undefined
      if (event.target.files && event.target.files[0]) {
        const imagem = event.target.files[0]
        const reader = new FileReader()
        reader.onloadend = e => {
          this.cropping = true
          this.imagem_temp = reader.result
          this.$refs.croppie.bind({
            url: reader.result
          })
          this.$nextTick(() => {
            this.$refs.croppie.setZoom(0.1)
          })
        }
        reader.readAsDataURL(imagem)
      }
    },
    recortarImagem () {
      const options = {
        type: 'base64',
        format: 'png'
      }
      options.circle = false
      this.$refs.croppie.result(options, output => {
        this.inserirArquivoFirebase(output)
        this.cropping = false
      })
      this.imagem_temp = undefined
    },
    inserirArquivoFirebase (imagem) {
      this.$store.dispatch('inserirArquivoFirebase', {
        arquivo: imagem,
        id: Date.parse(new Date()).toString(),
        local: this.item.id,
        tipo: 'png',
        rota: 'itens'
      }).then(url => {
        this.imagens[this.indexSelecionado] = url
        this.item.imagens = this.imagens.filter(item => !!item)
        this.indexSelecionado = undefined
        this.cadastrarItem()
          .then(() => {
            this.activeAlert('Imagem inserida com sucesso.', 'success')
          }).catch(() => {
            this.activeAlert('Não foi possível inserir imagem.', 'error')
          })
      })
    },
    removerImagem (imagem, index) {
      this.imagens.splice(index, 1)
      this.$store.dispatch('deletarArquivoFirebase', imagem)
      this.item.imagens = this.imagens.filter(item => !!item)
      this.imagens = this.imagens.length === 0 ? [undefined] : this.imagens
      this.item.imagens = this.imagens
      this.step_image = 1
      this.activeAlert('Imagem removida com sucesso.', 'success')
      this.cadastrarItem()
    }
  }
}
</script>
