<template>
  <v-card class="elevation-0">
    <l-map
      v-if="center"
      ref="map"
      style="height: 300px; width: 100%;"
      :zoom="zoom"
      :center="center"
      @update:zoom="zoomUpdated"
      @update:center="centerUpdated"
      @update:bounds="boundsUpdated"
    >
      <l-tile-layer
        :name="name"
        :url="url"
        :attribution="attribution"
      >
      </l-tile-layer>
      <l-marker
        :visible="visible"
        :draggable="draggable"
        :lat-lng.sync="position"
        @click="() => {}"
      ></l-marker>
    </l-map>
    <v-card-text
      class="pt-4"
      style="position: relative;"
    >
    <v-btn
      absolute
      top
      right
      fab
      @click="novaLocalizacao"
      style="z-index:5000;
      margin-top: -60px;"
      small
      color="gray lighten-2"
    >
      <v-icon>pin_drop</v-icon>
    </v-btn>
      <div class="pl-3">
        <h3 class="display-1 font-weight-light orange--text mb-2">Geolocalize sua empresa</h3>
        <div class="font-weight-light title mb-2">
          Tentamos encontrar você pelo endereço<br>
          mas precisamos que você ajuste para finalizarmos.
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        color="primary"
        block
        @click="confirmarGeoposition"
      >
        Confirmar geolocalização
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { empresasDao } from '@/api'
import { L, LMap, LTileLayer, LMarker } from 'vue2-leaflet'

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker
  },
  data () {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: 'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
      name: 'OpenTopoMap',
      maxZoom: 18,
      zoom: 17,
      coordenadas: {
        latitude: null,
        longitude: null
      },
      bounds: null,
      center: null,
      draggable: true,
      nomeBtnLocalizacao: 'Nova Localização',
      localizacaoFlag: false
    }
  },
  watch: {
    empresa (val) {
      if (val !== null && val !== undefined) {
        if (this.empresa.latitude && this.empresa.longitude) {
          this.carregarMapa(this.empresa.latitude, this.empresa.longitude)
        } else {
          if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
              this.carregarMapa(position.coords.latitude, position.coords.longitude)
            })
            /* geolocation is available */
          } else {
            alert('Não é possível acessar a geolocalização do seu dispositivo.')
          }
        }
      }
    },
    endereco (val) {
      if (val !== null && val !== undefined) {
        this.$store.dispatch('pegarCoordenadas', {
          logradouro: this.endereco.logradouro,
          numero: this.endereco.numero,
          bairro: this.endereco.bairro,
          cidade: this.endereco.cidade,
          estado: this.endereco.estado
        }).then(json => {
          this.coordenadas.latitude = json.latitude
          this.coordenadas.longitude = json.longitude
        })
      }
    }
  },
  computed: {
    headers () {
      return this.$store.getters.headers
    },
    visible () {
      return !!this.position
    },
    empresa () {
      return this.$store.getters.empresa
    },
    endereco () {
      return this.$store.getters.endereco
    }
  },
  methods: {
    zoomUpdated (zoom) {
      this.zoom = zoom
    },
    centerUpdated (center) {
      this.center = center
    },
    boundsUpdated (bounds) {
      this.bounds = bounds
    },
    carregarMapa (latitude, longitude) {
      this.position = L.latLng(latitude, longitude)
      this.center = L.latLng(latitude, longitude)
      setTimeout(function () { window.dispatchEvent(new Event('resize')) }, 250)
    },
    novaLocalizacao () {
      if (this.localizacaoFlag === false) {
        this.carregarMapa(this.coordenadas.latitude, this.coordenadas.longitude)
        this.localizacaoFlag = true
      } else {
        this.carregarMapa(this.empresa.latitude, this.empresa.longitude)
        this.localizacaoFlag = false
      }
    },
    confirmarGeoposition () {
      this.$store.commit('setLoading', true)

      const body = {
        cnpj: this.empresa.cnpj,
        latitude: this.position.lat,
        longitude: this.position.lng
      }

      empresasDao.geolocateEmpresa(body, this.headers).then(response => {
        this.$store.commit('setLoading', false)
        if (response.ok) {
          this.$store.dispatch('geoposicionarEmpresa', this.position)
        } else {
          this.$store.commit('setError', 'Não foi possível atualizar a gelocalização da empresa')
        }
      })
      this.$store.dispatch('setSuccess', 'Geolocalização atualizada com sucesso')
    }
  },
  mounted () {
    if (this.empresa) {
      if (this.empresa.latitude && this.empresa.longitude) {
        this.carregarMapa(this.empresa.latitude, this.empresa.longitude)
      } else {
        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition((position) => {
            this.carregarMapa(position.coords.latitude, position.coords.longitude)
          })
          /* geolocation is available */
        } else {
          alert('Não é possível acessar a geolocalização do seu dispositivo.')
        }
      }
    }
  }
}
</script>
