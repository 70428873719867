<template>
  <aviso-bloqueio v-if="empresa && (!empresa.ativo || ![4, 5, 6].includes(empresa.tipo_empresa))"/>
  <v-container fluid fill-height v-else>
    <v-layout align-space-around justify-start fill-height column>
      <v-flex sm12>
        <v-toolbar
          color="#eee"
          class="elevation-0 secondary--text mb-4"
          style="background: transparent"
        >
          <v-toolbar-title>Meus Clientes</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" v-on="on" @click="filtrar=!filtrar" rounded small>
                <v-icon>{{ filtrar ? 'filter_list_off' : 'filter_list'}}</v-icon>
                {{ filtrar ? 'Recolher Filtro' : 'Filtro'}}
              </v-btn>
            </template>
            <span>{{ filtrar ? 'Recolher Filtro' : 'Filtrar' }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                rounded
                color='primary'
                @click="dialogNotificacao = true"
                v-on="on"
                class="ml-3"
              >
                <v-icon class="mr-1">chat_bubble</v-icon> Enviar Notificação
              </v-btn>
            </template>
            <span>Enviar uma notificação para seus clientes</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                rounded
                color='primary'
                @click="dialogEmail = true"
                v-on="on"
                class="ml-3"
              >
                <v-icon class="mr-1">mail</v-icon> Enviar E-mail
              </v-btn>
            </template>
            <span>Enviar um e-mail para seus clientes</span>
          </v-tooltip>
        </v-toolbar>
        <v-slide-x-transition>
          <v-card v-show="filtrar" class="ml-4 mr-4 mb-4">
            <v-card-text>
              <v-layout wrap justify-space-between>
                <v-flex xs10 md11>
                  <v-layout wrap justify-space-around>
                    <v-flex xs11 sm5 md3 lg2 xl2>
                      <v-text-field
                        label="Identificador"
                        v-model="id_pessoa"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs11 sm5 md4 lg3 xl2>
                      <v-text-field label="Nome" v-model="nome"></v-text-field>
                    </v-flex>
                    <v-flex xs11 sm4 md4 lg3 xl2>
                      <v-checkbox
                        v-model="assinante"
                        label="Apenas Assinantes"
                      ></v-checkbox>
                    </v-flex>
                    <v-flex xs11 sm4 md4 lg3 xl2>
                      <v-checkbox
                        v-model="apenasPlus"
                        label="Apenas Cliente Plus"
                      ></v-checkbox>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs2 md1 align-self-center>
                  <v-btn fab color="primary" @click="carregarClientes(true)">
                    <v-icon>search</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-slide-x-transition>
      </v-flex>
      <v-data-table
        :hide-default-header="!clientes.length"
        :items="clientes"
        :options.sync="pagination"
        :headers="clienteHeader"
        :server-items-length="totalItems"
        class="elevation-1"
        :loading="loading"
        hide-default-footer
        no-data-text="Você ainda não possui clientes"
      >
      <v-alert v-slot:no-results :value="true" color="error" icon="warning">
          Desculpe, não encontramos nenhum dado!
      </v-alert>
      <template v-slot:item="props">
        <tr>
          <td style="width: 10px">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-avatar
                  color="primary"
                  size="30"
                  v-on="on"
                >
                  <v-icon dark>business</v-icon>
                </v-avatar>
              </template>
              <span>Inscrição em Empresa</span>
            </v-tooltip>
          </td>
          <td class="text-xs-start">
            <template v-if="props.item && props.item.pessoa.id_pessoa">
              {{ props.item.pessoa.id_pessoa }}
            </template>
          </td>
          <td class="text-xs-start">
            <template v-if="props.item && props.item.pessoa.nome_completo">
              {{ props.item.pessoa.nome_completo }}
            </template>
          </td>
          <td class="text-xs-start">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" :color="isSubscriber(props.item.inscricao_posto) ? 'success' : 'error'">
                  {{ isSubscriber(props.item.inscricao_posto) ? 'check_circle' : 'cancel' }}
                </v-icon>
              </template>
              <span>{{ isSubscriber(props.item.inscricao_posto) ? 'Participa do Programa de Assinatura' : 'Não participa do Programa de Assinatura' }}</span>
            </v-tooltip>
          </td>
          <td class="text-xs-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn text icon color="primary" v-on="on">
                    <v-icon>{{isPlus(props.item.inscricao_posto) ? 'star' : 'star_outline'}}</v-icon>
                  </v-btn>
                </template>
                <span>{{ isPlus(props.item.inscricao_posto) ? 'Cliente Plus' : 'Não é Cliente Plus' }}</span>
              </v-tooltip>
          </td>
          <td class="text-xs-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn text icon color="primary" @click="clienteSelecionado = props.item, dialogVisualizar = true" v-on="on">
                  <v-icon>remove_red_eye</v-icon>
                </v-btn>
              </template>
              <span>Visualizar mais Detalhes</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
      </v-data-table>
      <div class="text-xs-center">
      <v-pagination v-model="pagination.page" :length="pages" circle></v-pagination>
      </div>
    </v-layout>
    <v-dialog v-model="dialogNotificacao" v-if="dialogNotificacao" width="700">
      <enviar-notificacao @fechar="dialogNotificacao = false" @erro="errorSnackbar" @sucesso="sucessSnackbar"/>
    </v-dialog>
    <v-dialog v-model="dialogEmail" v-if="dialogEmail" width="700">
      <enviar-email @fechar="dialogEmail = false" @erro="errorSnackbar" @sucesso="sucessSnackbar"/>
    </v-dialog>
    <v-dialog v-model="dialogVisualizar" v-if="dialogVisualizar" width="700">
      <detalhes-cliente
        @fechar="dialogVisualizar = false, clienteSelecionado = undefined"
        @error="errorSnackbar"
        @success="sucessSnackbar"
        @carregar="carregarClientes(false)"
        :cliente="clienteSelecionado"
        scrollable
      />
    </v-dialog>
    <v-snackbar
      v-model="snackbar.ativo"
      :color="snackbar.color"
      :timeout="4000"
      :vertical="true"
    >
      {{snackbar.text}}
    </v-snackbar>
  </v-container>
</template>

<script>

import EnviarNotificacao from '@/components/dialogs/EnviarNotificacao'
import EnviarEmail from '@/components/dialogs/EnviarEmail'
import DetalhesCliente from '@/components/dialogs/DetalhesCliente'
import AvisoBloqueio from '@/components/AvisoBloqueio'

export default {
  components: { EnviarNotificacao, DetalhesCliente, EnviarEmail, AvisoBloqueio },
  data () {
    return {
      snackbar: {
        ativo: false,
        color: 'info',
        text: ''
      },
      filtrar: false,
      nome: undefined,
      id_pessoa: undefined,
      assinante: false,
      apenasPlus: false,
      dialogNotificacao: false,
      dialogEmail: false,
      dialogVisualizar: false,
      clienteSelecionado: undefined,
      clienteHeader: [
        {
          align: 'left',
          sortable: false
        },
        {
          text: 'Identificador',
          align: 'left',
          sortable: false,
          value: 'pessoa.id_pessoa'
        },
        {
          text: 'Nome',
          align: 'left',
          sortable: false,
          value: 'nome'
        },
        {
          text: 'Assinante',
          align: 'left',
          sortable: false
        },
        {
          text: 'Plus',
          align: 'center',
          sortable: false
        },
        {
          align: 'left',
          sortable: false
        }
      ],
      clientes: [],
      totalItems: 0,
      pagination: {
        page: 1,
        rowsPerPage: 10
      }
    }
  },
  computed: {
    empresa () {
      return this.$store.getters.empresa
    },
    loading () {
      return this.$store.getters.loading
    },
    pages () {
      if (this.pagination.rowsPerPage == null ||
        this.totalItems == null
      ) return 0

      const pages = Math.ceil(this.totalItems / this.pagination.rowsPerPage)
      return pages
    },
    ready () {
      return !!this.empresa
    }
  },
  watch: {
    pagination: {
      handler (val) {
        if (val && this.ready) {
          this.carregarClientes(false)
        }
      },
      deep: true
    },
    empresa (val) {
      if (val) {
        this.carregarClientes(false)
      }
    }
  },
  mounted () {
    if (this.ready) {
      if (this.ready && this.pagination && this.pagination.page) {
        this.carregarClientes(false)
      }
    }
  },
  methods: {
    carregarClientes (isBtn) {
      const data = { empresa: this.empresa.cnpj }
      data.page = isBtn ? 1 : this.pagination.page
      if (this.id_pessoa) data.pessoa = this.id_pessoa
      if (this.assinante) data.inscricao__assinatura__isnull = !this.assinante
      if (this.apenasPlus) data.inscricao__cliente_plus = this.apenasPlus
      if (this.nome) data.pessoa__nome__icontains = this.nome
      this.$store.dispatch('pegarClientes', data).then(value => {
        this.clientes = value.results
        this.totalItems = value.count
      })
    },
    isSubscriber (listaInscricoes) {
      if (!listaInscricoes || listaInscricoes.length === 0) return false
      const lista = listaInscricoes.filter((obj) => {
        return !!obj.assinatura
      })
      return lista.length > 0
    },
    isPlus (listaInscricoes) {
      if (!listaInscricoes || listaInscricoes.length === 0) return false
      const lista = listaInscricoes.filter((obj) => {
        return obj.cliente_plus
      })
      return lista.length > 0
    },
    errorSnackbar (text) {
      this.smartLockDialog = false
      this.snackbar.color = 'error'
      this.snackbar.text = text
      this.snackbar.ativo = true
    },
    sucessSnackbar (text) {
      this.smartLockDialog = false
      this.snackbar.color = 'primary'
      this.snackbar.text = text
      this.snackbar.ativo = true
    }
  }
}
</script>
