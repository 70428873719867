<template>
  <v-card>
    <v-card-title class="justify-center">
        <h2>{{ tituloCard }}</h2>
        <v-tooltip bottom v-if="step === 4">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              fab
              small
              color="info"
              style="position: absolute; right: 2em;"
              :disabled="fotos[fotos.length - 1].url === undefined"
              @click="adicionarNovaImagem"
            >
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <span>Inserir uma nova imagem</span>
        </v-tooltip>
    </v-card-title>
    <v-divider></v-divider>
    <v-alert
      v-show="alerta"
      :type="tipo"
      class="mt-4 mb-1 pa-2"
      :value="true"
    >
      <h4 justify-center>{{alerta}}</h4>
    </v-alert>
    <v-card-text>
      <v-container flex grid-list-md>
        <v-stepper v-model="step" style="box-shadow: none;">
          <v-stepper-items>
            <v-stepper-content step="1"  class="pa-0" style="box-shadow: none;">
              <v-container flex grid-list-md>
                <v-form lazy-validation v-model="valid" ref="formVeiculo">
                  <v-layout row wrap fill-height justify-space-between>
                    <v-flex xs12 style="justify-content: end; display: flex;">
                      <v-switch
                          slot="activator"
                          class="ml-4"
                          v-model="dadosVeiculo.usado"
                          label="Usado"
                          color="info"
                          inset
                        />
                    </v-flex>
                    <v-flex xs6 v-if="!isVenda || dadosVeiculo.usado">
                      <v-text-field
                        outlined
                        hint="Digite no formato ABC-1234 ou ABC-1D23"
                        label="Placa"
                        v-mask="['AAA-#X##']"
                        :rules="[
                          (v) => !!v || 'Esse campo é obrigatório',
                          (v) => !!v && v.length == 8 || 'Placa Inválida'
                        ]"
                        v-model="dadosVeiculo.identificacao"
                        required
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        outlined
                        v-model="dadosVeiculo.modelo"
                        label="Modelo"
                        :rules="[
                          (v) => !!v || 'Esse campo é obrigatório'
                        ]"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs6 v-if="isVenda">
                      <v-autocomplete
                        outlined
                        v-model="dadosVeiculo.marca"
                        :rules="[
                          (v) => !!v || 'Esse campo é obrigatório'
                        ]"
                        label="Marca"
                        :items="[
                          'Agrale',
                          'Aston Martin',
                          'Audi',
                          'Avelloz',
                          'Bentley',
                          'BMW',
                          'BMW Motorrad',
                          'BYD',
                          'Chery',
                          'Chevrolet',
                          'Chrysler',
                          'Citroen',
                          'Dafra',
                          'Dodge',
                          'Ducati',
                          'Effa',
                          'Exeed',
                          'Ferrari',
                          'Fiat',
                          'Ford',
                          'Foton',
                          'GM',
                          'Haojue',
                          'Harley Davidson',
                          'Honda',
                          'Husqvarna',
                          'Hyundai',
                          'Iveco',
                          'JAC',
                          'Jaguar',
                          'Jeep',
                          'Kasinski',
                          'KTM',
                          'Kawasaki',
                          'Kia',
                          'Kymco',
                          'Lamborghini',
                          'Land Rover',
                          'Lexus',
                          'Lifan',
                          'Maserati',
                          'McLaren',
                          'Mercedes-AMG',
                          'Mercedes-Benz',
                          'Mini',
                          'Mitsubishi',
                          'MXF',
                          'Nissan',
                          'Outra',
                          'Peugeot',
                          'Piaggio',
                          'Porsche',
                          'Pro Tork',
                          'RAM',
                          'Renault',
                          'Rolls-Royce',
                          'Royal Enfield',
                          'Shineray',
                          'Smart',
                          'Sousa Motos',
                          'Subaru',
                          'Sundown',
                          'Suzuki',
                          'Toyota',
                          'Triumph',
                          'Troller',
                          'Volkswagen',
                          'Volvo',
                          'Voltz Motors',
                          'Yamaha'
                        ]"
                      >
                      </v-autocomplete>
                    </v-flex>
                    <v-flex xs6 v-else>
                      <v-text-field
                        outlined
                        v-model="dadosVeiculo.marca"
                        label="Marca"
                        :rules="[
                          (v) => !!v || 'Esse campo é obrigatório'
                        ]"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs6>
                      <v-select
                        outlined
                        v-model="dadosVeiculo.tipo_veiculo_object"
                        :items="tipos_veiculo"
                        label="Tipo do veículo"
                        item-text="denominacao"
                        item-value="id_tipo_veiculo"
                        no-data-text="Desculpe, não foi possível carregar os tipos de veiculo"
                        return-object
                      ></v-select>
                    </v-flex>
                    <v-slide-x-transition>
                      <v-flex xs12 v-if="isVenda && dadosVeiculo.usado">
                        <v-text-field
                          label="Quilometragem"
                          outlined
                          v-model="dadosVeiculo.quilometragem"
                          type="number"
                          :rules="[
                            (v) => !!v || 'Esse campo é obrigatório',
                            (v) => v >= 0 || 'O valor não pode ser menor que zero'
                          ]"
                        />
                      </v-flex>
                    </v-slide-x-transition>
                    <v-flex xs12 v-if="isVenda && !dadosVeiculo.usado">
                      <v-text-field
                        label="Quantidade em estoque"
                        outlined
                        v-model="dadosVeiculo.qtd_estoque"
                        type="number"
                        :rules="[
                          (v) => !!v || 'Esse campo é obrigatório',
                          (v) => v >= 0 || 'O valor não pode ser menor que zero'
                        ]"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout wrap fill-height justify-space-between>
                    <v-flex xs12>
                      <v-select
                        outlined
                        v-model="dadosVeiculo.cor_texto"
                        label="Cor"
                        item-value="value"
                        :items="[
                          { text: 'Branca', value: 'BRANCA' },
                          { text: 'Preta', value: 'PRETA' },
                          { text: 'Prata', value: 'PRATA' },
                          { text: 'Cinza', value: 'CINZA' },
                          { text: 'Azul', value: 'AZUL' },
                          { text: 'Vermelha', value: 'VERMELHA' },
                          { text: 'Verde', value: 'VERDE' },
                          { text: 'Amarela', value: 'AMARELA' },
                          { text: 'Marrom', value: 'MARROM' },
                          { text: 'Bege', value: 'BEGE' },
                          { text: 'Rosa', value: 'ROSA' }
                        ]"
                      >
                      </v-select>
                    </v-flex>
                    <v-flex
                      xs12
                      v-if="isVenda"
                    >
                      <v-autocomplete
                        v-if="
                          tipo_veiculo !== 'motocicleta'
                        "
                        outlined
                        v-model="dadosVeiculo.motor"
                        label="Potência do motor"
                        :items="[
                          '1.0', '1.1', '1.2', '1.3', '1.4', '1.5', '1.6', '1.7', '1.8', '1.9',
                          '2.0', '2.1', '2.2', '2.3', '2.4', '2.5', '2.6', '2.7', '2.8', '2.9',
                          '3.0', '3.1', '3.2', '3.3', '3.4', '3.5', '3.6', '3.7', '3.8', '3.9',
                          '4.0', '4.1', '4.2', '4.3', '4.4', '4.5', '4.6', '4.7', '4.8', '4.9',
                          '5.0', '5.1', '5.2', '5.3', '5.4', '5.5', '5.6', '5.7', '5.8', '5.9',
                          '6.0', '6.1', '6.2', '6.3', '6.4', '6.5', '6.6', '6.7', '6.8', '6.9',
                          '7.0', '7.1', '7.2', '7.3', '7.4', '7.5', '7.6', '7.7', '7.8', '7.9',
                          '8.0', '8.1', '8.2', '8.3', '8.4', '8.5', '8.6', '8.7', '8.8', '8.9',
                          '9.0', '9.1', '9.2', '9.3', '9.4', '9.5', '9.6', '9.7', '9.8', '9.9',
                          '10.0', '10.1', '10.2', '10.3', '10.4', '10.5', '10.6', '10.7', '10.8', '10.9',
                          '11.0', '11.1', '11.2', '11.3', '11.4', '11.5', '11.6', '11.7', '11.8', '11.9',
                          '12.0', '12.1', '12.2', '12.3', '12.4', '12.5', '12.6', '12.7', '12.8', '12.9',
                          '13.0', '13.1', '13.2', '13.3', '13.4', '13.5', '13.6', '13.7', '13.8', '13.9',
                          '14.0', '14.1', '14.2', '14.3', '14.4', '14.5', '14.6', '14.7', '14.8', '14.9',
                          '15.0', '15.1', '15.2', '15.3', '15.4', '15.5', '15.6', '15.7', '15.8', '15.9'
                        ]"
                      />
                      <v-autocomplete
                        v-else
                        outlined
                        v-model="dadosVeiculo.cilindradas"
                        label="Cilindradas"
                        :items="[
                          '50', '100', '110', '125', '150', '160', '190', '200', '250', '300', '350', '400',
                          '420', '450', '500', '550', '600', '650', '700', '750', '800', '850', '900', '1000'
                        ]"
                      />
                    </v-flex>
                    <v-flex xs12
                      v-if="
                          isVenda &&
                          (
                            tipo_veiculo !== 'motocicleta'
                          )
                      "
                    >
                      <v-autocomplete
                        outlined
                        v-model="dadosVeiculo.qtd_portas"
                        label="Quantidade de Portas"
                        :items="[
                          1, 2, 3, 4, 5, 6
                        ]"
                      >
                      </v-autocomplete>
                    </v-flex>
                    <v-flex :xs="tipo_veiculo !== 'motocicleta' ? 9 : 12" v-if="step === 1">
                      <v-combobox
                        outlined
                        label="Combustivel"
                        :items="combustiveis"
                        item-text="nome"
                        :rules="[(v) => !!v.length || 'Esse campo é obrigatório']"
                        required
                        item-value="id_combustivel"
                        hide-no-data
                        :menu-props="{maxHeight: 120}"
                        hide-details
                        multiple
                        class="pb-1"
                        return-object
                        v-model="dadosVeiculo.combustiveis"
                      >
                        <template v-slot:selection="data">
                          <svg-filler :path="require(`@/assets/combs/${data.item.icone}.svg`)" width="35px" height="35px" :fill="data.item.color"/>
                        </template>
                      </v-combobox>
                    </v-flex>
                      <v-flex
                        xs6
                        v-if="
                          isVenda &&
                          (
                            tipo_veiculo !== 'motocicleta'
                          )
                        "
                      >
                        <v-select
                          label="Tipo de Câmbio"
                          outlined
                          v-model="dadosVeiculo.cambio"
                          :items="[
                            { text: 'Manual', value: 1 },
                            { text: 'Automático', value: 2 },
                            { text: 'Automatizado', value: 3 },
                            { text: 'CVT', value: 4 }
                          ]"
                        ></v-select>
                      </v-flex>
                      <v-flex
                        xs6
                        v-if="isVenda &&
                          (
                            tipo_veiculo !== 'motocicleta'
                          )
                        "
                      >
                        <v-select
                          label="Tipo de Direção"
                          v-model="dadosVeiculo.direcao"
                          outlined
                          :items="[
                            { text: 'Mecânica', value: 1 },
                            { text: 'Hidráulica', value: 2 },
                            { text: 'Elétrica', value: 3 },
                            { text: 'Eletro-hidráulica', value: 4 }
                          ]"
                        ></v-select>
                      </v-flex>
                      <v-flex xs6 v-if="isVenda">
                        <v-text-field
                          outlined
                          v-model="dadosVeiculo.ano_fabricacao"
                          label="Ano de Fabricação / modelo"
                          placeholder="0000/0000"
                          v-mask="['####/####']"
                        >
                        </v-text-field>
                      </v-flex>
                      <v-flex xs6 v-if="isVenda">
                        <v-text-field
                          v-money="money"
                          outlined
                          v-model.lazy="dadosVeiculo.valor"
                          label="Valor do Veículo"
                          prefix="R$"
                          v-if="!salvando"
                        />
                        <v-text-field
                          v-model="dadosVeiculo.valor"
                          label="Valor do Veículo"
                          prefix="R$"
                          outlined
                          v-else
                        />
                      </v-flex>
                      <v-flex xs12 v-if="dadosVeiculo.valor_estimado && isVenda" class="mb-4 mt-0">
                        <h3>Preço estimado do veículo: <b style="color:green">{{dadosVeiculo.valor_estimado | currency({ fractionCount: 2 })}}</b></h3>
                      </v-flex>
                    <v-layout
                      column
                      v-if="!isVenda"
                      class="ma-0 pa-0"
                    >
                      <v-flex xs3>
                        <v-select
                          v-model="select"
                          :items="options_cliente"
                          outlined
                          label="A quem pertence esse veículo?"
                          :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                          required
                        >
                        </v-select>
                      </v-flex>
                      <v-flex xs6 class="text-xs-center subheading gray--text font-weight-regular ma-0 pa-0" v-if="select == 'Sim'">
                        Selecione o funcinário dono do veículo
                      </v-flex>
                      <v-autocomplete  v-if="select == 'Do funcionário'"
                        outlined
                        :items="funcionarios"
                        v-model="funcionarioSelected"
                        color="primary"
                        item-text="nome_email"
                        label="Colaboradores"
                        return-object
                      ></v-autocomplete>
                    </v-layout>
                  </v-layout>
                </v-form>
              </v-container>
            </v-stepper-content>
            <v-stepper-content step="2" class="pa-0">
              <v-container flex grid-list-md>
                <v-layout
                  wrap
                  justify-space-between
                >
                  <v-flex xs12>
                    <h2>Opcionais</h2>
                  </v-flex>
                  <v-flex
                    xs4
                    v-for="(opcional, index) in opcionais"
                    :key="index"
                  >
                    <v-checkbox
                      slot="activator"
                      class="d-inline-block ml-4"
                      v-model="opcional.ativo"
                      :label="opcional.nome"
                      color="info"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    v-if="dadosVeiculo.usado"
                  >
                    <h2>Estado financeiro</h2>
                  </v-flex>
                  <v-radio-group
                    v-model="dadosVeiculo.quitando"
                    v-if="dadosVeiculo.usado"
                  >
                    <v-radio
                      label="Quitado"
                      color="info"
                      :value="false"
                    ></v-radio>
                    <v-radio
                      class="mt-4"
                      label="Financiado"
                      color="info"
                      :value="true"
                    ></v-radio>
                  </v-radio-group>
                  <v-flex
                    xs12
                    class="mt-4"
                    v-if="dadosVeiculo.usado"
                  >
                    <h2>Documentação e regularização</h2>
                  </v-flex>
                  <v-flex
                    xs12
                    style="flex-direction: column;
                    display: flex;"
                    v-if="dadosVeiculo.usado"
                  >
                    <v-checkbox
                      slot="activator"
                      class="d-inline-block ml-4"
                      v-model="dadosVeiculo.ipva_pago"
                      label="IPVA Pago"
                      color="info"
                    />
                    <v-checkbox
                      slot="activator"
                      class="d-inline-block ml-4"
                      v-model="dadosVeiculo.com_multa"
                      label="Com Multa"
                      color="info"
                    />
                    <v-checkbox
                      slot="activator"
                      class="d-inline-block ml-4"
                      v-model="dadosVeiculo.de_leilao"
                      label="De Leilão"
                      color="info"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    class="mb-4"
                  >
                    <h2>Conservação e garantia</h2>
                  </v-flex>
                  <v-flex
                    xs12
                    style="flex-direction: column; display: flex;"
                  >
                    <v-checkbox
                      v-if="dadosVeiculo.usado"
                      slot="activator"
                      class="d-inline-block ml-4"
                      v-model="dadosVeiculo.unico_dono"
                      label="Único Dono"
                      color="info"
                    />
                    <v-checkbox
                      slot="activator"
                      class="d-inline-block ml-4"
                      v-model="dadosVeiculo.com_manual"
                      label="Com Manual"
                      color="info"
                    />
                    <v-checkbox
                      slot="activator"
                      class="d-inline-block ml-4"
                      v-model="dadosVeiculo.com_garantia"
                      label="Com Garantia"
                      color="info"
                    />
                    <v-checkbox
                      v-if="dadosVeiculo.usado"
                      slot="activator"
                      class="d-inline-block ml-4"
                      v-model="dadosVeiculo.revisoes_feitas"
                      label="Revisões feitas em concessionária"
                      color="info"
                    />
                    <v-checkbox
                      slot="activator"
                      class="d-inline-block ml-4"
                      v-model="dadosVeiculo.chave_reserva"
                      label="Chave Reserva"
                      color="info"
                    />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-container flex grid-list-md>
                <v-layout justify-center wrap>
                  <v-bottom-navigation
                    active
                    v-model="stepOfertar"
                    color="info"
                    grow
                    class="ma-1 mb-4"
                    style="box-shadow: none;"
                  >
                    <v-btn style="font-size: 1em;" class="mr-4" x-large>
                      <v-icon size="30">person</v-icon>
                      Usuários do Aplicativo
                    </v-btn>
                    <v-btn style="font-size: 1em;" class="ml-4" x-large>
                      <v-icon size="30">business</v-icon>
                      Revendedoras de Veículo
                    </v-btn>
                  </v-bottom-navigation>
                  <v-flex xs12 class="ma-4">
                    <v-stepper v-model="stepOfertar">
                      <v-stepper-items>
                        <v-stepper-content step="0">
                          <h3 class="text-center">OFERTAR PARA PESSOAS FÍSICAS</h3>
                        </v-stepper-content>
                        <v-stepper-content step="1">
                          <h2 class="text-center" style="margin-bottom: 2em;">SELECIONE UMA OPÇÃO</h2>
                          <v-layout class="mt-4 mb-4" justify-space-around style="width: 100%;" wrap>
                            <v-flex xs6>
                              <v-btn
                                outlined
                                block
                                :text="btnAtivo !== 1"
                                color="info"
                                @click="btnAtivo = 1"
                                :class=" btnAtivo == 1 ?'white--text' : ''"
                              >
                                Todas as empresas
                              </v-btn>
                            </v-flex>
                            <v-flex xs6>
                              <v-btn
                                outlined
                                block
                                :text="btnAtivo !== 2"
                                color="info"
                                @click="btnAtivo = 2"
                                :class=" btnAtivo == 2 ?'white--text' : ''"
                              >
                                Empresas selecionadas
                              </v-btn>
                            </v-flex>
                            <v-flex xs12 class="mt-4">
                              <v-slide-x-transition>
                                <v-layout column v-if="btnAtivo == 2">
                                  <v-layout justify-end class="mr-2 mb-4">
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-btn
                                          v-on="on"
                                          fab
                                          small
                                          color="info"
                                          class="white--text"
                                          @click="filtroAtivo = !filtroAtivo"
                                        >
                                          <v-icon>{{ filtroAtivo ? 'filter_list_off' : 'filter_list' }}</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>
                                        {{ filtroAtivo ? 'Esconder filtro' : 'Mostrar filtro' }}
                                      </span>
                                    </v-tooltip>
                                  </v-layout>
                                  <v-slide-x-transition>
                                    <v-card class="ma-2" v-show="filtroAtivo">
                                      <v-card-actions class="justify-center d-inline-flex">
                                        <v-flex xs4>
                                          <v-text-field
                                            label="Nome"
                                            color="info"
                                            v-model="nome_fantasia"
                                          />
                                        </v-flex>
                                        <v-flex xs3>
                                          <v-text-field
                                            label="Cidade"
                                            color="info"
                                            v-model="cidade"
                                          />
                                        </v-flex>
                                        <v-flex xs3>
                                          <v-select
                                            label="Estado"
                                            color="info"
                                            :items="estados"
                                            v-model="estado"
                                            clearable
                                          />
                                        </v-flex>
                                        <v-flex xs1>
                                          <v-btn
                                            fab
                                            color="info"
                                            small
                                            class="white--text"
                                            @click="pegarRevendedoras(true)"
                                          >
                                            <v-icon>search</v-icon>
                                          </v-btn>
                                        </v-flex>
                                      </v-card-actions>
                                    </v-card>
                                  </v-slide-x-transition>
                                  <v-list>
                                    <v-list-item
                                      v-for="(empresa, index) in empresas"
                                      :key="index"
                                      @click="function () {
                                        const temp = isSelected(empresa)
                                        if (temp) removerEmpresa(empresa)
                                        else adicionarEmpresa(empresa)
                                      }"
                                      class="ma-1"
                                      :style="isSelected(empresa) ? {'border': '1px solid #2196f3'} : {}"
                                    >
                                      <v-list-item-avatar>
                                        <v-icon color="info">business</v-icon>
                                      </v-list-item-avatar>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          {{ empresa.nome_fantasia }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                          {{ empresa.cnpj }}
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                      <v-list-item-content>
                                        <v-list-item-subtitle>
                                          <v-icon color="info">map</v-icon>
                                          <span v-if="empresa.endereco" class="ml-1">{{ empresa.endereco.cidade }}/{{ empresa.endereco.estado }}</span>
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                  <div class="text-xs-center">
                                    <v-pagination
                                      v-model="pagination_empresas.page"
                                      :length="pages"
                                      circle
                                      color="info"
                                    ></v-pagination>
                                  </div>
                                </v-layout>
                              </v-slide-x-transition>
                            </v-flex>
                          </v-layout>
                          <h2 class="text-center" style="margin-bottom: 2em; margin-top: 2em;">É UMA DISPUTA DE LANCES?</h2>
                          <v-layout class="mt-4 mb-4" justify-space-around style="width: 100%;" wrap>
                            <v-flex xs6>
                              <v-btn
                                outlined
                                block
                                :text="tem_leilao"
                                color="info"
                                @click="tem_leilao = false"
                                :class="!tem_leilao ?'white--text' : ''"
                              >
                                NÃO
                              </v-btn>
                            </v-flex>
                            <v-flex xs6>
                              <v-btn
                                outlined
                                block
                                :text="!tem_leilao"
                                color="info"
                                @click="tem_leilao = true"
                                :class="tem_leilao ? 'white--text' : ''"
                              >
                                SIM
                              </v-btn>
                            </v-flex>
                            <v-flex xs12 class="mt-4">
                              <v-slide-x-transition>
                                <v-form v-if="tem_leilao" lazy-validation v-model="validoLeilao" ref="formularioLeilao">
                                  <v-layout wrap>
                                    <v-flex xs6>
                                      <v-menu
                                        ref="menuDataInicio"
                                        v-model="menuDataInicio"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                      >
                                        <template v-slot:activator="{ on }">
                                          <v-text-field
                                            label="Data de início"
                                            v-model="leilao.data_inicio"
                                            prepend-icon="mdi-calendar"
                                            required
                                            readonly
                                            v-on="on"
                                            :rules="[
                                                (v) => !!v || 'A data inicial é obrigatória'
                                              ]"
                                          />
                                        </template>
                                        <v-date-picker
                                          v-model="data_inicio"
                                          no-title
                                          @input="menuDataInicio = false"
                                          locale="pt-br"
                                          :allowed-dates="(date) => {
                                            return Date.parse(date) >= today
                                          }"
                                        ></v-date-picker>
                                      </v-menu>
                                    </v-flex>
                                    <v-flex xs6>
                                      <v-menu
                                        ref="menuDataEncerramento"
                                        v-model="menuDataEncerramento"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                      >
                                        <template v-slot:activator="{ on }">
                                          <v-text-field
                                            label="Data de encerramento"
                                            v-model="leilao.data_fim"
                                            prepend-icon="mdi-calendar"
                                            required
                                            readonly
                                            v-on="on"
                                            :rules="[
                                              (v) => !!v || 'A data de encerramneto é obrigatória'
                                            ]"
                                          />
                                        </template>
                                        <v-date-picker
                                          v-model="date_encerramento"
                                          no-title
                                          @input="menuDataEncerramento = false"
                                          locale="pt-br"
                                          :allowed-dates="(date) => {
                                            return testarDataFim(date)
                                          }"
                                        ></v-date-picker>
                                      </v-menu>
                                    </v-flex>
                                    <v-flex xs6>
                                      <v-menu
                                        ref="menuHorarioInicio"
                                        v-model="menuHorarioInicio"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                      >
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-text-field
                                            v-model="leilao.horario_inicio"
                                            required
                                            label="Horario de ínicio"
                                            prepend-icon="mdi-clock-time-four-outline"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            :rules="[
                                              (v) => !!v || 'A horário de inácia é obrigatória'
                                            ]"
                                          ></v-text-field>
                                        </template>
                                        <v-time-picker
                                          v-if="menuHorarioInicio"
                                          v-model="leilao.horario_inicio"
                                          full-width
                                          no-title
                                          @click:minute="menuHorarioInicio = false"
                                          format="24hr"
                                        ></v-time-picker>
                                      </v-menu>
                                    </v-flex>
                                    <v-flex xs6>
                                      <v-menu
                                        ref="menuHorarioEncerramento"
                                        v-model="menuHorarioEncerramento"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                      >
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-text-field
                                            required
                                            v-model="leilao.horario_fim"
                                            label="Horario de encerramento"
                                            prepend-icon="mdi-clock-time-four-outline"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            :rules="[
                                              (v) => !!v || 'A horário de encerramento é obrigatória'
                                            ]"
                                          ></v-text-field>
                                        </template>
                                        <v-time-picker
                                          v-if="menuHorarioEncerramento"
                                          v-model="leilao.horario_fim"
                                          full-width
                                          no-title
                                          @click:minute="menuHorarioEncerramento = false"
                                          format="24hr"
                                        ></v-time-picker>
                                      </v-menu>
                                    </v-flex>
                                    <v-flex xs12>
                                      <v-text-field
                                        v-model.lazy="leilao.valor_inicial"
                                        v-money="money"
                                        prefix="R$"
                                        v-if="!salvando"
                                        label="Valor Inicial"
                                      />
                                    </v-flex>
                                  </v-layout>
                                </v-form>
                              </v-slide-x-transition>
                            </v-flex>
                          </v-layout>
                        </v-stepper-content>
                      </v-stepper-items>
                    </v-stepper>
                  </v-flex>
                  <v-flex xs12 class="mt-4">
                    <v-textarea
                      outlined
                      label="Descrição"
                      color="info"
                      hint="Informe aqui o estado do veículo e outras informações relevantes"
                      placeholder="Informe aqui o estado do veículo e outras informações relevantes"
                      v-model="dadosVeiculo.descricao"
                      counter
                      maxlength="500"
                    >
                    </v-textarea>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-stepper-content>
            <v-stepper-content step="4" class="pa-0">
              <v-container flex grid-list-md>
                <v-layout justify-center wrap>
                  <input
                    type="file"
                    style="display:none"
                    accept="image/*"
                    ref="imagemInput"
                    @input="carregarImagem"
                  />
                  <v-flex xs12 v-show="cropping">
                    <vue-croppie
                      ref="croppie"
                      :enableExif="true"
                      :enforceBoundary="true"
                      :enableResize="false"
                      :enableOrientation="false"
                      :mouseWheelZoom="true"
                      :showZoomer="false"
                      :boundary="{ width: 700, height: 500 }"
                      :viewport="{ width: 600, height: 450}"
                    ></vue-croppie>
                    </v-flex>
                    <v-btn
                      v-show="cropping"
                      text
                      @click="recortarImagem()"
                      color="grey darken-2"
                      block
                      class="mt-4"
                    >
                      Recortar imagem
                      </v-btn>

                  <v-stepper
                    v-model="step_image"
                    style="
                      box-shadow: none;
                      height: 100%;
                      width: 100%;
                    "
                    v-if="!loading || !salvando"
                  >
                    <v-stepper-items style="box-shadow: none;">
                      <v-stepper-content
                        v-for="(item, index) in fotos"
                        :key="index"
                        :step="Number(index) + 1"
                        class="ma-0 pa-0"
                        style="box-shadow: none; height: 100%;"
                      >
                        <v-layout justify-center column full-width>
                          <v-tooltip bottom v-if="!cropping">
                            <template v-slot:activator="{ on }">
                              <v-hover v-slot="{ hover }">
                                <v-card
                                  :elevation="hover && !item.id ? 10 : 1"
                                  height="25em"
                                  class="ml-3 mr-3 mt-2 pa-0"
                                  :loading="loading"
                                  v-on="on"
                                  style="
                                    justify-content: center;
                                    align-items: center;
                                    display: flex;
                                  "
                                  @click="function () {
                                    if (!item.id) {
                                      $refs.imagemInput.click()
                                      indexSelecionado = index
                                    }
                                  }"
                                  :disabled="!item.regiao"
                                >
                                  <v-layout
                                    justify-center
                                    column
                                    style="height: 100%;"
                                    class="ma-0 pa-0"
                                  >
                                    <v-img
                                      v-if="item.url"
                                      :src="item.url"
                                      alt="Imagem"
                                      style="cursor: pointer;"
                                      max-height="100%"
                                      contain
                                    />
                                    <template v-if="!item.url && !cropping">
                                      <v-icon size="20em">image</v-icon>
                                      <h2
                                        class="text-center"
                                        style="color: rgba(0, 0, 0, 0.54);"
                                      >
                                        Clique para inserir imagem
                                      </h2>
                                    </template>
                                  </v-layout>
                                </v-card>
                              </v-hover>
                            </template>
                            <h4>{{ !item.id ? 'Clique para inserir uma imagem' : item.nome }}</h4>
                          </v-tooltip>
                          <template v-if="!item.id">
                            <v-flex class="ma-3">
                              <v-select
                                class="mt-4 mr-2 ml-2"
                                outlined
                                label="Selecione um região do veículo"
                                :items="tipos_regioes"
                                v-model="item.regiao"
                              ></v-select>
                              <v-text-field
                                v-if="item.regiao === 'Outra região'"
                                class="mr-2 ml-2"
                                outlined
                                label="Digite o nome da região do veículo referente a foto"
                                :items="tipos_regioes"
                                v-model="item.nome"
                              ></v-text-field>
                              <v-checkbox
                                  v-model="item.externa"
                                  color="primary"
                                  @update:modelValue="item.url = item.externa ? '' : item.url"
                                  class="mt-0"
                              >
                                  <template slot="label">
                                      <span class="body-2 grey--text">Inserir apenas URL?</span>
                                  </template>
                              </v-checkbox>
                              <v-text-field
                                v-if="item.externa"
                                :disabled="!!item.id"
                                outlined
                                class="mr-2 ml-2"
                                label="Digite a URL"
                                hint="Atenção se a imagem for excluída do sistema a qual faz parte a URL precisará ser troca"
                                v-model="item.url"/>
                            </v-flex>
                            <v-btn
                              v-if="item.externa"
                              :disabled="!!item.id || !item.regiao || !item.url"
                              block
                              small
                              color="success"
                              class="mr-2 ml-2"
                              @click="cadastrarUrl(item, index)"
                            ><v-icon class="mr-1">save</v-icon> Salvar Imagem</v-btn>
                          </template>
                          <v-btn
                            small
                            color="error"
                            class="mb-4 mt-4 mr-2 ml-2"
                            :disabled="!item.url && !item.regiao"
                            @click.stop.prevent="removerImagem(item, index)"
                          >
                            <v-icon class="mr-1">delete</v-icon>
                            Apagar Imagem
                          </v-btn>
                        </v-layout>
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
                  <v-card
                    v-else
                    hover
                    height="25em"
                    width="100%"
                    class="ml-3 mr-3 mt-2 pa-0"
                    style="
                      justify-content: center;
                      align-items: center;
                      display: flex;
                    "
                  >
                    <v-progress-circular
                      indeterminate
                      :size="50"
                      color="info"
                    ></v-progress-circular>
                  </v-card>
                  <v-layout wrap justify-space-between>
                    <v-btn
                      icon
                      color="info"
                      class="ma-3"
                      @click="step_image--"
                      :disabled="step_image === 1"
                    >
                      <v-icon>arrow_back</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      color="info"
                      class="ma-3"
                      @click="step_image++"
                      :disabled="fotos.length === step_image"
                    >
                      <v-icon>arrow_forward</v-icon>
                    </v-btn>
                  </v-layout>
                </v-layout>
              </v-container>
            </v-stepper-content>
            <v-stepper-content step="5" class="pa-0">
              <v-container flex grid-list-md>
                <v-layout justify-center wrap>
                  <input
                    type="file"
                    style="display:none"
                    accept="image/pdf*"
                    ref="arquivoInput"
                    @input="anexar"
                  />
                  <v-layout
                    justify-center
                    column
                    full-width
                    style="
                      justify-content: center;
                      align-items: center;
                      display: flex;
                    "
                  >
                    <v-tooltip bottom v-if="!loading || !salvando">
                      <template v-slot:activator="{ on }">
                        <v-hover v-slot="{ hover }">
                          <v-card
                            :elevation="hover && !arquivoAnexado ? 10 : 1"
                            height="35em"
                            width="25em"
                            class="ml-3 mr-3 mt-2 pa-0"
                            :loading="loading"
                            v-on="on"
                            style="
                              justify-content: center;
                              align-items: center;
                              display: flex;
                            "
                            @click="$refs.arquivoInput.click()"
                          >
                            <v-layout
                              justify-center
                              column
                              style="height: 100%;"
                              class="ma-0 pa-0"
                            >
                              <v-img
                                v-if="arquivoAnexado && !is_pdf"
                                :src="arquivoAnexado"
                                alt="Imagem"
                                style="cursor: pointer;"
                                max-height="100%"
                                contain
                              />
                              <object
                                v-else-if="arquivoAnexado && is_pdf"
                                :data="arquivoAnexado"
                                type="application/pdf"
                                width="auto"
                                height="100%"
                              ></object>
                              <template v-else>
                                <v-icon size="15em">attach_file</v-icon>
                                <h2
                                  class="text-center"
                                  style="color: rgba(0, 0, 0, 0.54);"
                                >
                                  Clique para anexar arquivo
                                </h2>
                              </template>
                            </v-layout>
                          </v-card>
                        </v-hover>
                      </template>
                      <h4>Clique para anexar arquivo</h4>
                    </v-tooltip>
                    <v-card
                      v-else
                      hover
                      height="35em"
                      width="25em"
                      class="ml-3 mr-3 mt-2 pa-0"
                      style="
                        justify-content: center;
                        align-items: center;
                        display: flex;
                      "
                    >
                      <v-progress-circular
                        indeterminate
                        :size="50"
                        color="info"
                      ></v-progress-circular>
                    </v-card>
                    <v-btn
                      small
                      color="error"
                      class="mb-4 mt-4 mr-2 ml-2"
                      :disabled="!arquivoAnexado"
                      @click="removerArquivo"
                      :loading="loading"
                    >
                      <v-icon class="mr-1">delete</v-icon>
                      Remover Arquivo
                    </v-btn>
                  </v-layout>
                </v-layout>
              </v-container>
            </v-stepper-content>
            <v-stepper-content step="6" class="pa-0">
              <div>
                <div class="headline pt-2">Dados do veículo:</div>
                <v-flex xs12 class="text-xs-left pt-2" v-if="veiculo_existente.usuario"> Proprietário: {{veiculo_existente.usuario.pessoa.nome}}  |  CPF: {{veiculo_existente.usuario.pessoa.cpf}}</v-flex>
                <v-flex xs12 class="text-xs-left pt-2" v-if="veiculo_existente.empresa"> Vinculado a empresa: {{veiculo_existente.empresa.razao_social}}</v-flex>
                <v-flex xs12 class="text-xs-left pt-2">Placa: {{veiculo_existente.identificacao}}</v-flex>
                <v-flex xs12 class="text-xs-left pt-2">Modelo: {{veiculo_existente.modelo_marca}}</v-flex>
                <v-flex xs12 class="text-xs-left pt-2">Cor: <v-btn :color="formatarCor(veiculo_existente.cor)"></v-btn></v-flex>
                <h3 v-if="!veiculo_existente.empresa" class="text-xs-center pt-4">Veículo pertence a um terceiro, Confirme para adicionar a sua frota!</h3>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-container>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="justify-space-around">
      <v-flex :xs="step === 4 ? 12 : 6">
        <v-btn
          text
          @click="function () {
            if (step === 1) fechar()
            else if (step === 2 || step === 3 || step === 5) step--
            else fechar()
          }"
          :disabled="salvando || loading"
          block
          large
        >
          {{ step === 1 || step === 4 ? 'Fechar' : 'Voltar' }}
        </v-btn>
      </v-flex>
      <v-divider vertical></v-divider>
      <v-flex xs6>
        <v-btn
          text
          block
          color="info"
          @click="function () {
            if (step === 1 && $refs.formVeiculo.validate()) {
              if (isVenda) step++
              else inserirVeiculo()
            }
            else if (step === 2) step++
            else if (step === 3) inserirVeiculo()
            else if (step === 4) step++
            else if (step === 5) salvarArquivo()
            else if (step === 6 && veiculo_existente && !veiculo_existente.empresa) editarVeiculo()
          }"
          large
          :disabled="disabled"
          :loading="salvando || loading"
        >
          Confirmar
        </v-btn>
      </v-flex>
    </v-card-actions>
  </v-card>
</template>

<script>
import { veiculoDao, funcionariosDao } from '@/api'
import { VMoney } from 'v-money'
// import Swatches from 'vue-swatches'

export default {
  // components: { Swatches },
  props: ['isVenda'],
  directives: { money: VMoney },
  data () {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        masked: false
      },
      color: null,
      valid: false,
      value: 0,
      stepOfertar: 0,
      validFinanciamento: false,
      step_image: 1,
      step: 1,
      btnAtivo: 1,
      indexSelecionado: undefined,
      modeloVeiculo: undefined,
      alerta: undefined,
      tipo: 'error',
      veiculo_existente: {
        empresa: null,
        usuario: null,
        identificacao: null,
        modelo_marca: null,
        cor: null,
        id_veiculo: null
      },
      dadosVeiculo: {
        empresa: null,
        usado: true,
        quilometragem: 0,
        valor: '0,00',
        valor_estimado: null,
        cambio: 1,
        direcao: 1,
        usuario: null,
        combustiveis: [],
        id_tipo_veiculo: null,
        tipo_veiculo_object: null,
        identificacao: null,
        modelo_marca: null,
        cor: null,
        chassi: null,
        marca: null,
        modelo: null,
        versao: null,
        versao_motor: null,
        motor: null,
        ano_modelo: null,
        ano_fabricacao: null,
        data_ultimo_licenciamento: null,
        qtd_vavulas: null,
        qtd_cilindros: null,
        qtd_assentos: null,
        tipo_tracao: null,
        tipo_transmissao: null,
        hp: null,
        cilindradas: null,
        tipo_carroceria: null,
        capacidade: null,
        qtd_proprietarios: null,
        qtd_fontes: null,
        qtd_alertas: null,
        data_primeiro_alerta: null,
        data_ultimo_alerta: null,
        tem_restricoes: false,
        teve_restricoes: false,
        primeira_data_expiracao_placa: null,
        ultima_data_expiracao_placa: null,
        local_registro: null,
        estado_registro: null,
        cor_texto: null,
        destinatario_oferta: null,
        descricao: null,
        quitando: false,
        ipva_pago: false,
        com_multa: false,
        de_leilao: false,
        unico_dono: false,
        com_manual: false,
        com_garantia: false,
        revisoes_feitas: false,
        chave_reserva: false,
        qtd_estoque: null
      },
      veiculo: null,
      select: null,
      options_cliente: [
        'Do funcionário',
        'Da empresa'
      ],
      pagination: {
        page: 1,
        rowsPerPage: 1000
      },
      funcionarios: [],
      tipos_veiculo: [],
      todos_opcionais: [],
      veiculoJaAvenda: false,
      salvando: false,
      cropping: false,
      fotos: [
        {
          url: undefined,
          externa: false,
          veiculo: undefined,
          nome: undefined,
          regiao: undefined
        }
      ],
      imagem: undefined,
      empresas: [],
      empresas_selecionadas: [],
      pagination_empresas: {
        page: 1,
        rowsPerPage: 5
      },
      totalItens: 0,
      filtroAtivo: false,
      nome_fantasia: undefined,
      estado: undefined,
      cidade: undefined,
      arquivoAnexado: undefined,
      is_pdf: false,
      tem_leilao: false,
      menuDataInicio: false,
      menuDataEncerramento: false,
      menuHorarioEncerramento: false,
      menuHorarioInicio: false,
      data_inicio: undefined,
      date_encerramento: undefined,
      validoLeilao: false,
      today: undefined,
      leilao: {
        id: undefined,
        veiculo: undefined,
        data_inicio: undefined,
        data_fim: undefined,
        horario_inicio: undefined,
        horario_fim: undefined,
        status: 1,
        valor_inicial: undefined
      }
    }
  },
  watch: {
    headers (val) {
      if (val) {
        this.carregarTiposVeiculo()
      }
    },
    pagination_empresas: {
      handler (val, oldval) {
        if (this.empresa) this.pegarRevendedoras(false)
      },
      deep: true
    },
    stepOfertar (val) {
      if (val === 1) this.pegarRevendedoras(false)
      else {
        this.empresas_selecionadas = []
      }
    },
    btnAtivo (val) {
      if (val === 1) this.empresas_selecionadas = []
    },
    'dadosVeiculo.combustiveis' (val) {
      if (typeof val === 'object' && val && val.length && val.length > 2) {
        const combs = this.dadosVeiculo.combustiveis
        this.dadosVeiculo.combustiveis = [combs[1], combs[2]]
      }
    },
    'dadosVeiculo.identificacao' (val) {
      if (this.dadosVeiculo.identificacao) this.dadosVeiculo.identificacao = this.dadosVeiculo.identificacao.toUpperCase()
      if (val && val.length === 8) {
        this.carregarInformacoes()
      }
    },
    'dadosVeiculo.cor_texto' () {
      this.mudarCor()
    },
    'dadosVeiculo.usado' (val) {
      if (val) this.activeAlert('Esse é um veículo usado, não esqueça de inserir a placa do veículo no campo acima.', 'info')
      else {
        this.activeAlert('Esse é um veículo novo, não é preciso preencher o campo "placa"', 'info')
        this.dadosVeiculo.identificacao = null
        this.dadosVeiculo.quilometragem = null
        this.dadosVeiculo.qtd_estoque = 1
        this.dadosVeiculo.quitando = false
        this.dadosVeiculo.ipva_pago = false
        this.dadosVeiculo.com_multa = false
        this.dadosVeiculo.de_leilao = false
        this.dadosVeiculo.unico_dono = false
        this.dadosVeiculo.com_manual = false
        this.dadosVeiculo.com_garantia = false
        this.dadosVeiculo.revisoes_feitas = false
        this.dadosVeiculo.chave_reserva = false
      }
    },
    'dadosVeiculo.tipo_veiculo_object' (val) {
      if (val) {
        this.dadosVeiculo.id_tipo_veiculo = val.id_tipo_veiculo
      }
    },
    tipo_veiculo (val) {
      for (var op in this.opcionais) {
        this.opcionais[op].ativo = false
      }
      if (val === 'motocicleta') {
        this.dadosVeiculo.direcao = null
        this.dadosVeiculo.cambio = null
      } else {
        this.dadosVeiculo.cilindradas = null
      }
    },
    filtro () {
      if (this.step === 1) this.pegarModeloVeiculo()
    },
    data_inicio (val) {
      this.leilao.data_inicio = this.formatDate(val)
      if (this.date_encerramento && Date.parse(this.data_inicio) > Date.parse(this.date_encerramento)) {
        this.date_encerramento = val
      }
    },
    date_encerramento (val) {
      this.leilao.data_fim = this.formatDate(val)
      if (!this.leilao.data_inicio || Date.parse(this.data_inicio) > Date.parse(val)) {
        this.data_inicio = val
      }
    },
    tem_leilao (val) {
      if (!val) {
        this.data_inicio = undefined
        this.date_encerramento = undefined
        this.leilao.horario_inicio = undefined
        this.leilao.horario_fim = undefined
      }
    }
  },
  computed: {
    headers () {
      return this.$store.getters.headers
    },
    combustiveis () {
      return this.$store.getters.combustiveis
    },
    empresa () {
      return this.$store.getters.empresa
    },
    estados () {
      return this.$store.getters.estados
    },
    filtro () {
      const data = {}
      if (this.dadosVeiculo.identificacao) data.identificacao__iexact = this.dadosVeiculo.identificacao.replace(/[-]/g, '').toUpperCase().trim()
      return data
    },
    pages () {
      const count = this.totalItens
      if (this.pagination_empresas.rowsPerPage == null || count == null) return 0

      return Math.ceil(count / this.pagination_empresas.rowsPerPage)
    },
    loading () {
      return this.$store.getters.loading
    },
    tituloCard () {
      switch (this.step) {
        case 1:
          return 'ADICIONAR VEÍCULO'
        case 2:
          return 'INFORMAÇÕES COMPLEMENTARES'
        case 3:
          return 'OFERECER VEÍCULO PARA'
        case 4:
          return 'CADASTRE AS FOTOS DO VEÍCULO'
        case 5:
          return 'ANEXAR LAUDO CAUTELAR'
        case 6:
          return 'VEÍCULO JÁ CADASTRADO'
        default:
          return ''
      }
    },
    tipo_veiculo () {
      if (this.dadosVeiculo && this.dadosVeiculo.tipo_veiculo_object) {
        return this.dadosVeiculo.tipo_veiculo_object.denominacao.toLowerCase()
      }
      return undefined
    },
    opcionais () {
      const tipoVeiculo = this.tipo_veiculo === 'motocicleta' ? 1 : 2
      return this.todos_opcionais.filter((op) => {
        if (op.tipo_veiculo === 0) return op
        else if (op.tipo_veiculo === tipoVeiculo) {
          return op
        }
      })
    },
    tipos_regioes () {
      let lista = [
        'Parte frontal',
        'Parte lateral esquerda',
        'Parte de trás',
        'Painel',
        'Outra região'
      ]
      if (this.tipo_veiculo !== 'motocicleta') {
        lista = lista.concat(['Painel com câmbio', 'Bancos', 'Porta malas'])
      }
      return lista
    },
    disabled () {
      if (this.salvando || this.loading) return true
      if (this.step === 1 && this.veiculoJaAvenda && this.dadosVeiculo.usado) return true
      return false
    },
    valor_inicial () {
      if (!this.leilao || !this.leilao.valor_inicial) return 0
      return this.formatarValor(this.leilao.valor_inicial)
    }
  },
  beforeMount () {
    this.today = new Date().toISOString().substr(0, 10)
    this.today = Date.parse(this.today)
    if (this.headers) {
      this.pegarOpcionaisDoVeiculo()
      this.carregarTiposVeiculo()
      this.carregarFuncionarios()
      this.$store.dispatch('carregarCombustiveis')
    }
  },
  methods: {
    fechar () {
      this.$refs.formVeiculo.reset()
      this.step = 1
      this.$emit('carregar')
      this.$emit('fechar')
    },
    showError (msg) {
      this.alerta = msg
      this.tipo = 'error'
    },
    activeAlert (text, type) {
      this.alerta = text
      this.tipo = type
      const interval = setInterval(() => {
        this.alerta = undefined
        clearInterval(interval)
      }, 5000)
    },
    pegarOpcionaisDoVeiculo () {
      this.$store.dispatch('pegarOpcionaisDoVeiculo').then(json => {
        this.todos_opcionais = json.map((op) => {
          return {
            ...op,
            ativo: false
          }
        })
      })
    },
    adicionarEmpresa (empresa) {
      if (!empresa) {
        this.activeAlert('Empresa inválida', 'error')
        return
      }
      const existe = !!this.empresas_selecionadas.find((item) => item === empresa.cnpj)
      if (existe) {
        this.activeAlert('Essa empresa já foi inserida', 'error')
        return
      }
      this.empresas_selecionadas.push(empresa.cnpj)
    },
    removerEmpresa (empresa) {
      if (!empresa) {
        this.activeAlert('Empresa inválida', 'error')
        return
      }
      const index = this.empresas_selecionadas.indexOf(empresa.cnpj)
      if (index === -1) {
        this.activeAlert('Essa empresa não foi inserida a lista.', 'error')
        return
      }
      this.empresas_selecionadas.splice(index, 1)
    },
    isSelected (empresa) {
      return !!this.empresas_selecionadas.find((item) => item === empresa.cnpj)
    },
    carregarTiposVeiculo () {
      this.$store.dispatch('carregarTiposVeiculo').then(json => {
        this.tipos_veiculo = json
        const result = Object.keys(json).find(key => {
          return json[key].denominacao === 'Carro'
        })
        this.dadosVeiculo.tipo_veiculo_object = json[result]
      })
    },
    carregarFuncionarios () {
      var data = {
        pagination: this.pagination,
        empresa: this.empresa.cnpj
      }
      funcionariosDao.getFuncionariosEmpresaConfirmados(data, this.headers)
        .then(res => res.json())
        .then(json => {
          this.funcionarios = json.results
        })
    },
    carregarInformacoes () {
      this.dadosVeiculo = {
        empresa: null,
        usuario: null,
        combustiveis: [],
        identificacao: this.dadosVeiculo.identificacao,
        id_tipo_veiculo: this.dadosVeiculo.id_tipo_veiculo,
        tipo_veiculo_object: this.dadosVeiculo.tipo_veiculo_object,
        modelo_marca: null,
        cor: null,
        chassi: null,
        marca: null,
        modelo: null,
        versao: null,
        versao_motor: null,
        motor: null,
        ano_modelo: null,
        ano_fabricacao: null,
        data_ultimo_licenciamento: null,
        qtd_vavulas: null,
        qtd_cilindros: null,
        qtd_assentos: null,
        tipo_tracao: null,
        tipo_transmissao: null,
        hp: null,
        cilindradas: null,
        tipo_carroceria: null,
        capacidade: null,
        qtd_proprietarios: null,
        qtd_fontes: null,
        qtd_alertas: null,
        data_primeiro_alerta: null,
        data_ultimo_alerta: null,
        tem_restricoes: false,
        teve_restricoes: false,
        primeira_data_expiracao_placa: null,
        ultima_data_expiracao_placa: null,
        local_registro: null,
        estado_registro: null,
        cor_texto: null,
        usado: true,
        quilometragem: 0,
        valor: '0,00',
        valor_estimado: null,
        quitando: false,
        ipva_pago: false,
        com_multa: false,
        de_leilao: false,
        unico_dono: false,
        com_manual: false,
        com_garantia: false,
        revisoes_feitas: false,
        chave_reserva: false,
        qtd_estoque: null,
        cambio: 1,
        direcao: 1,
        destinatario_oferta: null,
        descricao: null
      }

      this.$store.dispatch('pegarInformacoesVeiculo', this.dadosVeiculo.identificacao.replace(/[-]/g, '').toUpperCase().trim()).then(data => {
        if (data.informacoes.marca && data.informacoes.modelo) {
          this.dadosVeiculo.modelo_marca = `(${data.informacoes.marca}) ${data.informacoes.modelo.split(' ')[0]}`
        }
        // Verificar Combustível
        if (data.informacoes.tipo_combustivel && data.informacoes.tipo_combustivel !== '') {
          // Antiga API de informações veiculos
          /* if (data.informacoes.tipo_combustivel === 'FLEX') {
            this.combustiveis.map((c) => {
              if (c.nome.toUpperCase() === 'GASOLINA COMUM' || c.nome.toUpperCase() === 'ETANOL COMUM') {
                this.dadosVeiculo.combustiveis.push(c)
              }
            })
          } else {
            this.combustiveis.map((c) => {
              if (c.nome.toUpperCase().split(' ')[0] === data.informacoes.tipo_combustivel.toUpperCase()) {
                this.dadosVeiculo.combustiveis.push(c.id_combustivel)
              }
            })
          } */
          if (data.informacoes.tipo_combustivel.indexOf('/') === -1) {
            if (data.informacoes.tipo_combustivel.trim() === 'GASOLINA') {
              const result = Object.keys(this.combustiveis).find(key => {
                return this.combustiveis[key].nome.toUpperCase() === 'GASOLINA COMUM'
              })
              this.dadosVeiculo.combustiveis.push(this.combustiveis[result])
            }
            if (data.informacoes.tipo_combustivel.trim() === 'ALCOOL') {
              const result = Object.keys(this.combustiveis).find(key => {
                return this.combustiveis[key].nome.toUpperCase() === 'ETANOL COMUM'
              })
              this.dadosVeiculo.combustiveis.push(this.combustiveis[result])
            }
          } else {
            if (data.informacoes.tipo_combustivel.split('/')[0].trim() === 'GASOLINA' || data.informacoes.tipo_combustivel.split('/')[1].trim() === 'GASOLINA') {
              const result = Object.keys(this.combustiveis).find(key => {
                return this.combustiveis[key].nome.toUpperCase() === 'GASOLINA COMUM'
              })
              this.dadosVeiculo.combustiveis.push(this.combustiveis[result])
            }
            if (data.informacoes.tipo_combustivel.split('/')[0].trim() === 'ALCOOL' || data.informacoes.tipo_combustivel.split('/')[1].trim() === 'ALCOOL') {
              const result = Object.keys(this.combustiveis).find(key => {
                return this.combustiveis[key].nome.toUpperCase() === 'ETANOL COMUM'
              })
              this.dadosVeiculo.combustiveis.push(this.combustiveis[result])
            }
          }
        }
        if (data.informacoes.tipo && data.informacoes.tipo !== '') {
          this.tipos_veiculo.map((tipo) => {
            if (tipo.denominacao.toUpperCase() === data.informacoes.tipo.toUpperCase()) {
              this.dadosVeiculo.id_tipo_veiculo = tipo.id_tipo_veiculo
              this.dadosVeiculo.tipo_veiculo_object = tipo
            }
          })
        }
        let corVeiculo
        if (data.historico !== undefined && data.historico !== null && data.historico.length > 0) {
          corVeiculo = data.historico.map((h) => {
            if (h.cor !== null) {
              return h.cor
            }
          })
          corVeiculo = corVeiculo[0].toUpperCase()
        } else {
          corVeiculo = data.informacoes.cor
          corVeiculo = corVeiculo.toUpperCase()
        }
        if (corVeiculo) {
          this.dadosVeiculo.cor_texto = corVeiculo[0] + corVeiculo.toLowerCase().substring(1, corVeiculo.length)
          switch (corVeiculo) {
            case 'BRANCA':
              this.color = {
                alpha: 1,
                hex: '#FFFFFF',
                hexa: '#FFFFFFFF',
                hsla: {},
                hsva: {},
                hue: 0,
                rgba: {
                  a: 1,
                  b: 255,
                  g: 255,
                  r: 255
                }
              }
              break
            case 'CINZA':
              this.color = {
                alpha: 1,
                hex: '#808080',
                hexa: '#808080FF',
                hsla: {
                  a: 1,
                  h: 0,
                  l: 0.51,
                  s: 0
                },
                hsva: {
                  a: 1,
                  h: 0,
                  s: 1,
                  v: 0.51
                },
                hue: 0,
                rgba: {
                  a: 1,
                  b: 128,
                  g: 128,
                  r: 128
                }
              }
              break
            case 'PRATA':
              this.color = {
                alpha: 1,
                hex: '#C0C0C0',
                hexa: '#C0C0C0FF',
                hsla: {
                  a: 1,
                  h: 0,
                  l: 0.51,
                  s: 0
                },
                hsva: {
                  a: 1,
                  h: 0,
                  s: 1,
                  v: 0.51
                },
                hue: 0,
                rgba: {
                  a: 1,
                  b: 192,
                  g: 192,
                  r: 192
                }
              }
              break
            case 'PRETA':
              this.color = {
                alpha: 1,
                hex: '#000000',
                hexa: '#000000FF',
                hsla: {
                  a: 1,
                  h: 0,
                  l: 0,
                  s: 0
                },
                hsva: {
                  a: 1,
                  h: 0,
                  s: 0.9633333333333334,
                  v: 0
                },
                hue: 0,
                rgba: {
                  a: 1,
                  b: 0,
                  g: 0,
                  r: 0
                }
              }
              break
            case 'VERMELHA':
              this.color = {
                alpha: 1,
                hex: '#FF0000',
                hexa: '#FF0000FF',
                hsla: {
                  a: 1,
                  h: 0,
                  l: 0.5,
                  s: 1
                },
                hsva: {
                  a: 1,
                  h: 0,
                  s: 1,
                  v: 1
                },
                hue: 0,
                rgba: {
                  a: 1,
                  b: 0,
                  g: 0,
                  r: 255
                }
              }
              break
            case 'AZUL':
              this.color = {
                alpha: 1,
                hex: '#0000FF',
                hexa: '#0000FFFF',
                hsla: {
                  a: 1,
                  h: 228.78504672897193,
                  l: 0.5,
                  s: 1
                },
                hsva: {
                  a: 1,
                  h: 228.78504672897193,
                  s: 1,
                  v: 1
                },
                hue: 0,
                rgba: {
                  a: 1,
                  b: 255,
                  g: 0,
                  r: 0
                }
              }
              break
            case 'AMARELA':
              this.color = {
                alpha: 1,
                hex: '#FFFF00',
                hexa: '#FFFF00FF',
                hsla: {
                  a: 1,
                  h: 60.560747663551396,
                  l: 0.5,
                  s: 1
                },
                hsva: {
                  a: 1,
                  h: 60.560747663551396,
                  s: 1,
                  v: 1
                },
                hue: 0,
                rgba: {
                  a: 1,
                  b: 0,
                  g: 255,
                  r: 255
                }
              }
              break
            case 'VERDE':
              this.color = {
                alpha: 1,
                hex: '#00FF00',
                hexa: '#00FF00FF',
                hsla: {
                  a: 1,
                  h: 124.48598130841121,
                  l: 0.5,
                  s: 1
                },
                hsva: {
                  a: 1,
                  h: 124.48598130841121,
                  s: 1,
                  v: 1
                },
                hue: 0,
                rgba: {
                  a: 1,
                  b: 0,
                  g: 255,
                  r: 0
                }
              }
              break
            case 'ROSA':
              this.color = {
                alpha: 1,
                hex: '#FF007F',
                hexa: '#FF007FFF',
                hsla: {
                  a: 1,
                  h: 302.803738317757,
                  l: 0.5,
                  s: 1
                },
                hsva: {
                  a: 1,
                  h: 302.803738317757,
                  s: 1,
                  v: 1
                },
                hue: 0,
                rgba: {
                  a: 1,
                  b: 127,
                  g: 0,
                  r: 255
                }
              }
          }
        }
        if (!this.isVenda) {
          this.dadosVeiculo.tem_restricoes = data.tem_restricoes
          this.dadosVeiculo.teve_restricoes = data.teve_restricoes
          this.dadosVeiculo.chassi = data.informacoes.chassi ? data.informacoes.chassi : null
          this.dadosVeiculo.versao = data.informacoes.versao ? data.informacoes.versao : null
          this.dadosVeiculo.versao_motor = data.informacoes.versao_motor ? data.informacoes.versao_motor : null
          this.dadosVeiculo.motor = data.informacoes.motor ? data.informacoes.motor : null
          this.dadosVeiculo.data_ultimo_licenciamento = data.informacoes.data_ultimo_licenciamento ? data.informacoes.data_ultimo_licenciamento : null
          this.dadosVeiculo.qtd_vavulas = data.informacoes.qtd_vavulas ? data.informacoes.qtd_vavulas : null
          this.dadosVeiculo.qtd_cilindros = data.informacoes.qtd_cilindros ? data.informacoes.qtd_cilindros : null
          this.dadosVeiculo.qtd_assentos = data.informacoes.qtd_assentos ? data.informacoes.qtd_assentos : null
          this.dadosVeiculo.qtd_proprietarios = data.qtd_proprietarios ? data.qtd_proprietarios : null
          this.dadosVeiculo.qtd_fontes = data.qtd_fontes ? data.qtd_fontes : null
          this.dadosVeiculo.qtd_alertas = data.qtd_alertas ? data.qtd_alertas : null
          this.dadosVeiculo.tipo_tracao = data.informacoes.tipo_tracao ? data.informacoes.tipo_tracao : null
          this.dadosVeiculo.tipo_transmissao = data.informacoes.tipo_transmissao ? data.informacoes.tipo_transmissao : null
          this.dadosVeiculo.hp = data.informacoes.hp ? data.informacoes.hp : null
          this.dadosVeiculo.cilindradas = data.informacoes.cilindradas ? data.informacoes.cilindradas : null
          this.dadosVeiculo.tipo_carroceria = data.informacoes.tipo_carroceria ? data.informacoes.tipo_carroceria : null
          this.dadosVeiculo.capacidade = data.informacoes.capacidade ? data.informacoes.capacidade : null
          this.dadosVeiculo.ultima_data_expiracao_placa = this.formatarData(data.ultima_data_expiracao_placa)
          this.dadosVeiculo.primeira_data_expiracao_placa = this.formatarData(data.primeira_data_expiracao_placa)
          this.dadosVeiculo.data_primeiro_alerta = this.formatarData(data.data_primeiro_alerta)
          this.dadosVeiculo.data_ultimo_alerta = this.formatarData(data.data_ultimo_alerta)
        }
        this.dadosVeiculo.valor_estimado = data.informacoes.valor_estimado
        if (this.dadosVeiculo.marca == null) this.dadosVeiculo.marca = data.informacoes.marca ? data.informacoes.marca[0].toUpperCase() + data.informacoes.marca.toLowerCase().substring(1) : null
        if (this.dadosVeiculo.modelo == null) this.dadosVeiculo.modelo = data.informacoes.modelo ? data.informacoes.modelo : null
        if (this.dadosVeiculo.modelo_marca == null) this.dadosVeiculo.modelo_marca = `${data.informacoes.marca} ${data.informacoes.modelo.split(' ')[0]}`
        if (this.dadosVeiculo.ano_modelo == null) this.dadosVeiculo.ano_modelo = data.informacoes.ano_modelo ? data.informacoes.ano_modelo : null
        if (this.dadosVeiculo.quilometragem == null) this.dadosVeiculo.quilometragem = data.informacoes.quilometragem ? data.informacoes.quilometragem : 0
        if (this.dadosVeiculo.ano_fabricacao == null) this.dadosVeiculo.ano_fabricacao = data.informacoes.ano_fabricacao + '/' + data.informacoes.ano_modelo
        if (data.historico !== undefined && data.historico !== null && data.historico.length > 0) {
          const registro = data.historico[data.historico.length - 1]
          this.dadosVeiculo.local_registro = registro.local_registro
          this.dadosVeiculo.estado_registro = registro.estado_registro
          this.dadosVeiculo.cor_texto = registro.cor
        } else {
          this.dadosVeiculo.local_registro = data.informacoes.municipio
          this.dadosVeiculo.estado_registro = data.informacoes.estado
          this.dadosVeiculo.cor_texto = data.informacoes.cor
        }
      })
    },
    formatarData (data) {
      if (data !== null && data !== undefined) {
        return data.split('T')[0]
      }
    },
    is_number (value) {
      try {
        const teste = Number(value)
        if (!teste) return false
        return true
      } catch (e) {
        return false
      }
    },
    formatarValor (valor) {
      if (!valor) return 0
      if (this.is_number(valor)) {
        return Number(valor)
      } else {
        return Number(valor.replaceAll('.', '').replaceAll(',', '.'))
      }
    },
    async inserirVeiculo () {
      this.salvando = true
      if (this.veiculoJaAvenda && this.dadosVeiculo.usado) {
        this.activeAlert('Essa placa já foi vinculada a um veículo', 'error')
        this.salvando = false
        return
      }
      if (this.$refs.formVeiculo.validate() && this.empresa) {
        this.dadosVeiculo.empresa = this.empresa.cnpj
        this.dadosVeiculo.modelo_marca = `(${this.dadosVeiculo.marca}) ${this.dadosVeiculo.modelo.split(' ')[0]}`
        this.dadosVeiculo.cor = this.color.hex
        if (this.funcionarioSelected) {
          this.dadosVeiculo.usuario = this.funcionarioSelected.pessoa.id_pessoa
        }
        if (!this.dadosVeiculo.usado) {
          this.dadosVeiculo.identificacao = ''
        }

        if (this.dadosVeiculo.valor) this.dadosVeiculo.valor = Number(this.dadosVeiculo.valor.replaceAll('.', '').replace(',', '.'))
        if (this.empresas_selecionadas.length > 0) this.dadosVeiculo.empresas_ofertadas = this.empresas_selecionadas

        this.dadosVeiculo.aVenda = this.isVenda
        this.dadosVeiculo.destinatario_oferta = this.isVenda ? this.stepOfertar + 1 : null
        this.dadosVeiculo.descricao = this.dadosVeiculo.descricao ? this.dadosVeiculo.descricao : null
        this.dadosVeiculo.identificacao = this.dadosVeiculo.identificacao.replace(/[-]/g, '').toUpperCase().trim()

        if (this.isVenda) {
          const opcionais = this.opcionais.map((op) => { if (op.ativo === true) return op.codigo }).filter(op => op !== undefined)
          if (opcionais.length > 0) this.dadosVeiculo.opcionais = opcionais

          if (this.tem_leilao) {
            if (!this.$refs.formularioLeilao.validate()) {
              this.activeAlert('Você precisa preencher corretamente o fomulário da disputa de lances', 'error')
              this.salvando = false
              return
            }
          }
        }

        this.$store.dispatch('cadastrarVeiculo', this.dadosVeiculo).then(response => {
          if (response.status === 200) {
            response.retorno.then(json => {
              this.dadosVeiculo = json
              if (this.tem_leilao) {
                this.cadastrarLeilao()
              }
            })
            if (this.isVenda) this.step++
            else this.fechar()
            this.activeAlert('Veículo adicionado com sucesso', 'success')
            this.$emit('info', 'Veículo adicionado com sucesso')
            this.$emit('carregar')
          } else if (response.status === 202) {
            const veiculo = response.retorno
            this.veiculo_existente.empresa = veiculo.empresa
            this.veiculo_existente.usuario = veiculo.usuario
            this.veiculo_existente.identificacao = veiculo.identificacao
            this.veiculo_existente.modelo_marca = veiculo.modelo_marca
            this.veiculo_existente.cor = veiculo.cor
            this.veiculo_existente.id_veiculo = veiculo.id_veiculo
            this.step = 6
          }
        }).catch(() => {
          this.activeAlert('Desculpe, não foi possível adicionar o veículo no momento', 'error')
        })
      } else {
        this.activeAlert('Não foi possível inserir veículo, verifique se todas as informações foram preenchidas.', 'error')
      }
      this.salvando = false
    },
    editarVeiculo () {
      if (!this.veiculo_existente.empresa) {
        this.veiculo_existente.empresa = this.empresa.cnpj
        veiculoDao.adicionarVeiculoEmpresa(this.veiculo_existente, this.veiculo_existente.id_veiculo, this.headers)
          .then(response => {
            if (response.ok) {
              this.activeAlert('As informações do veículo foram alteradas com sucesso.', 'success')
              this.$store.dispatch('setSuccess', 'As informações do veículo foram alteradas com sucesso')
              this.$emit('carregar')
              this.fechar()
            } else {
              this.activeAlert('Não foi possível editar as informações do veículo, verifique se todas as informações foram preenchidas.', 'error')
              this.$store.dispatch('setError', 'Desculpe, não foi possível modificar o veículo no momento')
              this.fechar()
            }
          })
      }
    },
    formatarCor (cor) {
      if (cor === null) return ''
      if (cor === undefined) return ''
      if (cor.length === 6) return `#${cor}`
      if (cor.length === 7) return cor
      else {
        let teste = parseInt(cor)
        teste = teste.toString(16)
        return `#${teste.slice(2, 8)}`
      }
    },
    async anexar (event) {
      this.arquivoAnexado = undefined
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onload = event => {
        if (file.type.indexOf('image/') !== -1) {
          this.is_pdf = false
        } else {
          this.is_pdf = true
        }
        this.arquivoAnexado = event.target.result
      }
      if (file) {
        reader.readAsDataURL(file)
      }
    },
    carregarImagem (event) {
      if (this.indexSelecionado) {
        const foto = this.fotos[this.indexSelecionado]
        foto.url = undefined
        foto.externa = false
      }
      if (event.target.files && event.target.files[0]) {
        const imagem = event.target.files[0]
        const reader = new FileReader()
        reader.onloadend = e => {
          let ref = null
          this.cropping = true
          this.$refs.croppie.bind({
            url: reader.result
          })
          ref = this.$refs.croppie
          this.$nextTick(() => {
            ref.setZoom(0.1)
          })
        }
        reader.readAsDataURL(imagem)
      }
    }, // carregarImagem
    recortarImagem () {
      const options = {
        type: 'base64',
        format: 'png',
        circle: false
      }
      this.$refs.croppie.result(options, output => {
        this.inserirImagemFirebase(output)
        this.cropping = false
      })
    }, // recortarImagem
    async inserirImagemFirebase (imagem) {
      const foto = this.fotos[this.indexSelecionado]
      let regiao = foto.regiao
      if (regiao === 'Outra região') {
        regiao = foto.nome
      }
      regiao = regiao.replaceAll(/\s+/g, '_').trim().toUpperCase()

      let identificacao = this.dadosVeiculo.identificacao ? this.dadosVeiculo.identificacao.replace(/[-]/g, '').toUpperCase().trim() : undefined
      if (identificacao) identificacao += regiao
      else identificacao = regiao
      identificacao += new Date().toISOString()

      const data = {
        arquivo: imagem,
        id: this.empresa.cnpj,
        identificacao: identificacao,
        local: 'veiculos/imagens',
        prefixo: regiao,
        tipo: 'png',
        rota: 'veiculos',
        id_aux: this.dadosVeiculo.id_veiculo
      }

      const url = await this.$store.dispatch('inserirFirebase', data)

      let nome = foto.regiao
      if (foto.regiao === 'Outra região') {
        nome = this.foto.nome
      }

      this.cadastrarFoto(
        {
          nome: nome,
          url: url,
          externa: foto.externa,
          veiculo: this.dadosVeiculo.id_veiculo
        },
        this.indexSelecionado
      )

      this.cropping = false
    }, // inserirImagemFirebase
    mudarCor () {
      let corVeiculo = this.dadosVeiculo.cor_texto
      if (!corVeiculo) return
      corVeiculo = corVeiculo.toUpperCase()
      switch (corVeiculo) {
        case 'BRANCA':
          this.color = {
            alpha: 1,
            hex: '#FFFFFF',
            hexa: '#FFFFFFFF',
            hsla: {
              a: 1,
              h: 0,
              l: 1,
              s: 0
            },
            hsva: {
              a: 1,
              h: 0,
              s: 0,
              v: 1
            },
            hue: 0,
            rgba: {
              a: 1,
              b: 255,
              g: 255,
              r: 255
            }
          }
          break
        case 'CINZA':
          this.color = {
            alpha: 1,
            hex: '#636363',
            hexa: '#636363FF',
            hsla: {
              a: 1,
              h: 0,
              l: 0.39,
              s: 0
            },
            hsva: {
              a: 1,
              h: 0,
              s: 0,
              v: 0.39
            },
            hue: 0,
            rgba: {
              a: 1,
              b: 99,
              g: 99,
              r: 99
            }
          }
          break
        case 'PRATA':
          this.color = {
            alpha: 1,
            hex: '#C6C6C6',
            hexa: '#C6C6C6FF',
            hsla: {
              a: 1,
              h: 0,
              l: 0.7763541666666667,
              s: 0
            },
            hsva: {
              a: 1,
              h: 0,
              s: 0,
              v: 0.7763541666666667
            },
            hue: 0,
            rgba: {
              a: 1,
              b: 198,
              g: 198,
              r: 198
            }
          }
          break
        case 'PRETA':
          this.color = {
            alpha: 1,
            hex: '#000000',
            hexa: '#000000FF',
            hsla: {
              a: 1,
              h: 0,
              l: 0,
              s: 0
            },
            hsva: {
              a: 1,
              h: 0,
              s: 0.9633333333333334,
              v: 0
            },
            hue: 0,
            rgba: {
              a: 1,
              b: 0,
              g: 0,
              r: 0
            }
          }
          break
        case 'VERMELHA':
          this.color = {
            alpha: 1,
            hex: '#FF0000',
            hexa: '#FF0000FF',
            hsla: {
              a: 1,
              h: 0,
              l: 0.5,
              s: 1
            },
            hsva: {
              a: 1,
              h: 0,
              s: 1,
              v: 1
            },
            hue: 0,
            rgba: {
              a: 1,
              b: 0,
              g: 0,
              r: 255
            }
          }
          break
        case 'AZUL':
          this.color = {
            alpha: 1,
            hex: '#0013FF',
            hexa: '#0013FFFF',
            hsla: {
              a: 1,
              h: 235.51401869158877,
              l: 0.5,
              s: 1
            },
            hsva: {
              a: 1,
              h: 235.51401869158877,
              s: 1,
              v: 1
            },
            hue: 235.51401869158877,
            rgba: {
              a: 1,
              b: 255,
              g: 19,
              r: 0
            }
          }
          break
        case 'AMARELA':
          this.color = {
            alpha: 1,
            hex: '#F2F408',
            hexa: '#F2F408FF',
            hsla: {
              a: 1,
              h: 60.560747663551396,
              l: 0.4941163194444444,
              s: 0.935483870967742
            },
            hsva: {
              a: 1,
              h: 60.560747663551396,
              s: 0.4941163194444444,
              v: 0.935483870967742
            },
            hue: 60.560747663551396,
            rgba: {
              a: 1,
              b: 0,
              g: 255,
              r: 255
            }
          }
          break
        case 'VERDE':
          this.color = {
            alpha: 1,
            hex: '#15D013',
            hexa: '#15D013FF',
            hsla: {
              a: 1,
              h: 119.43925233644859,
              l: 0.4464444444444445,
              s: 0.8292682926829267
            },
            hsva: {
              a: 1,
              h: 119.43925233644859,
              s: 0.9066666666666666,
              v: 0.8166666666666667
            },
            hue: 119.43925233644859,
            rgba: {
              a: 1,
              b: 19,
              g: 208,
              r: 21
            }
          }
          break
        case 'ROSA':
          this.color = {
            alpha: 1,
            hex: '#FF007F',
            hexa: '#FF007FFF',
            hsla: {
              a: 1,
              h: 302.803738317757,
              l: 0.5,
              s: 1
            },
            hsva: {
              a: 1,
              h: 302.803738317757,
              s: 1,
              v: 1
            },
            hue: 0,
            rgba: {
              a: 1,
              b: 127,
              g: 0,
              r: 255
            }
          }
          break
        case 'MARROM':
          this.color = {
            alpha: 1,
            hex: '#794727',
            hexa: '#794727FF',
            hsla: {
              a: 1,
              h: 23.551401869158877,
              l: 0.31439374999999997,
              s: 0.5151515151515151
            },
            hsva: {
              a: 1,
              h: 23.551401869158877,
              s: 0.68,
              v: 0.47635416666666663
            },
            hue: 23.551401869158877,
            rgba: {
              a: 1,
              b: 39,
              g: 71,
              r: 121
            }
          }
          break
        case 'BEGE':
          this.color = {
            alpha: 1,
            hex: '#DAC011',
            hexa: '#DAC011FF',
            hsla: {
              a: 1,
              h: 52.14953271028037,
              l: 0.46243124999999996,
              s: 0.851851851851852
            },
            hsva: {
              a: 1,
              h: 52.14953271028037,
              s: 0.92,
              v: 0.8563541666666666
            },
            hue: 52.14953271028037,
            rgba: {
              a: 1,
              b: 17,
              g: 192,
              r: 218
            }
          }
      }
    }, // mudarCor
    setCombustiveis (combustiveis) {
      this.dadosVeiculo.combustiveis = []
      combustiveis.forEach(comb => {
        this.dadosVeiculo.combustiveis.push(comb.combustivel)
      })
    }, // setCombustiveis
    async pegarModeloVeiculo () {
      if (!this.dadosVeiculo.identificacao) return
      this.salvando = true
      this.alerta = ''
      this.veiculoJaAvenda = false
      const data = { empresa: this.empresa.cnpj }
      if (this.dadosVeiculo.identificacao) data.identificacao__iexact = this.dadosVeiculo.identificacao.replace(/[-]/g, '').toUpperCase().trim()
      await this.$store.dispatch('pegarDetalhesVeiculo', data).then(veiculos => {
        veiculos = veiculos.filter(veiculo => veiculo.ativo)
        if (veiculos.length > 0) {
          this.veiculoJaAvenda = true
          this.showError('Um veículo com essa placa já foi cadastrado no sistema')
        }
      })
      this.salvando = false
    }, // pegarModeloVeiculo
    pegarRevendedoras (isBtn) {
      const data = {
        tipo_empresa: 2,
        empresa: this.empresa.cnpj
      }
      data.page = isBtn ? 1 : this.pagination_empresas.page
      if (this.cidade) data.endereco__cidade__icontains = this.cidade
      if (this.estado) data.endereco__estado__icontains = this.estado
      if (this.nome_fantasia) data.nome_fantasia__icontains = this.nome_fantasia
      this.$store.dispatch('pegarEmpresasAtivas', data).then(json => {
        this.empresas = json.results
        this.totalItens = json.count
      })
    }, // pegarRevendedoras
    async cadastrarFoto (data, index) {
      this.salvando = true
      const json = await this.$store.dispatch('fotos', {
        method: 'POST',
        body: data,
        parametros: {
          empresa: this.empresa.cnpj
        }
      })
      this.fotos[index] = { ...json, regiao: json.nome }
      this.salvando = false
      this.indexSelecionado = undefined
      this.activeAlert('Imagem cadastrada com sucesso', 'success')
    },
    cadastrarUrl (foto, index) {
      let nome = foto.regiao
      if (foto.regiao === 'Outra região') {
        nome = foto.nome
      }
      this.cadastrarFoto(
        {
          nome: nome,
          url: foto.url,
          externa: foto.externa,
          veiculo: this.dadosVeiculo.id_veiculo
        },
        index
      )
    },
    async removerImagem (imagem, index) {
      if (!imagem.id && imagem.externa) {
        imagem.url = undefined
        return
      }
      if (!imagem.externa) {
        await this.$store.dispatch('deletarArquivoFirebase', imagem.url)
      }

      const response = await this.$store.dispatch('fotos', {
        method: 'DELETE',
        id: imagem.id,
        body: {
          empresa: this.empresa.cnpj
        }
      })

      if (response === 'ok') {
        if (this.fotos.length === 1) {
          imagem.id = undefined
          imagem.url = undefined
          imagem.externa = false
          imagem.regiao = undefined
          imagem.nome = undefined
        } else {
          if (this.fotos.length === this.step) {
            await this.step_image--
          }
          await this.fotos.splice(index, 1)
        }
        this.activeAlert('Imagem removida com sucesso', 'success')
      } else {
        this.activeAlert('Não foi possível remover a imagem', 'error')
      }
    },
    async adicionarNovaImagem () {
      await this.fotos.push(
        {
          url: undefined,
          nome: undefined,
          veiculo: undefined,
          externa: false
        }
      )
      this.step_image++
    },
    async removerArquivo () {
      this.arquivoAnexado = undefined
      this.is_pdf = false
    },
    async salvarArquivo () {
      if (this.arquivoAnexado) {
        const data = {
          arquivo: this.arquivoAnexado,
          id: this.empresa.cnpj,
          identificacao: this.dadosVeiculo.identificacao,
          local: 'veiculos/arquivos',
          prefixo: 'laudo_cautelar',
          tipo: this.is_pdf ? 'pdf' : 'png',
          rota: 'veiculos',
          id_aux: this.dadosVeiculo.id_veiculo
        }
        const url = await this.$store.dispatch('inserirFirebase', data)

        const response = await veiculoDao.updateForEmpresa(
          {
            url_laudo_cautelar: url
          },
          this.dadosVeiculo.id_veiculo,
          this.headers
        )
        if (response.ok) {
          this.$emit('info', 'O laudo cautelar foi anexado com sucesso')
          this.$emit('carregar')
          this.fechar()
        } else {
          this.$emit('error', 'Desculpe, não foi possível alexar o laudo cautelar do veículo')
        }
      }
      this.fechar()
    },
    formatarDadosLeilao (dados) {
      this.tem_leilao = true
      const dataInicioSeparada = dados.data_inicio.split('T')
      const dataFimSeparada = dados.data_fim.split('T')
      this.data_inicio = dataInicioSeparada[0]
      this.date_encerramento = dataFimSeparada[0]
      this.leilao = {
        ...dados,
        data_inicio: undefined,
        data_fim: undefined,
        horario_inicio: dataInicioSeparada[1].substring(0, 5),
        horario_fim: dataFimSeparada[1].substring(0, 5)
      }
    },
    cadastrarLeilao () {
      const data = {
        method: 'POST',
        body: {
          ...this.leilao,
          empresa: this.empresa.cnpj,
          veiculo_leiloado: this.dadosVeiculo.id_veiculo,
          data_inicio: `${this.data_inicio} ${this.leilao.horario_inicio}:00.764902-03`,
          data_fim: `${this.date_encerramento} ${this.leilao.horario_fim}:00.764902-03`,
          valor_inicial: this.valor_inicial
        }
        // Formato '2023-01-18 09:40:24.764902-03'
      }
      this.$store.dispatch('leilaoVeiculos', data).then(json => {
        this.formatarDadosLeilao(json)
      })
    },
    testarDataFim (data) {
      if (this.data_inicio) {
        return Date.parse(data) >= Date.parse(this.data_inicio)
      } else {
        return Date.parse(data) >= this.today
      }
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    }
  }
}
</script>

<style scoped>
.custom-ui-class {
    width: 348px;
    height: 320px;
    margin-left: auto;
    margin-right: auto;
}

</style>
