<template>
  <v-card style="border-radius: 1em;">
    <v-toolbar class="mb-4" color="info">
      <v-btn icon @click="$emit('fechar')"><v-icon color="white">close</v-icon></v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title class="white--text">Notificação</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <template v-if="funcionario">
        <v-flex xs12 class="mb-4" style="font-size: 1.3em; text-align: justify;">Um novo funcionário deseja fazer parte de sua empresa <b>{{empresa.nome_fantasia}}</b>, seu dados são os seguites:</v-flex>
        <v-flex xs12>
          Nome: <b>{{funcionario.pessoa.nome}} {{funcionario.pessoa.sobrenome || ''}}</b>
        </v-flex>
        <v-flex xs12>
          E-mail: <b>{{funcionario.email}}</b>
        </v-flex>
        <v-flex xs12>
          CPF: <b>{{funcionario.cpf}}</b>
        </v-flex>
        <v-flex xs12>
          Cadastro em: <b>{{funcionario.data_cadastro.split('-').reverse().join('/')}}</b>
        </v-flex>
      </template>
      <template v-if="veiculo">
        <v-flex xs12 class="mb-4" style="font-size: 1.3em; text-align: justify;">
          Um novo veículo foi cadastrado em sua empresa <b>{{empresa.nome_fantasia}}</b>, seu dados são os seguites:
        </v-flex>
        <v-layout wrap justify-space-between>
          <v-flex xs5>
            Placa: <b>{{veiculo.identificacao}}</b>
          </v-flex>
          <v-flex xs5 v-if="veiculo.modelo">
            Modelo: <b>{{veiculo.modelo}}</b>
          </v-flex>
          <v-flex xs5 v-if="veiculo.modelo">
            Marca: <b>{{veiculo.marca}}</b>
          </v-flex>
          <v-flex xs5 v-if="veiculo.ano_fabricacao">
            Ano de Fabricação / modelo: <b>{{veiculo.ano_fabricacao}}</b>
          </v-flex>
          <v-flex xs7 v-if="veiculo.local_registro">
            Local de registro: <b>{{veiculo.local_registro}}</b>
          </v-flex>
          <v-flex xs5 v-if="veiculo.estado_registro">
            Estado de registro: <b>{{veiculo.estado_registro}}</b>
          </v-flex>
          <v-flex xs5 v-if="veiculo.tipo_veiculo && veiculo.tipo_veiculo.denominacao">
            Tipo de veículo: <b>{{veiculo.tipo_veiculo.denominacao}}</b>
          </v-flex>
          <v-flex xs12 v-if="veiculo.cor">
            Cor do veiculo: <v-btn :color="formatarCor(veiculo.cor)" small></v-btn>
          </v-flex>
        </v-layout>
      </template>
      <v-layout justify-center class="mt-4">
        <v-flex xs7>
          <v-select
            label="Selecione ação"
            solo
            v-model="opcao"
            :items="[
              { text: 'Aceitar essa solicitação', value: 1 },
              { text: 'Rejeitar essa solicitação', value: 2 },
              { text: 'Aceitar todas desse tipo', value: 3 },
              { text: 'Rejeitar todas desse tipo', value: 4 }
            ]"
          ></v-select>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions>
      <v-btn block color="info" class="mb-4" @click="confirmar">Confirmar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['funcionario', 'veiculo'],
  data: () => ({
    opcao: 1
  }),
  computed: {
    empresa () {
      return this.$store.getters.empresa
    }
  },
  methods: {
    confirmar () {
      const data = {
        opcao: this.opcao,
        id_funcionario: null,
        id_veiculo: null,
        cnpj_empresa: this.empresa.cnpj
      }
      if (this.funcionario) data.id_funcionario = this.funcionario.id_funcionario
      else if (this.veiculo) data.id_veiculo = this.veiculo.id_veiculo
      this.$store.dispatch('responderNotificacao', data).then(() => {
        this.$emit('carregarNotificacoes')
        this.$emit('fechar')
      })
    },
    formatarCor (cor) {
      if (cor === null) return ''
      if (cor === undefined) return ''
      if (cor.length === 6) return `#${cor}`
      if (cor.length === 7) return cor
      if (cor.length === 9) return cor.slice(0, 7)
      else {
        let teste = parseInt(cor)
        teste = teste.toString(16)
        return `#${teste.slice(2, 8)}`
      }
    }
  }
}
</script>
