<template>
  <v-card>
    <v-card-title>
      <h4 style="text-align: center; width: 100%;">Selecione um Colaborador</h4>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text style="height: 350px;">
      <v-col class="d-flex" cols="12" sm="12">
        <v-autocomplete
            :items="funcionarios.results"
            v-model="funcionarioSelected"
            color="info"
            item-text="nome_email"
            label="Colaboradores"
            filled
            return-object
        >
      </v-autocomplete>
      </v-col>
      <v-col>
        <v-select
          v-model="dataVeiculo"
          :items="veiculos_por_funcionario"
          item-text="modelo_marca"
          label="Veiculo"
          return-object
          color="info"
          filled
          @input="associarMotorista(dataVeiculo)"
        >
        </v-select>
        <v-select class="mt-4" label="Selecione o tipo de combustível"
          clearable
          :items="itens"
          item-text="nome"
          item-color="combustivel.color"
          v-model="item"
          return-object
        ></v-select>
      </v-col>
      <!-- <v-chip
        v-if="veiculo"
        class="ma-2"
        close
        color="indigo darken-3"
        outlined
        @click:close="removerMotorista(veiculo)"
      >
      {{veiculo.modelo_marca}}
      </v-chip> -->
    </v-card-text>
    <v-footer color="white">
          <v-btn
            class="mb-4"
            block
            small
            color="primary"
            :disabled="!dataVeiculo || !funcionarioSelected || !item"
            @click="selecionarFuncionario"
          >
            Confirmar
          </v-btn>
      </v-footer>
  </v-card>
</template>

<script>
import { funcionariosDao } from '@/api'

export default {
  props: ['visible', 'voucher'],
  data () {
    return {
      funcionarioData: null,
      dependente: null,
      funcionarios: [],
      itens: [],
      funcionarioSelected: null,
      motorista: null,
      dataVeiculo: null,
      exibirVeiculo: true,
      item: undefined,
      dataMotorista: {
        cpf: null,
        cnpj: null
      },
      pagination: {
        page: 1,
        rowsPerPage: 1000
      }
    }
  },
  beforeMount () {
    this.restartFields()
    this.carregarFuncionarios()
    this.carregarVeiculos()
    this.prepararDados()
  },
  watch: {
    voucher (val) {
      if (val) {
        this.prepararDados()
      }
    },
    funcionarioSelected (val) {
      if (val) {
        this.carregarVeiculoPorFuncionario(val).then(() => {
          this.exibirVeiculo = true
          this.dataMotorista.cnpj = this.empresa.cnpj
          this.dataMotorista.cpf = this.funcionario.pessoa.cpf
          this.$store.dispatch('veiculoPorFuncionario', this.dataMotorista)
        })
      }
    }
  },
  computed: {
    headers () {
      return this.$store.getters.headers
    },
    veiculos () {
      return this.$store.getters.veiculos
    },
    veiculo () {
      return this.$store.getters.veiculo
    },
    veiculos_por_funcionario () {
      return this.$store.getters.veiculos_por_funcionario
    },
    funcionario () {
      return this.$store.getters.funcionario
    },
    empresa () {
      return this.$store.getters.empresa
    },
    combustiveis () {
      return this.$store.getters.combustiveis
    }
  },
  methods: {
    prepararDados () {
      if (this.voucher) {
        this.pegarItens()
        if (this.voucher.pedido && this.voucher.pedido.itens.length > 0) {
          this.item = this.voucher.pedido.itens[0].item
        } else if (this.voucher.combustivel) {
          this.item = this.itens.filter((i) => i.combustivel.id_combustivel === this.voucher.combustivel.id_combustivel)[0]
        }
        if (this.voucher.funcionario) {
          this.funcionarioSelected = this.voucher.funcionario
        }
        if (this.voucher.veiculo) {
          this.dataVeiculo = this.voucher.veiculo
        }
      }
    },
    restartFields () {
      this.funcionarioSelected = null
      this.dataVeiculo = null
    },
    pegarItens () {
      if (!this.empresa) return
      const data = {
        empresa: this.empresa.cnpj,
        categoria__referencia: 'combustivel',
        posto: this.voucher.posto ? this.voucher.posto.cnpj : this.voucher.rede.matriz.cnpj
      }

      this.$store.dispatch('pegarItens', data).then(json => {
        this.itens = json
      })
    },
    carregarFuncionarios () {
      var data = {
        pagination: this.pagination,
        empresa: this.empresa.cnpj
      }
      funcionariosDao.getFuncionariosEmpresaConfirmados(data, this.headers)
        .then(res => res.json())
        .then(json => {
          this.funcionarios = json
        })
    },
    carregarVeiculos () {
      this.$store.dispatch('carregarVoucherVeiculos', this.empresa.cnpj)
    },
    associarMotorista (dataVeiculo) {
      var data = {
        id_funcionario: this.funcionarioSelected.id_funcionario,
        veiculo: dataVeiculo
      }
      this.$store.dispatch('inserirMotorista', data)
      this.exibirVeiculo = false
    },
    removerMotorista () {
      var data = {
        id_funcionario: this.funcionario.id_funcionario,
        id_veiculo: this.veiculo.id_veiculo
      }
      this.$store.dispatch('removerMotorista', data)
      this.dataVeiculo = null
      this.exibirVeiculo = true
    },
    carregarVeiculoPorFuncionario (funcionario) {
      return new Promise(resolve => {
        this.$store.dispatch('selecionarFuncionario', funcionario)
        this.dataMotorista.cnpj = this.empresa.cnpj
        this.dataMotorista.cpf = funcionario.pessoa.cpf
        // this.$store.dispatch('veiculoPorFuncionario', this.dataMotorista)
        if (this.veiculo) this.dataVeiculo = this.veiculo
        resolve('')
      })
    },
    // Teste localmente antes para ver se ta indo os id diferente dos veidulos
    selecionarFuncionario () {
      const idCombustivel = this.item.combustivel.id_combustivel
      this.funcionarioData = {
        id_funcionario: this.funcionario.id_funcionario,
        id_veiculo: this.dataVeiculo.id_veiculo,
        id_combustivel: idCombustivel,
        id_item: this.item.id,
        empresa: this.empresa.cnpj,
        id_voucher: this.voucher.id_voucher,
        id_compra: this.voucher.id_compra
      }
      if (this.funcionario !== null) {
        this.$emit('funcionarioSelected', this.funcionarioData)
      }
    }
  }
}
</script>
