<template>
  <v-container fluid fill-height grid-list-xs pa-0>
    <v-layout row wrap align-stretch>
      <v-form v-model="valid" style="width: 100%;">
        <v-layout row wrap justify-center>
          <v-flex
            xs12
            sm6
            md3
            lg3
            v-if="empresa && empresa.tipo_empresa !== 1"
          >
            <v-flex xs12>
              <b>Comprador</b>
            </v-flex>
            <v-flex xs12>
              <v-select
                :items="[
                  { text: 'Todos', value: null },
                  { text: 'Clientes', value: 1 },
                  { text: 'Empresas', value: 2 },
                  { text: 'Postos', value: 3 }
                ]"
                item-text="text"
                label="Tipo de comprador"
                item-value="value"
                v-model="data.tipo_comprador"
              ></v-select>
            </v-flex>
            <v-flex xs12 v-if="data.tipo_comprador === null || data.tipo_comprador === 1">
              <v-text-field
                placeholder="Identificação do cliente"
                label="Identificação do cliente"
                class="pr-3"
                v-model="data.id_pessoa"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 v-if="data.tipo_comprador === null || data.tipo_comprador === 1">
              <v-text-field
                placeholder="CPF do Cliente"
                label="CPF do Cliente"
                v-mask="'###.###.###-##'"
                class="pr-3"
                v-model="data.cpf"
                return-masked-value
                :rules="[rules.cpf]"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 v-if="data.tipo_comprador === null || data.tipo_comprador === 2">
              <v-text-field
                placeholder="CNPJ da Empresa"
                label="CNPJ da Empresa"
                v-mask="'##.###.###/####-##'"
                class="pr-3"
                v-model="data.cnpj"
                return-masked-value
                :rules="[rules.cnpj]"
              ></v-text-field>
            </v-flex>
          </v-flex>
          <v-divider
            :vertical="$vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl"
            v-if="empresa && empresa.tipo_empresa !== 1"
          ></v-divider>
          <v-flex
            xs12
            :sm6="empresa && empresa.tipo_empresa !== 1"
            :sm12="empresa && empresa.tipo_empresa === 1"
            :md8="empresa && empresa.tipo_empresa !== 1"
            :md10="empresa && empresa.tipo_empresa === 1"
            :lg6="empresa && empresa.tipo_empresa !== 1"
            :lg9="empresa && empresa.tipo_empresa !== 1"
          >
            <v-flex xs12>
              <b>Dados da Compra:</b>
            </v-flex>
            <v-layout justify-space-around wrap>
              <v-flex xs12 sm6 md4 v-if="empresa && empresa.tipo_empresa === 1">
                <v-select
                  :items="funcionarios_empresa"
                  item-text="nome_email"
                  label="Funcionário"
                  item-value="id_funcionario"
                  v-model="data.funcionario"
                  clearable
                >
                </v-select>
              </v-flex>
              <v-flex xs12 sm6 md4 v-if="empresa && empresa.tipo_empresa === 1">
                <v-select
                  :items="veiculos"
                  item-text="identificacao"
                  label="Veículo"
                  item-value="identificacao"
                  v-model="data.veiculo"
                  clearable
                >
                </v-select>
              </v-flex>
              <v-flex xs12 sm6 md4 v-if="empresa && empresa.tipo_empresa === 1">
                <v-select
                  :disabled="data.rede !== null"
                  :items="postos"
                  item-text="nome_fantasia"
                  label="Posto"
                  item-value="cnpj"
                  v-model="data.posto"
                  clearable
                >
                </v-select>
              </v-flex>
              <v-flex xs12 sm6 md4 v-if="empresa && empresa.tipo_empresa === 1">
                <v-select
                  :disabled="data.posto !== null"
                  :items="redes"
                  item-text="nome"
                  label="Rede"
                  item-value="id_rede"
                  v-model="data.rede"
                  clearable
                >
                </v-select>
              </v-flex>
              <v-flex xs12 sm6 md4 v-if="empresa && empresa.tipo_empresa === 1">
                <v-select
                  :items="[
                    // {text: 'Pós-paga', value: 'pospago'},
                    {text: 'Pré-paga', value: 'prepago'},
                    {text: 'Presencial', value: 'presencial'}
                  ]"
                  item-text="text"
                  label="Tipo de proposta"
                  item-value="value"
                  v-model="data.tipo_proposta"
                  clearable
                >
                </v-select>
              </v-flex>
              <v-flex xs12 sm6 md4 v-if="empresa && empresa.tipo_empresa === 1">
                <v-select
                  :items="propostas"
                  label="Proposta"
                  item-text="identificacao"
                  item-value="id"
                  v-model="proposta"
                  clearable
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-select
                  :items="[
                    {
                      title: 'Confirmado',
                      value: true
                    },
                    {
                      title: 'Não Confirmado',
                      value: false
                    },
                    {
                      title: 'Todos',
                      value: null
                    }
                  ]"
                  item-text="title"
                  label="Pagamento"
                  item-value="value"
                  v-model="data.confirmado"
                >
                </v-select>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-select
                  :items="[
                    { text: 'Todos', value: null },
                    { text: 'Lido', value: false },
                    { text: 'Aguandando Leitura', value: true }
                  ]"
                  label="Status"
                  v-model="data.disponivel"
                >
                </v-select>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-select
                  :items="[
                    { text: 'Pré pago', value: 'prepago' },
                    { text: 'Pós pago', value: 'pospago' }
                  ]"
                  label="Método de Pagamento"
                  class="pr-3"
                  v-model="data.metodo_pagamento"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-select
                  :items="frentistas"
                  label="Funcionário"
                  class="pr-3"
                  item-value="id_frentista"
                  v-model="data.frentista"
                  hint="Funcionário que confirmou o recebimento"
                  clearable
                >
                  <template v-slot:item="data">
                    <v-avatar
                      color="grey"
                      size="30">
                      <img v-if="data.item.url_imagem_perfil || (data.item.pessoa && data.item.pessoa.url_imagem_perfil)" :src="data.item.url_imagem_perfil || data.item.pessoa.url_imagem_perfil" alt="trevor">
                      <v-icon dark v-else>face</v-icon>
                    </v-avatar>
                    <v-spacer></v-spacer>
                    <span v-text="`${data.item.nome ? (data.item.nome + ' ' + data.item.sobrenome) : (data.item.pessoa.nome + ' ' + data.item.pessoa.sobrenome)}`"></span>
                    <v-spacer></v-spacer>
                  </template>
                  <template v-slot:selection="data">
                    <v-avatar
                      color="grey"
                      size="30">
                      <img v-if="data.item.url_imagem_perfil || (data.item.pessoa && data.item.pessoa.url_imagem_perfil)" :src="data.item.url_imagem_perfil || data.item.pessoa.url_imagem_perfil" alt="trevor">
                      <v-icon dark v-else>face</v-icon>
                    </v-avatar>
                    <v-spacer></v-spacer>
                    <span v-text="`${data.item.nome ? (data.item.nome + ' ' + data.item.sobrenome) : (data.item.pessoa.nome + ' ' + data.item.pessoa.sobrenome)}`"></span>
                    <v-spacer></v-spacer>
                  </template>
                </v-select>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-text-field
                  label="Código"
                  class="pr-3"
                  v-model="data.codigo"
                  hint="Código da compra"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-text-field
                  label="NSU"
                  class="pr-3"
                  v-model="data.nsu"
                  hint="NSU da compra"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-select
                  label="Item Vendido"
                  :items="itens"
                  item-text="nome"
                  item-value="id"
                  clearable
                  v-model="data.item"
                >
                  <template v-slot:item="data">
                    <template v-if="data.item.combustivel">
                      <svg-filler :path="require(`@/assets/combs/${data.item.combustivel.icone}.svg`)" width="35px" height="35px" :fill="data.item.combustivel.color"/>
                      {{data.item.combustivel.nome}}
                    </template>
                    <template v-else>
                      <v-icon
                        :color="data.item.categoria.cor"
                        class="ma-2"
                      >
                        {{ data.item.categoria.icone }}
                      </v-icon>
                      <span>{{ data.item.nome }}</span>
                    </template>
                  </template>
                  <template v-slot:selection="data">
                    <template v-if="data.item.combustivel">
                      <svg-filler :path="require(`@/assets/combs/${data.item.combustivel.icone}.svg`)" width="35px" height="35px" :fill="data.item.combustivel.color"/>
                      {{data.item.combustivel.nome}}
                    </template>
                    <template v-else>
                      <v-icon
                        :color="data.item.categoria.cor"
                        class="ma-2"
                      >
                        {{ data.item.categoria.icone }}
                      </v-icon>
                      <span>{{ data.item.nome }}</span>
                    </template>
                  </template>
                </v-select>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-select
                  :items="[
                    {
                      title: 'Dia',
                      value: 'dia'
                    },
                    {
                      title: 'Mês',
                      value: 'mes'
                    },
                    {
                      title: 'Intervalo',
                      value: 'custom'
                    }
                  ]"
                  item-text="title"
                  item-value="value"
                  v-model="selectOpcoes"
                >
                </v-select>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-menu
                  ref="menuDataInicial"
                  v-model="menuDataInicial"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-combobox
                      v-if="data.custom"
                      v-model="data.intervalo_relatorio"
                      label="Dias"
                      multiple
                      persistent-hint
                      chips
                      small-chips
                      readonly
                      flat
                      prepend-icon="event"
                      required
                      v-on="on"
                      clearable
                    ></v-combobox>
                    <v-combobox
                      v-else-if="data.dia"
                      v-model="data.data_relatorio"
                      label="Dia"
                      persistent-hint
                      chips
                      small-chips
                      readonly
                      flat
                      @blur="date = parseDate(data.data_relatorio)"
                      prepend-icon="event"
                      required
                      v-on="on"
                      clearable
                    ></v-combobox>
                    <v-combobox
                      v-else
                      v-model="data.mes_relatorio"
                      label="Mes"
                      persistent-hint
                      chips
                      small-chips
                      readonly
                      flat
                      @blur="month = parseDate(data.mes_relatorio)"
                      prepend-icon="event"
                      required
                      v-on="on"
                      clearable
                    ></v-combobox>
                  </template>
                  <v-date-picker v-show="data.custom" multiple v-model="dates" type="date" no-title @input="dataSelecionada" locale="pt-br"></v-date-picker>
                  <v-date-picker v-show="data.dia" v-model="date" type="date" no-title @input="dataSelecionada" locale="pt-br"></v-date-picker>
                  <v-date-picker v-show="data.mes" v-model="month" type="month" no-title @input="dataSelecionada" locale="pt-br"></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex
            xs12
            sm12
            md1
            style="
              justify-content: center;
              align-items: center;
              display: flex;
            "
          >
            <v-flex
              xs12
              sm6
              md12
            >
              <v-btn
                :fab="$vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl"
                :block="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
                dark
                small
                color="primary"
                @click="carregarRelatorio(true)"
              >
                <v-icon>search</v-icon>
              </v-btn>
            </v-flex>
            <v-flex
              xs12
              sm6
              md12
            >
              <v-menu bottom left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    :icon="$vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl"
                    :block="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
                    v-on="on"
                    small
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    @click="print()"
                  >
                    <v-list-item-action>
                      <v-icon>print</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>Imprimir relatório</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-flex>
          </v-flex>
        </v-layout>
      </v-form>
      <v-flex xs12 class="print">
        <div class="pb-3 pt-5">
          <v-layout row>
            <h2> Relatório de Compras</h2>
            <v-spacer></v-spacer>
          </v-layout>
          <hr/>
        </div>
         <v-card>
          <v-data-table
            :items="compras"
            hide-default-footer
            :options.sync="paginationVoucher"
            :loading="loading"
          >
            <template v-slot:item="props">
              <tr>
                <td class="text-xs-center px-0" style="width: 60px"  v-if="headersVisible.item">
                  <template v-if="props.item.combustivel">
                    <v-tooltip class="hidden-print-only" :color="props.item.combustivel.color" top>
                        <template v-slot:activator="{ on }">
                            <span class="hidden-print-only" v-on="on">
                                <svg-filler :path="require(`@/assets/combs/${props.item.combustivel.icone}.svg`)" width="40px" height="40px" :fill="props.item.combustivel.color"/>
                            </span>
                        </template>
                        <span>{{props.item.combustivel.nome}}</span>
                    </v-tooltip>
                    <span class="hidden-screen-only">{{props.item.combustivel.nome}}</span>
                  </template>
                  <template v-else-if="props.item.pedido">
                    <v-tooltip class="hidden-print-only" top>
                        <template v-slot:activator="{ on }">
                            <span class="hidden-print-only" v-on="on">
                                <v-icon color="success">attach_money</v-icon>
                            </span>
                        </template>
                        <span>Compra de Item</span>
                    </v-tooltip>
                    <span class="hidden-screen-only">Compra de Item</span>
                  </template>
                </td>
                <td class="text-xs-left" v-if="headersVisible.codigo">
                  <b>{{ props.item.codigo }}</b>
                </td>
                <td class="text-xs-left" v-if="headersVisible.data_compra">
                  <template v-if="props.item.data_compra">
                    {{ props.item.data_compra | formatDate('DD/MM/YYYY') }}
                  </template>
                </td>
                <td class="text-xs-left" v-if="headersVisible.comprador">
                  <template v-if="props.item.empresa">
                    Empresa {{`${props.item.empresa.nome_fantasia}` | truncate(30) }}
                    <div><strong>{{props.item.empresa.cnpj}}</strong></div>
                  </template>
                  <template v-else-if="props.item.posto">
                    Posto {{`${props.item.posto.nome_fantasia}` | truncate(30) }}
                    <div><strong>{{props.item.posto.cnpj}}</strong></div>
                  </template>
                  <template v-else-if="props.item.rede">
                    {{`Rede ${props.item.rede.nome}` | truncate(30) }}
                  </template>
                  <template v-else-if="props.item.cliente">
                    Cliente {{`${props.item.cliente.pessoa.nome} ${props.item.cliente.pessoa.sobrenome}` | truncate(30) }}
                    <div><strong>{{props.item.cliente.pessoa.id_pessoa}}</strong></div>
                  </template>
                  <template v-else>
                    Não encontrado
                  </template>
                </td>
                <td class="text-xs-left" v-if="headersVisible.vendedor">
                  <template v-if="props.item.posto">
                    Posto: {{  props.item.posto.nome_fantasia }}
                  </template>
                  <template v-else-if="props.item.rede">
                    Rede: {{  props.item.rede.nome }}
                  </template>
                  <template v-else-if="props.item.empresa_vendedora">
                    Empresa: {{  props.item.empresa_vendedora.nome_fantasia }}
                  </template>
                </td>
                <td class="text-xs-left" v-if="headersVisible.tid">
                  {{props.item.token}}
                  <div>NSU: <strong>{{props.item.nsu}}</strong></div>
                  <div v-if="props.item.brand && props.item.card">Cartão: <strong>{{props.item.brand}} - Final: {{props.item.card.substr(props.item.card.length - 4)}}</strong></div>
                </td>
                <td class="text-xs-left" v-if="headersVisible.frentista">
                  <template v-if="empresa.tipo_empresa > 3 && props.item.frentista">
                    {{ props.item.frentista.nome }}
                  </template>
                  <template v-else-if="props.item.funcionario">
                    {{ props.item.funcionario.nome_email }}
                  </template>
                  <template v-else-if="empresa.tipo_empresa > 3 && props.item.usuario_utilizador">
                    {{`${props.item.usuario_utilizador.nome}`}}
                  </template>
                </td>
                <td class="text-xs-left" v-if="headersVisible.kms_odometro">
                  <div v-if="props.item.kms_odometro !== null">
                    {{ props.item.kms_odometro }} Km
                  </div>
                  <div v-else>
                      Não informado
                  </div>
                </td>
                <td class="text-xs-left" v-if="headersVisible.valor_total">
                  <template v-if="props.item.valor_total">
                    {{ props.item.valor_total | currency({ fractionCount: 2 }) }}
                  </template>
                  <template v-else-if="props.item.valor">
                    {{ props.item.valor | currency({ fractionCount: 2 }) }}
                  </template>
                </td>
                <td class="text-xs-center" v-if="headersVisible.desconto">
                  {{props.item.desconto || 0}}%
                </td>
                <td class="text-xs-left" v-if="headersVisible.valor">
                  {{ props.item.valor | currency({ fractionCount: 2 }) }}
                </td>
                <td class="text-xs-left" v-if="headersVisible.pagamento">
                  <template v-if="props.item.tipo_pagamento">
                    {{ props.item.tipo_pagamento }}
                  </template>
                </td>
                <td class="text-xs-left" v-if="headersVisible.data_inativado">
                  <template v-if="props.item.data_inativado">
                    {{ props.item.data_inativado | formatDate('DD/MM/YYYY') }}
                  </template>
                </td>
                <td class="text-xs-center" v-if="headersVisible.confirmada">
                  <v-tooltip v-if="props.item.confirmado" top>
                      <template v-slot:activator="{ on }">
                          <v-icon v-on="on" color="teal">done_all</v-icon>
                      </template>
                      <span>Pagamento efetuado</span>
                  </v-tooltip>
                  <v-tooltip v-else top>
                      <template v-slot:activator="{ on }">
                          <v-icon v-on="on" color="red">close</v-icon>
                      </template>
                      <span>Pagamento não efetuado</span>
                  </v-tooltip>
                </td>
                <td class="text-xs-center" v-if="headersVisible.disponivel">
                  <v-tooltip v-if="!props.item.disponivel" top>
                      <template v-slot:activator="{ on }">
                          <v-icon v-on="on" color="teal">check_circle</v-icon>
                      </template>
                      <span>Leitura realizada</span>
                  </v-tooltip>
                  <v-tooltip v-else top>
                      <template v-slot:activator="{ on }">
                          <v-icon v-on="on" color="red">cancel</v-icon>
                      </template>
                      <span>Leitura não realizada</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
            <template v-slot:header>
              <tr>
                <th class="text-xs-left px-4 pa-4" v-if="headersVisible.item">
                  <v-btn flat icon color="grey" v-if="editando" @click="headersVisible.item = false">
                    <v-icon>remove_circle</v-icon>
                  </v-btn>
                </th>
                <th class="text-xs-left px-3 pa-4" v-if="headersVisible.codigo">
                  <v-btn flat icon color="grey" v-if="editando" @click="headersVisible.codigo = false">
                    <v-icon>remove_circle</v-icon>
                  </v-btn>
                  Código
                </th>
                <th class="text-xs-left px-3 pa-4" v-if="headersVisible.data_compra">
                  <v-btn flat icon color="grey" v-if="editando" @click="headersVisible.data_compra = false">
                    <v-icon>remove_circle</v-icon>
                  </v-btn>
                  Data
                </th>
                <th class="text-xs-left px-3 pa-4" v-if="headersVisible.comprador">
                  <v-btn flat icon color="grey" v-if="editando" @click="headersVisible.comprador = false">
                    <v-icon>remove_circle</v-icon>
                  </v-btn>
                  Comprador
                </th>
                <th class="text-xs-left px-3 pa-4" v-if="headersVisible.vendedor">
                  <v-btn flat icon color="grey" v-if="editando" @click="headersVisible.vendedor = false">
                    <v-icon>remove_circle</v-icon>
                  </v-btn>
                  Vendedor
                </th>
                <th class="text-xs-left px-3 pa-4" v-if="headersVisible.tid">
                  <v-btn flat icon color="grey" v-if="editando" @click="headersVisible.tid = false">
                    <v-icon>remove_circle</v-icon>
                  </v-btn>
                  Transação
                </th>
                <th class="text-xs-left px-3 pa-4" v-if="headersVisible.frentista">
                  <v-btn flat icon color="grey" v-if="editando" @click="headersVisible.frentista = false">
                    <v-icon>remove_circle</v-icon>
                  </v-btn>
                  Funcionário
                </th>
                <th class="text-xs-left px-3 pa-4" v-if="headersVisible.kms_odometro">
                  <v-btn flat icon color="grey" v-if="editando" @click="headersVisible.kms_odometro = false">
                    <v-icon>remove_circle</v-icon>
                  </v-btn>
                  Hodômetro
                </th>
                <th class="text-xs-left px-3 pa-4" v-if="headersVisible.valor_total">
                  <v-btn flat icon color="grey" v-if="editando" @click="headersVisible.valor_total = false">
                    <v-icon>remove_circle</v-icon>
                  </v-btn>
                  Valor Total (R$)
                </th>
                <th class="text-xs-center px-3 pa-4" v-if="headersVisible.desconto">
                  <v-btn flat icon color="grey" v-if="editando" @click="headersVisible.desconto = false">
                    <v-icon>remove_circle</v-icon>
                  </v-btn>
                  Desconto
                </th>
                <th class="text-xs-right px-3 pa-4" v-if="headersVisible.valor">
                  <v-btn flat icon color="grey" v-if="editando" @click="headersVisible.valor = false">
                    <v-icon>remove_circle</v-icon>
                  </v-btn>
                  Valor Pago (R$)
                </th>
                <th class="text-xs-center px-4 pa-4" v-if="headersVisible.pagamento">
                  <v-btn flat icon color="grey" v-if="editando" @click="headersVisible.pagamento = false">
                    <v-icon>remove_circle</v-icon>
                  </v-btn>
                  Tipo de Pagamento
                </th>
                <th class="text-xs-left  px-3 pa-4" v-if="headersVisible.data_compra">
                  <v-btn flat icon color="grey" v-if="editando" @click="headersVisible.data_compra = false">
                    <v-icon>remove_circle</v-icon>
                  </v-btn>
                  Data da Leitura
                </th>
                <th class="text-xs-center px-4 pa-4" v-if="headersVisible.disponivel">
                  <v-btn flat icon color="grey" v-if="editando" @click="headersVisible.disponivel = false">
                    <v-icon>remove_circle</v-icon>
                  </v-btn>
                </th>
              </tr>
            </template>
          </v-data-table>
        </v-card>
        <div class="text-xs-center px-4 ma-4">
          <v-pagination
            v-model="paginationVoucher.page"
            :length="pages"
            circle
            class="ma-4"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
    <v-footer
      app
      style="background: transparent;"
    >
      <v-expand-transition>
        <v-toolbar dense v-show="compras.length">
          <v-toolbar-title>
            Total: {{ total_relatorio_vouchers | currency({ fractionCount: 2 }) }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title>
            Valor Recebido: {{ total_recebido | currency({ fractionCount: 2 }) }}
          </v-toolbar-title>
        </v-toolbar>
      </v-expand-transition>
    </v-footer>
  </v-container>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      pagination: {
        page: 1,
        rowsPerPage: 20
      },
      paginationVoucher: {
        page: 1,
        rowsPerPage: 5
      },
      data: {
        disponivel: null,
        funcionario: null,
        rede: null,
        posto: null,
        veiculo: null,
        tipo_proposta: null,
        id_pessoa: null,
        tipo_comprador: null,
        cpf: null,
        cnpj: null,
        confirmado: null,
        metodo_pagamento: 'prepago',
        frentista: null,
        item: null,
        codigo: null,
        nsu: null,
        dia: true,
        custom: false,
        mes: false,
        data_relatorio: moment().format('DD/MM/YYYY'),
        mes_relatorio: null,
        intervalo_relatorio: [],
        recebidos: false
      },
      itens: [],
      frentista: [], // É o funcionário de uma empresa
      proposta: undefined,
      valid: false,
      combustivel: '',
      filtro: 'todos',
      editando: false,
      menuDataInicial: false,
      date: moment().format('YYYY-MM-DD'),
      month: new Date().toISOString().substr(0, 10),
      dates: [],
      headersVisible: {
        desconto: true,
        item: true,
        data_compra: true,
        data_inativado: true,
        pagamento: true,
        confirmada: true,
        tid: true,
        valor: true,
        valor_total: true,
        disponivel: true,
        codigo: true,
        frentista: true,
        comprador: true,
        vendedor: true,
        usuario_utilizador: true,
        kms_odometro: true
      },
      funcionarios_empresa: [],
      today: null,
      selectOpcoes: 'dia',
      postos: [],
      redes: [],
      rules: {
        cpf: value => {
          const pattern = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/
          return !value || pattern.test(value) || 'CPF inválido.'
        },
        cnpj: value => {
          const pattern = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/
          return !value || pattern.test(value) || 'CNPJ inválido.'
        }
      },
      total_relatorio_vouchers: 0,
      total_recebido: 0,
      compras: [],
      search: '',
      total_itens: 0
    }
  },
  computed: {
    loading () {
      return this.$store.getters.loading
    },
    total_relatorio_compras () {
      return this.$store.getters.total_relatorio_compras
    },
    empresa () {
      return this.$store.getters.empresa
    },
    ready () {
      return !!this.headers && !!this.empresa
    },
    funcionarios () {
      return this.$store.getters.funcionarios
    },
    frentistas () {
      return this.$store.getters.frentistas
    },
    veiculos () {
      return this.$store.getters.veiculos
    },
    pages () {
      if (this.paginationVoucher.rowsPerPage == null ||
        this.total_itens == null
      ) return 0

      return Math.ceil(this.total_itens / this.paginationVoucher.rowsPerPage)
    },
    propostas () {
      return this.$store.getters.propostas
    },
    filtro_proposta () {
      return {
        rede: this.data.rede,
        posto: this.data.posto,
        modelo_faturamento: this.data.tipo_proposta,
        not_page: true
      }
    }
  },
  watch: {
    empresa (val) {
      if (val !== null && val !== undefined) {
        if (val.tipo_empresa === 1) {
          this.carregarPostos()
          this.carregarRedes()
          this.carregarFuncionarios()
          this.carregarVeiculos()
          this.carregarPropostas()
        } else {
          this.pegarTodosItens()
          this.carregarFrentista()
        }
      }
    },
    filtro (val) {
      this.data.recebidos = false
      if (val === 'recebidos') {
        this.data.recebidos = true
      }
    },
    date (val) {
      if (val) {
        this.data.data_relatorio = this.formatDate(this.date)
      }
    },
    month (val) {
      if (val) {
        this.data.mes_relatorio = this.formatDate(this.month)
      }
    },
    filtro_proposta () {
      this.carregarPropostas()
    },
    selectOpcoes (val) {
      this.limparOpcoes()
      if (val === 'dia') {
        this.data.dia = true
        this.data.data_relatorio = this.formatDate(this.date)
      } else if (val === 'custom') {
        this.data.custom = true
        this.data.data_relatorio = this.formatDate(this.date)
      } else if (val === 'mes') {
        this.data.mes = true
        this.data.mes_relatorio = this.formatDate(this.month)
      }
    },
    'paginationVoucher.page': {
      handler (val, oldval) {
        if (this.empresa) this.carregarRelatorio(false)
      },
      deep: true
    }
  },
  mounted () {
    this.today = new Date().setHours(0, 0, 0, 0)
  },
  beforeMount () {
    if (this.ready) {
      if (this.empresa.tipo_empresa === 1) {
        this.carregarPostos()
        this.carregarRedes()
        this.carregarFuncionarios()
        this.carregarVeiculos()
        this.carregarPropostas()
      } else {
        this.pegarTodosItens()
        this.carregarFrentista()
      }
    }
  },
  methods: {
    print () {
      window.print()
    },
    carregarFrentista () {
      this.$store.dispatch('frentistas', {
        empresa: this.empresa.cnpj,
        tudo: true
      })
    },
    formatDate (date) {
      if (!date) return null

      if (this.data.dia || this.data.custom) {
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      } else {
        const [year, month] = date.split('-')
        return `${month}/${year}`
      }
    },
    orderDates () {
      this.dates.sort((a, b) => {
        a = new Date(a.split('-'))
        b = new Date(b.split('-'))
        return a < b ? -1 : a > b ? 1 : 0
      })
    },
    parseDate (date) {
      if (!date) return null

      if (this.data.dia || this.data.custom) {
        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      } else {
        const [month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}`
      }
    },
    limparOpcoes () {
      this.data.dia = false
      this.data.custom = false
      this.data.mes = false
    },
    carregarRelatorio (isBtn) {
      this.headersVisible.desconto = this.empresa.tipo_empresa >= 4
      this.headersVisible.tid = this.empresa.tipo_empresa >= 4 && this.data.metodo_pagamento === 'prepago'
      this.headersVisible.comprador = this.empresa.tipo_empresa >= 4
      this.headersVisible.vendedor = this.empresa.tipo_empresa < 4
      this.headersVisible.kms_odometro = this.empresa.tipo_empresa < 4
      const filtro = {
        page: isBtn ? 1 : this.paginationVoucher.page
      }
      if (this.data.posto !== null && this.data.posto !== undefined) {
        filtro.posto = this.data.posto
      }
      if (this.data.rede !== null && this.data.rede !== undefined) {
        filtro.rede = this.data.rede
      }
      if (this.data.funcionario !== null && this.data.funcionario !== undefined) {
        filtro.vouchers_funcionario__funcionario = this.data.funcionario
      }
      if (this.data.veiculo !== null && this.data.veiculo !== undefined) {
        filtro.veiculo__identificacao = this.data.veiculo
      }
      if (this.data.disponivel !== null && this.data.disponivel !== undefined) {
        filtro.disponivel = this.data.disponivel
      }
      if (this.data.id_pessoa) {
        filtro.cliente__pessoa__id_pessoa = this.data.id_pessoa
      }
      if (this.data.codigo) {
        filtro.codigo__iexact = this.data.codigo
      }
      if (this.data.nsu) {
        filtro.nsu = this.data.nsu
      }
      if (this.data.cpf) {
        filtro.cliente__pessoa__cpf = this.data.cpf.replace(/\D/g, '')
      }
      if (this.data.cnpj) {
        filtro.empresa__cnpj = this.data.cnpj.replace(/\D/g, '')
      }
      if (this.data.metodo_pagamento) {
        filtro.metodo_pagamento = this.data.metodo_pagamento
      }
      if (this.data.frentista) {
        filtro.frentista = this.data.frentista
      }
      if (this.data.item) {
        filtro.item = this.data.item
      }
      if (this.data.confirmado !== null) {
        filtro.confirmado = this.data.confirmado
      }
      if (this.data.tipo_comprador) {
        if (this.data.tipo_comprador === 1) filtro.cliente__isnull = false
        else if (this.data.tipo_comprador === 2) filtro.empresa__isnull = false
        else if (this.data.tipo_comprador === 3) filtro.posto__isnull = false
      }
      if (this.data.disponivel === false) {
        if (this.data.custom && this.data.intervalo_relatorio && this.data.intervalo_relatorio.length === 2) {
          filtro.data_inativado__gte = this.data.intervalo_relatorio[0]
          filtro.data_inativado__lte = this.data.intervalo_relatorio[1]
        } else if (this.data.dia && this.date) {
          filtro.data_inativado = this.date.split('-').reverse().join('/')
        } else if (this.data.mes && this.month) {
          const initialDate = new Date(`${this.month.split('-')[1]}-01-${this.month.split('-')[0]}`.split('-').join('/'))
          const finalDate = new Date(initialDate.getFullYear(), initialDate.getMonth() + 1, 0)

          filtro.data_inativado__gte = initialDate.toISOString().substr(0, 10).split('-').reverse().join('/')
          filtro.data_inativado__lt = finalDate.toISOString().substr(0, 10).split('-').reverse().join('/')
        }
      } else {
        if (this.data.custom && this.data.intervalo_relatorio && this.data.intervalo_relatorio.length === 2) {
          filtro.data_compra__gte = this.data.intervalo_relatorio[0]
          filtro.data_compra__lte = this.data.intervalo_relatorio[1]
        } else if (this.data.dia && this.date) {
          filtro.data_compra = this.date.split('-').reverse().join('/')
        } else if (this.data.mes && this.month) {
          const initialDate = new Date(`${this.month.split('-')[1]}-01-${this.month.split('-')[0]}`.split('-').join('/'))
          const finalDate = new Date(initialDate.getFullYear(), initialDate.getMonth() + 1, 0)

          filtro.data_compra__gte = initialDate.toISOString().substr(0, 10).split('-').reverse().join('/')
          filtro.data_compra__lt = finalDate.toISOString().substr(0, 10).split('-').reverse().join('/')
        }
      }
      if (this.data.tipo_proposta !== null && this.data.tipo_proposta !== undefined) {
        filtro.proposta__modelo_faturamento = this.data.tipo_proposta
      }
      if (this.proposta) filtro.proposta = this.proposta
      this.$store.dispatch('relatorioVouchers', filtro).then(relatorio => {
        this.compras = relatorio.results.compras
        this.total_relatorio_vouchers = relatorio.results.valor_total
        this.total_recebido = relatorio.results.valor
        this.total_itens = relatorio.count
      })
    },
    carregarPostos () {
      this.$store.dispatch('postosComProposta').then(json => {
        this.postos = json
      })
    },
    carregarRedes () {
      this.$store.dispatch('redesComProposta').then(json => {
        this.redes = json
      })
    },
    dataSelecionada () {
      if (this.data.custom) {
        if (this.dates.length === 3) {
          const day1 = this.dates[0]
          const day2 = this.dates[2]
          this.dates = []
          this.dates = [day1, day2]
        }
        if (this.dates.length) {
          this.orderDates()
        }
        this.data.intervalo_relatorio = this.dates.map(this.formatDate)
      } else {
        this.menuDataInicial = false
      }
    },
    carregarFuncionarios () {
      this.$store.dispatch('pegarFuncionarios').then(json => {
        this.funcionarios_empresa = json
      })
    },
    carregarPropostas () {
      this.$store.dispatch('buscarPropostas', { pagination: this.pagination, filtros: this.filtro_proposta })
    },
    pegarTodosItens () {
      const data = {
        method: 'GET',
        parameters: {
          empresa: this.empresa.cnpj,
          empresa__cnpj: this.empresa.cnpj
        }
      }
      this.$store.dispatch('itens', data).then(json => {
        this.itens = json
      })
    },
    carregarVeiculos () {
      var data = {
        page: this.pagination.page,
        cnpj: this.empresa.cnpj
      }
      this.$store.dispatch('carregarVeiculos', data)
    }
  }
}
</script>
