<template>
  <v-card>
      <v-card-title v-if="step != 3">
        {{ step == 1 ? 'Mudar Tipo de Empresa' : 'Detalhes da Solicitação' }}
      </v-card-title>
      <v-card-text>
        <v-stepper v-model="step" style="box-shadow: none;" class="mt-4">
          <v-stepper-items>
            <v-stepper-content step="1" class="pa-0 ma-0">
              <v-alert
                text
                type="warning"
                v-if="alerta"
                class="pa-2 mb-3"
              >
                <v-layout justify-space-between row>
                  <v-flex xs11 class="ma-0 pa-0">
                    <h4 class="mt-2">Uma solicitação já foi enviada para a Apetrus, Em breve você será respondido.</h4>
                  </v-flex>
                  <v-flex xs1 class="ma-0 pa-0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" color="warning" @click="step++">
                          <v-icon>visibility</v-icon>
                        </v-btn>
                      </template>
                      <span>Visualizar Solicitação</span>
                    </v-tooltip>
                  </v-flex>
                </v-layout>
              </v-alert>
              <v-select
                v-model="tipo_empresa"
                label="Tipo de empresa"
                :items="tipos"
                outlined
                class="mt-2"
              />
              <h3 style="text-align: center; width: 100%;" class="mb-3">
                {{tipos[tipo_empresa -1].text}}
              </h3>
              <div style="font-size: 1.2em">{{tipos[tipo_empresa -1].descricao}}</div>
            </v-stepper-content>
            <v-stepper-content step="2" class="pa-0 ma-0">
              <v-layout wrap align-content-space-between style="font-size: 1.2em;" v-if="solicitacao">
                <v-flex xs5 class="mb-3">
                  <b>Data de envio:</b> {{solicitacao.data}}
                </v-flex>
                <v-flex xs5 class="mb-3">
                  <b>Data de encerramento:</b> {{solicitacao.data_encerramento ? solicitacao.data_encerramento : 'Não finalizada'}}
                </v-flex>
                <v-flex xs5 class="mb-3">
                  <b>Status:</b> {{returnStatus(solicitacao.status)}}
                </v-flex>
                <v-flex xs5 class="mb-3">
                  <b>Tipo:</b> {{solicitacao.tipo}}
                </v-flex>
                <v-flex xs12 class="mb-2" v-if="solicitacao && solicitacao.campos_dinamicos">
                  <b>Descrição:</b> Deseja troca do tipo de empresa de <b>{{ tipos[solicitacao.campos_dinamicos.tipo_anterio -1 ].text }}</b>
                  para o tipo <b>{{ tipos[solicitacao.campos_dinamicos.novo_tipo - 1].text }}</b>
                </v-flex>
              </v-layout>
            </v-stepper-content>
            <v-stepper-content step="3" class="pa-0 ma-0">
              <h2 class="error--text mb-1 mt-4" style="text-align: center;">
                Tem certeza que deseja excluir essa solicitação?
              </h2>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
      <v-card-actions class="justify-space-around">
        <v-flex xs5>
          <v-btn
            block
            text
            class="mb-1"
            @click="function () {
              if (step === 1) $emit('fechar')
              else step--
            }"
          >
            {{ step === 1 ? 'Fechar' : step === 2 ? 'Voltar' : 'Não' }}
          </v-btn>
        </v-flex>
        <v-flex xs5>
          <v-btn
            :color="step === 1 ? 'success' : 'error'"
            :disabled="disabled"
            @click="function () {
              if (step === 1) enviarSolicitacao()
              else if (step === 2) step++
              else excluirSolicitacao()
            }"
            block
            text
            class="mb-1"
          >
            {{ step === 1 ? 'CONFIRMAR MUDANÇA DE TIPO' : step === 2 ? 'EXCLUIR SOLICITAÇÃO' : 'SIM' }}
          </v-btn>
        </v-flex>
      </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    tipo_empresa: 1,
    alerta: false,
    step: 1,
    solicitacao: undefined,
    tipos: [
      {
        text: 'Frota de veículos',
        value: 1,
        descricao: 'Esse tipo de empresa busca gerenciar o abastecimento de um conjunto de veículos, além disso, é possível gerenciar os funcionários, permissões de abastecimento e fazer negociações com postos ou rede de postos.'
      },
      {
        text: 'Revenda de veículos',
        value: 2,
        descricao: 'Esse tipo de empresa busca gerenciar suas compras, vendas, revendas, consórcios, agenciamentos, promoções e ofertas de veículos, além disso,  também é possível negociar com clientes.'
      },
      {
        text: 'Automação',
        value: 3,
        descricao: 'Esse tipo de empresa busca integrar-se a API externa da Apetrus, tornando possível pegar ofertas, promoções, compras, vouchers e combustíveis, além disso, é possível cadastrar postos, ofertas, promoções, combustíveis e realizar abastecimento.'
      },
      {
        text: 'Venda de Produtos',
        value: 4,
        descricao: 'Esse tipo de empresa lhe possíbilita vender produtos e gerenciar suas vendas.'
      },
      {
        text: 'Prestação de Serviços',
        value: 5,
        descricao: 'Esse tipo de empresa lhe possíbilita vender serviços e gerenciar suas vendas.'
      },
      {
        text: 'Venda de Produtos e Serviços',
        value: 6,
        descricao: 'Esse tipo de empresa lhe possíbilita vender produtos e serviços e gerenciar suas vendas.'
      },
      {
        text: 'Instituição Beneficente',
        value: 7,
        descricao: 'Esse tipo de empresa, tratá-se de uma empresa/instituição sem fins lugrativos que tem o objetivo de receber doações.'
      },
      {
        text: 'Integradora',
        value: 8,
        descricao: 'Esse tipo de empresa busca integrar-se a API externa da Apetrus, tornando possível a integração com diversos serviços da Apetrus.'
      }
    ]
  }),
  computed: {
    empresa () {
      return this.$store.getters.empresa
    },
    disabled () {
      if (!this.empresa) return true
      if (this.step === 1 && (this.tipo_empresa === this.empresa.tipo_empresa || this.alerta)) {
        return true
      } else if (this.step === 2 && (!this.solicitacao || this.solicitacao.status !== null)) {
        return true
      }
      return false
    }
  },
  watch: {
    empresa (val) {
      if (!val) this.tipo_empresa = 1
      else {
        this.tipo_empresa = val.tipo_empresa
      }
    }
  },
  beforeMount () {
    if (this.empresa) {
      this.tipo_empresa = this.empresa.tipo_empresa
      this.pegarSolicitacoes()
    }
  },
  methods: {
    returnStatus (status) {
      switch (status) {
        case true:
          return 'Aceita'
        case false:
          return 'Negada'
        default:
          return 'Aguardando Resposta'
      }
    },
    enviarSolicitacao () {
      const data = {
        referencia: 'mudanca_tipo',
        tipo: 'Mudança de Tipo',
        empresa: this.empresa.cnpj,
        novo_tipo: this.tipo_empresa,
        method: 'POST',
        id: null,
        descricao: `A empresa ${this.empresa.nome_fantasia} deseja mudar seu tipo para ${this.tipos[this.tipo_empresa - 1].text}`
      }
      this.$store.dispatch('solicitacao', data).then(() => this.$emit('fechar'))
    },
    excluirSolicitacao () {
      const data = {
        method: 'DELETE',
        id: this.solicitacao.id
      }
      this.$store.dispatch('solicitacao', data).then(() => {
        this.step = 1
        this.pegarSolicitacoes()
      })
    },
    pegarSolicitacoes () {
      const data = {
        referencia: 'mudanca_tipo',
        empresa: this.empresa.cnpj,
        status__isnull: true
      }
      this.$store.dispatch('pegarSolicitacoes', data).then(json => {
        if (json.length > 0) {
          this.alerta = true
          this.solicitacao = json[json.length - 1]
        } else {
          this.alerta = false
          this.solicitacao = undefined
        }
      })
    }
  }
}
</script>
