<template>
    <v-card>
      <v-stepper v-model="step" class="pa-0 ma-0">
        <v-stepper-items>
          <v-stepper-content step="1" class="pa-0 ma-0">
            <v-toolbar color="primary">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon @click="fechar"><v-icon color="white">close</v-icon></v-btn>
                  </template>
                  <span>Fechar</span>
                </v-tooltip>
                <v-toolbar-title style="color:white; font-size: 1.2em;">Meios de recebimento do posto</v-toolbar-title>
            </v-toolbar>
             <v-card-text class="text-xs-center">
               <v-expansion-panels>
                  <v-expansion-panel
                    v-for="(meio, i) in meios_de_recebimento"
                    :key="i"
                  >
                    <v-expansion-panel-header>
                      {{meio.descricao}}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <template v-if="meio.chave_pix">
                        Chava PIX: {{meio.chave_pix}}
                      </template>
                      <template v-if="meio.banco">
                        <v-flex xs6>
                          Agência: {{meio.agencia}}
                        </v-flex>
                        <v-flex xs6>
                          Conta: {{meio.conta}}
                        </v-flex>
                        <v-flex xs6>
                          Banco: {{meio.banco}}
                        </v-flex>
                        <v-flex xs6>
                          CNPJ: {{meio.posto}}
                        </v-flex>
                      </template>
                      <template v-if="meio.cod_boleto">
                        Código do boleto: {{meio.cod_boleto}}
                      </template>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <template v-if="meios_de_recebimento === null || meios_de_recebimento.length === 0">
                  <h2>Não existe um meio de recebimento cadastrado.</h2>
                  <h4>Entre em contato com o posto</h4>
                </template>
             </v-card-text>
            <v-card-actions>
                <v-btn block class="mb-3" color="primary" @click="step++">Continuar</v-btn>
            </v-card-actions>
          </v-stepper-content>
          <v-stepper-content step="2" class="pa-0 ma-0">
            <v-toolbar color="primary">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon @click="fechar"><v-icon color="white">close</v-icon></v-btn>
                  </template>
                  <span>Fechar</span>
                </v-tooltip>
                <v-toolbar-title style="color:white; font-size: 1.2em;">Anexe aqui seu comprovante de pagamento</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="text-xs-center">
                <v-layout column>
                <v-flex align-self-center>
                  <template v-if="!fatura.comprovante_pagamento || imagemAnexada || arquivoAnexado">
                    <div>
                        <img v-if="imagemAnexada" :src="imagemAnexada" alt="imagem do comprovante" width="300" @click="$refs.imageInput.click()">
                        <object v-if="arquivoAnexado" width="300" height="300" type="application/pdf" :data="arquivoAnexado" @click="$refs.imageInput.click()"></object>
                        <v-card v-if="!imagemAnexada && !arquivoAnexado" color="grey" width="300" height="300" @click="$refs.imageInput.click()">
                            <v-layout justify-center align-center fill-height>
                                <div class="title white--text pa-4 text-xs-center">Clique para adicionar Comprovante</div>
                            </v-layout>
                        </v-card>
                    </div>
                  </template>
                  <template v-else>
                    <div v-if="!imagemAnexada && !arquivoAnexado">
                      <object v-if="this.fatura.comprovante_pagamento.indexOf('png') === -1" width="400" height="400" type="application/pdf" :data="fatura.comprovante_pagamento" @click="$refs.imageInput.click()"></object>
                      <img v-else alt="imagem do comprovante" :src="this.fatura.comprovante_pagamento" width="300" @click="$refs.imageInput.click()">
                    </div>
                  </template>
                <input
                    type="file"
                    style="display:none"
                    accept="image/pdf*"
                    ref="imageInput"
                    @input="anexar"
                />
                </v-flex>
                </v-layout>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="!imagemAnexada && !arquivoAnexado" class="mb-3" color="primary" @click="$refs.imageInput.click()">Anexar</v-btn>
                <v-btn v-else class="mb-3" color="primary" @click="inserir">Confirmar</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
</template>
<script>

import { faturamentoDao, postoDao } from '@/api'

export default {
  props: ['fatura', 'apetrus'],
  data () {
    return {
      step: 1,
      arquivoAnexado: null,
      imagemAnexada: null,
      meios_de_recebimento: []
    }
  },
  computed: {
    loading () {
      return this.$store.getters.loading
    },
    empresa () {
      return this.$store.getters.empresa
    },
    headers () {
      return this.$store.getters.headers
    }
  },
  beforeMount () {
    if (this.fatura) {
      if (this.fatura.posto || this.fatura.rede) {
        this.pegarMeiosRebebimento()
      }
      if (this.fatura.comprovante_pagamento) {
        this.imagemAnexada = this.fatura.comprovante_pagamento
      }
      if (this.apetrus) {
        this.step = 2
      }
    }
  },
  methods: {
    anexar (event) {
      this.arquivoAnexado = null
      this.imagemAnexada = null
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onload = event => {
        const arquivo = event.target.result
        if (file.type.indexOf('image/') !== -1) {
          this.imagemAnexada = arquivo
        } else {
          this.arquivoAnexado = arquivo
        }
      }
      if (file) {
        reader.readAsDataURL(file)
      }
    },
    pegarMeiosRebebimento () {
      const posto = this.fatura.posto == null ? this.fatura.rede.matriz : this.fatura.proposta.posto
      postoDao.pegarMeioRecebimento(this.headers, posto).then(response => {
        if (!response.ok) {
          this.meios_de_recebimento = []
          return false
        } else {
          return response.json()
        }
      }).then(json => {
        json.map((m) => {
          if (m.descricao.split(' ')[0] !== 'Cartão') {
            this.meios_de_recebimento.push(m)
          }
        })
        return true
      })
    },
    inserir () {
      this.$store.dispatch('inserirFirebase', {
        prefixo: 'comprovante',
        id: this.fatura.id,
        local: 'faturas',
        arquivo: this.imagemAnexada ? this.imagemAnexada : this.arquivoAnexado,
        tipo: this.imagemAnexada ? 'png' : 'pdf'
      }).then(downloadURL => {
        faturamentoDao.inserirComprovante(
          {
            comprovante_pagamento: downloadURL,
            id: this.fatura.id
          },
          this.empresa.cnpj,
          this.headers
        ).then(() => {
          this.$emit('carregarFaturas')
          this.$store.dispatch('setSuccess', 'Comprovante inserido com sucesso.')
          this.fechar()
        }).catch(() => {
          this.$store.dispatch('setError', 'Não foi possível inserir seu comprovante')
        })
      })
    },
    fechar () {
      this.$emit('fechar')
      this.limparCampos()
    },
    limparCampos () {
      this.arquivoAnexado = null
      this.imagemAnexada = null
    }
  }
}
</script>
