import settings from '@/settings'

const multMethods = (headers, data) => {
  let url = `${settings.apetrusApi}/leilao/`

  if (data.id) {
    url += `${data.id}/`
  }

  const parameter = {
    method: data.method,
    mode: 'cors',
    headers: headers
  }

  if (data.parametros) {
    Object.keys(data.parametros).forEach((filter) => {
      url = `${url}${url[url.length - 1] === '/' ? '?' : '&'}${filter}=${data.parametros[filter]}`
    })
  }

  if (data.method === 'GET') {
    parameter.cache = 'default'
  } else if (data.body) parameter.body = JSON.stringify(data.body)

  return fetch(url, parameter)
}

const lances = (headers, data) => {
  let url = `${settings.apetrusApi}/leilao/lances/`

  if (data.id) {
    url += `${data.id}/`
  }

  const parameter = {
    method: data.method,
    mode: 'cors',
    headers: headers
  }

  if (data.parametros) {
    Object.keys(data.parametros).forEach((filter) => {
      url = `${url}${url[url.length - 1] === '/' ? '?' : '&'}${filter}=${data.parametros[filter]}`
    })
  }

  if (data.method === 'GET') {
    parameter.cache = 'default'
  } else if (data.body) parameter.body = JSON.stringify(data.body)

  return fetch(url, parameter)
}

const realizarPagamento = (headers, data) => {
  return fetch(`${settings.apetrusApi}/leilao/${data.id}/realizar-pagamento/`, {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: headers
  })
}

export default {
  multMethods,
  lances,
  realizarPagamento
}
