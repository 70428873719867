<template>
  <v-card class="pa-4">
      <v-card-title class="primary--text">
        <v-menu offset-x max-width="350" right v-if="step != 3">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              small
              color="error"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text class="justify-center text-center">
              <h2>DESEJA EXCLUIR AS INFORMAÇÕES DE E-MAIL?</h2>
            </v-card-text>
            <v-card-actions class="justify-space-around">
              <v-btn
                small
                rounded
                class="mb-2"
              >
              Não
            </v-btn>
            <v-btn
                small
                rounded
                color="error"
                @click="deletar_provedor()"
                class="mb-2"
              >
              SIM
            </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
        <v-spacer></v-spacer>
        <h2>
          {{ step == 2 ? 'Enviar e-mail para clientes' :
             step == 1 && provedor ? 'Editar provedor de e-mail' :
             'Cadastrar provedor de e-mail'
          }}
        </h2>
        <v-spacer></v-spacer>
        <v-tooltip bottom v-if="step != 3">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              small
              @click="preparar_dados"
              color="info"
            >
              <v-icon>edit</v-icon>
            </v-btn>
          </template>
          Editar Provedor
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-stepper v-model="step" class="pa-0 ma-0 mt-3" style="box-shadow: none;">
          <v-stepper-items>
            <v-stepper-content step="1" class="pa-0 ma-0">
              <v-form lazy-validation v-model="valid" ref="form">
                <v-text-field
                    class="mt-4"
                    v-model="cadastrar_email.email"
                    label="E-mail"
                    outlined
                    required
                    dense
                    :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                />
                <v-text-field

                    v-model="cadastrar_email.senha"
                    label="Senha"
                    required
                    outlined
                    dense
                    :type="show ? 'text' : 'password'"
                    :append-icon="show ? 'visibility' : 'visibility_off'"
                    @click:append="show = !show"
                    :rules="[v => !!v || 'Insira um código válido']"
                />
                <v-select
                    v-model="server"
                    label="Selecione sevidor de email"
                    required
                    single-line
                    :items="['Gmail', 'Outlook / Hotmail', 'Yahoo', 'Outro']"
                    outlined
                    dense
                />
                <template v-if="server === 'Outro'">
                  <v-text-field
                      v-model="cadastrar_email.server"
                      label="Servidor de email"
                      required
                      outlined
                      dense
                      clearable
                  />

                  <v-text-field
                      v-model="cadastrar_email.porta"
                      label="Porta"
                      required
                      outlined
                      dense
                      clearable
                  />
                </template>
              </v-form>
            </v-stepper-content>
            <v-stepper-content step="2" class="pa-0 ma-0">
              <v-text-field
                  v-model="assunto"
                  label="Assunto"
                  filled
                  required
                  :rules="[(v) => !!v || 'Esse campo é obrigatório']"
              />
              <v-textarea
                  v-model="message"
                  label="Mensagem"
                  filled
                  required
                  :rules="[(v) => !!v || 'Esse campo é obrigatório']"
              />
              <v-layout justify-center>
                <v-btn
                  rounded
                  color="secondary"
                  @click="step = 3"
                >
                  <v-icon>
                    visibility
                  </v-icon>
                  Visualizar Email
                </v-btn>
              </v-layout>
            </v-stepper-content>
            <v-stepper-content step="3" class="pa-0 ma-0">
              <div v-html="texto_formatado" class="ma-2"></div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
      <v-card-actions>
          <v-btn rounded @click="voltar">
            {{
              step === 3 ? 'Voltar' : 'Fechar'
            }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="step != 3"
            rounded
            color='primary'
            @click="btnSalvar"
          >
            {{ step === 2 ? 'Enviar' : 'Salvar' }}
          </v-btn>
      </v-card-actions>
  </v-card>
</template>
<script>
import { aesKey, iv } from '@/keys.json'
const CryptoJS = require('crypto-js')
export default {
  data: () => ({
    step: 1,
    message: '',
    assunto: '',
    email: '',
    show: false,
    cadastrar_email: {
      server: '',
      porta: '',
      email: '',
      senha: ''
    },
    server: '',
    data: { subject: '', message: '', cnpj: '' },
    provedor: undefined
  }),
  computed: {
    empresa () {
      return this.$store.getters.empresa
    },
    texto_formatado () {
      return `<!DOCTYPE html>
              <html>
                  <head>
                      <style>
                          .card {
                            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); max-width: 600px; margin: auto; font-family: arial;
                          }
                          .price {
                            color: grey;font-size: 22px;
                          }
                          .card a{
                            text-decoration: none;color: #4169E1;
                          }
                          .card button {
                            text-align: center;cursor: pointer;
                            width: 100%;
                            font-size: 18px;
                            border: none;
                            outlined: 0;
                            padding: 12px;
                            color: white;
                            background-color: #0e5781;
                          }
                          .card button:hover {
                            opacity: 0.7;
                          }
                          .titulo {
                            color: #0e5781;
                          }
                          .texto {
                            margin-left: 10px;
                            margin-right: 10px;
                            margin-top: 0.7em;
                            text-align: justify;
                            font-size: 15pt;
                          }
                          .contato {
                            margin-left: 10px;
                            margin-right: 10px;
                            text-align: center;
                            font-size: 10pt;
                          }
                      </style>
                  </head>
                  <body>
                    <div class="card">
                      <h1 style="text-align: center" class="titulo">Olá! Somos a empresa ${this.empresa.nome_fantasia}.</h1>
                      <p class="texto">${this.message}</p>
                      ${
                        this.empresa.endereco ? `<p class="contato">
                          Nos encontre  em ${this.empresa.endereco.cidade}, bairro ${this.empresa.endereco.bairro}, ${this.empresa.endereco.logradouro}, N°${this.empresa.endereco.numero}, CEP:${this.empresa.endereco.cep}
                        </p>` : ''
                      }
                      <p>
                          <a href='https://play.google.com/store/apps/details?id=apetrus.com.br.appcliente&hl=pt_BR&showAllReviews=true'><button>Acessar aplicativo</button></a>
                      </p>
                    </div>
                  </body>
              </html>`
    }
  },
  beforeMount () {
    if (this.empresa) this.pegar_provedor()
    if (this.provedor) {
      this.step = 2
    }
  },
  methods: {
    enviar () {
      const data = {
        empresa: this.empresa.cnpj,
        message: this.texto_formatado,
        subject: this.assunto
      }
      this.$store.dispatch('enviarEmail', data).then(() => {
        this.voltar()
      })
    },
    preparar_dados () {
      if (this.provedor.servidor === 'smtp.gmail.com') {
        this.server = 'Gmail'
      } else if (this.provedor.servidor === 'SMTP.office365.com') {
        this.server = 'Outlook / Hotmail'
      } else if (this.provedor.servidor === 'smtp.mail.yahoo.com') {
        this.server = 'Yahoo'
      } else {
        this.server = 'Outro'
      }
      this.cadastrar_email.email = this.provedor.email
      this.cadastrar_email.sevidor = this.provedor.sevidor
      this.cadastrar_email.porta = this.provedor.porta
      this.step = 1
    },
    pegar_provedor () {
      const data = {
        parametros: {
          empresa: this.empresa.cnpj
        },
        method: 'GET'
      }

      if (this.provedor) data.id = this.provedor.id
      else if (this.empresa.provedor_smtp) data.id = this.empresa.provedor_smtp

      this.$store.dispatch('provedorSMTP', data).then(json => {
        this.provedor = json
        this.step++
      }).catch(() => {
        this.provedor = undefined
      })
    },
    deletar_provedor () {
      const data = {
        parametros: {
          empresa: this.empresa.cnpj
        },
        method: 'DELETE'
      }

      if (this.provedor) data.id = this.provedor.id
      else if (this.empresa.provedor_smtp) data.id = this.empresa.provedor_smtp
      else {
        return
      }

      this.$store.dispatch('provedorSMTP', data).then(() => {
        this.provedor = undefined
        this.step = 1
      }).catch(() => {
        this.provedor = undefined
      })
    },
    cadastrar_provedor () {
      if (this.server !== 'Outro') {
        if (this.server === 'Gmail') {
          this.cadastrar_email.servidor = 'smtp.gmail.com'
        } else if (this.server === 'Outlook / Hotmail') {
          this.cadastrar_email.servidor = 'SMTP.office365.com'
        } else {
          this.cadastrar_email.servidor = 'smtp.mail.yahoo.com'
        }
        this.cadastrar_email.porta = 587
      }

      const ciphertext = CryptoJS.AES.encrypt(
        JSON.stringify({
          senha: this.cadastrar_email.senha
        }),
        CryptoJS.enc.Utf8.parse(aesKey),
        {
          iv: CryptoJS.enc.Utf8.parse(iv),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        }
      ).toString()

      const data = {
        method: 'POST',
        body: {
          ...this.cadastrar_email,
          senha: ciphertext,
          empresa: this.empresa.cnpj
        }
      }

      this.$store.dispatch('provedorSMTP', data).then(json => {
        this.provedor = json
        this.cadastrar_email = { sevidor: '', porta: '', email: '', senha: '' }
        this.step++
      }).catch(() => {
        this.provedor = undefined
      })
    },
    atualizar_provedor () {
      if (this.server !== 'Outro') {
        if (this.server === 'Gmail') {
          this.cadastrar_email.servidor = 'smtp.gmail.com'
        } else if (this.server === 'Outlook / Hotmail') {
          this.cadastrar_email.servidor = 'SMTP.office365.com'
        } else {
          this.cadastrar_email.servidor = 'smtp.mail.yahoo.com'
        }
        this.cadastrar_email.porta = 587
      }

      const ciphertext = CryptoJS.AES.encrypt(
        JSON.stringify({
          senha: this.cadastrar_email.senha
        }),
        CryptoJS.enc.Utf8.parse(aesKey),
        {
          iv: CryptoJS.enc.Utf8.parse(iv),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        }
      ).toString()

      const data = {
        method: 'PUT',
        id: this.provedor.id,
        body: {
          ...this.cadastrar_email,
          senha: ciphertext,
          empresa: this.empresa.cnpj
        }
      }

      this.$store.dispatch('provedorSMTP', data).then(json => {
        this.provedor = json
        this.cadastrar_email = { sevidor: '', porta: '', email: '', senha: '' }
        this.step = 2
      }).catch(() => {
        this.provedor = undefined
      })
    },
    btnSalvar () {
      if (this.step === 1 && !this.provedor) this.cadastrar_provedor()
      else if (this.step === 1 && this.provedor) this.atualizar_provedor()
      else this.enviar()
    },
    voltar () {
      switch (this.step) {
        case 1:
          this.$emit('fechar')
          break
        case 2:
          this.$emit('fechar')
          break
        case 3:
          this.step = 2
      }
    }
  }
}
</script>
