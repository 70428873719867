<template>
  <v-card>
    <v-card-title> Mandar Email </v-card-title>
    <v-container flex grid-list-xs>
      <v-form lazy-validation v-model="valid" ref="formConvite">
        <v-layout row wrap fill-height align-content-center justify-start>
          <v-flex sm12>
            <v-text-field
              v-model="cpf"
              label="CPF"
              :rules="[(v) => !!v || 'Esse campo é obrigatório']"
              chips
              v-mask="'###.###.###-##'"
              required
            ></v-text-field>
          </v-flex>
          <v-flex sm12>
            <v-text-field
              v-model="email"
              label="Email"
          ></v-text-field>
          </v-flex>
          <v-flex sm12>
            <v-text-field
              v-model="nome"
              label="Nome"
              :rules="[(v) => !!v || 'Esse campo é obrigatório']"
              chips
              required
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-form>
    </v-container>
    <v-card-actions>
      <v-btn text color="info" @click="fechar()">Voltar</v-btn>
      <v-spacer></v-spacer>
      <v-btn text color="info" @click="incluirFuncionario()">Confirmar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { funcionariosDao, pessoasDao } from '@/api'

export default {
  props: ['visible'],
  data () {
    return {
      valid: false,
      combobox: null,
      funcionarios: {
        funcionario: null,
        nome: null,
        empresa: null
      },
      model: '',
      email: '',
      nome: '',
      cpf: '',
      pessoas: []
    }
  },
  computed: {
    headers () {
      return this.$store.getters.headers
    },
    ready () {
      return !!this.combobox && this.pessoas.length === 0
    },
    empresa () {
      return this.$store.getters.empresa
    }
  },
  watch: {
    '$route' () {
      this.this.carregarPessoas()
    }
  },
  methods: {
    fechar () {
      this.$refs.formConvite.reset()
      this.$emit('fechar')
    },
    incluirFuncionario () {
      if (this.empresa) {
        this.funcionarios.empresa = this.empresa.cnpj
        this.funcionarios.cpf = this.cpf.replace('.', '').replace('.', '').replace('-', '')
        this.funcionarios.nome = this.nome
        this.funcionarios.email = this.email
      }
      if (this.$refs.formConvite.validate()) {
        funcionariosDao.inserir(this.funcionarios, this.headers)
          .then(res => {
            this.fechar()
            res.json().then(value => {
              if (res.ok) {
                if (res.status === 200) {
                  this.$emit('reload')
                  this.$emit('info')
                }
              } else {
                this.$emit('error', value.mensagem)
              }
            })
          })
      }
    },
    carregarPessoas () {
      pessoasDao.porEmail(this.email, this.headers).then(response => {
        return response.json()
      }).then(json => {
        this.$emit('reload')
        this.pessoas = json
      })
    }
  }
}
</script>
