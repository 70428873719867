<template>
  <aviso-bloqueio v-if="empresa && (!empresa.ativo || ![1, 4, 5, 6].includes(empresa.tipo_empresa))"/>
  <v-container fluid fill-height grid-list-md pt-0 style="padding-bottom: 50px" v-else>
    <v-layout column>
    <v-toolbar
      color="#eee"
      class="elevation-0 secondary--text"
      style="background: transparent"
      >
        <v-select
          v-if="empresa"
          v-model="selectedReport"
          :items="rotas"
          label="Selecione um relatório para visualizar"
          item-text="nome"
          item-value="path"
          solo
        ></v-select>
    </v-toolbar>
    <router-view></router-view>
    </v-layout>
  </v-container>
</template>

<script>
// import SmartLockMessage from '@/components/SmartLockMessage'
import AvisoBloqueio from '@/components/AvisoBloqueio'

export default {
  components: { AvisoBloqueio },
  data () {
    return {
      reports: [
        {
          nome: 'Relatório de propostas',
          path: '/relatorio/propostas',
          tipos_empresa: [1]
        },
        {
          nome: 'Relatório de Recargas',
          path: '/relatorio/recargas',
          tipos_empresa: [1]
        },
        {
          nome: 'Relatório de compra',
          path: '/relatorio/compras',
          tipos_empresa: [1, 4, 5, 6]
        },
        {
          nome: 'Relatório de vouchers',
          path: '/relatorio/vouchers',
          tipos_empresa: [1]
        }
      ],
      selectedReport: null
    }
  },
  watch: {
    selectedReport (val) {
      if (val !== null && val !== undefined) {
        this.$router.push(val)
      }
    },
    '$route' (val) {
      if (val.path === '/relatorio' && this.empresa) {
        this.selectedReport = this.rotas[0].path
        this.$router.push(this.rotas[0].path)
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (!this.selectedReport && this.empresa) {
        this.selectedReport = this.rotas[0].path
      }
    })
  },
  computed: {
    smartLock () {
      return this.$store.getters.smartLock
    },
    empresa () {
      return this.$store.getters.empresa
    },
    rotas () {
      if (this.empresa) {
        return this.reports.filter(
          item => item.tipos_empresa.includes(this.empresa.tipo_empresa)
        )
      }
      return []
    }
  }
}
</script>
