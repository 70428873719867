import settings from '@/settings'

const get = (headers) => {
  return fetch(`${settings.apetrusApi}/categorias/`, {
    method: 'GET',
    mode: 'cors',
    headers: headers,
    cache: 'default'
  })
}

export default {
  get
}
