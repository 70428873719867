<template>
  <v-layout align-space-around justify-start fill-height column class="pt-5">
    <v-toolbar
      color="transparent"
      class="elevation-0 secondary--text"
      style="background: transparent">
      <v-toolbar-title>Configurações</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" large class="mt-4" rounded :disabled="!smartlock && smartlockEmpresa" @click="openDialogSmartLock" :loading="loading" color="primary">
          <v-icon class="mr-2">password</v-icon>
          {{smartlockEmpresa ? 'Desativar' : 'Ativar'}} Smartlock
        </v-btn>
      </template>
      <span v-if="!smartlockEmpresa">Inseri uma autenticação extra para uma maior segurança.</span>
      <span v-else>Desativa o smartlock</span>
    </v-tooltip>
    <v-tooltip bottom v-if="empresa && empresa.tipo_empresa === 1">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" large class="mt-4" rounded color="primary" @click="$store.dispatch('verificacaoVeiculo')" :loading="loading">
          <v-icon class="mr-2">commute</v-icon>
          {{empresa && !empresa.verificacao_veiculos ? 'Ativar': 'Desativar'}} verificação de veículos
        </v-btn>
      </template>
      <span v-if="empresa && empresa.verificacao_veiculos">Desativa a verificação de veículo. Os veículos entrarão automaticamente em sua empresa.</span>
      <span v-else>Ativa a verificação de veículo, antes que eles entrem em sua empresa</span>
    </v-tooltip>
    <v-tooltip bottom v-if="empresa && empresa.tipo_empresa === 1">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" large class="mt-4" rounded color="primary"  @click="$store.dispatch('verificacaoFuncionario')" :loading="loading">
          <v-icon class="mr-2">people_alt</v-icon>
          {{empresa && !empresa.verificacao_funcionarios ? 'Ativar': 'Desativar'}} verificação de funcionários
        </v-btn>
      </template>
      <span v-if="empresa && empresa.verificacao_funcionarios">Desativa a verificação de funcionários. Os funcionários entrarão automaticamente em sua empresa.</span>
      <span v-else>Ativa a verificação de funcionários, antes que eles entrem em sua empresa</span>
    </v-tooltip>
    <v-tooltip top v-if="empresa && [4, 5, 6].includes(empresa.tipo_empresa)">
      <template v-slot:activator="{ on }">
        <v-btn
          color="primary"
          block
          large
          rounded
          class="mb-3 mt-4 text-start"
          v-on="on"
          :loading="loading"
          @click="() => editarEmpresa({ tem_cashback: !empresa.tem_cashback })"
        >
          <v-icon class="mr-2 material-icons">attach_money</v-icon>
          {{empresa.tem_cashback ? 'Desativar' : 'Ativar'}} Cashback da Empresa
        </v-btn>
      </template>
      <span>
        Com essa função ativa os clientes da sua empresa ganharão um percentual de cashback em cima de cada compra,
        no lugar de ganhar desconto no valor da compra, seus cliente ganharão um valor de cashback que só pode ser usado no sua empresa.
      </span>
    </v-tooltip>
    <v-tooltip top v-if="empresa && [4, 5, 6].includes(empresa.tipo_empresa)">
      <template v-slot:activator="{ on }">
        <v-btn
          color="primary"
          block
          large
          class="mb-3"
          :loading="loading"
          v-on="on"
          rounded
          @click="() => editarEmpresa({ recebe_pospago: !empresa.recebe_pospago })"
        >
          <v-icon class="mr-2">payments</v-icon>
          {{empresa.recebe_pospago ? 'Desativar' : 'Ativar'}} recebimento pós-pago
        </v-btn>
      </template>
      <span>
        Com essa função ativa seus clientes poderão realizar o pagamento de suas compras no seu estabelecimento comercial.
      </span>
    </v-tooltip>
    <integracao-automatica v-if="empresa && empresa.tipo_empresa === 2"/>
    <v-tooltip top v-if="empresa && [4, 5, 6].includes(empresa.tipo_empresa)">
      <template v-slot:activator="{ on }">
        <v-btn
          color="primary"
          block
          large
          class="mb-3"
          v-on="on"
          rounded
          :loading="loading"
          @click="() => editarEmpresa({ limitar_geolocalizacao: !empresa.limitar_geolocalizacao })"
        >
          <v-icon class="mr-2">location_on</v-icon>
          {{empresa.limitar_geolocalizacao ? 'Desativar' : 'Ativar'}}  bloqueio de geolocalização
        </v-btn>
      </template>
      <span>
        Com essa função ativa, se seus clientes estiverem localizados fora área do seu estabelecimento comercial,
        eles serão impedidos de realizar compras para pagamento no estabelecimento.
      </span>
    </v-tooltip>
    <template v-if="empresa && [4, 5, 6].includes(empresa.tipo_empresa)">
      <v-flex xs12 class="mt-4">
        <h3>PROGRAMAS:</h3>
      </v-flex>
      <v-flex xs12 class="ma-4">
        <v-btn color="primary" block rounded large class="mb-3 text-xs-start" @click="dialogFidelidade = true" :loading="loading">
          <v-icon class="mr-2 material-icons">loyalty</v-icon>
          Fidelidade
        </v-btn>
        <v-btn color="primary" block rounded large class="mb-3" @click="dialogAssinatura = true" :loading="loading">
          <v-icon class="mr-2">local_atm</v-icon>
          Assinatura
        </v-btn>
      </v-flex>
    </template>
    <v-dialog v-model="dialogSmartLock" width="650" v-if="dialogSmartLock">
      <dialog-ativar-smart-lock @openSmartLock="openSmartLock" @fechar="dialogSmartLock = false" ></dialog-ativar-smart-lock>
    </v-dialog>
    <v-dialog v-model="dialogFidelidade" v-if="dialogFidelidade" width="900">
      <cad-fidelidade @fechar="dialogFidelidade = false"/>
    </v-dialog>
    <v-dialog v-model="dialogAssinatura" v-if="dialogAssinatura" width="600">
      <programa-de-assinatura
        @fechar="dialogAssinatura = false"
      />
    </v-dialog>
  </v-layout>
</template>

<script>
import DialogAtivarSmartLock from '@/components/dialogs/DialogAtivarSmartLock'
import CadFidelidade from '@/components/cadastros/CadFidelidade'
import ProgramaDeAssinatura from '@/components/dialogs/ProgramaAssinatura'
import IntegracaoAutomatica from '../../components/dialogs/IntegracaoAutomatica.vue'

export default {
  components: {
    DialogAtivarSmartLock,
    CadFidelidade,
    ProgramaDeAssinatura,
    IntegracaoAutomatica
  },
  data () {
    return {
      dialogSmartLock: false,
      dialogFidelidade: false,
      dialogAssinatura: false,
      settings_list: [
        { text: `${this.smartlock ? 'Desativar' : 'Ativar'} Smartlock`, icon: 'lock', funcao: this.openSmartLock }
      ]
    }
  },
  computed: {
    pessoa () {
      return this.$store.getters.pessoa
    },
    loading () {
      return this.$store.getters.loading
    },
    smartlockEmpresa () {
      if (!this.pessoa) return undefined
      return this.pessoa.smartlock_empresa
    },
    smartlock () {
      return this.$store.getters.smartLock
    },
    empresa () {
      return this.$store.getters.empresa
    }
  },
  methods: {
    openDialogSmartLock () {
      if (this.smartlockEmpresa) {
        this.$store.dispatch('ativarPin')
      } else {
        this.dialogSmartLock = true
      }
    },
    openSmartLock () {
      this.$store.dispatch('ativarPin')
    },
    editarEmpresa (data) {
      data.cnpj = this.empresa.cnpj
      this.$store.dispatch('editarEmpresa', data).then(() => {
        const empresa = this.empresa
        this.$store.dispatch('escolherEmpresa', empresa)
      })
    }
  },
  beforeMount () {
    this.$store.dispatch('carregarPerfil')
  },
  watch: {
    pessoa (val) {
      if (val) {
        this.settings_list = [
          { text: `${this.smartlock ? 'Desativar' : 'Ativar'} Smartlock`, icon: 'lock', funcao: this.openSmartLock }
        ]
      }
    }
  }
}
</script>
