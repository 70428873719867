<template>
    <v-card class="pa-4">
        <v-card-title class="primary--text">
            <h1 style="width: 100%; text-align: center;">Enviar notificação para clientes</h1>
        </v-card-title>
        <v-card-text>
            <v-text-field
                v-model="notification.title"
                label="Título"
                full-width
                persistent-hint
                required
                maxlength="100"
                :rules="[(v) => !!v || 'Esse campo é obrigatório']"
            />
            <v-divider></v-divider>
            <v-textarea
                v-model="notification.message"
                label="Mensagem"
                counter
                maxlength="250"
                full-width
                single-line
                required
                :rules="[(v) => !!v || 'Esse campo é obrigatório']"
            />

            <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
            <v-btn small rounded @click="fechar">Voltar</v-btn>
            <v-spacer></v-spacer>
            <v-btn
            small
            rounded
            color='primary'
            :disabled="!notification.title || !notification.message"
            @click="enviar"
            >
            Enviar
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
  data () {
    return {
      notification: {
        message: '',
        title: ''
      }
    }
  },
  computed: {
    empresa () {
      return this.$store.getters.empresa
    }
  },
  methods: {
    enviar () {
      this.notification.empresa = this.empresa.cnpj
      this.$store.dispatch('pushNotification', this.notification).then(() => {
        this.$emit('sucesso', 'Notificação enviada com sucesso')
        this.fechar()
      }).catch(() => {
        this.$emit('erro', 'Não foi possível enviar notificação')
      })
    },
    fechar () {
      this.notification = { message: '', title: '' }
      this.$emit('fechar')
    }
  }
}
</script>
