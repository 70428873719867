<template>
  <v-card color="grey lighten-3">
    <v-card-title class="justify-center">
      <h2>CADASTRAR FUNCIONÁRIO</h2>
    </v-card-title>
    <v-card-text>
      <v-layout align-center justify-center column>
        <v-avatar
          color="grey"
          size="60"
          class="mb-3"
        >
            <img v-if="frentista_model.url_imagem_perfil" :src="frentista_model.url_imagem_perfil" alt="trevor">
            <v-icon dark v-else>face</v-icon>
        </v-avatar>
      </v-layout>
      <v-layout column pl-3 pr-3 pt-0 pb-0>
        <v-flex>
            <v-text-field
            class="mt-1"
            background-color="transparent"
            v-model="frentista_model.matricula"
            label="Matrícula:"
            required
            solo
            v-mask="'####'"
            :rules="[
              v => !!v || 'Campo Obrigatório'
            ]"
          ></v-text-field>
          <h5
            class="error--text text-xs-center"
            v-show="matriculaExiste"
          >
            Matrícula já existe
          </h5>
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-space-around>
        <v-flex
          xs5
        >
          <v-text-field
            label="Nome"
            v-model="frentista_model.nome"
            hint="Insira o primeiro nome"
            :rules="[
              v => !!v || 'Campo Obrigatório'
            ]"
            required
          ></v-text-field>
        </v-flex>
        <v-flex
          xs5
        >
          <v-text-field
            label="Sobrenome"
            v-model="frentista_model.sobrenome"
            hint="Insira o sobrenome"
            :rules="[
              v => !!v || 'Campo Obrigatório'
            ]"
          ></v-text-field>
        </v-flex>
        <v-flex
          xs8
        >
          <v-text-field
            v-model="frentista_model.cpf"
            :rules="repCpfRules"
            label="CPF"
            v-mask="'###.###.###-##'"
            required
          ></v-text-field>
          <h5
            class="error--text text-xs-center"
            v-show="cpfExiste"
          >
            CPF já existe
          </h5>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions class="primary">
      <v-btn
        text
        dark
        block
        @click.prevent="salvarFrentista"
        :disabled="matriculaExiste || cpfExiste"
      >
        Confirmar cadastro
        <v-icon right>check</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  data () {
    return {
      valid: true,
      dialogErro: false,
      frentista_model: {
        nome: '',
        sobrenome: '',
        empresa: null,
        cpf: ''
      },
      matriculaExiste: false,
      cpfExiste: false,
      repCpfRules: [v => !!v || 'CPF obrigatório!']
    }
  },
  computed: {
    empresa () {
      return this.$store.getters.empresa
    }
  },
  methods: {
    salvarFrentista () {
      this.$store.dispatch('inserirFrentista', this.frentista_model).then((json) => {
        this.$emit('complete')
        if (!this.frentista_model.id_pessoa) {
          this.frentista_model = json
        }
      }).catch((e) => {
        alert(e)
      })
    },
    async verificarDados (data) {
      data.empresa = this.empresa.cnpj
      let existe = false
      await this.$store.dispatch('frentistas', data).then(json => {
        if (json.length > 0) existe = true
      })
      return existe
    }
  },
  watch: {
    'frentista_model.matricula' (val) {
      if (val) {
        this.verificarDados({ matricula: val }).then(value => {
          this.matriculaExiste = value
        })
      }
    },
    'frentista_model.cpf' (val) {
      if (val) {
        this.verificarDados({ cpf: val }).then(value => {
          this.cpfExiste = value
        })
      }
    }
  }
}
</script>
