<template>
  <v-card>
    <v-card-title
      class="
        justify-center
        primary--text
        darken-2
      "
    >
      <v-btn icon @click="$emit('fechar')">
        <v-icon>
          close
        </v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <h2>Visualizar Empresa</h2>
      <v-spacer></v-spacer>
    </v-card-title>
        <img
          v-if="empresa.url_imagem_posto"
          :src="empresa.url_imagem_posto"
          alt="Capa"
          width="100%"
        >
        <v-card v-else width="100%" height="20em" color="primary" dark>
          <v-layout justify-center align-center fill-height>
            <v-icon size="200" color="white">image</v-icon>
          </v-layout>
        </v-card>
    <v-card-text class="mt-4">
      <v-layout wrap justify-space-around class="mt-4">
        <v-flex xs3>
          <img
            width="100%"
            v-if="empresa.url_logo"
            :src="empresa.url_logo"
            alt="Logo da empresa"
            style="border-radius: 10em"
          >
          <v-avatar
            v-else
            color="primary"
            size="150"
            dark
          >
            <v-icon size="100" color="white">domain</v-icon>
          </v-avatar>
        </v-flex>
        <v-flex xs8 style="font-size: 1.3em">
          <v-layout wrap justify-space-between>
            <v-flex xs12 class="mb-3">
              <h3 style="text-align: center">Informaçôes da Empresa</h3>
            </v-flex>
            <v-flex xs12 v-if="empresa.nome_fantasia" class="mb-2">
              Nome fantasia: <b>{{empresa.nome_fantasia}}</b>
            </v-flex>
            <v-flex xs12 v-if="empresa.cnpj" class="mb-2">
              CNPJ: <b>{{empresa.cnpj}}</b>
            </v-flex>
            <v-flex xs12 v-if="empresa.razao_social" class="mb-2">
              Razão Social: <b>{{empresa.razao_social}}</b>
            </v-flex>
            <v-flex xs12 v-if="empresa.razao_social" class="mb-2">
              Tipo de Empresa: <b>{{tipo_empresa[empresa.tipo_empresa]}}</b>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-layout wrap justify-space-around class="mt-4">
          <v-flex xs12 class="mb-3">
              <h2 style="text-align: center">Endereço</h2>
          </v-flex>
          <v-flex xs11 v-if="empresa.endereco" class="mb-2">
            Logradouro: <b>{{empresa.endereco.logradouro}}</b>
          </v-flex>
          <v-flex xs4 v-if="empresa.endereco" class="mb-2">
            Número: <b>{{empresa.endereco.numero}}</b>
          </v-flex>
          <v-flex xs6 v-if="empresa.endereco" class="mb-2">
            Bairro: <b>{{empresa.endereco.bairro}}</b>
          </v-flex>
          <v-flex xs4 v-if="empresa.endereco" class="mb-2">
            Cidade: <b>{{empresa.endereco.cidade}}</b>
          </v-flex>
          <v-flex xs6 v-if="empresa.endereco" class="mb-2">
            CEP: <b>{{empresa.endereco.cep}}</b>
          </v-flex>
          <v-flex xs12 class="mb-3">
            <h2 style="text-align: center">Contatos</h2>
          </v-flex>
          <v-flex xs15 v-if="empresa.contato1" class="mb-2">
            <v-icon dark color="success">phone_in_talk</v-icon>: <b>{{empresa.contato1}}</b>
          </v-flex>
          <v-flex xs5 v-if="empresa.contato2" class="mb-2">
            <v-icon dark color="success">phone_in_talk</v-icon>: <b>{{empresa.contato2}}</b>
          </v-flex>
          <v-flex xs12 v-if="empresa.contato3" class="mb-2">
            <v-icon dark color="success">phone_in_talk</v-icon>: <b>{{empresa.contato3}}</b>
          </v-flex>
          <v-flex xs12 v-if="empresa.email" class="mb-2">
            <v-icon dark color="error">alternate_email</v-icon> <b>{{empresa.email}}</b>
          </v-flex>
          <v-flex xs12 v-if="empresa.site" class="mb-2">
            <v-icon dark color="secondary">web_asset</v-icon>
            <a :href="empresa.site" target="_blank" style="text-decoration: none; color: gray;">
              {{ empresa.site.length > 60 ? `${empresa.site.slice(0, 60)}...` : empresa.site }}
            </a>
          </v-flex>
        </v-layout>
        <v-flex xs12 class="mb-3 mt-3">
          <h2 style="text-align: center" v-if="redesSociais.length > 0">Redes Sociais</h2>
        </v-flex>
        <v-layout wrap justify-space-around v-for="(rede, index) in redesSociais" :key="index">
          <v-flex xs1>
            <v-tooltip bottom v-if="rede.nome">
              <template v-slot:activator="{ on }">
                <img
                  v-on="on"
                  style="width: 70%;"
                  :src="require(`@/assets/icones/${rede.nome}.png`)"
                  class="mr-2"
                />
              </template>
              <span>{{rede.nome}}</span>
            </v-tooltip>
          </v-flex>
          <v-flex xs9 style="font-size: 1em">
            <a :href="rede.link_perfil" target="_blank" style="text-decoration: none; color: gray">
              {{rede.link_perfil}}
            </a>
          </v-flex>
        </v-layout>
        <v-flex xs12 class="mb-4 mt-3">
          <h2 style="text-align: center" v-if="filiais.length > 0">Filiais</h2>
        </v-flex>
        <v-layout
          class="mt-3"
          v-if="filiais.length > 0"
          align-start
          justify-space-around
          row
          wrap
          fill-height
          style="min-height: 19em;"
        >
          <v-flex
            xs12
            sm6
            md5
            lg4
            xl5
            v-for="(filial, index) in filiais"
            :key="index"
            class="mb-3"
          >
            <template>
              <v-card>
                <v-card-text>
                  <v-layout wrap justify-space-around>
                    <v-flex xs2>
                      <img
                        width="100%"
                        v-if="filial.url_logo"
                        :src="filial.url_logo"
                        alt="Logo da empresa"
                        style="border-radius: 10em"
                      >
                      <v-avatar
                        v-else
                        color="primary"
                        size="2.4em"
                        dark
                      >
                        <v-icon size="1.5em" color="white">domain</v-icon>
                      </v-avatar>
                    </v-flex>
                    <v-flex xs8>
                      <h2 style="font-size: 1.1em">{{filial.nome}}</h2>
                      <v-layout wrap justify-space-between>
                        <v-flex xs1>
                          <v-icon size="1.2em" dark color="error">pin_drop</v-icon>
                        </v-flex>
                        <v-flex xs10>
                          <h5 style="font-size: 0.7em"> {{filial.endereco.bairro}} - {{filial.endereco.cidade}}/{{filial.endereco.estado}}</h5>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </template>
          </v-flex>
        </v-layout>
        <v-layout justify-center style="width:100%">
          <v-pagination v-if="filiais.length > 0" v-model="pagination.page" :length="pages" circle></v-pagination>
        </v-layout>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ['empresa'],
  data: () => ({
    filiais: [],
    totalItens: 0,
    redesSociais: [],
    tipo_empresa: [
      'Todos os tipos',
      'Frota de veículos',
      'Revenda de veículos',
      'Automação',
      'Venda de Produtos',
      'Venda de Serviços',
      'Venda de Produtos e Serviços',
      'Instituição Beneficente',
      'Integradora'
    ],
    pagination: {
      page: 1,
      rowsPerPage: 6
    }
  }),
  computed: {
    pages () {
      const count = this.totalItens
      if (this.pagination.rowsPerPage == null || count == null) return 0

      return Math.ceil(count / this.pagination.rowsPerPage)
    }
  },
  methods: {
    pegarRedesSociais () {
      const data = { cnpj: this.empresa.cnpj, method: 'GET' }
      this.$store.dispatch('redesSociais', data).then(json => {
        this.redesSociais = json
      })
    },
    listarFiliais () {
      this.$store.dispatch('listarFiliais', {
        empresa_parceira__cnpj: this.empresa.cnpj,
        page: this.pagination.page
      }).then(json => {
        this.filiais = json.results
        this.totalItens = json.count
      })
    }
  },
  watch: {
    pagination: {
      handler (val, oldval) {
        this.listarFiliais()
      },
      deep: true
    }
  },
  beforeMount () {
    if (!this.empresa) {
      this.$store.dispatch('setError', 'Empresa não encontrada')
      this.$emit('fechar')
    }
    this.listarFiliais()
    this.pegarRedesSociais()
  }
}
</script>
