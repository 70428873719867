<template>
  <v-card
    class="mx-auto"
    color="grey lighten-4"
    min-width="400"
    max-height="490"
  >
    <v-img height="130" v-if="item.imagens.length > 0" :src="item.imagens[0]"/>
    <v-card
      v-else
      color="#EBEBEB"
      height="130"
      style="border-radius: 1em 1em 0em 0em;"
      elevation="0"
    >
      <v-layout justify-center align-center fill-height column>
          <v-icon
            v-if="!item.combustivel"
            size="6em"
            :color="item.combustivel ? item.combustivel.color : item.categoria.cor"
          >
          {{ item.categoria.icone }}
        </v-icon>
        <svg-filler
          v-else
          :path="require(`@/assets/combs/${item.combustivel.icone}.svg`)"
          width="6em"
          height="6em"
          :fill="item.combustivel.color"
          class="mr-2"
        />
      </v-layout>
    </v-card>
    <v-card-title
      class="mt-0 pt-0"
      :style="{'color': item.combustivel ? item.combustivel.color : item.categoria.cor}"
    >
    </v-card-title>
    <v-card-text
      class="pb-0 pt-0 mb-4"
    >
      <v-list v-if="item.ofertas && item.ofertas.length" class="transparent" subheader>
        <template v-for="(oferta, index) in item.ofertas">
          <v-list-item
            :key="index"
            ripple
            @click="function () {
                if (!oferta.promocao) {
                  ofertaSelected = oferta
                  cancelarOfertaDialog = true
                }
              }
            "
          >
            <v-list-item-avatar>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" :color="`${oferta && oferta.para_assinantes ? 'secondary' : ''}`">
                    {{ oferta.promocao ? 'sell' : 'percent' }}
                  </v-icon>
                </template>
                <span>
                  Válida para o empresa
                </span>
              </v-tooltip>
            </v-list-item-avatar>
            <v-list-item-avatar>
              <span
                :class="`${oferta && oferta.para_assinantes ? 'secondary' : ''}--text`"
              >
                {{oferta.percentual_desconto}}%
              </span>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-subtitle
                :class="`${oferta.para_assinantes ? 'secondary' : ''}--text pt-1`"
              >
                {{
                  oferta.promocao ?
                  'Promoção'
                  :
                  'Oferta'
                }}:
                {{ calcularDesconto(item.preco, oferta.percentual_desconto) | currency({fractionCount: 2})}}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="oferta.promocao">
              <template v-if="new Date(`${oferta.data_fim}T23:59:59`) >= new Date()">
                <v-list-item-action-text
                  :class="`${oferta && oferta.para_assinantes ? 'secondary' : ''}--text`"
                >
                  Início: {{ oferta.data_inicio | formatDate('DD/MM') }}
                </v-list-item-action-text>
                <v-list-item-action-text
                  :class="`${oferta && oferta.para_assinantes ? 'secondary' : ''}--text`"
                >
                  Fim: {{ oferta.data_fim | formatDate('DD/MM') }}
                </v-list-item-action-text>
              </template>
              <template v-else>
                <v-list-item-action-text
                  style="color: #8B0000;"
                  class="ml-3"
                >
                  Data expirou
                </v-list-item-action-text>
              </template>
            </v-list-item-action>
            <v-list-item-avatar v-if="oferta.para_assinantes">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    class="ml-3"
                    v-on="on"
                    color="secondary"
                  >
                    star
                  </v-icon>
                </template>
                Apenas Para Assinantes
              </v-tooltip>
            </v-list-item-avatar>
            <v-list-item-action>
              <v-menu offset-x max-width="250" right v-if="oferta.promocao">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon :color="`${oferta && oferta.para_assinantes ? 'secondary' : 'grey'} darken-2`">more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list dense class="pa-0">
                  <v-list-item ripple @click="carregarBanner(oferta)">
                    <v-list-item-content>
                      <v-list-item-title
                        class="font-weight-light body-1 success--text text-xs-center"
                      >CARREGAR BANNER</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="success">image</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item
                    ripple
                    @click="() => { ofertaSelected = oferta; cancelarOfertaDialog = true }"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        class="font-weight-light body-1 error--text text-xs-center"
                      >CANCELAR PROMOÇÃO</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="error">close</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-icon
                v-else
                small
                :color="`${oferta && oferta.para_assinantes ? 'secondary' : 'grey'} lighten-1`"
              >
                pan_tool
              </v-icon>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
      <template v-if="notificacoes && notificacoes.length">
        <template v-for="(notificacao, index) in notificacoes">
          <div :key="index" class="notificacao">
            <span>*{{notificacao}}</span>
          </div>
        </template>
      </template>
      <v-dialog v-model="cancelarOfertaDialog" persistent max-width="320">
        <v-card>
          <v-card-title class="headline">Interromper esta oferta?</v-card-title>
          <v-card-text>Depois de confirmar, seus clientes não poderão mais comprar com este desconto!</v-card-text>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-textarea
                    outline
                    v-model="motivoCancelamento"
                    name="input-7-4"
                    label="Motivo do cancelamento"
                    value
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions class="justify-space-between">
            <v-btn color="grey" text @click="cancelarOfertaDialog = false">Não!</v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="cancelarOferta(ofertaSelected, motivoCancelamento)"
            >Confirmar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
    <v-toolbar
      :color="item.combustivel ? item.combustivel.color : item.categoria.cor"
      style="color: white;"
    >
      <h3 class="font-weight-bold">{{item.nome.toUpperCase()}}</h3>
      <v-spacer></v-spacer>
      <span class="subheading font-weight-bold">{{ item.preco | currency({fractionCount: 2}) }}</span>
    </v-toolbar>
    <v-card-actions class="justify-space-around">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="$emit('visualizarItem', item)">
            <v-icon>visibility</v-icon>
          </v-btn>
        </template>
        <h4>Visualizar Item</h4>
      </v-tooltip>
      <v-divider vertical></v-divider>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="$emit('editarItem', item)">
            <v-icon>edit</v-icon>
          </v-btn>
        </template>
        <h4>Editar Item</h4>
      </v-tooltip>
      <v-divider vertical></v-divider>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="$emit('editarDisponibilidade', item)">
            <v-icon>{{ item.disponivel ? 'check_circle' : 'cancel' }}</v-icon>
          </v-btn>
        </template>
        <h4>Editar Disponibilidade</h4>
      </v-tooltip>
      <v-divider vertical></v-divider>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="$emit('deletarItem', item)">
            <v-icon>close</v-icon>
          </v-btn>
        </template>
        <h4>Deletar Item</h4>
      </v-tooltip>
    </v-card-actions>
    <v-dialog
      width="600"
      persistent
      v-model="dialogBannerPromocional"
    >
      <carregar-banner
        v-if="promocaoBanner"
        @close="(url_imagem_promocao) => closeBannerDialog(promocaoBanner, url_imagem_promocao)"
        :promocao="promocaoBanner"
      ></carregar-banner>
    </v-dialog>
  </v-card>
</template>

<script>
import CarregarBanner from '@/components/CarregarBanner'
export default {
  components: { CarregarBanner },
  props: ['item'],
  data () {
    return {
      promocaoBanner: null,
      cancelarOfertaDialog: false,
      dialogBannerPromocional: false,
      motivoCancelamento: '',
      ofertaSelected: null,
      dialogCodigoPromocional: false,
      dialogCadastrarOferta: false,
      dialogCadastrarPromocao: false,
      dialogAlterarPrecoBomba: false,
      dialogListOfertas: false,
      notificacoes: []
    }
  },
  computed: {
    combustiveisSilenciados () {
      return this.$store.getters.combustiveisSilenciados
    },
    loading () {
      return this.$store.getters.loading
    },
    empresa () {
      return this.$store.getters.empresa
    }
  },
  methods: {
    closeBannerDialog (oferta, bannerUrl) {
      if (bannerUrl) oferta.url_imagem_promocao = bannerUrl
      this.promocaoBanner = null
      this.dialogBannerPromocional = false
    },
    carregarBanner (oferta) {
      this.promocaoBanner = oferta
      this.dialogBannerPromocional = true
    },
    novaOferta () {
      this.$emit('novaOferta')
      this.dialogCadastrarOferta = !!this.item.preco
      if (!this.dialogCadastrarOferta) {
        this.castError(
          'Você precisa ter um preço de bomba ativo para inserir a oferta'
        )
      }
    },
    calcularValorFinal (preco, oferta) {
      if (oferta.valor) {
        if (this.empresa.truncamento_item) {
          return 'R$ ' + (preco - (oferta.valor / 100) * preco).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
        } else {
          return 'R$ ' + (preco - (oferta.valor / 100) * preco).toFixed(2)
        }
      } else {
        if (this.empresa.truncamento_item) {
          return 'R$ ' + (preco - parseFloat(oferta.desconto)).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
        } else {
          return 'R$ ' + (preco - parseFloat(oferta.desconto)).toFixed(2)
        }
      }
    },
    calcularDesconto (preco, percentual) {
      const valorComDesconto = (preco - preco * (percentual / 100)).toFixed(8) // Faz calculo é obriga a função a trabalhar com 4 digitos

      if (this.empresa.truncamento_item) { // Se é truncamento
        return 'R$ ' + valorComDesconto.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] // Remove casas decimais adicionais e conserva o número
      } else { // Se trucamento não está ativo
        const casasDecimais = valorComDesconto.toString().split('.')[1]
        const algarismoConservado = Math.floor(valorComDesconto * 100) % 10
        const algarismoSeguinteAoConservado = Math.floor(valorComDesconto * 1000) % 10
        const algarismoSeguinteSeguidoDeZeros = casasDecimais.substring(3).split('').some(numeroDecial => numeroDecial !== '0')

        if (algarismoSeguinteAoConservado < 5) {
          return 'R$ ' + valorComDesconto.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
        } else if (algarismoSeguinteSeguidoDeZeros) {
          return 'R$ ' + (parseFloat(valorComDesconto) + 0.001).toFixed(2)
        } else if (algarismoSeguinteAoConservado === 5 && !algarismoSeguinteSeguidoDeZeros) {
          if (algarismoConservado % 2 === 0) {
            return 'R$ ' + valorComDesconto.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
          } else {
            return 'R$ ' + (parseFloat(valorComDesconto) + 0.001).toFixed(2)
          }
        }
        return 'R$ ' + (parseFloat(valorComDesconto) + 0.001).toFixed(2)
      }
    },
    cancelarOferta (oferta, motivoCancelamento) {
      const data = {
        id: oferta.id,
        method: 'DELETE',
        body: {
          empresa: this.empresa.cnpj,
          motivo_cancelamento: motivoCancelamento,
          item: this.item.id
        }
      }
      this.$store
        .dispatch('oferta', data)
        .then(() => {
          this.$emit('carregar')
          motivoCancelamento = ''
          oferta = null
          this.cancelarOfertaDialog = false
        })
      // cancelarOfertaDialog = false
    },
    castError (error) {
      this.$emit('error', error)
    },
    verificarValor () {
      if (this.item.preco && this.item.preco.toString().includes('.')) {
        return this.item.preco.toString().split('.')[1].length > 2
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.notificacao {
  color: #8B0000 !important;
  font-size: 9pt;
}
</style>
