<template>
  <v-card>
    <v-card-title v-if="text_title" class="justify-center">
      {{text_title}}
    </v-card-title>
    <v-stepper v-model="step" style="box-shadow: none">
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-layout justify-center class="mt-0 pa-0">
              <img
                style="width: 70%;"
                :src="require(`@/assets/logomarca2.png`)"
                class="mr-2"
              />
            </v-layout>
            <h1 style="text-align: center; color: rgb(8, 77, 110);">Anúncios</h1>
            <v-card-text style="font-size: 1.2em; text-align: justify;">
              <v-flex xs12>
                Com o <b>Apetrus Anúncios</b> você pode promover sua marca oferecendo benefícios aos clientes Apetrus em sua empresa.
              </v-flex>
              <v-layout justify-center class="mt-4">
                <v-btn
                  color="rgb(8, 77, 110)"
                  class="white--text mb-4"
                  rounded
                  @click="step++"
                >Participar</v-btn>
              </v-layout>
            </v-card-text>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-layout justify-space-around wrap full-width>
                <v-flex xs11>
                    <v-textarea
                        label="Descrição"
                        v-model="empresa_data.descricao"
                        counter
                        maxlength="700"
                        :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                    ></v-textarea>
                    <v-text-field
                        label="Site da empresa"
                        v-model="empresa_data.site"
                        placeholder="https://www.dominio.com.br/"
                        maxlength="255"
                        counter
                        :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                    ></v-text-field>
                    <v-text-field
                        label="Desconto oferecido"
                        v-model="empresa_data.desconto_apetrus"
                        type="number"
                        :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                    ></v-text-field>
                    <v-text-field
                        label="Número de WhatsApp"
                        v-model="empresa_data.contato1"
                        placeholder="(12) 9 3456-7891"
                        v-mask="'(##) # ####-####'"
                        :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                    ></v-text-field>
                    <v-text-field
                        label="Número de telefone (Fixo)"
                        v-model="empresa_data.contato2"
                        placeholder="(12) 3456-7891"
                        v-mask="'(##) ####-####'"
                    ></v-text-field>
                    <v-text-field
                        label="Número de celular"
                        v-model="empresa_data.contato3"
                        placeholder="(12) 9 3456-7891"
                        v-mask="'(##) # ####-####'"
                    ></v-text-field>
                    <v-text-field
                        label="Email da empresa"
                        v-model="empresa_data.email"
                        placeholder="empresa@apetrus.com.br"
                    ></v-text-field>
                </v-flex>
            </v-layout>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-layout justify-center column full-width  style="height: 100%;">
                <v-flex align-self-center>
                    <img v-if="imagem && mostrar" :src="imagem" alt="Logo da empresa" width="100%">
                    <v-card color="grey" height="250" width="250" v-if="!imagem && !croppingLogo">
                        <v-layout justify-center align-center fill-height>
                            <div class="title white--text pa-4 text-xs-center">Sem imagem</div>
                        </v-layout>
                    </v-card>
                    <v-btn class="mt-3 mb-3" block color="primary" @click="$refs.logoInput.click()" v-if="mostrar">
                        Inserir imagem
                        <input
                            type="file"
                            style="display:none"
                            accept="image/*"
                            ref="logoInput"
                            @input="carregarImagem"
                        />
                    </v-btn>
                </v-flex>
                <v-flex xs12 v-show="croppingLogo">
                    <vue-croppie
                        ref="croppieRef"
                        :enableExif="true"
                        :enforceBoundary="true"
                        :enableResize="false"
                        :enableOrientation="false"
                        :mouseWheelZoom="true"
                        :showZoomer="false"
                        :boundary="{ width: 250, height: 250 }"
                        :viewport="{ width: 200, height: 200, type : 'circle' }"
                        ></vue-croppie>
                        <v-btn
                        text
                        @click="recortarImagem"
                        color="grey darken-2"
                        block
                        class="mt-3"
                        >Recortar imagem</v-btn>
                </v-flex>
            </v-layout>
        </v-stepper-content>
        <v-stepper-content step="4">
          <v-layout justify-center align-center fill-height  style="hight: 100%;">
            <v-flex align-self-center>
              <img v-if="capa && mostrar2" :src="capa" alt="Banner da empresa" width="100%">
              <v-card color="grey" height="250" width="450" v-if="!capa && !croppingCapa">
                <v-layout justify-center align-center fill-height>
                    <div class="title white--text pa-4 text-xs-center">Sem imagem</div>
                </v-layout>
              </v-card>
              <v-btn class="mt-3" block color="primary" @click="$refs.capaInput.click()" v-if="mostrar2">
                Inserir imagem
                <input
                    type="file"
                    style="display:none"
                    accept="image/*"
                    ref="capaInput"
                    @input="carregarImagem"
                />
              </v-btn>
            </v-flex>
            <v-flex xs12 v-show="croppingCapa">
                <vue-croppie
                ref="croppieCapaRef"
                :enableExif="true"
                :enforceBoundary="true"
                :enableResize="false"
                :enableOrientation="false"
                :mouseWheelZoom="true"
                :showZoomer="false"
                :boundary="{ width: 450, height: 250 }"
                :viewport="{ width: 350, height: 200}"
                ></vue-croppie>
                <v-btn
                text
                @click="recortarImagem"
                color="grey darken-2"
                block
                class="mt-3"
                >Recortar imagem</v-btn>
            </v-flex>
          </v-layout>
        </v-stepper-content>
        <v-stepper-content step="5">
          <v-layout justify-space-around wrap full-width>
              <v-flex xs11>
                  <v-text-field
                      label="CEP"
                      v-model="endereco.cep"
                      mask="##.###-###"
                      @keyup="pesquisarCep"
                      :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                      :disabled="!!empresa && !!empresa.endereco"
                  ></v-text-field>
              </v-flex>
              <v-flex xs7>
                  <v-text-field
                  label="Logradouro"
                  v-model="endereco.logradouro"
                  :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                  :disabled="!!empresa && !!empresa.endereco"
                  ></v-text-field>
              </v-flex>
              <v-flex xs3>
                  <v-text-field
                  label="Número"
                  v-model="endereco.numero"
                  :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                  :disabled="!!empresa && !!empresa.endereco"
                  ></v-text-field>
              </v-flex>
              <v-flex xs5>
                  <v-text-field
                      label="Complemento"
                      v-model="endereco.complemento"
                      :disabled="!!empresa && !!empresa.endereco"
                  ></v-text-field>
              </v-flex>
              <v-flex xs5>
                  <v-text-field
                      label="Bairro"
                      v-model="endereco.bairro"
                      :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                      :disabled="!!empresa && !!empresa.endereco"
                  ></v-text-field>
              </v-flex>
              <v-flex xs7>
                  <v-text-field
                      label="Cidade"
                      v-model="endereco.cidade"
                      :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                      :disabled="!!empresa && !!empresa.endereco"
                  ></v-text-field>
              </v-flex>
              <v-flex xs3>
                  <v-select
                      label="Estado"
                      :items="estados"
                      v-model="endereco.estado"
                      :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                      :disabled="!!empresa && !!empresa.endereco"
                  ></v-select>
              </v-flex>
              <v-flex xs11>
                  <v-text-field
                      label="País"
                      v-model="endereco.pais"
                      :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                      :disabled="!!empresa && !!empresa.endereco"
                  ></v-text-field>
              </v-flex>
              <v-flex xs8>
                  <v-checkbox
                      v-model="has_geolocation"
                      color="primary"
                      class="mt-0"
                  >
                      <template slot="label">
                          <span class="body-2 grey--text">Cadastrar latitude e longitude desse endereço?</span>
                      </template>
                  </v-checkbox>
              </v-flex>
          </v-layout>
        </v-stepper-content>
        <v-stepper-content step="6">
          <v-layout justify-space-around wrap full-width>
            <v-flex xs3>
              <v-select
                :items="['Instagram', 'Facebook', 'Twitter', 'Linkedin', 'YouTube','Whatsapp','Telegram']"
                v-model="rede_selecionada"
              />
            </v-flex>
            <v-flex xs6>
              <v-text-field
                v-model="link_perfil"
                label="Link do perfil"
              />
            </v-flex>
            <v-flex xs1>
              <v-btn fab small color="primary" class="mt-4" @click="cadastrarRedeSocial">
                <v-icon>add</v-icon>
              </v-btn>
            </v-flex>
            <v-flex xs12 v-if="redesSociais.length > 0">
              <v-list>
                <v-list-item v-for="(redeSocial, index) in redesSociais" :key="index">
                  <v-list-item-icon style="width: 10%;">
                      <img
                        v-if="redeSocial.nome"
                        style="width: 70%;"
                        :src="require(`@/assets/icones/${redeSocial.nome}.png`)"
                        class="mr-2"
                      />
                  </v-list-item-icon>
                  <v-list-item-content style="width: 80%;">
                    <v-list-item-title>
                      {{redeSocial.nome}}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <a :href="redeSocial.link_perfil" target="_blank" style="text-decoration: none; color: gray">
                        {{redeSocial.link_perfil}}
                      </a>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action style="width: 10%;">
                    <v-btn icon @click="deletarRedeSocial(redeSocial.id)">
                      <v-icon>close
                      </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-flex>
          </v-layout>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-card-actions class="justify-space-around" v-if="step !== 1">
      <v-btn rounded class="mb-3" @click="$emit('fechar')">Fechar</v-btn>
      <v-btn rounded class="mb-3" color="primary" @click="$emit('fechar')" v-if="step === 6">
        Finalizar
      </v-btn>
      <v-btn rounded class="mb-3" color="primary" @click="avancar" v-else>
        {{step === 5 ? 'Salvar' : 'Avançar'}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mask } from 'vue-the-mask'
export default {
  directives: {
    mask
  },
  props: ['empresa'],
  data: () => ({
    step: 1,
    rede_selecionada: 'Instagram',
    link_perfil: undefined,
    croppingLogo: false,
    croppingCapa: false,
    mostrar: true,
    mostrar2: true,
    imagem: null,
    capa: null,
    redesSociais: [],
    has_geolocation: false,
    empresa_data: {
      nome_fantasia: '',
      razao_social: '',
      cnpj: '',
      email: '',
      is_empresa_parceira: false,
      url_banner: '',
      descricao: '',
      site: '',
      url_logo: '',
      contato1: ''
    },
    endereco: {
      cep: '',
      logradouro: '',
      numero: '',
      bairro: '',
      cidade: '',
      estado: '',
      pais: 'Brasil'
    }
  }),
  methods: {
    pegarRedesSociais () {
      const data = { cnpj: this.empresa.cnpj, method: 'GET' }
      this.$store.dispatch('redesSociais', data).then(json => {
        this.redesSociais = json
      })
    },
    cadastrarRedeSocial () {
      if (!this.link_perfil) this.$store.dispatch('setError', 'Você precisa preencher o campo link do perfil')
      const data = { cnpj: this.empresa.cnpj, method: 'POST', nome: this.rede_selecionada, link_perfil: this.link_perfil }
      this.$store.dispatch('redesSociais', data).then(() => {
        this.pegarRedesSociais()
      })
    },
    deletarRedeSocial (idRede) {
      const data = { cnpj: this.empresa.cnpj, method: 'DELETE', id_rede_social: idRede }
      this.$store.dispatch('redesSociais', data).then(() => {
        this.pegarRedesSociais()
      })
    },
    avancar () {
      switch (this.step) {
        case 1:
          this.step++
          break
        case 2:
          if (!this.empresa_data.descricao) {
            this.$store.dispatch('setError', 'A Descrição é obrigatoria')
            break
          }
          this.step++
          break
        case 3:
          if (this.imagem && this.imagem !== this.empresa_data.url_logo) {
            this.inserirImagemFirebase(this.imagem, 'logo')
          } else this.step++
          break
        case 4:
          if (this.capa && this.capa !== this.empresa_data.url_banner) {
            this.inserirImagemFirebase(this.capa, 'banner')
          } else this.step++
          break
        case 5:
          this.editarEmpresa()
          break
        default:
          this.$store.dispatch('setError', 'Tela inválida')
      }
    }, // Avançar
    async inserirImagemFirebase (imagem, local) {
      const data = {
        arquivo: imagem,
        id: this.empresa_data.cnpj,
        local: local,
        prefixo: local,
        tipo: 'png',
        rota: 'empresas'
      }
      const variavel = await this.$store.dispatch('inserirFirebase', data)
      if (this.step === 3) this.empresa_data.url_logo = variavel
      else this.empresa_data.url_banner = variavel
      this.step++
    }, // inserirImagemFirebase
    pesquisarCep () {
      fetch(`https://viacep.com.br/ws/${this.endereco.cep}/json/`, {
        method: 'get',
        mode: 'cors',
        headers: {
          Accept: 'application/json'
        }
      })
        .then(res => res.json())
        .then(json => {
          this.endereco.logradouro = json.logradouro
          this.endereco.bairro = json.bairro
          this.endereco.cidade = json.localidade
          this.endereco.estado = json.uf
        })
    }, // pesquisarCep
    carregarImagem (event) {
      if (this.step === 3) {
        this.imagem = null
        this.mostrar = false
      } else {
        this.capa = null
        this.mostrar2 = false
      }
      if (event.target.files && event.target.files[0]) {
        const imagem = event.target.files[0]
        const reader = new FileReader()
        reader.onloadend = e => {
          if (this.step === 3) {
            this.croppingLogo = true
            this.$refs.croppieRef.bind({
              url: reader.result
            })
            this.$nextTick(() => {
              this.$refs.croppieRef.setZoom(0.1)
            })
          } else {
            this.croppingCapa = true
            this.$refs.croppieCapaRef.bind({
              url: reader.result
            })
            this.$nextTick(() => {
              this.$refs.croppieCapaRef.setZoom(0.1)
            })
          }
        }
        reader.readAsDataURL(imagem)
      }
    }, // carregarImagem
    recortarImagem () {
      const options = {
        type: 'base64',
        format: 'png'
      }
      if (this.step === 3) {
        this.mostrar = true
        options.circle = true
      } else this.mostrar2 = true

      const refscroppie = this.step === 3 ? this.$refs.croppieRef : this.$refs.croppieCapaRef
      refscroppie.result(options, output => {
        if (this.step === 3) {
          this.imagem = output
          this.croppingLogo = false
        } else {
          this.capa = output
          this.croppingCapa = false
        }
      })
    }, // recortarImagem
    editarEmpresa () {
      this.empresa_data.is_solicitation = true && !this.empresa_data.is_empresa_parceira
      this.empresa.contato1 = this.empresa.contato1 != null ? this.empresa.contato1.replace(/[^\d]+/g, '') : null
      this.empresa.contato2 = this.empresa.contato2 != null ? this.empresa.contato2.replace(/[^\d]+/g, '') : null
      this.empresa.contato3 = this.empresa.contato3 != null ? this.empresa.contato3.replace(/[^\d]+/g, '') : null
      this.$store.dispatch('editarEmpresa', this.empresa_data).then(() => {
        this.$emit('carregar')
        this.step++
      })
    }
  },
  computed: {
    estados () {
      return this.$store.getters.estados
    },
    text_title () {
      switch (this.step) {
        case 1:
          return undefined
        case 2:
          return 'Cadastre os seguintes dados'
        case 3:
          return 'Insira o logo da sua empresa'
        case 4:
          return 'Insira o banner do anúncio'
        case 5:
          return 'Confirme o endereço da empresa'
        case 6:
          return 'Cadastre as Rede Sociais da Sua Empresa'
        default:
          return ''
      }
    }
  }, // cumputed
  beforeMount () {
    if (!this.empresa) {
      this.$store.dispatch('setError', 'Empresa não encontrada')
      this.$emit('fechar')
    }
    this.pegarRedesSociais()
    this.empresa_data = this.empresa
    this.endereco = this.empresa.endereco
    this.endereco.cep = this.endereco.cep || ''
    this.imagem = this.empresa.url_logo
    this.capa = this.empresa.url_banner
    this.has_geolocation = this.empresa.geoposition
    if (this.empresa.is_empresa_parceira) this.step = 2
  },
  watch: {
    has_geolocation (val) {
      if (val && this.endereco.cep) {
        try {
          this.$store.dispatch('pegarCoordenadas', {
            logradouro: this.endereco.logradouro,
            numero: this.endereco.numero,
            bairro: this.endereco.bairro,
            cidade: this.endereco.cidade,
            estado: this.endereco.estado
          }).then(json => {
            this.empresa_data.latitude = json.latitude
            this.empresa_data.longitude = json.longitude
          }).catch(() => {
            this.$store.dispatch('setError', 'Não foi possível pegar as coordenadas geograficas da sua empresa.')
          })
        } catch {
          this.$store.dispatch('setError', 'Não foi possível pegar as coordenadas geograficas da sua empresa.')
        }
      } else {
        this.empresa_data.latitude = null
        this.empresa_data.longitude = null
      }
    }
  }
}
</script>
