<template>
  <v-card>
    <v-layout column class="pa-4">
      <h2 style="width: 100%; text-align: center;" class="mb-2">Chave Pix</h2>
      <v-form ref="form" v-model="valid">
        <v-container fluid grid-list-xl>
          <v-layout wrap align-center>
            <v-flex xs12 sm6 d-flex>
              <v-select :items="items" item-value="value" item-text="text" label="Tipo de chave PIX" v-model="tipo" :rules="requiredRules"></v-select>
            </v-flex>
          <v-flex xs12 sm6 d-flex>
            <v-text-field label="Chave PIX" v-model="chave" :mask="mask" :rules="rules" readonly></v-text-field>
          </v-flex>
          </v-layout>
          <v-layout row>
            <v-spacer></v-spacer>
            <v-btn color="success" :disabled="!valid || (chave === empresa.chave_pix && tipo === empresa.tipo_chave_pix)" @click="cadastrarChavePix">Cadastrar chave Pix</v-btn>
          </v-layout>
        </v-container>
      </v-form>
    </v-layout>
  </v-card>
</template>
<script>

export default {
  props: ['empresa'],
  data () {
    return {
      items: [
        {
          value: 'cnpj', text: 'CNPJ'
        },
        {
          value: 'numero_de_telefone', text: 'Número de Telefone'
        },
        {
          value: 'email', text: 'E-mail'
        }
      ],
      tipo: '',
      chave: '',
      valid: false,
      mask: '',
      rules: [],
      requiredRules: [
        v => !!v || 'Você se esqueceu de selecionar o tipo da chave PIX'
      ]
    }
  },
  beforeMount () {
    if (this.empresa.chave_pix) {
      this.tipo = this.empresa.tipo_chave_pix
      this.chave = this.empresa.chave_pix
    }
  },
  methods: {
    cadastrarChavePix () {
      this.$refs.form.validate()
      if (this.valid) {
        const dados = {
          tipo_chave_pix: this.tipo,
          chave_pix: this.chave
        }
        this.$emit('cadastrarChavePix', dados)
      }
    }
  },
  watch: {
    tipo (value) {
      if (value === 'email') {
        this.rules = [
          v => !!v || 'Você se esqueceu de informar o e-mail',
          v => /.+@.+\..+/.test(v) || 'Confira o endereço digitado. Nos parece que não está em um formato válido.'
        ]
        this.mask = ''
        this.chave = this.empresa.email ? this.empresa.email : this.empresa.administrador ? this.empresa.administrador.email : undefined
      } else if (value === 'numero_de_telefone') {
        this.rules = [
          v => !!v || 'Você se esqueceu de informar o número do telefone',
          v => /^[1-9]{2}9?[0-9]{8}$/.test(v) || 'Confira o número do telefone digitado. Nos parece que ele não é válido.'
        ]
        this.mask = '(##) #####-####'
        this.chave = this.empresa.contato1 ? this.empresa.contato1 : this.empresa.administrador.telefone
      } else if (value === 'cnpj') {
        this.rules = [
          v => !!v || 'Você se esqueceu de informar o CNPJ',
          v => /^[0-9]{14}$/.test(v) || 'Confira o CNPJ digitado. Nos parece que ele não está em um formato válido.'
        ]
        this.mask = '##.###.###/####-##'
        this.chave = this.empresa.cnpj
      } else {
        this.rules = [
          v => !!v || 'Você se esqueceu de preencher este campo'
        ]
        this.mask = ''
      }
    }
  }
}
</script>
