<template>
  <v-card>
    <v-layout column class="pa-4">
      <h2 style="width: 100%; text-align: center;" class="mb-2">Solicitar retirada de saldo</h2>
      <v-form ref="form" v-model="valid">
        <v-container fluid grid-list-xl>
          <v-flex>
            <v-text-field label="Valor de retirada" v-model.lazy="valor" prefix="R$" v-money="money"></v-text-field>
          </v-flex>
          <v-layout row>
            <v-spacer></v-spacer>
            <v-btn color="success" :disabled="(valor_decimal <= 0) || (valor_decimal > parseFloat(carteira.saldo))" @click="solicitarRetiradaDeSaldo">Solicitar retirada</v-btn>
          </v-layout>
        </v-container>
      </v-form>
    </v-layout>
  </v-card>
</template>

<script>

import { VMoney } from 'v-money'

export default {
  props: ['carteira'],
  directives: { money: VMoney },
  data () {
    return {
      valor: 0.00,
      valor_decimal: 0.00,
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        masked: false
      },
      valid: false
    }
  },
  methods: {
    solicitarRetiradaDeSaldo () {
      this.$refs.form.validate()
      if (this.valid) {
        this.$emit('solicitarRetiradaDeSaldo', this.valor_decimal)
      }
    }
  },
  watch: {
    valor (value) {
      this.valor_decimal = value
      this.valor_decimal = this.valor_decimal.replaceAll('.', '')
      this.valor_decimal = this.valor_decimal.replace(',', '.')
    }
  }
}
</script>

<style>
</style>
