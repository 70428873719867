<template>
  <v-card style="border-radius: 1em;">
    <v-img v-if="rede.capa" width="700" :src="rede.capa" alt="Capa da rede" class="mb-3"></v-img>
    <v-card v-else color="grey" height="300" class="rounded-0 mb-3">
      <v-layout justify-center align-center fill-height>
        <div class="title white--text pa-4 text-xs-center">
          Sem imagem
        </div>
      </v-layout>
    </v-card>

    <v-card-text class="mb-4">
    <v-card-title class="pb-0">
      <h2 v-if="rede" style="width: 100%; text-align: center;">
        {{ rede.nome }}
      </h2>
    </v-card-title>
    <v-layout row class="mt-4">
      <v-flex xs5>
        <v-list v-if="(postos.results.length > 0) || (rede.matriz)">
          <template v-if="rede.matriz">
            <v-list-item>
              <v-list-item-avatar v-if="rede.matriz.url_logo">
                <v-img :src="rede.matriz.url_logo"></v-img>
              </v-list-item-avatar>
              <v-list-item-avatar v-else>
                <v-icon>image</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-card-text v-if="rede.matriz" style="font-size: 1.3em;">
                  {{ rede.matriz.nome_fantasia }}
                </v-card-text>
              </v-list-item-content>
          </v-list-item>
          </template>
          <template v-if="postos.results.length > 0">
            <v-list-item v-for="(posto, index) in postos.results" :key="index">
              <v-list-item-avatar v-if="posto.url_logo">
                <v-img :src="posto.url_logo"></v-img>
              </v-list-item-avatar>
              <v-list-item-avatar v-else>
                <v-icon>image</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-card-text v-if="posto" style="font-size: 1.3em;">
                  {{ posto.nome_fantasia }}
                </v-card-text>
              </v-list-item-content>
          </v-list-item>
          </template>
        </v-list>
        <v-card-text v-else>
          Sem postos associados
        </v-card-text>
      </v-flex>
      <v-flex xs7>
        <v-card-text style="font-size: 1.3em;" v-if="rede && rede.matriz && rede.matriz.endereco">
          Endereço do posto matriz ({{ rede.matriz.nome_fantasia }}): {{ rede.matriz.endereco.logradouro }}, bairro {{ rede.matriz.endereco.bairro }}, número {{ rede.matriz.endereco.numero }} — {{ rede.matriz.endereco.cidade }} ({{ rede.matriz.endereco.estado }}) — CEP {{ rede.matriz.endereco.cep  }}
        </v-card-text>
      </v-flex>
    </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ['rede'],
  data () {
    return {
      postos: {
        results: []
      }
    }
  },
  methods: {
    pegarPostos () {
      this.$store.dispatch('pegarPostosDaRede', this.rede.id_rede).then((postos) => {
        this.postos = postos
      })
    }
  },
  beforeMount () {
    if (this.rede) {
      this.pegarPostos()
    }
  }
}
</script>

<style>
</style>
