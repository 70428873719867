<template>
  <v-card>
    <v-card-title
      class="
        justify-center
        primary--text
        darken-2
      "
    >
      <h2>Cadastrar Filial</h2>
    </v-card-title>
    <v-card-text>
      <v-stepper v-model="step" style="box-shadow: none">
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-layout justify-space-around wrap>
              <v-flex xs11>
                <v-text-field
                  label="Nome da filial"
                  v-model="empresa_filial.nome"
                  required
                  outlined
                  :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 class="mb-3">
                <h2 style="text-align: center">Endereço</h2>
              </v-flex>
              <v-flex xs11>
                <v-text-field
                  label="CEP"
                  v-model="empresa_filial.cep"
                  v-if="step == 1"
                  required
                  outlined
                  v-mask="'##.###-###'"
                  @keyup="pesquisarCep"
                  :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                ></v-text-field>
              </v-flex>
              <v-flex xs7>
              <v-text-field
                label="Logradouro"
                v-model="empresa_filial.logradouro"
                required
                outlined
                :readonly="!edit_endereco"
                :rules="[(v) => !!v || 'Esse campo é obrigatório']"
              ></v-text-field>
              </v-flex>
              <v-flex xs3>
                <v-text-field
                  label="Número"
                  v-model="empresa_filial.numero"
                  required
                  outlined
                  :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                ></v-text-field>
              </v-flex>
              <v-flex xs5>
                <v-text-field
                  label="Complemento"
                  v-model="empresa_filial.complemento"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs5>
                <v-text-field
                  label="Bairro"
                  required
                  outlined
                  :readonly="!edit_endereco"
                  v-model="empresa_filial.bairro"
                  :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                ></v-text-field>
              </v-flex>
              <v-flex xs5>
                <v-text-field
                  label="Cidade"
                  required
                  outlined
                  :readonly="!edit_endereco"
                  v-model="empresa_filial.cidade"
                  :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                ></v-text-field>
              </v-flex>
              <v-flex xs5>
                <v-select
                  label="Estado"
                  :items="estados"
                  v-model="empresa_filial.estado"
                  required
                  outlined
                  :readonly="!edit_endereco"
                  :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-layout justify-center column full-width  style="hight: 100%;">
                <v-flex align-self-center>
                    <img v-if="imagem && mostrar" :src="imagem" alt="Logo da empresa" width="100%">
                    <v-card color="grey" height="250" width="250" v-if="!imagem && !croppingLogo">
                        <v-layout justify-center align-center fill-height>
                            <div class="title white--text pa-4 text-xs-center">Sem imagem</div>
                        </v-layout>
                    </v-card>
                    <v-btn class="mt-3 mb-3" block color="primary" @click="$refs.logoInput.click()" v-if="mostrar">
                        Inserir imagem
                        <input
                            type="file"
                            style="display:none"
                            accept="image/*"
                            ref="logoInput"
                            @input="carregarImagem"
                        />
                    </v-btn>
                    <v-btn
                      v-if="empresa.url_logo && mostrar"
                      class="mt-4 mb-1"
                      block
                      @click="
                        empresa_filial.url_logo = empresa.url_logo,
                        imagem = empresa.url_logo
                      "
                    >
                        Usar logo da empresa
                    </v-btn>
                    <v-btn
                      v-else-if="mostrar"
                      class="mt-4 mb-1"
                      block
                      @click="
                        salvarFilial
                      "
                    >
                        Pular
                    </v-btn>
                </v-flex>
                <v-flex xs12 v-show="croppingLogo">
                    <vue-croppie
                        ref="croppieRef"
                        :enableExif="true"
                        :enforceBoundary="true"
                        :enableResize="false"
                        :enableOrientation="false"
                        :mouseWheelZoom="true"
                        :showZoomer="false"
                        :boundary="{ width: 250, height: 250 }"
                        :viewport="{ width: 200, height: 200, type : 'circle' }"
                        ></vue-croppie>
                        <v-btn
                        text
                        @click="recortarImagem"
                        color="grey darken-2"
                        block
                        class="mt-3"
                        >Recortar imagem</v-btn>
                </v-flex>
            </v-layout>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-card-actions class="justify-space-around">
      <v-btn
        rounded
        class="mb-3"
        @click="$emit('fechar')"
      >Calcelar</v-btn>
      <v-btn
        rounded
        color="primary"
        dark
        class="mb-3"
        :loading="loading"
        @click="confirmar"
      >
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mask } from 'vue-the-mask'
export default {
  props: ['empresa'],
  directives: {
    mask
  },
  data: () => ({
    step: 1,
    croppingLogo: false,
    edit_endereco: false,
    imagem: undefined,
    mostrar: true,
    empresa_filial: {
      nome: '',
      cep: '',
      logradouro: '',
      numero: '',
      bairro: '',
      cidade: '',
      estado: '',
      pais: 'Brasil',
      latitude: '',
      longitude: '',
      url_logo: ''
    }
  }),
  computed: {
    loading () {
      return this.$store.getters.loading
    },
    estados () {
      return this.$store.getters.estados
    }
  },
  methods: {
    async confirmar () {
      switch (this.step) {
        case 1:
          if (this.empresa_filial.nome &&
            this.empresa_filial.cep &&
            this.empresa_filial.logradouro &&
            this.empresa_filial.numero
          ) {
            this.step++
            try {
              await this.buscarCoordenadas()
            } catch {
              this.$store.dispatch('setError', 'Não foi possível pegar coordenas para esse endereço.')
            }
            break
          }
          this.$store.dispatch('setError', 'Você deve preencher os campos obrigatórios.')
          break
        case 2:
          if (!this.imagem && !this.empresa_filial.url_logo) {
            this.$store.dispatch('setError', 'Você deve inserir uma imagem.')
            break
          }
          if (this.imagem && (this.imagem !== this.empresa_filial.url_logo)) {
            this.inserirImagemFirebase(this.imagem, 'filial')
          }
          this.salvarFilial()
          break
      }
    },
    pesquisarCep () {
      if (this.empresa_filial.cep) {
        fetch(`https://viacep.com.br/ws/${this.empresa_filial.cep.replace(/[^a-zA-Z0-9]/g, '')}/json/`, {
          method: 'get',
          mode: 'cors',
          headers: {
            Accept: 'application/json'
          }
        })
          .then(res => res.json())
          .then(json => {
            this.empresa_filial.logradouro = json.logradouro
            this.empresa_filial.bairro = json.bairro
            this.empresa_filial.cidade = json.localidade
            this.empresa_filial.estado = json.uf
            if (!json.localidade || !json.bairro) this.edit_endereco = true
          })
      } else {
        this.edit_endereco = false
      }
    },
    buscarCoordenadas () {
      return this.$store.dispatch('pegarCoordenadas', {
        logradouro: this.empresa_filial.logradouro,
        numero: this.empresa_filial.numero,
        bairro: this.empresa_filial.bairro,
        cidade: this.empresa_filial.cidade,
        estado: this.empresa_filial.estado
      }).then(json => {
        this.empresa_filial.latitude = json.latitude
        this.empresa_filial.longitude = json.longitude
      })
    },
    async inserirImagemFirebase (imagem, local) {
      const cod = `${new Date().getTime()}${Math.floor(Math.random() * 1000) + 1}`
      const data = {
        arquivo: imagem,
        id: this.empresa_filial.cnpj,
        local: local,
        prefixo: cod,
        tipo: 'png',
        rota: 'empresas'
      }
      this.empresa_filial.url_logo = await this.$store.dispatch('inserirFirebase', data)
    },
    carregarImagem (event) {
      this.imagem = null
      this.mostrar = false
      if (event.target.files && event.target.files[0]) {
        const imagem = event.target.files[0]
        const reader = new FileReader()
        reader.onloadend = e => {
          this.croppingLogo = true
          this.$refs.croppieRef.bind({
            url: reader.result
          })
          this.$nextTick(() => {
            this.$refs.croppieRef.setZoom(0.1)
          })
        }
        reader.readAsDataURL(imagem)
      }
    },
    recortarImagem () {
      const options = {
        type: 'base64',
        format: 'png'
      }
      this.mostrar = true
      options.circle = true
      this.$refs.croppieRef.result(options, output => {
        this.imagem = output
        this.croppingLogo = false
      })
    },
    limparCampos () {
      this.empresa_filial = {
        nome: '',
        cep: '',
        logradouro: '',
        numero: '',
        bairro: '',
        cidade: '',
        estado: '',
        pais: 'Brasil',
        latitude: '',
        longitude: '',
        url_logo: ''
      }
      this.step = 1
      this.croppingLogo = false
      this.edit_endereco = false
      this.imagem = undefined
      this.mostrar = true
    },
    salvarFilial () {
      this.empresa_filial.cnpj = this.empresa.cnpj
      this.empresa_filial.cep = this.empresa_filial.cep.replace(/[^a-zA-Z0-9]/g, '')
      this.$store.dispatch('cadastrarFilial', this.empresa_filial).then(() => {
        this.$store.dispatch('setSuccess', 'Filial Cadastrada com sucesso')
        this.limparCampos()
        this.$emit('fechar')
      }).catch(() => {
        this.$store.dispatch('setError', 'Não foi possível cadastrar filial')
      })
    }
  }
}
</script>
