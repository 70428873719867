<template>
  <v-card>
    <v-card-title class="justify-center">
      <h2>DETALHES DA DISPUTA DE LANCES</h2>
    </v-card-title>
    <v-alert :value="true" v-if="alerta" :type="tipo">
      <h3>{{ alerta }}</h3>
    </v-alert>
    <v-alert
      v-if="exibir_alerta"
      type="error"
      class="mt-4 mb-1 pa-2"
    >
      <v-layout justify-center wrap>
        <v-flex xs12>
          <h4>
            Você precisa realizar o pagamento da taxa da disputa de lances para poder visualizar
            o contato do {{ isEmpresa ?  'interessado n' : 'propríetario d' }}o veículo.
          </h4>
        </v-flex>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
          <v-btn
            elevation="1"
            color="white"
            class="error--text mr-4 mt-2"
            v-on="on"
            small
            rounded
          >
            <v-icon class="mr-1">paid</v-icon>
            Realizar Pagamento
          </v-btn>
        </template>
        <v-card width="25em">
          <v-card-title class="justify-center error--text">
            REALIZAR PAGAMENTO
          </v-card-title>
          <v-card-text>
            <h3 class="mb-2">
              Assim que seu pagamento for confirmado, você poderá visualizar as informações
              do {{ isEmpresa ? 'interessado n' : 'propríetario d' }}o veículo e entrar em contato com ele.
            </h3>
            <h4
              v-if="
                isEmpresa && !pagamento_taxa_empresa_vendedora
              "
            >
              Taxa:
              {{
                configuracoes_gerais.taxa_leilao_empresa_vendedora | currency({ fractionCount: 2 })
              }}
            </h4>
            <h4
              v-else-if="
                !isEmpresa && !pagamento_taxa_empresa_compradora"
            >
              Taxa:
              {{ configuracoes_gerais.taxa_leilao_empresa_compradora | currency({ fractionCount: 2 }) }}
            </h4>
            <h4 v-if="carteira">Saldo da sua carteira virtual: {{ carteira.saldo | currency({ fractionCount: 2 }) }}</h4>
            <h4
              class="
                error--text
                text-center
                mt-2
              "
              v-if="
                !carteira ||
                (isEmpresa && parseFloat(configuracoes_gerais.taxa_leilao_empresa_vendedora) > parseFloat(carteira.saldo)) ||
                (!isEmpresa && parseFloat(configuracoes_gerais.taxa_leilao_empresa_compradora) > parseFloat(carteira.saldo))
              "
            >
              Seu saldo é insuficiente! Recarregue sua carteira.
            </h4>
          </v-card-text>
          <v-card-actions>
            <v-btn
              elevation="1"
              color="error"
              @click="authenticationDialog = true"
              block
              small
              :disabled="
                !carteira ||
                (isEmpresa && parseFloat(configuracoes_gerais.taxa_leilao_empresa_vendedora) > parseFloat(carteira.saldo)) ||
                (!isEmpresa && parseFloat(configuracoes_gerais.taxa_leilao_empresa_compradora) > parseFloat(carteira.saldo))
              "
              :loading="loading"
            >
              Pagar
            </v-btn>
          </v-card-actions>
        </v-card>
        </v-menu>
      </v-layout>
    </v-alert>
    <v-divider></v-divider>
    <v-card-text class="justify-space-around text-h6 d-flex flex-wrap" v-if="leilao">
      <v-flex xs5 class="mt-4">
        Data de inicío: <b>{{ leilao.data_inicio | formatDate('DD/MM/YYYY') }}</b>
      </v-flex>
      <v-flex xs5 class="mt-4">
        Horário de inicío: <b>{{ leilao.data_inicio | formatDate('HH:mm') }}</b>
      </v-flex>
      <v-flex xs5>
        Data de encerramento: <b>{{ leilao.data_fim | formatDate('DD/MM/YYYY') }}</b>
      </v-flex>
      <v-flex xs5>
        Horário de inicío: <b>{{ leilao.data_fim | formatDate('HH:mm') }}</b>
      </v-flex>
      <v-flex xs5>
        Status: <b>{{
          leilao.status === 1 ?
          'em aberto' :
          leilao.status === 2 ? 'encerrado' :
          leilao.status === 3 ? 'cancelado' :
          leilao.status === 4 ? 'em andamento' :
          'concluído'
        }}</b>
      </v-flex>
      <v-flex xs5>
        Valor Inicial: <b>{{ Number(leilao.valor_inicial) | currency({ fractionCount: 2 }) }}</b>
      </v-flex>
      <template v-if="ultimo_lance && leilao.empresa && leilao.empresa.cnpj === empresa.cnpj">
        <v-flex xs12 class="mt-4">
          <h4>ÚLTIMO LANCE</h4>
        </v-flex>
        <v-flex xs5>
          Responsavel: <b>{{ substituirPorAsteriscos(ultimo_lance.responsavel.nome_fantasia) }}</b>
        </v-flex>
        <v-flex xs5>
          CNPJ: <b>{{ substituirPorAsteriscos(ultimo_lance.responsavel.cnpj) }}</b>
        </v-flex>
        <v-flex xs5>
          Data: <b>{{ ultimo_lance.data | formatDate('DD/MM/YYYY') }}</b>
        </v-flex>
        <v-flex xs5>
          Hora: <b>{{ ultimo_lance.data | formatDate('HH:mm') }}</b>
        </v-flex>
        <v-flex xs11>
          Valor: <b>{{ ultimo_lance.valor | currency({ fractionCount: 2 }) }}</b>
        </v-flex>
        <v-flex
          xs11
          v-if="
            exibir_dados && (
              ultimo_lance.responsavel.contato1 ||
              ultimo_lance.responsavel.contato2 ||
              ultimo_lance.responsavel.contato3
            )
          ">
          Telefones: <b>{{ ultimo_lance.responsavel.contato1 ? `${formatarTelefone(ultimo_lance.responsavel.contato1)}` : '' }}{{ ultimo_lance.responsavel.contato2 ? `, ${formatarTelefone(ultimo_lance.responsavel.contato2)}` : '' }}{{ ultimo_lance.responsavel.contato3 ? `, ${formatarTelefone(ultimo_lance.responsavel.contato3)}` : '' }}
          </b>
        </v-flex>
        <v-flex xs11 v-if="exibir_dados && ultimo_lance.responsavel.email">
          E-mail: {{ ultimo_lance.responsavel.email }}
        </v-flex>
      </template>
      <template v-else-if="ultimo_lance && ultimo_lance.responsavel.cnpj === empresa.cnpj">
        <v-flex xs12 class="mt-4">
          <h4>RESPONSÁVEL PELA DISPUTA DE LANCES</h4>
        </v-flex>
        <v-flex xs5>
          Nome:
          <template v-if="leilao.empresa">
            <b>{{ substituirPorAsteriscos(leilao.empresa.nome_fantasia) }}</b>
          </template>
          <template v-else>
            <b>{{ substituirPorAsteriscos(leilao.cliente.pessoa.nome_completo) }}</b>
          </template>
        </v-flex>
        <v-flex xs5>
          <template v-if="leilao.empresa">
            CNPJ: <b>{{ substituirPorAsteriscos(leilao.empresa.cnpj) }}</b>
          </template>
          <template v-else>
            Identificação: <b>{{ substituirPorAsteriscos(leilao.cliente.pessoa.id_pessoa) }}</b>
          </template>
        </v-flex>
        <v-flex
          xs11
          v-if="exibir_dados"
        >
          Telefones:
          <template v-if="leilao.empresa && (
              leilao.empresa.contato1 ||
              leilao.empresa.contato2 ||
              leilao.empresa.contato3
            )"
          >
            <b>
              {{ leilao.empresa.contato1 ? `${formatarTelefone(leilao.empresa.contato1)}` : '' }}{{ leilao.empresa.contato2 ? `, ${formatarTelefone(leilao.empresa.contato2)}` : '' }}{{ leilao.empresa.contato3 ? `, ${formatarTelefone(leilao.empresa.contato3)}` : '' }}
            </b>
          </template>
          <template v-else-if="leilao.cliente && leilao.cliente.pessoa.telefone">
            <b>
              {{ formatarTelefone(leilao.cliente.pessoa.telefone) }}
            </b>
          </template>
        </v-flex>
        <v-flex xs11 v-if="exibir_dados && ultimo_lance.responsavel.email">
          E-mail:
          <template v-if="leilao.empresa">
            <b>{{ leilao.empresa.email }}</b>
          </template>
          <template v-else>
            <b>{{ leilao.cleinte.pessoa.email }}</b>
          </template>
        </v-flex>
      </template>
      <v-flex xs12 class="mt-4">
        <h4>VEÍCULO LEILOADO</h4>
      </v-flex>
      <v-flex xs5 v-if="exibir_dados">
        Placa: {{ leilao.veiculo_leiloado.identificacao }}
      </v-flex>
      <v-flex xs5>
        Novo: {{ leilao.veiculo_leiloado.usado ? 'NÃO' : 'SIM' }}
      </v-flex>
      <v-flex xs5>
        Código: {{ leilao.veiculo_leiloado.id_veiculo }}
      </v-flex>
      <v-flex xs5>
        Cor: {{ leilao.veiculo_leiloado.cor_texto }}
      </v-flex>
      <v-flex xs5>
        Modolo: {{ leilao.veiculo_leiloado.modelo }}
      </v-flex>
      <v-flex :xs5="exibir_dados" :xs11="!exibir_dados">
        Marca: {{ leilao.veiculo_leiloado.marca }}
      </v-flex>
      <v-flex xs12 class="ma-4">
        <v-btn
          text
          color="info"
          block
          @click="visualizarVeiculo = true"
      >
        Mostrar mais informações do veículo
      </v-btn>
      </v-flex>
      <v-flex xs12 class="mt-4">
        <h4>HISTORÍCO DE LANCES</h4>
      </v-flex>
      <v-flex
        xs12
        class="mt-4"
        style="
          height: 9.5em;
          overflow-y: auto;
          overflow-x: hidden;
        "
      >
        <v-list>
          <template v-for="(item, index) in lances">
            <v-list-item :key="`${index}item`">
              <v-list-item-icon>
                <v-icon :color="item.id === ultimo_lance.id ? 'info' : ''">{{ item.id === ultimo_lance.id ? 'emoji_events' : 'pan_tool' }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <template v-if="item.responsavel.cnpj === empresa.cnpj">
                    Seu Lance
                  </template>
                  <template v-else>
                    {{ substituirPorAsteriscos(item.responsavel.nome_fantasia) }} - {{ substituirPorAsteriscos(item.responsavel.cnpj) }}
                  </template>
                </v-list-item-title>
                <v-list-item-subtitle>
                  As {{ item.data | formatDate('HH:mm') }} de {{ item.data | formatDate('DD/MM/YYYY') }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content>
                {{ item.valor | currency({ fractionCount: 2 }) }}
              </v-list-item-content>
              <v-list-item-action>
                <v-menu
                  left
                  offset-y
                  :nudge-width="200"
                  transition="scale-transition"
                  ref="menu"
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      color="error"
                      :disabled="disabledCancelamento(item)"
                    >
                      <v-icon>cancel</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-text class="error--text text-h6 text-center">
                      Tem certeza que deseja cancelar esse lance?
                    </v-card-text>
                    <v-card-actions class="justify-space-around">
                      <v-flex xs6>
                        <v-btn text block>
                          Não
                        </v-btn>
                      </v-flex>
                      <v-divider vertical></v-divider>
                      <v-flex xs6 @click="cancelarLances(item)">
                        <v-btn color="error" text block>
                          SIM
                        </v-btn>
                      </v-flex>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="index < lances.length - 1" :key="`${index}divider`"></v-divider>
          </template>
          <v-list-item v-if="lances.length === 0">
            <v-list-item-content>
                <v-list-item-title>
                  Ainda nenhum lance foi dado
                </v-list-item-title>
            </v-list-item-content>
            </v-list-item>
        </v-list>
      </v-flex>
    </v-card-text>
    <v-card-actions class="justify-space-around">
      <v-flex :xs="leilao.empresa && leilao.empresa.cnpj === empresa.cnpj ? 6 : 12">
        <v-btn
          @click="$emit('fechar')"
          text
          block
        >
          Fechar
        </v-btn>
      </v-flex>
      <v-divider vertical v-if="leilao.empresa && leilao.empresa.cnpj === empresa.cnpj"></v-divider>
      <v-flex xs6 v-if="leilao.empresa && leilao.empresa.cnpj === empresa.cnpj">
        <v-btn
          @click="aceitarLance"
          text
          block
          color="info"
          :disabled="leilao.status === 5 || leilao.status === 3 || lances.length === 0 || !exibir_dados"
        >
          Confirmar Entrega do Veículo
        </v-btn>
      </v-flex>
    </v-card-actions>
    <v-dialog v-model="visualizarVeiculo" v-if="visualizarVeiculo" scrollable width="55em">
      <dialog-visualizar-veiculo
        :veiculo="leilao.veiculo_leiloado"
        @fechar="visualizarVeiculo = false"
        :mostrarBotao="false"
      />
    </v-dialog>
    <v-dialog
      v-model="authenticationDialog"
      v-if="authenticationDialog"
      width="350"
    >
      <autenticar-smart-lock
        @fechar="authenticationDialog = false"
        @autenticado="realizarPagamentoLeilao(), authenticationDialog = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import DialogVisualizarVeiculo from '@/components/dialogs/VisualizarVeiculo'
import AutenticarSmartLock from '@/components/dialogs/AutenticarSmartLock'
export default {
  components: { DialogVisualizarVeiculo, AutenticarSmartLock },
  props: ['leilaoSelecionado'],
  data: () => ({
    alerta: '',
    tipo: 'error',
    lances: [],
    leilao: {
      empresa: undefined,
      cliente: undefined
    },
    ultimo_lance: undefined,
    visualizarVeiculo: false,
    carteira: undefined,
    authenticationDialog: false,
    configuracoes_gerais: {
      taxa_leilao_empresa_compradora: 1,
      taxa_leilao_empresa_vendedora: 1,
      taxa_leilao_cliente_vendedor: 1
    }
  }),
  computed: {
    loading () {
      return this.$store.getters.loading
    },
    empresa () {
      return this.$store.getters.empresa
    },
    isEmpresa () { // Se a disputa de lances é da empresa logada
      if (!this.leilaoSelecionado || !this.leilaoSelecionado.empresa) return false
      return this.leilaoSelecionado.empresa.cnpj === this.empresa.cnpj
    },
    pagamento_taxa_empresa_vendedora () { // vendendo
      return !this.configuracoes_gerais.taxa_leilao_empresa_vendedora || (this.leilao && this.leilao.pagamento_taxa_empresa_vendedora)
    },
    pagamento_taxa_empresa_compradora () { // comprador
      return !this.configuracoes_gerais.taxa_leilao_empresa_compradora || (this.leilao && this.leilao.pagamento_taxa_empresa_compradora)
    },
    pagamento_taxa_cliente_vendedor () { // vendendo
      return !this.configuracoes_gerais.taxa_leilao_cliente_vendedor || (this.leilao && this.leilao.pagamento_taxa_cliente_vendedor)
    },
    exibir_alerta () {
      if (this.leilao.status !== 2) return false
      if (this.lances.length === 0) return false
      if (this.isEmpresa) {
        return !this.pagamento_taxa_empresa_vendedora
      } else {
        return !this.pagamento_taxa_empresa_compradora
      }
    },
    exibir_dados () {
      if (this.leilao.status === 5) return true
      if (this.leilao.empresa) {
        return this.pagamento_taxa_empresa_vendedora && this.pagamento_taxa_empresa_compradora
      } else {
        return this.pagamento_taxa_cliente_vendedor && this.pagamento_taxa_empresa_compradora
      }
    }
  },
  created () {
    this.leilao = { ...this.leilaoSelecionado }
    if (this.leilaoSelecionado) {
      this.ultimo_lance = this.leilaoSelecionado.ultimo_lance
      this.pegarLances()
    }
  },
  mounted () {
    this.pegarConfirguracoes()
    this.pegarCarteiraVirtual()
    const interval = setInterval(() => {
      if (this.isEmpresa && this.pagamento_taxa_empresa_vendedora && !this.pagamento_taxa_empresa_compradora) {
        this.ativarAlerta('Aguarde o vencedor da disputa de lances pagar a taxa da disputa de lances para os dados dele serem exibidos, caso ele não pague, cancele o lance dele.', 'error', true)
      }
      clearInterval(interval)
    }, 1000)
  },
  methods: {
    pegarConfirguracoes () {
      this.$store.dispatch('getConfigApetrus')
        .then(json => {
          this.configuracoes_gerais = json
        })
    },
    ativarAlerta (texto, tipo, permanente) {
      this.alerta = texto
      this.tipo = tipo
      if (!permanente) {
        const interval = setInterval(() => {
          this.alerta = undefined
          clearInterval(interval)
        }, 5000)
      }
    },
    pegarLances () {
      this.$store.dispatch('lancesLeilao', {
        method: 'GET',
        parametros: {
          leilao: this.leilao.id,
          cancelado: false
        }
      }).then(json => {
        this.lances = json
      })
    },
    pegarLeilao () {
      this.$store.dispatch('leilaoVeiculos', {
        id: this.leilaoSelecionado.id,
        parametros: {
          empresa: this.empresa.cnpj,
          responsavel_lance: this.empresa.cnpj,
          responsavel: this.empresa.cnpj
        }
      }).then(json => {
        this.leilao = { ...json }
      })
    },
    pegarCarteiraVirtual () {
      this.$store.dispatch('pegarCarteiraVirtual', { cnpj: this.empresa.cnpj }).then((json) => {
        this.carteira = json
      })
    },
    cancelarLances (lance) {
      this.$store.dispatch('lancesLeilao', {
        method: 'DELETE',
        id: lance.id,
        parametros: {
          empresa: this.empresa.cnpj
        }
      }).then(() => {
        this.pegarLeilao()
        this.pegarLances()
        if (this.isEmpresa && this.pagamento_taxa_empresa_vendedora && !this.pagamento_taxa_empresa_compradora) {
          this.ativarAlerta('O novo vencedor da disputa de lances já foi notificado aguarde ele pagar a taxa para visualizar suas informações de contato.', 'info', false)
        }
      })
    },
    aceitarLance () {
      this.$store.dispatch('lancesLeilao', {
        method: 'PATCH',
        id: this.ultimo_lance.id,
        body: {
          empresa: this.empresa.cnpj,
          aceito: true
        }
      }).then(() => {
        this.$emit('carregar')
        this.pegarLeilao()
        this.ativarAlerta('Seu pagamento foi realizado com sucesso.', 'success')
      })
    },
    realizarPagamentoLeilao () {
      this.$store.dispatch('realizarPagamentoLeilao', {
        empresa: this.empresa.cnpj,
        id: this.leilaoSelecionado.id,
        responsavel: this.empresa.cnpj
      }).then(() => {
        this.ativarAlerta('O pagamento da taxa foi realizado com sucesso', 'success', false)
        this.pegarLeilao()
        this.$emit('carregar')
      }).catch(() => {
        this.ativarAlerta('Não foi possível realizar o pagamento da taxa do leião', 'error', false)
      })
    },
    disabledCancelamento (item) {
      if (item.responsavel.cnpj === this.empresa.cnpj && !item.aceito) return false
      else if (
        item.responsavel.cnpj !== this.empresa.cnpj &&
        (this.lances.length === 1 || item.aceito || (item.id !== this.ultimo_lance.id) || this.leilao.status !== 2 || !this.pagamento_taxa_empresa_vendedora)
      ) return true
      return false
    },
    substituirPorAsteriscos (texto) {
      if (!texto) return ''
      if (this.exibir_dados && (this.isEmpresa || (this.ultimo_lance && this.ultimo_lance.responsavel.cnpj === this.empresa.cnpj))) return texto

      // Calcula quantos caracteres devem ser substituídos
      const numCaracteresSubstituir = Math.floor(texto.length * 0.7)

      // Gera uma string com asteriscos
      const asteriscos = '*'.repeat(numCaracteresSubstituir)

      // Concatena os asteriscos com o restante da string original
      const resultado = asteriscos + texto.slice(numCaracteresSubstituir)

      return resultado
    },
    formatarTelefone (numero) {
      const numeroLimpo = numero.replace(/\D/g, '')
      if (numeroLimpo.length === 10) {
        return `(${numeroLimpo.substring(0, 2)}) ${numeroLimpo.substring(2, 6)}-${numeroLimpo.substring(6)}`
      } else if (numeroLimpo.length === 11) {
        return `(${numeroLimpo.substring(0, 2)}) ${numeroLimpo.substring(2, 3)} ${numeroLimpo.substring(3, 7)}-${numeroLimpo.substring(7)}`
      } else if (numeroLimpo.length === 9) {
        return `(${numeroLimpo.substring(0, 1)}) ${numeroLimpo.substring(1, 5)}-${numeroLimpo.substring(5)}`
      } else if (numeroLimpo.length === 8) {
        return `(${numeroLimpo.substring(0, 2)}) ${numeroLimpo.substring(2, 6)}-${numeroLimpo.substring(6)}`
      } else {
        return ''
      }
    }
  }
}
</script>

<style>

</style>
