import settings from '@/settings'

const get = (headers, filtros) => {
  let url = `${settings.apetrusApi}/itens/`

  if (filtros) {
    const keys = Object.keys(filtros)
    for (let i = 0; i < keys.length; i++) {
      url = `${url}${url[url.length - 1] === '/' ? '?' : '&'}${keys[i]}=${filtros[keys[i]]}`
    }
  }
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: headers
  })
}

const item = (headers, data) => {
  let url = `${settings.apetrusApi}/itens/`

  if (data.id) {
    url += `${data.id}/`
  }

  const parameter = {
    method: data.method,
    mode: 'cors',
    headers: headers
  }

  if (data.method === 'GET') {
    parameter.cache = 'default'
    if (data.parameters) {
      Object.keys(data.parameters).forEach((filter) => {
        url = `${url}${url[url.length - 1] === '/' ? '?' : '&'}${filter}=${data.parameters[filter]}`
      })
    }
  } else if (data.body) parameter.body = JSON.stringify(data.body)

  return fetch(url, parameter)
}

export default {
  get,
  item
}
