<template>
  <v-card class="pa-3">
    <v-card-text>
        <h2
            v-if="item.disponivel"
            class="error--text text-center mt-1"
        >
            VOCÊ DESEJA MARCAR ESSE ITEM COMO INDISPONÍVEL?
        </h2>
        <h2
            v-else
            class="success--text text-center mt-1"
        >
          VOCÊ DESEJA MARCAR ESSE ITEM COMO DISPONÍVEL?
        </h2>
        <v-flex xs12 v-if="disponibilizar" class="mt-4 ml-3">
            <v-switch slot="activator" v-model="tem_limite_qtd" color="success" :disabled="!!item.combustivel">
                <template v-slot:label>
                    <span style="font-size: 1.2em;">Tem controle de quantidade</span>
                </template>
            </v-switch>
            <v-slide-x-transition>
                <v-text-field
                    v-if="tem_limite_qtd"
                    label="Quantidade Disponível"
                    outline
                    v-model="item.quantidade_disponivel"
                    type="number"
                ></v-text-field>
            </v-slide-x-transition>
        </v-flex>
    </v-card-text>
    <v-card-actions class="justify-space-around">
        <v-flex xs6>
            <v-btn
                block
                text
                @click="$emit('fechar')"
            >
                fechar
            </v-btn>
        </v-flex>
        <v-divider vertical></v-divider>
        <v-flex xs6>
            <v-btn
                block
                text
                @click="editarDisponibilidade"
                :color="item.disponivel ? 'error' : 'success'"
            >
                Confirmar
            </v-btn>
        </v-flex>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['item'],
  data: () => ({
    tem_limite_qtd: false,
    disponibilizar: false
  }),
  watch: {
    tem_limite_qtd (val) {
      if (!val) this.item.quantidade_disponivel = null
    }
  },
  computed: {
    empresa () {
      return this.$store.getters.empresa
    }
  },
  methods: {
    editarDisponibilidade () {
      if (!this.item.disponivel && !this.disponibilizar) {
        this.disponibilizar = true
        return
      }
      const data = {
        method: 'PUT',
        body: {
          empresa: this.empresa.cnpj,
          ...this.item,
          categoria: this.item.categoria.id,
          combustivel: this.item.combustivel ? this.item.combustivel.id_combustivel : null,
          disponivel: !this.item.disponivel
        },
        id: this.item.id
      }
      this.$store.dispatch('itens', data).then(() => {
        this.$emit('carregar')
        this.$emit('fechar')
        if (this.item.disponivel) this.$emit('success', 'Esse item agora está indisponível.')
        else this.$emit('success', 'Esse item agora escontra-se disponível.')
      })
    }
  }
}
</script>

<style>

</style>
