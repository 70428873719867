<template>
  <v-card text>
    <v-toolbar
      color="#eee"
      class="elevation-0 secondary--text"
      style="background: transparent"
    >
      <v-toolbar-title>Meus Vouchers</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-data-table
      hide-default-footer
      :items="compras"
      :headers="comprasHeaders"
      no-data-text="Você ainda não possui compras"
    >
      <template v-slot:item="props">
        <tr>
          <td class="text-xs-left pr-0">
            <v-tooltip right v-if="props.item.combustivel">
              <template v-slot:activator="{ on }" v-if="props.item.combustivel">
                <svg-filler
                    v-on="on"
                    :path="props.item.combustivel ? require(`@/assets/combs/${props.item.combustivel.icone}.svg`) : require(`@/assets/combs/gas.svg`)"
                    width="40px"
                    height="40px"
                    :fill="props.item.combustivel ? props.item.combustivel.color : ''"
                  />
              </template>

              <span>{{props.item.combustivel ? props.item.combustivel.nome : 'Não informado'}}</span>
            </v-tooltip>
          </td>
          <td class="text-xs-left pr-0">
            {{ props.item.codigo ||  props.item.numero }}
          </td>
          <template v-if="$vuetify.breakpoint.xs">
            <td class="text-xs-left pl-0">
              <v-tooltip v-if="props.item.disponivel" bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon text v-on="on" color="blue lighten-2">
                    <v-icon>check_circle</v-icon>
                  </v-btn>
                </template>
                <span>Disponível</span>
              </v-tooltip>
              <v-tooltip v-else bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon text v-on="on" color="grey lighten-2">
                    <v-icon>check_circle</v-icon>
                  </v-btn>
                </template>
                <span>Usado</span>
              </v-tooltip>
            </td>
          </template>
          <template v-else>
            <td @click="selecionarCompra(props.item)" class="text-xs-left">
              <v-btn
                text
                icon
                v-if="props.item.disponivel"
                color="blue lighten-2"
              >
                <v-icon>check_circle</v-icon>
                DISPONÍVEL
              </v-btn>

              <v-btn text icon v-else disabled color="grey lighten-2">
                <v-icon>check_circle</v-icon>
                USADO
              </v-btn>
            </td>
          </template>
          <td
            @click="selecionarCompra(props.item)"
            class="text-xs-left pl-0 pr-0"
          >
            {{ props.item.valor | currency({ fractionCount: 2 }) }}
          </td>
          <td
            @click="
              props.item.dependencia == null && props.item.disponivel
                ? compartilharCompra(props.item)
                : selecionarCompra(props.item)
            "
            class="text-xs-left pr-0 pl-0"
          >
            <v-btn
              icon
              text
              v-if="
                !props.item.funcionario &&
                props.item.disponivel
              "
              color="info"
            >
              <v-icon>share</v-icon>
            </v-btn>
            <v-chip v-else-if="props.item.funcionario" class="ma-2" color="black" outlined>{{
              props.item.funcionario.pessoa ? props.item.funcionario.pessoa.nome_completo :  props.item.nome_email
            }}</v-chip>
            <!-- <v-avatar
                    v-else-if="props.item.dependencia"
                >
                    <v-img
                    v-if="props.item.dependencia.avatar_dependente"
                    :src="props.item.dependencia.avatar_dependente"
                    height="30"
                    contain
                    />
                    <v-icon size="30" v-else>perm_identity</v-icon>
                </v-avatar> -->
          </td>
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
              <v-btn
                @click="selecionarCompra(props.item)"
                icon
                color="info"
                v-on="on"
              >
                <v-icon>remove_red_eye</v-icon>
              </v-btn>
              </template>
              <span>Visualizar detalhes</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
      <template v-slot:headers>
        <th class="text-xs-left pr-0">Combustível</th>
        <th class="text-xs-left pr-0">Código</th>
        <th class="text-xs-left pl-2" v-if="$vuetify.breakpoint.xs">Ativo</th>
        <th class="text-xs-left" v-else>Consumido</th>
        <th class="text-xs-left pr-0 pl-0">Valor (R$)</th>
        <th class="text-xs-left pl-0 pr-0"></th>
      </template>
    </v-data-table>
    <div class="text-xs-center">
      <v-pagination
        v-model="pagination.page"
        :length="pages"
        circle
      ></v-pagination>
    </div>
  </v-card>
</template>

<script>

export default {
  props: ['filtros'],
  data () {
    return {
      funcionarios: [],
      compras: [],
      snackbar: {
        active: false,
        color: '',
        multiLine: true,
        timeout: 4000,
        vertical: true,
        text: ''
      },
      pagination: {
        page: 1,
        rowsPerPage: 5
      },
      totalItems: 0,
      compra: null,
      today: null,
      comprasHeaders: [
        {
          text: 'Combustível',
          align: 'left',
          sortable: false,
          value: 'compra.combustivel'
        },
        {
          text: 'Código',
          align: 'left',
          sortable: false,
          value: 'codigo'
        },
        {
          text: 'Situação',
          align: 'left',
          sortable: false,
          value: 'ativo'
        },
        {
          text: 'Valor (R$)',
          value: 'valor_total',
          sortable: false,
          align: 'left'
        },
        {
          text: 'Funcionário beneficiado',
          value: 'funcionario',
          sortable: false,
          align: 'left'
        },
        {
          align: 'left',
          sortable: false
        }
      ]
    }
  },
  watch: {
    pagination: {
      handler (val, oldval) {
        if (this.empresa) this.carregarCompras()
      },
      deep: true
    },
    voucher (val, oldval) {
      if (this.empresa) this.carregarCompras()
    },
    empresa (val, oldval) {
      if (val !== null && val !== undefined && val !== oldval) {
        this.carregarCompras()
      }
    }
  },
  computed: {
    pages () {
      if (this.pagination.rowsPerPage == null || this.totalItems == null) {
        return 0
      }
      return Math.ceil(this.totalItems / this.pagination.rowsPerPage)
    },
    headers () {
      return this.$store.getters.headers
    },
    voucher () {
      return this.$store.getters.voucher
    },
    empresa () {
      return this.$store.getters.empresa
    }
  },
  mounted () {
    if (this.headers) {
      this.carregarCompras()
      // this.carregarFuncionarios()
      // this.porCodigoFrota()
    }
    this.today = new Date().setHours(0, 0, 0, 0)
  },
  methods: {
    selecionarCompra (compra) {
      this.$emit('compraSelecionada', compra)
    },
    compartilharCompra (compra) {
      this.$emit('compartilharCompra', compra)
    },
    carregarCompras (event) {
      if (event) {
        this.pagination.page = 1
      }

      var data = {
        page: this.pagination.page,
        empresa: this.empresa.cnpj
      }

      if (this.filtros.codigo) data.codigo__iexact = this.filtros.codigo
      if (this.filtros.combustivel) data.combustivel = this.filtros.combustivel
      if (this.filtros.funcionario) data.funcionario = this.filtros.funcionario
      if (this.filtros.disponivel) data.disponivel = this.filtros.disponivel

      this.$store.dispatch('pegarCompras', data).then(json => {
        this.compras = json.results
        this.totalItems = json.count
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
