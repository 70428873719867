import settings from '@/settings'

const atualizarImagemPerfil = (imagem, headers) => {
  return fetch(`${settings.apetrusApi}/pessoas/atualizarimagemperfil/`, {
    method: 'PUT',
    body: JSON.stringify(imagem),
    mode: 'cors',
    headers: headers
  })
}

const insert = (data, headers) => {
  return fetch(`${settings.apetrusApi}/pessoas/cadastrar/`, {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: headers
  })
}

const insertClient = (headers) => {
  return fetch(`${settings.apetrusApi}/clientes/cadastrar/`, {
    method: 'POST',
    mode: 'cors',
    headers: headers
  })
}

const atualizarEndereco = (data, headers) => {
  return fetch(`${settings.apetrusApi}/pessoas/atualizarendereco/`, {
    method: 'PUT',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: headers
  })
}

const atualizarTelefone = (data, headers) => {
  return fetch(`${settings.apetrusApi}/pessoas/atualizartelefone/`, {
    method: 'PUT',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: headers
  })
}

const atualizarUsuario = (data, headers) => {
  return fetch(`${settings.apetrusApi}/pessoas/atualizarusuario/`, {
    method: 'PUT',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: headers
  })
}

const pegarQuestionario = (data, headers) => {
  return fetch(`${settings.apetrusApi}/pessoas/questionario/`, {
    method: 'PUT',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: headers
  })
}

const enviarQuestionario = (data, headers) => {
  return fetch(`${settings.apetrusApi}/pessoas/questionario/`, {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: headers
  })
}

const porEmail = (email, headers) => {
  return fetch(`${settings.apetrusApi}/pessoas/poremail/?email=${email}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const inscreverEmTopico = (data, headers) => {
  return fetch(`${settings.apetrusApi}/pessoas/inscreveremtopico/`, {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: headers
  })
}

const removerTopico = (data, headers) => {
  return fetch(`${settings.apetrusApi}/pessoas/removertopico/`, {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: headers
  })
}

const buscarNotificacoes = (headers) => {
  return fetch(`${settings.apetrusApi}/pessoas/buscarnotificacoes/`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const inscreverNovoToken = (data, headers) => {
  return fetch(`${settings.apetrusApi}/pessoas/inscrevernovotoken/`, {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: headers
  })
}

const criarGrupoDeDispositivos = (data, headers) => {
  return fetch(`${settings.apetrusApi}/pessoas/criargrupodedispositivos/`, {
    method: 'PUT',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: headers
  })
}

const buscarDispositivosPessoa = (headers) => {
  return fetch(`${settings.apetrusApi}/pessoas/dispositivos/`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const excluirGrupo = (headers) => {
  return fetch(`${settings.apetrusApi}/pessoas/excluirgrupo/`, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const cadastrardispositivo = (headers, data) => {
  return fetch(`${settings.apetrusApi}/pessoas/cadastrardispositivo/`, {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: headers
  })
}

const auth = (headers) => {
  return fetch(`${settings.apetrusApi}/pessoas/`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const enviarEmailParaClientes = (data, headers) => {
  return fetch(`${settings.apetrusApi}/pessoas/emailforallusers/`, {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: headers
  })
}

const verificarPessoa = (cpf, headers) => {
  return fetch(`${settings.apetrusApi}/pessoas/verificarcpf/?cpf=${cpf}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

export default {
  atualizarImagemPerfil,
  insert,
  insertClient,
  atualizarEndereco,
  atualizarUsuario,
  porEmail,
  inscreverEmTopico,
  removerTopico,
  buscarNotificacoes,
  inscreverNovoToken,
  atualizarTelefone,
  criarGrupoDeDispositivos,
  excluirGrupo,
  buscarDispositivosPessoa,
  cadastrardispositivo,
  auth,
  pegarQuestionario,
  enviarQuestionario,
  enviarEmailParaClientes,
  verificarPessoa
}
