import settings from '@/settings'

const relatorioCompras = (filtros, cnpj, headers) => {
  const keys = Object.keys(filtros)
  let url = `${settings.apetrusApi}/relatorio/comprasempresa/?empresa=${cnpj}`
  for (let i = 0; i < keys.length; i++) {
    url = `${url}&${keys[i]}=${filtros[keys[i]]}`
  }
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const relatorioComprasPorPosto = (filtros, cnpj, posto, headers) => {
  const keys = Object.keys(filtros)
  let url = `${settings.apetrusApi}/relatorio/comprasempresaporposto/?empresa=${cnpj}`
  for (let i = 0; i < keys.length; i++) {
    url = `${url}&${keys[i]}=${filtros[keys[i]]}`
  }
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const relatorioVouchers = (filtros, cnpj, headers, pagination) => {
  const keys = Object.keys(filtros)
  let url = `${settings.apetrusApi}/relatorio/vouchersempresa/?page=${pagination.page}&empresa=${cnpj}`
  for (let i = 0; i < keys.length; i++) {
    url = `${url}&${keys[i]}=${filtros[keys[i]]}`
  }
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const relatorioVeiculos = (filtros, cnpj, headers) => {
  const keys = Object.keys(filtros)
  let url = `${settings.apetrusApi}/relatorio/vouchersempresaveiculo/?empresa=${cnpj}`
  for (let i = 0; i < keys.length; i++) {
    url = `${url}&${keys[i]}=${filtros[keys[i]]}`
  }
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const relatorioFuncionarios = (filtros, cnpj, headers) => {
  const keys = Object.keys(filtros)
  let url = `${settings.apetrusApi}/relatorio/vouchersempresafuncionario/?empresa=${cnpj}`
  for (let i = 0; i < keys.length; i++) {
    url = `${url}&${keys[i]}=${filtros[keys[i]]}`
  }
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

export default {
  relatorioCompras,
  relatorioComprasPorPosto,
  relatorioVouchers,
  relatorioVeiculos,
  relatorioFuncionarios
}
