import settings from '@/settings'

const get = () => {
  return fetch(`${settings.apetrusApi}/termosdeuso/empresa/`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default'
  })
}

const getTermoCliente = () => {
  return fetch(`${settings.apetrusApi}/termosdeuso/cliente/`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default'
  })
}

export default {
  get,
  getTermoCliente
}
