import settings from '@/settings'

const transacoes = (headers, data) => {
  let url = `${settings.apiPayment}/pagpixbank/transacoes/`
  const parametros = {
    method: data.method,
    mode: 'cors',
    headers: headers
  }
  if (data.method === 'GET') {
    if (data) {
      const keys = Object.keys(data)
      for (let i = 0; i < keys.length; i++) {
        url = `${url}${url[url.length - 1] === '/' ? '?' : '&'}${keys[i]}=${data[keys[i]]}`
      }
    }
    parametros.cache = 'default'
  } else {
    parametros.body = JSON.stringify(data)
  }

  return fetch(url, parametros)
}

export default {
  transacoes
}
