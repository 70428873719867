<template>
  <aviso-bloqueio v-if="empresa && (!empresa.ativo || empresa.tipo_empresa !== 2)"/>
  <v-layout align-space-around justify-start fill-height column class="pt-5" v-else>
    <v-toolbar
      color="transparent"
      class="elevation-0 secondary--text"
      style="background: transparent">
      <v-toolbar-title>Veículos</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" small fab color="info" @click="showFilter = !showFilter">
            <v-icon>{{ !showFilter ? 'filter_list' : 'filter_list_off' }}</v-icon>
          </v-btn>
        </template>
        <span>{{!showFilter ? 'Mostrar filtros' : 'Recolher filtros'}}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" small fab @click="dialogAdicionarVeiculo = true" color="info" class="ml-4">
            <v-icon>add</v-icon>
          </v-btn>
        </template>
        <span>
          Adicionar Veículo
        </span>
      </v-tooltip>
    </v-toolbar>
    <v-slide-x-transition>
      <v-card v-show="showFilter" class="ml-4 mr-4">
        <v-card-text>
          <v-layout wrap justify-space-between>
            <v-flex xs10 md11>
              <v-layout wrap justify-space-around>
                <v-flex xs11 sm5 md3 lg2 xl2>
                  <v-text-field label="Código" v-model="id_veiculo"></v-text-field>
                </v-flex>
                <v-flex xs11 sm5 md4 lg3 xl2>
                  <v-select
                    label="Tipo de Veículos"
                    :items="tipos_veiculo"
                    v-model="tipo_veiculo"
                    item-text="denominacao"
                    item-value="id_tipo_veiculo"
                    clearable
                  ></v-select>
                </v-flex>
                <v-flex xs11 sm5 md4 lg3 xl2>
                  <v-text-field label="Marca" v-model="marca"></v-text-field>
                </v-flex>
                <v-flex xs11 sm5 md4 lg3 xl2>
                  <v-text-field label="Modelo" v-model="modelo"></v-text-field>
                </v-flex>
                <v-flex xs11 sm5 md4 lg3 xl2>
                  <v-text-field label="Ano" v-model="ano"></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs2 md1 align-self-center>
              <v-btn small fab color="info" @click="carregarVeiculos(true)">
                <v-icon>search</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-slide-x-transition>
      <v-data-table
        hide-default-footer
        :headers="veiculosHeaders"
        :items="veiculos"
        :options.sync="pagination"
        no-data-text="Você ainda não possui veículo cadastrado"
        class="elevation-1"
      >
        <template v-slot:item="props">
          <tr>
            <td>
              <v-icon
                :color="formatarCor(props.item.cor)"
                >
                {{ getIcon(props.item.tipo_veiculo) }}
              </v-icon>
            </td>
            <td>{{ props.item.id_veiculo }}</td>
            <td>{{ props.item.marca }}</td>
            <td>{{ props.item.modelo }}</td>
            <td>{{ props.item.tipo_veiculo? props.item.tipo_veiculo.denominacao : undefined }}</td>
            <td class="text-xs-right">{{ props.item.cor_texto }}</td>
            <td class="text-xs-right">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    :color="props.item.aVenda ? 'success' : 'error'"
                    @click="colocarAvenda(props.item)"
                  >
                    <v-icon>{{ props.item.aVenda ? 'check_circle_outline' : 'highlight_off' }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ props.item.aVenda ? 'Clique para não colocar veículo a venda' : 'Clique para colocar veículo a venda'}}</span>
              </v-tooltip>
            </td>
            <td class="text-xs-right">
              <v-tooltip left>
                <template v-slot:activator="{ on2 }">
                  <v-menu offset-x v-on="on2">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon color="error" @click="() => {}">
                        <v-icon>close</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>Tem Ceteza Que Deseja Excluir esse Veículo?</v-card-title>
                      <v-card-actions class="justify-space-around">
                        <v-btn class="mb-2" rounded>Não</v-btn>
                        <v-btn class="mb-2" rounded color="error" @click="excluirVeiculo(props.item.id_veiculo)">Sim</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </template>
                <span>Excluir Veículo</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    color="success"
                    disabled
                    @click="
                      veiculoSelecionado = props.item,
                      cadFinanciamento = true"
                  >
                    <v-icon>monetization_on</v-icon>
                  </v-btn>
                </template>
                <span>Opções de Financiamento</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon color="info"
                    @click="
                      veiculoSelecionado = props.item,
                      dialogEditar = true"
                  >
                    <v-icon>edit</v-icon>
                  </v-btn>
                </template>
                <span>Editar Veículo</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    color="primary"
                    dark
                    @click="
                      veiculoSelecionado = props.item,
                      visualizar = true
                    "
                  >
                      <v-icon>visibility</v-icon>
                  </v-btn>
                </template>
                <span>Visualizar Dados do Veículo</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    <div class="text-xs-center">
      <v-pagination v-model="pagination.page" :length="pages" circle></v-pagination>
    </div>
    <v-dialog v-model="visualizar" v-if="visualizar" scrollable width="650" fullscreen>
      <visualizar-veiculo
        :veiculo="veiculoSelecionado"
        @fechar="visualizar = false, veiculoSelecionado = undefined"
        :mostrarBotao="false"
      />
    </v-dialog>
    <v-dialog
      v-if="dialogEditar"
      v-model="dialogEditar"
      scrollable
      :fullscreen="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
      width="800"
      persistent
    >
      <editar-veiculo
        :veiculo="veiculoSelecionado"
        :isVenda="true"
        @fechar="dialogEditar = false, veiculoSelecionado = undefined"
        @carregar="carregarVeiculos(false)"
      />
    </v-dialog>
    <v-dialog
      v-model="dialogAdicionarVeiculo"
      v-if="dialogAdicionarVeiculo"
      width="800"
      scrollable
      :fullscreen="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
      persistent
    >
      <adicionar-veiculo
        @fechar="dialogAdicionarVeiculo = false"
        :isVenda="true"
        @info="showInfo"
        @error="showError"
        @carregar="carregarVeiculos(false)"
      />
    </v-dialog>
    <v-dialog
      v-model="cadFinanciamento"
      v-if="cadFinanciamento"
      width="800"
      scrollable
      :fullscreen="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
    >
      <cad-financiamento
        @fechar="cadFinanciamento = false, veiculoSelecionado = undefined"
        :veiculo="veiculoSelecionado"
      />
    </v-dialog>
    <v-snackbar
      v-model="snackbar.active"
      :color="snackbar.color"
      :multi-line="snackbar.multiLine"
      :timeout="snackbar.timeout"
      :vertical="snackbar.vertical"
    >
      {{ snackbar.text }}
      <v-btn
        text
        @click="snackbar.active = false"
      >
        Fechar
      </v-btn>
    </v-snackbar>
  </v-layout>
</template>

<script>

import VisualizarVeiculo from '@/components/dialogs/VisualizarVeiculo'
import EditarVeiculo from '@/components/dialogs/DialogEditarVeiculo'
import AdicionarVeiculo from '@/components/dialogs/DialogAdicionarVeiculo'
import CadFinanciamento from '@/components/cadastros/CadFinanciamento'
import AvisoBloqueio from '@/components/AvisoBloqueio'

export default {
  components: { VisualizarVeiculo, EditarVeiculo, AdicionarVeiculo, AvisoBloqueio, CadFinanciamento },
  data: () => ({
    dialogAdicionarVeiculo: false,
    dialogEditar: false,
    cadFinanciamento: false,
    showFilter: false,
    snackbar: {
      active: false,
      color: '',
      multiLine: true,
      timeout: 4000,
      vertical: true,
      text: ''
    },
    pagination: {
      page: 1,
      rowsPerPage: 10
    },
    veiculosHeaders: [
      {
        align: 'right',
        sortable: false
      },
      {
        text: 'Código',
        align: 'left',
        sortable: false,
        value: 'id_veiculo'
      },
      {
        text: 'Marca',
        align: 'left',
        sortable: false,
        value: 'marca'
      },
      {
        text: 'Modelo',
        align: 'left',
        sortable: false,
        value: 'modelo'
      },
      {
        text: 'Tipo do Veículo',
        align: 'left',
        sortable: false,
        value: 'tipo_veiculo'
      },
      {
        text: 'Cor',
        align: 'left',
        sortable: false,
        value: 'cor_texto'
      },
      {
        text: 'A venda',
        align: 'left',
        sortable: false
      },
      {
        align: 'right',
        sortable: false
      }
    ],
    veiculos: [],
    modelo: undefined,
    marca: undefined,
    tipo_veiculo: undefined,
    id_veiculo: undefined,
    ano: undefined,
    totalItens: 0,
    visualizar: false,
    veiculoSelecionado: undefined,
    tipos_veiculo: []
  }),
  computed: {
    headers () {
      return this.$store.getters.headers
    },
    empresa () {
      return this.$store.getters.empresa
    },
    ready () {
      return !!this.headers && !!this.empresa
    },
    pages () {
      const count = this.totalItens
      if (this.pagination.rowsPerPage == null || count == null) return 0

      return Math.ceil(count / this.pagination.rowsPerPage)
    }
  },
  methods: {
    formatarCor (cor) {
      if (cor === null) return ''
      if (cor === undefined) return ''
      if (cor.length === 6) return `#${cor}`
      if (cor.length === 7) return cor
      if (cor.length === 9) return cor.slice(0, 7)
      else {
        let teste = parseInt(cor)
        teste = teste.toString(16)
        return `#${teste.slice(2, 8)}`
      }
    },
    getIcon (tipo) {
      if (tipo && typeof tipo === 'object') tipo = tipo.denominacao
      if (tipo === 'Passageiro' || tipo === 'Automóvel' || tipo === 'Outros') return 'airport_shuttle'
      else if (tipo === 'Caminhão') return 'local_shipping'
      else if (tipo === 'Caminhão trator') return 'agriculture'
      else if (tipo === 'Caminhonete') return 'agriculture'
      else if (tipo === 'Ciclomotor' || tipo === 'Motoneta' || tipo === 'Motocicleta' || tipo === 'Triciclo') return 'two_wheeler'
      else if (tipo === 'Micro-ônibus' || tipo === 'Ônibus') return 'directions_bus'
      return 'airport_shuttle'
    },
    carregarVeiculos (isBtn) {
      const data = { empresa: this.empresa.cnpj, naoOrdenar: true }
      data.page = isBtn ? 1 : this.pagination.page
      data.page_size = this.pagination.rowsPerPage
      if (this.tipo_veiculo) data.tipo_veiculo = this.tipo_veiculo
      if (this.modelo) data.modelo__icontains = this.modelo
      if (this.marca) data.marca__icontains = this.marca
      if (this.id_veiculo) data.id_veiculo = this.id_veiculo
      if (this.ano) data.ano_fabricacao = this.ano
      this.$store.dispatch('listarVeiculosAVenda', data).then(json => {
        this.veiculos = json.results
        this.totalItens = json.count
      })
    },
    colocarAvenda (veiculo) {
      if (!veiculo.valor && !veiculo.aVenda) {
        this.$store.dispatch('setError', 'Insira um preço ao veículo antes de colocá-lo a venda')
        return
      }
      this.$store.dispatch('colocarAvenda', veiculo.id_veiculo).then(() => {
        this.carregarVeiculos(false)
      })
    },
    pegarTipoVeiculo () {
      this.$store.dispatch('listarTipoVeiculo').then(json => {
        this.tipos_veiculo = json
      })
    },
    excluirVeiculo (id) {
      this.$store.dispatch('apagarVeiculo', id).then(() => {
        this.carregarVeiculos(false)
      })
    },
    showError (error) {
      this.snackbar.color = 'error'
      this.snackbar.text = error
      this.snackbar.active = true
    },
    showInfo (info) {
      this.snackbar.color = 'info'
      this.snackbar.text = info
      this.snackbar.active = true
    }
  },
  watch: {
    pagination: {
      handler (val, oldval) {
        if (this.empresa) this.carregarVeiculos(false)
      },
      deep: true
    },
    ready (val) {
      if (val) this.carregarVeiculos(false)
    },
    showFilter (val) {
      if (!val) {
        this.tipo_veiculo = undefined
        this.modelo = undefined
        this.marca = undefined
        this.id_veiculo = undefined
        this.ano = undefined
        this.carregarVeiculos(true)
      } else {
        this.pegarTipoVeiculo()
      }
    },
    empresa: {
      handler (val, oldval) {
        if (val) this.carregarVeiculos(false)
      },
      deep: true
    }
  }
}
</script>
