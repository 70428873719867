<template>
  <v-container pa-0 fill-height fluid>
    <v-layout align-space-around justify-start fill-height column>
      <v-form ref="form" lazy-validation>
        <v-container pt-0 fluid grid-list-md>
          <h3 class="headline font-weight-light orange--text mb-2">
            Dados pessoais
          </h3>
          <div class="font-weight-light title mb-2"></div>

          <v-layout wrap>
            <v-flex xs12 sm6 md4>
              <v-text-field
                label="Nome"
                required
                v-model="edicao.nome"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md4>
              <v-text-field
                label="Sobrenome"
                required
                v-model="edicao.sobrenome"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md4>
              <v-text-field
                label="CPF"
                required
                v-model="edicao.cpf"
                readonly
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md4>
              <v-text-field
                label="Data de nascimento"
                required
                v-model="edicao.data_nascimento_custom"
                mask="##/##/####"
                readonly
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md4>
              <v-text-field
                label="E-mail"
                required
                v-model="edicao.email"
                readonly
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md4>
              <v-text-field
                label="Telefone"
                required
                v-model="edicao.telefone"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <h3 class="headline font-weight-light orange--text mb-2">Endereço</h3>
          <div class="font-weight-light title mb-2"></div>

          <v-layout wrap>
            <v-flex xs12 sm6 md4>
              <v-text-field
                label="CEP"
                required
                v-model="edicao.cep"
                @keyup="searchCep"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md4>
              <v-text-field
                label="Logradouro"
                required
                v-model="edicao.logradouro"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md4>
              <v-text-field
                label="Número"
                required
                v-model="edicao.numero"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md4>
              <v-text-field
                label="Complemento"
                required
                v-model="edicao.complemento"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md4>
              <v-text-field
                label="Bairro"
                required
                v-model="edicao.bairro"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md4>
              <v-text-field
                label="Cidade"
                required
                v-model="edicao.cidade"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md4>
              <v-text-field
                label="Estado"
                required
                v-model="edicao.estado"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md4>
              <v-text-field
                label="País"
                required
                v-model="edicao.pais"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-card-actions>
            <v-spacer> </v-spacer>
            <v-btn text color="primary" @click="atualizarPerfil">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
      <v-dialog v-model="dialog" scrollable width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on" :disabled="pessoa.verificacao_cpf === 3">
            Verificar autenticidade
          </v-btn>
        </template>
        <DialogVerificarIdentidade v-if="pessoa.tentativas_quiz < 2" :edicao="edicao" @close="dialog = false"></DialogVerificarIdentidade>
        <v-card v-else>
          <v-card-title>
            Número de tentativas excedido
          </v-card-title>
          <v-card-text>
            Entre em contato com a Apetrus para verificarmos se podemos te ajudar: <a href="mailto:contato@apetrus.com.br">contato@apetrus.com.br</a>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import DialogVerificarIdentidade from '@/components/dialogs/DialogVerificarIdentidade'
export default {
  components: {
    DialogVerificarIdentidade
  },
  data () {
    return {
      data_nascimento: '16/04/2021',
      verificado: false,
      dialog: false,
      confirmacaoDados: false,
      edicao: {
        id_pessoa: '',
        nome: '',
        sobrenome: '',
        cpf: '',
        sexo: '',
        data_cadastro: '',
        data_nascimento: '',
        telefone: '',
        email: '',
        cep: '',
        logradouro: '',
        bairro: '',
        numero: '',
        complemento: '',
        cidade: '',
        estado: '',
        pais: '',
        url_imagem_perfil: ''
      }
    }
  },
  mounted () {
    if (this.pessoa) {
      this.edicao = {
        id_pessoa: this.pessoa.id_pessoa,
        nome: this.pessoa.nome,
        sobrenome: this.pessoa.sobrenome,
        cpf: this.pessoa.cpf,
        sexo: '',
        data_cadastro: this.pessoa.data_cadastro,
        data_nascimento: this.pessoa.data_nascimento,
        telefone: this.pessoa.telefone,
        email: this.pessoa.email,
        url_imagem_perfil: this.pessoa.url_imagem_perfil
      }
      if (this.pessoa.endereco) {
        this.edicao.cep = this.pessoa.endereco.cep
        this.edicao.logradouro = this.pessoa.endereco.logradouro
        this.edicao.bairro = this.pessoa.endereco.bairro
        this.edicao.numero = this.pessoa.endereco.numero
        this.edicao.complemento = this.pessoa.endereco.complemento
        this.edicao.cidade = this.pessoa.endereco.cidade
        this.edicao.estado = this.pessoa.endereco.estado
        this.edicao.pais = this.pessoa.endereco.pais
      }
      if (this.pessoa.data_nascimento) {
        const data = this.pessoa.data_nascimento
        const ano = data.slice(0, 4)
        const mes = data.slice(5, 7)
        const dia = data.slice(8, 10)
        this.edicao.data_nascimento_custom = `${dia}/${mes}/${ano}`
      }
    }
  },
  watch: {
    pessoa (val) {
      if (val !== null && val !== undefined) {
        this.edicao = {
          id_pessoa: val.id_pessoa,
          nome: val.nome,
          sobrenome: val.sobrenome,
          cpf: val.cpf,
          sexo: '',
          data_cadastro: val.data_cadastro,
          data_nascimento: val.data_nascimento,
          telefone: val.telefone,
          email: val.email,
          cep: val.endereco.cep,
          logradouro: val.endereco.logradouro,
          bairro: val.endereco.bairro,
          numero: val.endereco.numero,
          complemento: val.endereco.complemento,
          cidade: val.endereco.cidade,
          estado: val.endereco.estado,
          pais: val.endereco.pais,
          url_imagem_perfil: val.url_imagem_perfil
        }
      }
    }
  },
  computed: {
    user (val) {
      return this.$store.getters.user
    },
    headers () {
      return this.$store.getters.headers
    },
    pessoa () {
      return this.$store.getters.pessoa
    },
    ready () {
      return this.headers && this.cliente
    },
    fotoPerfil () {
      return this.$store.getters.fotoPerfil
    }
  },
  methods: {
    searchCep () {
      fetch(`https://viacep.com.br/ws/${this.edicao.cep}/json/`, {
        method: 'get',
        mode: 'cors',
        headers: {
          Accept: 'application/json'
        }
      })
        .then((res) => res.json())
        .then((json) => {
          this.edicao.logradouro = json.logradouro
          this.edicao.bairro = json.bairro
          this.edicao.cidade = json.localidade
          this.edicao.estado = json.uf
        })
    },
    atualizarPerfil () {
      this.$store.dispatch('atualizarUsuario', this.edicao).then(() => {
        this.confirmacaoDados = true
      })
    }
  }
}
</script>

<style>
</style>
