<template>
  <v-container fluid fill-height>
    <v-layout row wrap justify-center align-content-center fill-height>
      <v-flex xs12 md5 v-if="mode === 'verifyEmail'">
        <h1>Aguarde enquanto verificamos seu email</h1>
      </v-flex>
      <v-flex xs12 md5 v-else-if="mode === 'resetPassword'">
        <v-form>
          <v-layout column>
            <v-flex class="pb-4">
              Insira uma nova senha para:
              <v-chip v-if="user" color="primary" dark>{{ user.email }}</v-chip>
            </v-flex>
            <v-flex>
              <v-text-field
                v-model="password"
                :rules="passwordRules"
                box
                label="Nova Senha"
                type="password"
                required
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-text-field
                v-model="passwordRepeat"
                :rules="[comparePasswords]"
                box
                label="Repita a Senha"
                type="password"
                required
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-btn block text color="secondary" @click="resetarEmail">Confirmar</v-btn>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  props: ['mode', 'oobCode', 'lang', 'continueUrl'],
  data () {
    return {
      user: null,
      password: '',
      passwordRepeat: '',
      passwordRules: [v => !!v || 'Insira sua senha!'],
      dialogPassoword: false
    }
  },
  computed: {
    comparePasswords () {
      return this.password !== this.passwordRepeat
        ? 'As senhas não são idênticas!'
        : true
    }
  },
  mounted () {
    var auth = firebase.auth()
    switch (this.mode) {
      case 'resetPassword':
        // Display reset password handler and UI.
        this.handleResetPassword(
          auth,
          this.oobCode,
          this.continueUrl,
          this.lang
        )
        break
      case 'recoverEmail':
        // Display email recovery handler and UI.
        this.handleRecoverEmail(auth, this.oobCode, this.lang)
        break
      case 'verifyEmail':
        // Display email verification handler and UI.
        this.handleVerifyEmail(auth, this.oobCode, this.continueUrl, this.lang)
        break
      default:
        alert('Função inexistente!')
    }
  },
  methods: {
    resetarEmail () {
      this.$store.dispatch('resetPassword', { actionCode: this.oobCode, password: this.password, user: this.user }).then(() => {
        this.$router.push('/')
      })
    },
    handleResetPassword (auth, actionCode, continueUrl, lang) {
      auth
        .verifyPasswordResetCode(actionCode)
        .then(email => {
          this.user = {
            email: email
          }
        })
    },
    handleRecoverEmail (auth, actionCode, lang) {
      auth
        .checkActionCode(actionCode)
        .then(info => {
          this.user = {
            email: info.data.email
          }

          return auth.applyActionCode(actionCode)
        })
        .then(() => {
          if (confirm('Tem certeza que deseja recuperar seu email?')) {
            auth
              .sendPasswordResetEmail(this.user.email)
              .then(() => {
                alert('Concluído! Consulte seu email para continuar.')
              })
              .catch(error => {
                console.log(error)
              })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    handleVerifyEmail (auth, actionCode, continueUrl, lang) {
      auth
        .applyActionCode(actionCode)
        .then(resp => {
          this.$router.push('/')
        })
    }
  }
}
</script>
