<template>
  <aviso-bloqueio v-if="empresa && (!empresa.ativo || empresa.tipo_empresa !== 1)"/>
  <v-layout justify-center align-start row wrap class="pt-5" v-else>
    <v-flex xs12>
      <v-toolbar color="transparent" class="elevation-0 secondary--text">
        <v-spacer></v-spacer>
        <v-btn class="mr-4" small fab color="info"  @click="showFilter = !showFilter">
          <v-icon>{{ !showFilter ? 'filter_list' : 'filter_list_off' }}</v-icon>
        </v-btn>
        <v-dialog v-model="dialogCadastroFuncionario" width="400" persistent >
          <template v-slot:activator="{ on }">
            <v-chip class="ma-2" color="info" v-on="on">
              <v-icon left>person</v-icon>
              Cadastrar colaborador
            </v-chip>
          </template>
          <inserir-funcionario
            v-on:fechar="dialogCadastroFuncionario = false"
            v-on:info="showInfo('Colaborador cadastrado com sucesso!')"
            v-on:error="showError"
            @reload="reload"
          ></inserir-funcionario>
        </v-dialog>
      </v-toolbar>
      <v-slide-x-transition>
      <v-card v-show="showFilter" class="ml-4 mr-4 mb-5">
        <v-card-text>
          <v-layout wrap justify-space-between>
            <v-flex xs10 md11>
              <v-layout wrap justify-space-around>
                <v-flex xs12 sm6 md6 lg2 xl2>
                  <v-text-field label="Nome" v-model="filtros.nome_email"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6 lg2 xl2>
                  <v-text-field label="CPF" v-model="filtros.cpf" v-mask="'###.###.###-##'"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6 lg2 xl2>
                  <v-checkbox v-model="filtros.tem_veiculo" :label="`${filtros.tem_veiculo? 'Possuí veículo' : 'Não possuí veículo' } `"></v-checkbox>
                </v-flex>
                <v-flex xs12 sm6 md6 lg2 xl2>
                  <v-text-field label="Cidade" v-model="filtros.endereco__cidade"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6 lg2 xl2>
                  <v-select label="Estado" v-model="filtros.endereco__estado" :items="estados"></v-select>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 md1 align-self-center>
              <v-btn small fab color="info" @click="carregarFuncionarios($event)">
                <v-icon>search</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-slide-x-transition>
      <v-tabs v-model="tab" grow>
        <v-tab ripple class="font-weight-medium green--text text--darken-1"
          >Colaboradores</v-tab
        >
        <v-tab-item>
          <funcionarios-tables
            ref="meusDependentes"
            v-on:error="showError"
            v-on:info="showInfo"
            :filtros="filtros"
          ></funcionarios-tables>
        </v-tab-item>
      </v-tabs>
    </v-flex>
    <v-snackbar
      v-model="snackbar.active"
      :color="snackbar.color"
      :multi-line="snackbar.multiLine"
      :timeout="snackbar.timeout"
      :vertical="snackbar.vertical"
    >
      {{ snackbar.text }}
      <v-btn text @click="snackbar.active = false">Fechar</v-btn>
    </v-snackbar>
  </v-layout>
</template>

<script>
import FuncionariosTables from '@/components/tables/FuncionariosTables'
// import MinhasDependencias from '@/components/tables/MinhasDependencias'
import InserirFuncionario from '@/components/dialogs/DialogConvidarFuncionario'
import AvisoBloqueio from '@/components/AvisoBloqueio'

export default {
  components: {
    FuncionariosTables,
    InserirFuncionario,
    AvisoBloqueio
  },
  data () {
    return {
      estados: [
        { value: null, text: 'Selecione um estado' },
        { value: 'AC', text: 'Acre' },
        { value: 'AL', text: 'Alagoas' },
        { value: 'AP', text: 'Amapá' },
        { value: 'AM', text: 'Amazonas' },
        { value: 'BA', text: 'Bahia' },
        { value: 'CE', text: 'Ceará' },
        { value: 'DF', text: 'Distrito Federal' },
        { value: 'ES', text: 'Espírito Santo' },
        { value: 'GO', text: 'Goiás' },
        { value: 'MA', text: 'Maranhão' },
        { value: 'MT', text: 'Mato Grosso' },
        { value: 'MS', text: 'Mato Grosso do Sul' },
        { value: 'MG', text: 'Minas Gerais' },
        { value: 'PA', text: 'Pará' },
        { value: 'PB', text: 'Paraíba' },
        { value: 'PR', text: 'Paraná' },
        { value: 'PE', text: 'Pernambuco' },
        { value: 'PI', text: 'Piauí' },
        { value: 'RJ', text: 'Rio de Janeiro' },
        { value: 'RN', text: 'Rio Grande do Norte' },
        { value: 'RS', text: 'Rio Grande do Sul' },
        { value: 'RO', text: 'Rondônia' },
        { value: 'RR', text: 'Roraima' },
        { value: 'SC', text: 'Santa Catarina' },
        { value: 'SP', text: 'São Paulo' },
        { value: 'SE', text: 'Sergipe' },
        { value: 'TO', text: 'Tocantins' }
      ],
      showFilter: false,
      snackbar: {
        active: false,
        color: '',
        multiLine: true,
        timeout: 4000,
        vertical: true,
        text: ''
      },
      tab: null,
      dialogCadastroFuncionario: false,
      filtros: {
        nome_email: '',
        cpf: '',
        tem_veiculo: false,
        endereco__cidade: '',
        endereco__estado: ''
      }
    }
  },
  computed: {
    empresa () {
      return this.$store.getters.empresa
    }
  },
  watch: {
    '$route' () {
      this.this.showInfo()
    }
  },
  methods: {
    carregarFuncionarios (event) {
      this.$refs.meusDependentes.carregarFuncionarios(event)
    },
    showError (error) {
      this.snackbar.color = 'error'
      this.snackbar.text = error
      this.snackbar.active = true
    },
    showInfo (info) {
      this.snackbar.color = 'info'
      this.snackbar.text = info
      this.snackbar.active = true
      // Reload paginator
      this.reload()
    },
    reload () {
      this.$refs.meusDependentes.carregarFuncionarios()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
