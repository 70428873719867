<template>
  <aviso-bloqueio v-if="empresa && (!empresa.ativo || empresa.tipo_empresa !== 1)"/>
  <v-layout align-space-around justify-start fill-height column class="pt-5" v-else>
    <v-toolbar
      color="transparent"
      class="elevation-0 secondary--text"
      style="background: transparent"
    >
      <v-spacer></v-spacer>
      <v-toolbar color="transparent" class="elevation-0 secondary--text">
        <v-spacer></v-spacer>
        <v-btn class="mr-4" small fab color="info" @click="showFilter = !showFilter">
          <v-icon>{{ !showFilter ? 'filter_list' : 'filter_list_off' }}</v-icon>
        </v-btn>
      </v-toolbar>
    </v-toolbar>
    <v-slide-x-transition>
      <v-card v-show="showFilter" class="ml-4 mr-4 mb-5">
        <v-card-text>
          <v-layout wrap justify-space-between>
            <v-flex xs10 md11>
              <v-layout wrap justify-space-around>
                <v-flex xs11 sm5 md3 lg2 xl2>
                  <v-text-field label="Código de identificação" v-model="filtros.codigo" type="text"></v-text-field>
                </v-flex>
                <v-flex xs11 sm5 md4 lg3 xl2>
                  <v-select
                    v-model="filtros.combustivel"
                    :items="combustiveis"
                    label="Combustível"
                    item-text="nome"
                    item-value="id_combustivel"
                    clearable
                  ></v-select>
                </v-flex>
                <v-flex xs11 sm5 md4 lg3 xl2>
                  <v-autocomplete
                    label="Funcionários"
                    :items="funcionarios"
                    item-text="pessoa.nome_completo"
                    item-value="id_funcionario"
                    v-model="filtros.funcionario"
                    clearable
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs11 sm5 md4 lg3 xl2>
                  <v-select
                    v-model="filtros.disponivel"
                    :items="[{value:'true', text:'Ativo'},{value:'false', text:'Usado'}]"
                    label="Situação"
                    item-text="text"
                    item-value="value"
                    clearable
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs2 md1 align-self-center>
              <v-btn small fab color="info" @click="filtrarCompras($event)">
                <v-icon>search</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-slide-x-transition>
    <v-flex md5 sm12>
      <meus-vouchers :filtros="filtros" ref="meusVouchers" v-on:compraSelecionada="selecionarCompra" v-on:compartilharCompra="selectCompraCompartilhada"></meus-vouchers>
    </v-flex>
    <detalhes-voucher :visible="visible" :voucher="compraSelecionada" v-on:fechar="compraSelecionada = null"></detalhes-voucher>
    <v-dialog v-model="dependentesVisible" v-if="dependentesVisible" width="350">
      <funcionario-select :voucher="compraCompartilhada" v-on:funcionarioSelected="compartilharCompra"></funcionario-select>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.active"
      :color="snackbar.color"
      :multi-line="snackbar.multiLine"
      :timeout="snackbar.timeout"
      :vertical="snackbar.vertical"
    >
      {{ snackbar.text }}
      <v-btn
        text
        @click="snackbar.active = false"
      >
        Fechar
      </v-btn>
    </v-snackbar>
  </v-layout>
</template>

<script>
import meusVouchers from '@/components/tables/MeusVouchers'
// import vouchersCompartilhados from '@/components/tables/VouchersCompartilhados'
import detalhesVoucher from '@/components/dialogs/DialogDetalhesVoucher'
import funcionarioSelect from '@/components/dialogs/FuncionarioSelect'
import { voucherDao, funcionariosDao } from '@/api'
import AvisoBloqueio from '@/components/AvisoBloqueio'

export default {
  components: { meusVouchers, detalhesVoucher, funcionarioSelect, AvisoBloqueio },
  data () {
    return {
      showFilter: false,
      pagination: {
        page: 1,
        rowsPerPage: 99
      },
      funcionarios: [],
      filtros: {
        codigo: '',
        combustivel: '',
        funcionario: '',
        disponivel: false
      },
      snackbar: {
        active: false,
        color: '',
        multiLine: true,
        timeout: 4000,
        vertical: true,
        text: ''
      },
      dependentesVisible: false,
      compraSelecionada: null,
      compraCompartilhada: null
    }
  },
  computed: {
    headers () {
      return this.$store.getters.headers
    },
    visible () {
      return this.compraSelecionada !== null
    },
    empresa () {
      return this.$store.getters.empresa
    },
    combustiveis () {
      return this.$store.getters.combustiveis
    }
  },
  methods: {
    carregarFuncionarios () {
      var data = {
        pagination: this.pagination,
        empresa: this.empresa.cnpj
      }
      funcionariosDao.getFuncionariosEmpresaConfirmados(data, this.headers)
        .then(res => res.json())
        .then(json => {
          this.funcionarios = json.results
        })
    },
    filtrarCompras (event) {
      this.$refs.meusVouchers.carregarCompras(event)
    },
    selecionarCompra (compra) {
      this.compraSelecionada = compra
    },
    compartilharCompra (data) {
      this.dependentesVisible = false
      voucherDao.compartilharVoucher(data, this.headers)
        .then(res => {
          if (res.ok) {
            this.snackbar.text = 'Voucher compartilhado com sucesso'
            this.snackbar.color = 'info'
            this.snackbar.active = true
            this.$refs.meusVouchers.carregarCompras()
          } else {
            this.snackbar.text = 'O voucher não pôde ser compartilhado'
            this.snackbar.color = 'error'
            this.snackbar.active = true
          }
        })
    },
    selectCompraCompartilhada (compra) {
      this.compraCompartilhada = compra
      this.dependentesVisible = true
    },
    showError (error) {
      this.snackbar.color = 'error'
      this.snackbar.text = error
      this.snackbar.active = true
      this.carregarCompras()
    },
    showInfo (info) {
      this.snackbar.color = 'info'
      this.snackbar.text = info
      this.snackbar.active = true
      this.carregarCompras()
    }
  },
  watch: {
    headers (val) {
      if (val) this.$store.dispatch('carregarCombustiveis')
    },
    empresa (val) {
      if (val) this.carregarFuncionarios()
    }
  },
  beforeMount () {
    if (this.headers) {
      this.$store.dispatch('carregarCombustiveis')
    }
    if (this.empresa) {
      this.carregarFuncionarios()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
