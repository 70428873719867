const getCoordenadas = (rua, numero, bairro, cidade, estado) => {
  const enderecoUri = encodeURIComponent(
    `${rua}, ${numero}, ${bairro}, ${cidade}, ${estado}`
  )
  return fetch('https://nominatim.openstreetmap.org/search?q=' + enderecoUri + '&format=json&limit=1', {
    method: 'get',
    mode: 'cors',
    cache: 'default',
    headers: {
      Accept: 'application/json'
    }
  })
}

const getCoordenadasMapBox = (rua, numero, bairro, cidade) => {
  return fetch('https://api.mapbox.com/geocoding/v5/mapbox.places/' + rua + '%2C%20' + numero + '%2C%20' + bairro + '%2C%20' + cidade + '.json?access_token=pk.eyJ1IjoiYXBldHJ1cyIsImEiOiJjanJnamNiczAxN20zM3lwdTNwZTFlaXJmIn0.DySij7vvV7IiJdNwUG5Zqw', {
    method: 'get',
    mode: 'cors',
    cache: 'default',
    headers: {
      Accept: 'application/json'
    }
  })
}

const getCoordenadasGoogle = (rua, numero, bairro, cidade, estado) => {
  const enderecoUri = encodeURIComponent(
    `${rua}, ${numero} - ${bairro}, ${cidade}, ${estado}, Brasil`
  )
  return fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${enderecoUri}&key=AIzaSyBfM3pNkLaNBfVkKjTbhM-dsly9ZGYn2io`, {
    method: 'get',
    mode: 'cors',
    cache: 'default',
    headers: {
      Accept: 'application/json'
    }
  })
}

export default {
  getCoordenadas,
  getCoordenadasMapBox,
  getCoordenadasGoogle
}
