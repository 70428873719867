import settings from '@/settings'

const meusClientes = (headers, filtros) => {
  let url = `${settings.apetrusApi}/clientes/inscritos-em-vendedor/`
  if (filtros) {
    const keys = Object.keys(filtros)
    for (let i = 0; i < keys.length; i++) {
      url = `${url}${url[url.length - 1] === '/' ? '?' : '&'}${keys[i]}=${filtros[keys[i]]}`
    }
  }
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

export default {
  meusClientes
}
