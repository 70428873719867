<template>
    <v-card text>
        <v-card-title class="text-xs-center">
            Insira seu email para que possamos enviar um link para refazer sua senha
        </v-card-title>
        <v-card-text>
            <v-text-field
                v-model="email"
                box
                hide-details
                label="E-mail"
            ></v-text-field>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                text
                color="secondary"
                @click="sendResetMail"
                :loading="loading"
            >
                Confirmar
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
  data () {
    return {
      step: 1,
      email: null
    }
  },
  computed: {
    loading () {
      return this.$store.getters.loading
    }
  },
  methods: {
    sendResetMail () {
      this.$store.dispatch('sendResetEmail', this.email).then(() => {
        this.email = null
        this.$emit('sucesso')
      }).catch(() => {
        this.email = null
        this.$emit('fechar')
      })
    }
  }
}
</script>
