<template>
    <v-card>
        <v-card-title class="justify-center">
          <h3>
            {{ step === 1 ? 'Confirme o telefone usado em seu cadastro' : 'Um SMS foi enviado ao seu dispositivo' }}
          </h3>
        </v-card-title>
        <v-card-text>
          <v-stepper v-model="step" class="elevation-0">
              <v-stepper-items>
                  <v-stepper-content step="1" class="pa-0">
                    <h4 class="subheading grey--text text--darken-2 font-weight-medium mt-3 text-center">
                        Enviaremos um SMS logo em seguida
                    </h4>
                      <v-container grid-list-lg class="pt-0 pb-0">
                        <v-form v-model="valid" ref="form" lazy-validation>
                          <v-layout row wrap>
                            <v-flex xs12 class="pb-0 mb-3">
                                <v-text-field
                                    v-model="telefone"
                                    outline
                                    clearable
                                    single-line
                                    class="centered-input headline font-weight-medium"
                                    v-mask="'(##) #####-####'"
                                    required
                                    :rules="[
                                      t => (
                                        !t ||
                                        t.length < 11 ||
                                        pessoa.telefone.endsWith(t.replace(/\D/g, ''))
                                      ) || 'O número inserido não é igual ao usado em seu cadastro'
                                    ]"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 id="captcha-container" class="pa-0"></v-flex>
                          </v-layout>
                        </v-form>
                      </v-container>
                  </v-stepper-content>
                  <v-stepper-content step="2" class="pa-0">
                    <h4 class="subheading grey--text text--darken-2 font-weight-medium mt-3 text-center">
                      Confirme-o para concluir
                    </h4>
                      <v-container grid-list-lg class="pt-0">
                          <v-layout row wrap>
                              <v-flex xs12>
                                  <v-text-field
                                      v-model="codigo"
                                      outline
                                      clearable
                                      single-line
                                      class="centered-input headline font-weight-medium"
                                      v-mask="'#########'"
                                      hide-details
                                  ></v-text-field>
                              </v-flex>
                          </v-layout>
                      </v-container>
                  </v-stepper-content>
              </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-card-actions>
            <v-btn text color="grey darken-2" @click="voltarTela()">
                CANCELAR
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn :disabled="continuarDesativado" text color="green" @click="continuarTela()" :loading="loading">
                CONTINUAR
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
  data () {
    return {
      step: 1,
      telefone: null,
      codigo: null,
      valid: false
    }
  },
  computed: {
    pessoa () {
      return this.$store.getters.pessoa
    },
    loading () {
      return this.$store.getters.loading
    },
    continuarDesativado () {
      switch (this.step) {
        case 1:
          if (!this.telefone) return true
          if (this.pessoa.telefone.startsWith('+55')) {
            return this.telefone.replace(/\D/g, '') !== this.pessoa.telefone.substr(3, 11)
          } else {
            return this.telefone.replace(/\D/g, '') !== this.pessoa.telefone
          }
      }
      return false
    }
  },
  methods: {
    fecharTela () {
      this.step = 1
      this.codigo = null
      this.telefone = null
      this.$emit('fechar')
    },
    voltarTela () {
      this.fecharTela()
    },
    continuarTela () {
      switch (this.step) {
        case 1:
          this.$store.dispatch('sendSmsVerification', 'captcha-container').then(() => {
            this.step += 1
          }).catch((error) => {
            if (error.code === 'auth/too-many-requests') {
              this.$emit('error', 'Desculpe! Você está bloqueado por excesso de tentativas. Tente novamente mais tarde.')
            } else if (error.code === 'auth/invalid-phone-number') {
              this.$emit('error', 'Desculpe! O número de telefone em seu cadastro foi registrado em um formato inválido.')
            } else if (error.code === 'auth/missing-phone-number') {
              this.$emit('error', 'Desculpe! Você não tem um número registrado em seu cadastro.')
            } else if (error.code === 'auth/unverified-email') {
              this.$emit('error', 'Desculpe! Você precisa fazer a verificação do seu e-mail antes de continuar.')
            } else if (error.code === 'auth/user-disabled') {
              this.$emit('error', 'Desculpe! Você seu usuário foi desativado.')
            } else this.$emit('error', 'Desculpe, não foi possível enviar o seu código, tente novamente mais tarde.')
            this.fecharTela()
          })
          break
        case 2:
          this.$store.dispatch('confirmSmsVerification', this.codigo).then(() => {
            this.$emit('callDialog')
            this.fecharTela()
          }).catch((error) => {
            if (error.code === 'auth/user-mismatch') {
              this.$emit('error', 'Desculpe! A credencial fornecida não corresponder ao usuário.')
            } else if (error.code === 'auth/user-not-found') {
              this.$emit('error', 'Desculpe! A credencial fornecida não corresponder a nenhum usuário existente.')
            } else if (error.code === 'auth/invalid-credential') {
              this.$emit('error', 'Desculpe! A credencial do provedor não está mais válida. Atualize a página e tente novamente.')
            } else if (error.code === 'auth/invalid-verification-code') {
              this.$emit('error', 'Desculpe! Código inválido, tente novamente.')
            } else this.$emit('error', 'Desculpe, mas não foi possível confirmar seu código, por favor, tente novamente.')
            this.fecharTela()
          })
      }
    }
  }
}
</script>
