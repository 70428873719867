var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.veiculo)?_c('v-card',{staticClass:"mx-auto rounded-lg",attrs:{"width":"20em","height":_vm.leilao ? '27em' : '20em',"hover":""},on:{"click":function($event){return _vm.$emit('visualizar', _vm.veiculo)}}},[(_vm.veiculo.fotos && _vm.veiculo.fotos.length > 0)?_c('v-img',{attrs:{"height":"10em","src":_vm.veiculo.fotos[0].url}}):_c('v-layout',{staticStyle:{"height":"10em"},attrs:{"justify-center":"","align-center":"","fill-height":""}},[_c('v-icon',{attrs:{"color":"gray","size":"8em"}},[_vm._v(_vm._s(_vm.getIcon(_vm.veiculo.tipo_veiculo)))])],1),_c('v-card-title',{attrs:{"primary-title":""}},[_c('v-layout',{staticStyle:{"font-size":"0.75em"},attrs:{"wrap":""}},[(_vm.veiculo.valor && _vm.veiculo.valor != '0.00' && !_vm.leilao)?_c('div',{staticClass:"mb-2",staticStyle:{"font-size":"1.4em"}},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.veiculo.valor,{ fractionCount: 2 }))+" ")]):_vm._e(),(_vm.veiculo.modelo && _vm.veiculo.marca)?_c('div',{style:({
          'width': '100%',
          'font-size': _vm.leilao ? '1.4em' : '1.1em'
        })},[_vm._v(" "+_vm._s(_vm.veiculo.modelo.split(' ')[0])+"/"+_vm._s(_vm.veiculo.marca)+" ")]):_c('div',{staticStyle:{"width":"100%","font-size":"1.1em"}},[_vm._v(" "+_vm._s(_vm.veiculo.modelo_marca)+" ")]),(_vm.leilao)?[_c('h5',{staticStyle:{"text-align":"center","width":"100%"}},[_vm._v(_vm._s(_vm.leilao.ultimo_lance ? 'Último Lance' : 'Valor Inicial'))]),(_vm.leilao.ultimo_lance)?_c('h2',{staticStyle:{"text-align":"center","width":"100%","height":"1.6em"}},[_vm._v(_vm._s(_vm._f("currency")(_vm.leilao.ultimo_lance.valor,{ fractionCount: 2 })))]):_c('h2',{staticStyle:{"text-align":"center","width":"100%","height":"1.6em"}},[_vm._v(_vm._s(_vm._f("currency")(_vm.leilao.valor_inicial,{ fractionCount: 2 })))]),_c('h2',{staticStyle:{"text-align":"center","width":"100%","font-size":"1.9em"}},[_c('i',{staticClass:"material-icons"},[_vm._v("timer")]),_vm._v(_vm._s(_vm.tempo_restante))]),(_vm.leilao.data_inicio)?_c('h4',{staticStyle:{"text-align":"center","width":"100%"}},[_c('i',[_vm._v("Início: "+_vm._s(_vm._f("formatDate")(_vm.leilao.data_inicio,'DD/MM/YYYY')))]),_vm._v(" "),_c('i',[_vm._v("Final: "+_vm._s(_vm._f("formatDate")(_vm.leilao.data_fim,'DD/MM/YYYY')))])]):_vm._e()]:_vm._e()],2)],1),_c('v-footer',{staticStyle:{"height":"4em"},attrs:{"absolute":"","color":"transparent"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [(_vm.veiculo.endereco)?_c('div',_vm._g({staticStyle:{"justify-content":"center","align-items":"center","display":"flex","font-size":"0.8em"}},on),[_c('v-icon',{staticClass:"mr-1"},[_vm._v("location_on")]),_vm._v(" "+_vm._s(_vm.veiculo.endereco)+" ")],1):_vm._e()]}}],null,false,1903995492)},[_c('span',[_vm._v("Localização")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [(_vm.veiculo.data_avenda)?_c('div',_vm._g({staticStyle:{"justify-content":"center","align-items":"center","display":"flex","font-size":"0.8em"}},on),[_c('v-icon',[_vm._v("calendar_month")]),_vm._v(_vm._s(_vm._f("formatDate")(_vm.veiculo.data_avenda,'DD/MM/YYYY'))+" ")],1):_vm._e()]}}],null,false,835958646)},[_c('span',[_vm._v("Data de Publicação")])])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }