import settings from '@/settings'

const getList = (headers, filtros) => {
  // É obrigatório enviar o cnpj da empresa
  let url = `${settings.apetrusApi}/solicitacoes/`
  if (filtros) {
    const keys = Object.keys(filtros)
    for (let i = 0; i < keys.length; i++) {
      url = `${url}${url[url.length - 1] === '/' ? '?' : '&'}${keys[i]}=${filtros[keys[i]]}`
    }
  }

  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const multipleMethods = (headers, payload) => {
  // Cadastra, deleta e pegar solicitações
  let url = `${settings.apetrusApi}/solicitacoes/${payload.id}/`

  const data = {
    method: payload.method || 'GET',
    mode: 'cors',
    headers: headers
  }

  if (payload.method === 'GET') {
    data.cache = 'default'
    if (payload) {
      const keys = Object.keys(payload)
      for (let i = 0; i < keys.length; i++) {
        url = `${url}${url[url.length - 1] === '/' ? '?' : '&'}${keys[i]}=${payload[keys[i]]}`
      }
    }
  } else data.body = JSON.stringify(payload)

  return fetch(url, data)
}

export default {
  getList,
  multipleMethods
}
