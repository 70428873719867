<template>
  <aviso-bloqueio v-if="empresa && (!empresa.ativo || empresa.tipo_empresa !== 1)"/>
  <v-layout align-space-around justify-start fill-height column class="pt-5" v-else>
    <v-toolbar color="transparent" class="elevation-0 secondary--text">
      <v-spacer></v-spacer>
      <v-btn class="mr-4" small fab color="info"  @click="showFilter = !showFilter">
        <v-icon>{{ !showFilter ? 'filter_list' : 'filter_list_off' }}</v-icon>
      </v-btn>
    </v-toolbar>
    <v-toolbar
      color="transparent"
      class="elevation-0 secondary--text"
      style="background: transparent"
    >
      <v-toolbar-title>Permissões Concedidas</v-toolbar-title>

    </v-toolbar>
    <v-slide-x-transition>
      <v-card v-show="showFilter" class="ml-4 mr-4 mb-4">
        <v-card-text>
          <v-layout wrap justify-space-between>
            <v-flex xs10 md11>
              <v-layout wrap justify-space-around>
                <v-flex xs6 sm5 md3 lg5 xl2>
                  <v-text-field label="Nome" v-model="filtros.funcionario__nome_email"></v-text-field>
                </v-flex>
                <v-flex xs6 sm5 md4 lg5 xl2>
                  <v-select
                    v-model="filtros.aberto"
                    :items="[{value:'true', text:'Aberto'},{value:'false', text:'Fechado'}]"
                    label="Disponibilidade de crédito"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs2 md1 align-self-center>
              <v-btn small fab color="info" @click="carregarCreditos($event)">
                <v-icon>search</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-slide-x-transition>
    <v-data-table
      hide-default-footer
      :headers="funcionariosHeaders"
      :items="creditos.results"
      :options.sync="pagination"
      no-data-text="Você ainda não possui compras"
      class="elevation-1"
    >
      <template v-slot:item="props">
        <tr>
          <td>
            <v-icon color="info">
              {{ props.item.funcionario ? 'person ' : 'directions_car'  }}
            </v-icon>
          </td>
          <td>
            {{ props.item.funcionario ? props.item.funcionario.nome_email : undefined  }}
            {{ props.item.veiculo ? props.item.veiculo : undefined }}
          </td>
          <td v-if="props.item.proposta.posto != null">{{ props.item.proposta.posto.nome_fantasia }}</td>
          <td v-else-if="props.item.proposta.rede">{{ props.item.proposta.rede.nome }}</td>
          <td v-else>Apetrus</td>
          <td v-if="props.item.aberto==true">
            <v-tooltip bottom>
               <template v-slot:activator="{ on }">
                  <v-icon color="teal" dark v-on="on">money</v-icon>
                  </template>
               <span>Permissão para usar saldo da empresa</span>
            </v-tooltip>
          </td>
          <td v-else class="green--text">
            ${{ props.item.valor }}
          </td>
          <td v-if="props.item.valor > 0 && props.item.valor_usado > 0" class="green--text">
            ${{ props.item.valor_usado }}
          </td>
           <td v-else class="green--text">
            $ 0.0
          </td>
          <td class="text-xs-right">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon color="primary"  :disabled="props.item.aberto" @click="reduzirCredito(props.item)">
                    <v-icon>remove</v-icon>
                  </v-btn>
                </template>
                <span>Reduzir crédito</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon color="green" :disabled="props.item.aberto" @click="aumentarCredito(props.item)">
                    <v-icon>add</v-icon>
                  </v-btn>
                </template>
                <span>Adicionar mais crédito</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon color="red"  @click="retirarPermissao(props.item)">
                    <v-icon>cancel</v-icon>
                  </v-btn>
                </template>
                <span>Retirar Permissão</span>
              </v-tooltip>
            </td>
        </tr>
      </template>
    </v-data-table>
    <div class="text-xs-center">
      <v-pagination
        v-model="pagination.page"
        :length="pages"
        circle
      ></v-pagination>
    </div>
    <v-snackbar
      v-model="snackbar.active"
      :color="snackbar.color"
      :multi-line="snackbar.multiLine"
      :timeout="snackbar.timeout"
      :vertical="snackbar.vertical"
    >
      {{ snackbar.text }}
      <v-btn text @click="snackbar.active = false"> Fechar </v-btn>
    </v-snackbar>
    <v-dialog v-if="dialogPermissao" v-model="dialogPermissao" width="400">
      <dialog-retirar-permissao @fechar="dialogPermissao = false" @carregar="carregarCreditos()" :credito="creditoSelecionado"/>
    </v-dialog>
    <v-dialog v-if="dialogAumentar" v-model="dialogAumentar" width="400">
      <dialog-aumentar-credito @fechar="dialogAumentar = false" @carregar="carregarCreditos()" :credito="creditoSelecionado"/>
    </v-dialog>
    <v-dialog v-if="dialogReduzir" v-model="dialogReduzir" width="400">
      <dialog-reduzir-credito @fechar="dialogReduzir = false" @carregar="carregarCreditos()" :credito="creditoSelecionado"/>
    </v-dialog>
  </v-layout>
</template>

<script>
import DialogRetirarPermissao from '../../components/dialogs/DialogRetirarPermissao.vue'
import DialogAumentarCredito from '../../components/dialogs/DialogAumentarCredito.vue'
import DialogReduzirCredito from '../../components/dialogs/DialogReduzirCredito.vue'
import AvisoBloqueio from '@/components/AvisoBloqueio'

export default {
  components: { DialogRetirarPermissao, DialogAumentarCredito, DialogReduzirCredito, AvisoBloqueio },
  data () {
    return {
      showFilter: false,
      filtros: {
        // aberto: '',
        // funcionario__nome_email: ''
      },
      dialogPermissao: false,
      dialogAumentar: false,
      dialogReduzir: false,
      creditoSelecionado: null,
      snackbar: {
        active: false,
        color: '',
        multiLine: true,
        timeout: 4000,
        vertical: true,
        text: ''
      },
      pagination: {
        page: 1,
        rowsPerPage: 5
      },
      funcionariosHeaders: [
        {
          text: '',
          align: 'left',
          sortable: false,
          value: undefined
        },
        {
          text: 'Vinculado a',
          align: 'left',
          sortable: false,
          value: undefined
        },
        {
          text: 'Proposta em:',
          align: 'left',
          sortable: false,
          value: 'credito.propostas'
        },
        {
          text: 'Valor total disponibilizado',
          value: 'aberto',
          sortable: false,
          align: 'left'
        },
        {
          text: 'Valor total usado',
          value: 'valor_usado',
          sortable: false,
          align: 'left'
        }
      ]
    }
  },
  watch: {
    pagination: {
      handler (val, oldval) {
        if (this.empresa) this.carregarCreditos()
      },
      deep: true
    },
    empresa (val, oldval) {
      if (val !== null && val !== undefined && val !== oldval) {
        // this.carregarFuncionarios()
        this.carregarCreditos()
      }
    }
  },
  mounted () {
    if (this.empresa) this.carregarCreditos()
  },
  computed: {
    pages () {
      if (this.pagination.rowsPerPage == null || this.totalItems == null) {
        return 0
      }
      return Math.ceil(this.totalItems / this.pagination.rowsPerPage)
    },
    funcionarios () {
      return this.$store.getters.funcionarios
    },
    headers () {
      return this.$store.getters.headers
    },
    totalItems () {
      return this.$store.getters.totalItemsPage
    },
    empresa () {
      return this.$store.getters.empresa
    },
    creditos () {
      return this.$store.getters.creditos
    }
  },
  methods: {
    retirarPermissao (credito) {
      this.creditoSelecionado = credito
      this.dialogPermissao = true
    },
    aumentarCredito (credito) {
      this.creditoSelecionado = credito
      this.dialogAumentar = true
    },
    reduzirCredito (credito) {
      this.creditoSelecionado = credito
      this.dialogReduzir = true
    },
    carregarFuncionarios () {
      var data = {
        page: this.pagination.page,
        empresa: this.empresa.cnpj
      }

      this.$store.dispatch('carregarFuncionarios', data)
    },
    carregarCreditos (event) {
      if (event) {
        this.pagination.page = 1
      }

      var data = {
        page: this.pagination.page,
        empresa: this.empresa.cnpj
      }

      if (this.filtros.aberto) data.aberto = this.filtros.aberto
      if (this.filtros.funcionario__nome_email) data.funcionario__nome_email__icontains = this.filtros.funcionario__nome_email

      this.$store.dispatch('carregarCreditos', data)
    },
    recarregarPagina () {
      document.location.reload(true)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
