import settings from '@/settings'

const gerenciar = (headers, data) => {
  // métodos suportados POST, GET, PUT, PATCH, DELETE
  let url = `${settings.apetrusApi}/provedorsmtp/${data.id ? `${data.id}/` : ''}`

  const request = {
    method: data.method,
    mode: 'cors',
    headers: headers
  }

  if (data.parametros) {
    Object.keys(data.parametros).forEach((filter) => {
      url = `${url}${url[url.length - 1] === '/' ? '?' : '&'}${filter}=${data.parametros[filter]}`
    })
  }

  if (data.method === 'GET') {
    request.cache = 'default'
  } else request.body = JSON.stringify(data.body)

  return fetch(url, request)
}

export default {
  gerenciar
}
