import settings from '@/settings'

const pagar = (data, headers) => {
  return fetch(data.url, {
    method: 'POST',
    body: JSON.stringify({
      id_compra: data.id_compra,
      id_cartao: data.id_cartao,
      cartao: data.cartao,
      cvv: data.cvv,
      user_agent: data.user_agent
    }),
    mode: 'no-cors',
    cache: 'default',
    headers: headers
  })
}

const cadastrarCliente = (data, headers) => {
  return fetch(`${settings.apetrusApi}/clientes/cadastrar/`, {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const pagarCieloEmpresa = (data, headers) => {
  return fetch(`${settings.apetrusApi}/paycieloenpresa/`, {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const hashCartao = (data, headers) => {
  return fetch(`${settings.apetrusApi}/cartoes/`, {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const obterHash = (data, headers) => {
  return fetch(`${settings.apetrusApi}/cartoes/obterhash/${data.id_cartao}/`, {
    method: 'PUT',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const validarCartao = (id, data, headers) => {
  return fetch(`${settings.apetrusApi}/cartoes/?id=${id}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const getCartoes = (id, headers) => {
  return fetch(`${settings.apetrusApi}/cartoes/porpessoa/?id=${id}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const deleteCartao = (id, headers) => {
  return fetch(`${settings.apetrusApi}/cartoes/?id=${id}`, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const getCartao = (id, headers) => {
  return fetch(`${settings.apetrusApi}/cartoes/?id=${id}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const pagarCieloDebito = (data, headers) => {
  return fetch(`${settings.apetrusApi}/debitcielo/`, {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const pagarlinx = (data, headers) => {
  return fetch(`${settings.apetrusApi}/linx/pay/`, {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const pagarCartao = (data, headers) => {
  // return fetch('http://127.0.0.1:8000/payments/linx/pay/', {
  return fetch(data.url, {
    method: 'POST',
    body: JSON.stringify({
      id_compra: data.id_compra,
      id_cartao: data.id_cartao,
      cartao: data.cartao,
      cvv: data.cvv,
      user_agent: data.user_agent
    }),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const formaDepagamento = (cnpj) => {
  return fetch(`${settings.apetrusApi}/formaspagamento/formapagamentoposto/?cnpj=${cnpj}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default'
  })
}

const listFormasPagamento = () => {
  return fetch(`${settings.apetrusApi}/formaspagamento/`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default'
  })
}

const inserirFormaPagamento = (formaPagamento, headers) => {
  return fetch(`${settings.apetrusApi}/formaspagamento/cadastrar/`, {
    method: 'POST',
    body: JSON.stringify(formaPagamento),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

export default {
  pagar,
  pagarCieloDebito,
  hashCartao,
  formaDepagamento,
  listFormasPagamento,
  inserirFormaPagamento,
  pagarCieloEmpresa,
  pagarlinx,
  cadastrarCliente,
  validarCartao,
  getCartoes,
  deleteCartao,
  pagarCartao,
  getCartao,
  obterHash
}
