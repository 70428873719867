import settings from '@/settings'

// Pega redes ativos
const pegarRedes = (headers, data) => {
  return fetch(`${settings.apetrusApi}/redes/ativas/${data && data.pagination ? `?page=${data.pagination.page}&page_size=${data.pagination.rowsPerPage}` : ''}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const filtrodeRedes = (headers, filtros) => {
  let url = `${settings.apetrusApi}/redes/ativas/?`
  const keys = Object.keys(filtros)
  for (let i = 0; i < keys.length; i++) {
    url = `${url}${url[url.length - 1] === '?' ? '' : '&'}${keys[i]}=${filtros[keys[i]]}`
  }
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const pegarPostosDaRede = (headers, id) => {
  return fetch(`${settings.apetrusApi}/redes/postosrede/${id}/`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const comProposta = (headers) => {
  return fetch(`${settings.apetrusApi}/proposta/redescomproposta/`, {
    method: 'GET',
    mode: 'cors',
    headers: headers
  })
}

export default {
  pegarRedes,
  filtrodeRedes,
  comProposta,
  pegarPostosDaRede
}
