import Vue from 'vue'

import 'leaflet/dist/leaflet.css'
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css' // Re-uses images from ~leaflet package
import 'leaflet'
import 'leaflet-defaulticon-compatibility'

import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'croppie/croppie.css'
import vuetify from './plugins/vuetify'
import firebase from 'firebase/app'
import 'firebase/auth'
import SvgFiller from 'vue-svg-filler'
import moment from 'moment'
import VueCurrencyFilter from 'vue-currency-filter'
import { VueMaskDirective } from 'v-mask'
import VueCroppie from 'vue-croppie'
import crypto from 'crypto-js'
import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor'
import settings from '@/settings'

moment.locale('pt-br')

Vue.config.productionTip = false
Vue.use(VueCroppie)
Vue.use(crypto)
Vue.use(VueCurrencyFilter,
  {
    symbol: 'R$',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true
  })

Vue.use(VueMonacoEditorPlugin, {
  paths: {
    // The recommended CDN config
    vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs'
  }
})
Vue.filter('formatDate', (value, format) => {
  if (value) {
    return moment(String(value)).format(format)
  }
})

Vue.component('svg-filler', SvgFiller)
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  created () {
    firebase.initializeApp(settings.firebaseConfig)
  },
  mounted () {
    firebase.auth().onAuthStateChanged((user) => {
      this.$store.dispatch('userChanged', user)
    })
  }
}).$mount('#app')

// Código principal

// Código da diretiva
Vue.directive('scroll', {
  inserted: function (el, binding) {
    const f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

// Para usar mascara
Vue.directive('mask', VueMaskDirective)
