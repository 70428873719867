import settings from '@/settings'

const get = () => {
  return fetch(`${settings.apetrusApi}/politicasprivacidade/posto/`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default'
  })
}

export default {
  get
}
