import settings from '@/settings'

const getClassesporEmpresa = (empresa, headers) => {
  return fetch(`${settings.apetrusApi}/classedeveiculo/getclassesveiculos/?empresa=${empresa}`, {
    method: 'get',
    mode: 'cors',
    headers: headers
  })
}

const insertClasseVeiculo = (data, headers) => {
  return fetch(`${settings.apetrusApi}/classedeveiculo/insertclasseveiculo/`, {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const inserirVeiculoNoGrupo = (data, headers) => {
  return fetch(`${settings.apetrusApi}/classedeveiculo/inserirveiculonogrupo/`, {
    method: 'PUT',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const removerVeiculoDoGrupo = (data, headers) => {
  return fetch(`${settings.apetrusApi}/classedeveiculo/removerveiculodogrupo/`, {
    method: 'PUT',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

export default {
  getClassesporEmpresa,
  insertClasseVeiculo,
  inserirVeiculoNoGrupo,
  removerVeiculoDoGrupo
}
