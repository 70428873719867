var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","fill-height":"","grid-list-xs":"","pa-0":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","align-stretch":""}},[_c('v-form',{staticStyle:{"width":"100%"},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs4":"","md2":""}},[_c('v-select',{attrs:{"disabled":_vm.rede !== null,"items":[
              { text: 'Pré-Paga', value: 'prepago' },
              // { text: 'Pós-Paga', value: 'pospago' },
              { text: 'Presencial', value: 'presencial' }
            ],"item-text":"text","label":"Forma de pagamento","item-value":"value"},model:{value:(_vm.filtros.modelo_faturamento),callback:function ($$v) {_vm.$set(_vm.filtros, "modelo_faturamento", $$v)},expression:"filtros.modelo_faturamento"}})],1),_c('v-flex',{attrs:{"xs4":"","md2":""}},[_c('v-select',{attrs:{"disabled":_vm.rede !== null,"items":_vm.postos,"item-text":"nome_fantasia","label":"Posto","item-value":"cnpj","clearable":""},model:{value:(_vm.posto),callback:function ($$v) {_vm.posto=$$v},expression:"posto"}})],1),_c('v-flex',{attrs:{"xs4":"","md2":""}},[_c('v-select',{attrs:{"disabled":_vm.posto !== null,"items":_vm.redes,"item-text":"nome","label":"Rede","item-value":"id_rede","clearable":""},model:{value:(_vm.rede),callback:function ($$v) {_vm.rede=$$v},expression:"rede"}})],1),_c('v-flex',{attrs:{"xs4":"","md3":""}},[_c('v-select',{attrs:{"items":[
              { 'text': 'Todas', value: undefined },
              { 'text': 'Aceitas', value: true },
              { 'text': 'Rejeitadas', value: false },
              { 'text': 'Aguardando Responta', value: null }
            ],"item-text":"text","label":"Status","item-value":"value"},model:{value:(_vm.filtros.status),callback:function ($$v) {_vm.$set(_vm.filtros, "status", $$v)},expression:"filtros.status"}})],1),_c('v-flex',{attrs:{"shrink":""}},[_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":_vm.carregarPropostas}},[_c('v-icon',[_vm._v("search")])],1)],1),_c('v-flex',{attrs:{"shrink":""}},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.print()}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("print")])],1),_c('v-list-item-title',[_vm._v("Imprimir relatório")])],1)],1)],1)],1)],1)],1),_c('v-flex',{staticClass:"print",attrs:{"xs12":""}},[_c('div',{staticClass:"pb-3 pt-5"},[_c('v-layout',{attrs:{"row":""}},[_c('h1',[_vm._v(" Relatório de propostas")]),_c('v-spacer'),_c('span',{staticClass:"display-2"},[_vm._v("Total: "+_vm._s(_vm._f("currency")(_vm.valor_total_gasto(),{ fractionCount: 2 })))])],1),_c('hr')],1),_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.propostas,"hide-default-footer":"","options":_vm.pagination,"loading":_vm.loading,"hide-default-header":!_vm.propostas || !_vm.propostas.length,"no-data-text":"Não foi encontrado proposta"},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.icone",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(_vm._s(item.posto ? 'local_gas_station' : 'ac_unit'))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.posto ? 'Posto' : 'Rede'))])])]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [(item.status === true)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"teal"}},on),[_vm._v("done_all")])]}}],null,true)},[_c('span',[_vm._v("Confirmada")])]):(item.status === null)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"indo"}},on),[_vm._v("query_builder")])]}}],null,true)},[_c('span',[_vm._v("Aguardando resposta")])]):(item.status === false)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red"}},on),[_vm._v("close")])]}}],null,true)},[_c('span',[_vm._v("Rejeitada")])]):_vm._e()]}},{key:"item.valor_usado",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.valor_usado,{ fractionCount: 2 }))+" ")]}},{key:"item.valor_inicial",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.valor_gasto,{ fractionCount: 2 }))+" ")]}},{key:"item.data_resposta",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data_abertura,'DD/MM/YYYY'))+" ")]}},{key:"item.nome",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.posto ? ("" + (item.posto.nome_fantasia)) : ("" + (item.rede.nome)))+" ")]}},{key:"item.desconto",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.desconto ? item.desconto : 0)+"% ")]}}])})],1),_c('div',{staticClass:"text-xs-center hidden-print-only"},[_c('v-pagination',{attrs:{"length":_vm.pages,"circle":""},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1)],1),_c('v-footer',{staticStyle:{"background":"transparent"},attrs:{"absolute":"","dense":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }