<template>
  <v-card>
    <v-card-title class="justify-center text-h5">
      EDITAR DISPUTA DE LANCES
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form lazy-validation v-model="validoLeilao" ref="formularioLeilao">
        <v-layout wrap>
          <v-flex xs6>
            <v-menu
              ref="menuDataInicio"
              v-model="menuDataInicio"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  label="Data de início"
                  v-model="leilao.data_inicio"
                  prepend-icon="mdi-calendar"
                  required
                  readonly
                  v-on="on"
                  :rules="[
                      (v) => !!v || 'A data inicial é obrigatória'
                    ]"
                />
              </template>
              <v-date-picker
                v-model="data_inicio"
                no-title
                @input="menuDataInicio = false"
                locale="pt-br"
                :allowed-dates="(date) => {
                  return Date.parse(date) >= today
                }"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs6>
            <v-menu
              ref="menuDataEncerramento"
              v-model="menuDataEncerramento"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  label="Data de encerramento"
                  v-model="leilao.data_fim"
                  prepend-icon="mdi-calendar"
                  required
                  readonly
                  v-on="on"
                  :rules="[
                    (v) => !!v || 'A data de encerramneto é obrigatória'
                  ]"
                />
              </template>
              <v-date-picker
                v-model="date_encerramento"
                no-title
                @input="menuDataEncerramento = false"
                locale="pt-br"
                :allowed-dates="(date) => {
                  return testarDataFim(date)
                }"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs6>
            <v-menu
              ref="menuHorarioInicio"
              v-model="menuHorarioInicio"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="leilao.horario_inicio"
                  required
                  label="Horario de ínicio"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[
                    (v) => !!v || 'A horário de inácia é obrigatória'
                  ]"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menuHorarioInicio"
                v-model="leilao.horario_inicio"
                full-width
                no-title
                @click:minute="menuHorarioInicio = false"
                format="24hr"
              ></v-time-picker>
            </v-menu>
          </v-flex>
          <v-flex xs6>
            <v-menu
              ref="menuHorarioEncerramento"
              v-model="menuHorarioEncerramento"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  required
                  v-model="leilao.horario_fim"
                  label="Horario de encerramento"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[
                    (v) => !!v || 'A horário de encerramento é obrigatória'
                  ]"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menuHorarioEncerramento"
                v-model="leilao.horario_fim"
                full-width
                no-title
                @click:minute="menuHorarioEncerramento = false"
                format="24hr"
              ></v-time-picker>
            </v-menu>
          </v-flex>
        </v-layout>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="justify-space-around">
      <v-flex xs6>
        <v-btn
          @click="$emit('fechar')"
          text
          block
        >
          Fechar
        </v-btn>
      </v-flex>
      <v-divider vertical></v-divider>
      <v-flex xs6>
        <v-btn
          @click="editarLeilao"
          :loading="loading"
          text
          block
          color="info"
        >
          Editar
        </v-btn>
      </v-flex>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['leilaoSelecionado'],
  data: () => ({
    validoLeilao: false,
    menuHorarioEncerramento: false,
    menuHorarioInicio: false,
    menuDataEncerramento: false,
    menuDataInicio: false,
    data_inicio: undefined,
    date_encerramento: undefined,
    today: undefined,
    leilao: {
      id: undefined,
      veiculo_leiloado: undefined,
      data_inicio: undefined,
      data_fim: undefined,
      horario_inicio: undefined,
      horario_fim: undefined,
      status: 1
    }
  }),
  beforeMount () {
    this.today = new Date().toISOString().substr(0, 10)
    this.today = Date.parse(this.today)
    if (this.leilaoSelecionado) {
      this.formatarDadosLeilao(this.leilaoSelecionado)
    }
  },
  computed: {
    empresa () {
      return this.$store.getters.empresa
    },
    loading () {
      return this.$store.getters.loading
    }
  },
  watch: {
    data_inicio (val) {
      this.leilao.data_inicio = this.formatDate(val)
      if (this.date_encerramento && Date.parse(this.data_inicio) > Date.parse(this.date_encerramento)) {
        this.date_encerramento = val
      }
    },
    date_encerramento (val) {
      this.leilao.data_fim = this.formatDate(val)
      if (!this.leilao.data_inicio || Date.parse(this.data_inicio) > Date.parse(val)) {
        this.data_inicio = val
      }
    }
  },
  methods: {
    formatarDadosLeilao (dados) {
      this.tem_leilao = true
      const dataInicioSeparada = dados.data_inicio.split('T')
      const dataFimSeparada = dados.data_fim.split('T')
      this.data_inicio = dataInicioSeparada[0]
      this.date_encerramento = dataFimSeparada[0]
      this.leilao = {
        ...dados,
        data_inicio: undefined,
        data_fim: undefined,
        horario_inicio: dataInicioSeparada[1].substring(0, 5),
        horario_fim: dataFimSeparada[1].substring(0, 5),
        veiculo_leiloado: typeof dados.veiculo_leiloado === 'object' ? dados.veiculo_leiloado.id_veiculo : dados.veiculo_leiloado
      }
    },
    editarLeilao () {
      const data = {
        method: 'PATCH',
        id: this.leilao.id,
        body: {
          ...this.leilao,
          empresa: this.empresa.cnpj,
          data_inicio: `${this.data_inicio} ${this.leilao.horario_inicio}:00.764902-03`,
          data_fim: `${this.date_encerramento} ${this.leilao.horario_fim}:00.764902-03`
        }
        // Formato '2023-01-18 09:40:24.764902-03'
      }
      this.$store.dispatch('leilaoVeiculos', data).then(json => {
        this.$emit('carregar')
        this.$store.dispatch('setSuccess', 'O leião foi editado com sucesso')
        this.$emit('fechar')
      })
    },
    testarDataFim (data) {
      if (this.data_inicio) {
        return Date.parse(data) >= Date.parse(this.data_inicio)
      } else {
        return Date.parse(data) >= this.today
      }
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    }
  }
}
</script>

<style>

</style>
