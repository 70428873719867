<template>
  <v-card v-if="proposta && mostrar" style="height: 50em;" :loading="loading">
    <v-card-title class="justify-center">
      <h2>DETALHES DA PROPOSTA</h2>
    </v-card-title>
    <v-alert
      v-if="posicao && posicao > 1 && this.proposta.solicitacao.status == null"
      type="error"
      class="mt-4 mb-1 pa-2"
    >
      Você ainda não pode responder essa proposta, pois outras propostas estão na frente da fila de espera.
    </v-alert>
    <v-alert
      v-if="
        !exibir_alerta &&
        proposta.solicitacao.status === true &&
        proposta.solicitacao.finalizado === false
      "
      type="error"
      class="mt-4 mb-1 pa-2"
    >
      <v-layout justify-center wrap>
        <v-flex xs12>
          <h4>
            Você precisa realizar o pagamento da taxa de negociação para poder visualizar
            o contato do {{ isEmpresa ? 'propríetario' : 'interessado' }} do veículo.
          </h4>
        </v-flex>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
          <v-btn
            elevation="1"
            color="white"
            class="error--text mr-4 mt-2"
            v-on="on"
            small
            rounded
          >
            <v-icon class="mr-1">paid</v-icon>
            Realizar Pagamento
          </v-btn>
        </template>
        <v-card width="25em">
          <v-card-title class="justify-center error--text">
            REALIZAR PAGAMENTO
          </v-card-title>
          <v-card-text>
            <h3 class="mb-2">
              Assim que seu pagamento for confirmado, você poderá visualizar as informações
              do {{ isEmpresa ? 'propríetario' : 'interessado' }} do veículo e entrar em contato com ele.
            </h3>
            <h4
              v-if="
                isEmpresa &&
                (configuracoes_gerais.taxa_por_finalizacao_empresa || configuracoes_gerais.taxa_por_finalizacao_empresa_para_empresa)
              "
            >
              Taxa:
              {{
                (pagamento_taxa_por_finalizacao_empresa_para_empresa ? configuracoes_gerais.taxa_por_finalizacao_empresa :  configuracoes_gerais.taxa_por_finalizacao_empresa_para_empresa) | currency({ fractionCount: 2 })
              }}
            </h4>
            <h4
              v-else-if="
                configuracoes_gerais.taxa_por_negociacao_empresa || configuracoes_gerais.taxa_por_negociacao_empresa_para_empresa
              "
            >
              Taxa:
              {{
                (pagamento_taxa_por_negociacao_empresa_para_empresa ? configuracoes_gerais.taxa_por_negociacao_empresa : configuracoes_gerais.taxa_por_negociacao_empresa_para_empresa) | currency({ fractionCount: 2 })
              }}
            </h4>
            <h4>Saldo da sua carteira virtual: {{ carteira.saldo | currency({ fractionCount: 2 }) }}</h4>
            <h4
              class="
                error--text
                text-center
                mt-2
              "
              v-if="
                (isEmpresa && parseFloat(configuracoes_gerais.taxa_por_finalizacao_empresa) > parseFloat(carteira.saldo)) ||
                (!isEmpresa && parseFloat(configuracoes_gerais.taxa_por_negociacao_empresa) > parseFloat(carteira.saldo)) ||
                (isEmpresa && parseFloat(configuracoes_gerais.taxa_por_finalizacao_empresa_para_empresa) > parseFloat(carteira.saldo)) ||
                (!isEmpresa && parseFloat(configuracoes_gerais.taxa_por_negociacao_empresa_para_empresa) > parseFloat(carteira.saldo))
              "
            >
              Seu saldo é insuficiente! Recarregue sua carteira.
            </h4>
          </v-card-text>
          <v-card-actions>
            <v-btn
              elevation="1"
              color="error"
              @click="authenticationDialog = true, fila = false"
              block
              small
              :disabled="
                (isEmpresa && parseFloat(configuracoes_gerais.taxa_por_finalizacao_empresa) > parseFloat(carteira.saldo)) ||
                (!isEmpresa && parseFloat(configuracoes_gerais.taxa_por_negociacao_empresa) > parseFloat(carteira.saldo)) ||
                (isEmpresa && parseFloat(configuracoes_gerais.taxa_por_finalizacao_empresa_para_empresa) > parseFloat(carteira.saldo)) ||
                (!isEmpresa && parseFloat(configuracoes_gerais.taxa_por_negociacao_empresa_para_empresa) > parseFloat(carteira.saldo))
              "
              :loading="loading"
            >
              Pagar
            </v-btn>
          </v-card-actions>
        </v-card>
        </v-menu>
      </v-layout>
    </v-alert>
    <v-alert
      v-else-if="
        !exibir_dados &&
        proposta.solicitacao.status === true &&
        proposta.solicitacao.finalizado === false
      "
      type="info"
      class="mt-4 mb-1 pa-2"
    >
      <v-layout justify-center wrap>
        <v-flex xs12>
          <h4>
            Aguarde a confirmação do {{ isEmpresa ? 'propríetario do' : 'interessado no' }} veículo para poder visualizar seu contato.
          </h4>
        </v-flex>
      </v-layout>
    </v-alert>
    <v-card-text>
      <v-stepper v-model="step" style="box-shadow: none; min-height: 44em;">
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-layout wrap justify-space-between style="font-size: 1.2em;">
              <v-flex
                xs5
                v-if="
                  exibir_dados || (proposta.empresa && proposta.empresa.cnpj === empresa.cnpj)
                "
              >
                Placa do Veículo: <b>{{ proposta.veiculo.identificacao || 'Não possui' }}</b>
              </v-flex>
              <template v-if="exibir_dados || isEmpresa">
                <v-flex xs5 v-if="isCliente && proposta.solicitacao.cliente.pessoa.nome_completo">
                  Enviada por: <b>{{ proposta.solicitacao.cliente.pessoa.nome_completo }}</b>
                </v-flex>
                <v-flex xs5 v-else-if="proposta.solicitacao.empresa">
                  Enviada por: <b>{{ proposta.solicitacao.empresa.nome_fantasia }}</b>
                </v-flex>
              </template>
              <v-flex xs5>
                Status:
                <b
                  :class="proposta.solicitacao.status === null ? 'info--text' : proposta.solicitacao.status ? 'success--text' : 'error--text'"
                >
                  {{ proposta.solicitacao.status === null ? 'Aguadando resposta' :  proposta.solicitacao.status ? 'Aceita' : 'Recusada'}}
                </b>
              </v-flex>
              <v-flex xs5>
                Data: <b>{{ proposta.solicitacao.data | formatDate('DD/MM/YYYY') }}</b>
              </v-flex>
              <v-flex xs5 v-if="proposta.solicitacao.hora">
                Hora de Envio: <b>{{ formatarHora(proposta.solicitacao.hora) }}</b>
              </v-flex>
              <v-flex xs5>
                Data de Encerramento: <b v-if="proposta.solicitacao.data_encerramento">{{ proposta.solicitacao.data_encerramento | formatDate('DD/MM/YYYY') }}</b>
                <b v-else>Não encerrada</b>
              </v-flex>
              <v-flex xs12 v-if="proposta.solicitacao.valor_oferecido">
                Valor oferecido: <b>{{ proposta.solicitacao.valor_oferecido | currency({ fractionCount: 2 }) }}</b>
                <h6 class="text-center error--text mb-4" style="font-size: 0.8em;">
                  * Esse valor estará sujeito a alteração após o veículo ser avaliado presencialmente
                </h6>
              </v-flex>
              <v-flex xs12 v-if="proposta.solicitacao.valor_retorno">
                Valor de retorno: <b>{{ proposta.solicitacao.valor_retorno | currency({ fractionCount: 2 }) }}</b>
                <h6 class="text-center error--text mb-4" style="font-size: 0.8em;">
                  * Esse valor estará sujeito a alteração após o veículo ser avaliado presencialmente
                </h6>
              </v-flex>
              <v-flex xs12 v-if="proposta.solicitacao.valor_oferecido_troca">
                Valor a retornar em troca: <b>{{ proposta.solicitacao.valor_oferecido_troca | currency({ fractionCount: 2 }) }}</b>
                <h6 class="text-center error--text mb-4" style="font-size: 0.8em;">
                  * Esse valor estará sujeito a alteração após o veículo ser avaliado presencialmente
                </h6>
              </v-flex>
              <template v-if="exibir_dados || isEmpresa">
                <v-flex xs5  v-if="isCliente &&  proposta.solicitacao.cliente && proposta.solicitacao.cliente.pessoa.telefone">
                  Número para contato :
                  <b>
                    {{
                      proposta.solicitacao.cliente.pessoa.telefone.startsWith('+55') ?
                      proposta.solicitacao.cliente.pessoa.telefone :
                      `+55${proposta.solicitacao.cliente.pessoa.telefone}`
                    }}
                  </b>
                </v-flex>
                <v-flex xs5  v-if="proposta.solicitacao.pagamento_taxa_por_finalizacao_empresa && proposta.cliente && proposta.cliente.pessoa.telefone">
                  Número para contato :
                  <b>
                    {{
                      proposta.cliente.pessoa.telefone.startsWith('+55') ?
                      proposta.cliente.pessoa.telefone :
                      `+55${proposta.cliente.pessoa.telefone}`
                    }}
                  </b>
                </v-flex>
                <template v-else-if="proposta.solicitacao.empresa && !isEmpresa"
                >
                  <v-flex
                    xs5
                    v-if="proposta.solicitacao.empresa.contato1"
                  >
                    Whatsapp:
                    <b>
                      {{
                        proposta.solicitacao.empresa.contato1.startsWith('+55') ?
                        proposta.solicitacao.empresa.contato1:
                        `+55${proposta.solicitacao.empresa.contato1}`
                      }}
                    </b>
                  </v-flex>
                  <v-flex
                    xs5
                    v-if="proposta.solicitacao.empresa.contato2"
                  >
                    Telefone:
                    <b>
                      {{
                        proposta.solicitacao.empresa.contato2.startsWith('+55') ?
                        proposta.solicitacao.empresa.contato2 :
                        `+55${proposta.solicitacao.empresa.contato2}`
                      }}
                    </b>
                  </v-flex>
                  <v-flex
                    xs5
                    v-if="proposta.solicitacao.empresa.contato3"
                  >
                    Celular:
                    <b>
                      {{
                        proposta.solicitacao.empresa.contato3.startsWith('+55') ?
                        proposta.solicitacao.empresa.contato3 :
                        `+55${proposta.solicitacao.empresa.contato3}`
                      }}
                    </b>
                  </v-flex>
                </template>
              </template>
              <v-layout justify-center wrap v-if="numero_propostas" style="width: 100%;">
                <v-flex xs12 class="mb-4 mt-4">
                  <h3 class="text-center">FILA DE ESPERA</h3>
                </v-flex>
                <v-flex xs5><h3>POSIÇÃO: <b>{{ posicao }}</b></h3></v-flex>
                <v-flex xs2><h3>TOTAL: <b>{{ numero_propostas }}</b></h3></v-flex>
                <v-flex xs11>
                  <v-menu
                    offset-y
                    v-if="
                      numero_propostas > 1 &&
                      proposta.empresa && proposta.empresa.cnpj == empresa.cnpj &&
                      (
                        (!!proposta.solicitacao.cliente && !proposta.solicitacao.pagamento_taxa_por_negociacao_empresa) ||
                        (!!proposta.solicitacao.empresa && !proposta.solicitacao.pagamento_taxa_por_negociacao_empresa_para_empresa)
                      ) && !proposta.solicitacao.pagamento_fila && proposta.solicitacao.status == null
                    "
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn block class="mt-4" color="error" v-on="on">
                        <v-icon class="mr-1">paid</v-icon>
                        Pagar para ver fila inteira
                      </v-btn>
                  </template>
                  <v-card width="45em">
                    <v-card-title class="justify-center error--text">
                      REALIZAR PAGAMENTO
                    </v-card-title>
                    <v-card-text>
                      <h3 class="mb-2">
                        Ao realizar o pagamento, você terá acesso as informações de contato de todos os clientes que lhe enviaram uma proposta.
                      </h3>
                      <h4
                        v-if="
                          proposta.empresa && proposta.solicitacao.cliente &&
                          configuracoes_gerais.taxa_por_fila_empresa_para_cliente
                        "
                      >
                        Taxa:
                        {{
                          configuracoes_gerais.taxa_por_fila_empresa_para_cliente | currency({ fractionCount: 2 })
                        }}
                      </h4>
                      <h4
                        v-else-if="
                          configuracoes_gerais.taxa_por_fila_empresa_para_empresa && proposta.empresa && proposta.solicitacao.empresa
                        "
                      >
                        Taxa:
                        {{
                          configuracoes_gerais.taxa_por_fila_empresa_para_empresa | currency({ fractionCount: 2 })
                        }}
                      </h4>
                      <h4>Saldo da sua carteira virtual: {{ carteira.saldo | currency({ fractionCount: 2 }) }}</h4>
                      <h4
                        class="
                          error--text
                          text-center
                          mt-2
                        "
                        v-if="
                          (
                            proposta.empresa && proposta.solicitacao.cliente &&
                            parseFloat(configuracoes_gerais.taxa_por_fila_empresa_para_cliente) > parseFloat(carteira.saldo)
                          )
                          ||
                          (
                            proposta.empresa && proposta.solicitacao.empresa && parseFloat(configuracoes_gerais.taxa_por_fila_empresa_para_empresa) > parseFloat(carteira.saldo)
                          )
                        "
                      >
                        Seu saldo é insuficiente! Recarregue sua carteira.
                      </h4>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        elevation="1"
                        color="error"
                        @click="authenticationDialog = true, fila = true"
                        block
                        small
                        :disabled="
                          (proposta.empresa && proposta.solicitacao.cliente && parseFloat(configuracoes_gerais.taxa_por_fila_empresa_para_cliente) > parseFloat(carteira.saldo)) ||
                          (proposta.empresa && proposta.solicitacao.empresa && parseFloat(configuracoes_gerais.taxa_por_fila_empresa_para_empresa) > parseFloat(carteira.saldo))
                        "
                        :loading="loading"
                      >
                        Pagar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
                </v-flex>
              </v-layout>
              <template v-if="proposta.solicitacao.veiculo">
                <v-flex xs12 class="justify-center mb-4">
                  <h4 style="text-align: center;">Veículos Negociados</h4>
                </v-flex>
                <v-flex xs5 v-if="proposta.veiculo">
                  <v-card class="ma-1" elevation="2">
                    <v-layout justify-center>
                      <v-icon size="80" :color="formatarCor(proposta.veiculo.color)">{{getIcon(proposta.veiculo.tipo_veiculo)}}</v-icon>
                    </v-layout>
                    <v-card-text>
                      <v-flex v-if="exibir_dados || (proposta.empresa && proposta.empresa.cnpj === empresa.cnpj)">
                        Placa do Veículo: <b>{{ proposta.veiculo.identificacao || 'Não possui' }}</b>
                      </v-flex>
                      <v-flex>
                        Marca: <b>{{proposta.veiculo.marca}}</b>
                      </v-flex>
                      <v-flex>
                        Modelo: <b>{{proposta.veiculo.modelo}}</b>
                      </v-flex>
                      <v-flex>
                        Ano: <b>{{proposta.veiculo.ano_fabricacao}}</b>
                      </v-flex>
                      <v-flex>
                          Usado: <b>{{proposta.veiculo.usado ? 'SIM' : 'NÃO'}}</b>
                      </v-flex>
                      <v-flex v-if="proposta.veiculo.usado">
                          KM rodados: <b>{{proposta.veiculo.quilometragem}}</b>
                      </v-flex>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                      <h5 v-if="proposta.cliente && exibir_dados">
                        Veículo de {{proposta.cliente.pessoa.nome.toUpperCase()}}
                      </h5>
                      <h5 v-if="proposta.empresa && exibir_dados">
                        {{proposta.empresa.nome_fantasia.toUpperCase()}}
                      </h5>
                    </v-card-actions>
                  </v-card>
                </v-flex>
                <v-flex xs2 style="justify-content: center; display: flex;">
                  <v-icon>
                    sync_alt
                  </v-icon>
                </v-flex>
                <v-flex xs5 v-if="proposta.solicitacao.veiculo">
                  <v-card class="ma-1" elevation="2">
                    <v-layout justify-center>
                      <v-icon size="80" :color="formatarCor(proposta.solicitacao.veiculo.color)">{{getIcon(proposta.solicitacao.veiculo.tipo_veiculo)}}</v-icon>
                    </v-layout>
                    <v-card-text>
                      <v-flex v-if="exibir_dados || isEmpresa">
                        Placa do Veículo: <b>{{ proposta.solicitacao.veiculo.identificacao || 'Não possui' }}</b>
                      </v-flex>
                      <v-flex>
                        Marca: <b>{{proposta.solicitacao.veiculo.marca}}</b>
                      </v-flex>
                      <v-flex>
                        Modelo: <b>{{proposta.solicitacao.veiculo.modelo}}</b>
                      </v-flex>
                      <v-flex>
                        Ano: <b>{{proposta.solicitacao.veiculo.ano_fabricacao}}</b>
                      </v-flex>
                      <v-flex>
                          Usado: <b>{{proposta.solicitacao.veiculo.usado ? 'SIM' : 'NÃO'}}</b>
                      </v-flex>
                      <v-flex v-if="proposta.solicitacao.veiculo.usado">
                          KM rodados: <b>{{proposta.solicitacao.veiculo.quilometragem}}</b>
                      </v-flex>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                      <h5 v-if="proposta.solicitacao.cliente && exibir_dados">
                        Veículo de {{proposta.solicitacao.cliente.pessoa.nome.toUpperCase()}}
                      </h5>
                      <h5 v-if="proposta.solicitacao.empresa && exibir_dados">
                        {{proposta.solicitacao.empresa.nome_fantasia.toUpperCase()}}
                      </h5>
                    </v-card-actions>
                  </v-card>
                </v-flex>
                <v-flex xs12 v-if="exibir_dados || (proposta.empresa && proposta.empresa.cnpj === empresa.cnpj)">
                  <v-card class="ma-1">
                    <v-card-title class="justify-center">
                      <h4 class="text-xs-center">Informações do Vendedor</h4>
                    </v-card-title>
                    <v-card-text>
                      <template v-if="
                          exibir_dados ||
                          (proposta.empresa && proposta.empresa.cnpj == empresa.cnpj)
                        "
                      >
                        <template
                          v-if="
                            proposta.cliente &&
                            proposta.cliente.pessoa
                          "
                        >
                          <v-flex
                            xs11
                            class="mb-1"
                          >
                              Nome Completo do Proprietário:
                              <b>
                                {{
                                  proposta.cliente.pessoa.nome_completo
                                }}
                              </b>
                          </v-flex>
                          <v-flex xs11 v-if="proposta.cliente.pessoa.telefone" class="mb-1">
                              Contato do Proprietário:
                              <b>
                                {{
                                  proposta.cliente.pessoa.telefone.startsWith('+55') ?
                                  proposta.cliente.pessoa.telefone :
                                  `+55${proposta.cliente.pessoa.telefone}`
                                }}
                              </b>
                          </v-flex>
                        </template>
                        <template
                          v-else-if="
                            proposta.empresa
                          "
                        >
                          <v-flex
                            xs11
                            class="mb-1"
                          >
                            Empresa Proprietária:
                            <b>
                              {{
                                proposta.empresa.nome_fantasia
                              }}
                            </b>
                          </v-flex>
                          <v-flex
                            xs5
                            v-if="proposta.empresa.contato1"
                            class="mb-1"
                          >
                            Whatsapp:
                            <b>
                              {{
                                proposta.empresa.contato1.startsWith('+55') ?
                                proposta.empresa.contato1:
                                `+55${proposta.empresa.contato1}`
                              }}
                            </b>
                          </v-flex>
                        <v-flex
                          xs5
                          v-if="proposta.empresa.contato2"
                          class="mb-1"
                        >
                          Telefone:
                          <b>
                            {{
                              proposta.empresa.contato2.startsWith('+55') ?
                              proposta.empresa.contato2 :
                              `+55${proposta.empresa.contato2}`
                            }}
                          </b>
                        </v-flex>
                        <v-flex
                          xs5
                          v-if="proposta.empresa.contato3"
                          class="mb-1"
                        >
                          Celular:
                          <b>
                            {{
                              proposta.empresa.contato3.startsWith('+55') ?
                              proposta.empresa.contato3 :
                              `+55${proposta.empresa.contato3}`
                            }}
                          </b>
                        </v-flex>
                        </template>
                      </template>
                      <v-flex xs11 v-if="proposta.veiculo.endereco" class="mb-4">
                        Endereço do Proprietário: <b>{{proposta.veiculo.endereco}}</b>
                      </v-flex>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </template>
              <v-layout justify-center v-else class="mt-4">
                <v-card style="width: 90%;" class="mb-2">
                  <v-card-title class="justify-center mb-3">Veículo Negociado</v-card-title>
                  <v-carousel
                    v-if="proposta.veiculo.fotos.length > 0"
                    height="20em"
                  >
                    <v-carousel-item
                      v-for="(foto ,i) in proposta.veiculo.fotos"
                      :key="i"
                      :src="foto.url"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                    ></v-carousel-item>
                  </v-carousel>
                  <v-layout
                    v-else
                    justify-center
                    align-center
                    fill-height
                    style="
                      height: 10em;
                    "
                    >
                      <v-icon color="info" size="20em">{{getIcon(proposta.veiculo.tipo_veiculo)}}</v-icon>
                  </v-layout>
                  <v-card-text style="font-size: 1.1em;">
                    <v-layout wrap justify-space-between>
                      <v-flex xs5>Modelo: <b>{{proposta.veiculo.modelo}}</b></v-flex>
                      <v-flex xs5>Marca: <b>{{proposta.veiculo.marca}}</b></v-flex>
                      <v-flex xs5 v-if="proposta.veiculo.ano_modelo">Ano do modelo: <b>{{proposta.veiculo.ano_modelo}}</b></v-flex>
                      <v-flex xs5 v-if="proposta.veiculo.ano_fabricacao">Ano de Fabricação / modelo: <b>{{proposta.veiculo.ano_fabricacao}}</b></v-flex>
                      <v-flex xs5 v-if="proposta.veiculo.cor_texto" class="mb-1">
                          Cor em Texto: <b>{{proposta.veiculo.cor_texto}}</b>
                      </v-flex>
                      <v-flex xs5 v-if="proposta.veiculo.cor" class="mb-1">
                          Cor: <v-btn x-small :color="formatarCor(proposta.veiculo.cor)"></v-btn>
                      </v-flex>
                      <v-flex xs5 v-if="proposta.veiculo.versao_motor" class="mb-1">
                          Versão do motor: <b>{{proposta.veiculo.versao_motor}}</b>
                      </v-flex>
                      <v-flex xs5 v-if="proposta.veiculo.versao" class="mb-1">
                          Versão: <b>{{proposta.veiculo.versao}}</b>
                      </v-flex>
                      <v-flex xs5 v-if="proposta.veiculo.capacidade" class="mb-1">
                          Capacidade: <b>{{proposta.veiculo.capacidade}} Pessoas</b>
                      </v-flex>
                      <v-flex xs5 v-if="proposta.veiculo.qtd_assentos" class="mb-1">
                          Qtd assentos: <b>{{proposta.veiculo.qtd_assentos}}</b>
                      </v-flex>
                      <v-flex xs5 v-if="proposta.veiculo.cilindradas" class="mb-1">
                          Cilindradas: <b>{{proposta.veiculo.cilindradas}}</b>
                      </v-flex>
                      <v-flex xs5 v-if="proposta.veiculo.hp" class="mb-1">
                          HP: <b>{{proposta.veiculo.hp}}</b>
                      </v-flex>
                      <v-flex xs5 class="mb-1">
                          Usado: <b>{{proposta.veiculo.usado ? 'SIM' : 'NÃO'}}</b>
                      </v-flex>
                      <v-flex xs5 class="mb-1" v-if="proposta.veiculo.usado">
                          KM rodados: <b>{{proposta.veiculo.quilometragem}}</b>
                      </v-flex>
                      <template v-if="
                          exibir_dados ||
                          (proposta.empresa && proposta.empresa.cnpj == empresa.cnpj)
                        "
                      >
                        <template
                          v-if="
                            proposta.cliente &&
                            proposta.cliente.pessoa
                          "
                        >
                          <v-flex
                            xs11
                            class="mb-1"
                          >
                              Nome Completo do Proprietário:
                              <b>
                                {{
                                  proposta.cliente.pessoa.nome_completo
                                }}
                              </b>
                          </v-flex>
                          <v-flex xs11 v-if="proposta.cliente.pessoa.telefone" class="mb-1">
                              Contato do Proprietário:
                              <b>
                                {{
                                  proposta.cliente.pessoa.telefone.startsWith('+55') ?
                                  proposta.cliente.pessoa.telefone :
                                  `+55${proposta.cliente.pessoa.telefone}`
                                }}
                              </b>
                          </v-flex>
                        </template>
                        <template
                          v-else-if="
                            proposta.empresa
                          "
                        >
                          <v-flex
                            xs11
                            class="mb-1"
                          >
                            Empresa Proprietária:
                            <b>
                              {{
                                proposta.empresa.nome_fantasia
                              }}
                            </b>
                          </v-flex>
                          <v-flex
                            xs5
                            v-if="proposta.empresa.contato1"
                            class="mb-1"
                          >
                            Whatsapp:
                            <b>
                              {{
                                proposta.empresa.contato1.startsWith('+55') ?
                                proposta.empresa.contato1:
                                `+55${proposta.empresa.contato1}`
                              }}
                            </b>
                          </v-flex>
                        <v-flex
                          xs5
                          v-if="proposta.empresa.contato2"
                          class="mb-1"
                        >
                          Telefone:
                          <b>
                            {{
                              proposta.empresa.contato2.startsWith('+55') ?
                              proposta.empresa.contato2 :
                              `+55${proposta.empresa.contato2}`
                            }}
                          </b>
                        </v-flex>
                        <v-flex
                          xs5
                          v-if="proposta.empresa.contato3"
                          class="mb-1"
                        >
                          Celular:
                          <b>
                            {{
                              proposta.empresa.contato3.startsWith('+55') ?
                              proposta.empresa.contato3 :
                              `+55${proposta.empresa.contato3}`
                            }}
                          </b>
                        </v-flex>
                        </template>
                      </template>
                      <v-flex xs11 v-if="proposta.veiculo.endereco" class="mb-4">
                        Endereço do Proprietário: <b>{{proposta.veiculo.endereco}}</b>
                      </v-flex>
                      <v-layout wrap justify-space-around style="width:100%;">
                        <v-flex xs12>
                          <h3 class="text-center mb-4">OPCIONAIS</h3>
                        </v-flex>
                        <v-flex
                          xs3
                          v-for="(opcional, index) in proposta.veiculo.opcionais"
                          :key="index"
                        >
                          <v-icon
                            color="success"
                          >
                            done
                          </v-icon>
                          {{ opcional.nome }}
                        </v-flex>
                      </v-layout>
                    </v-layout>
                </v-card-text>
              </v-card>
            </v-layout>
            <v-layout justify-center v-if="(isCliente || !isEmpresa) && proposta.solicitacao.status == null">
              <v-select
                class="mt-4"
                :items="[
                  'Aceitar',
                  'Rejeitar'
                ]"
                label="Responder Proposta"
                filled
                v-model="resposta"
              ></v-select>
            </v-layout>
          </v-layout>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-dialog
        v-model="authenticationDialog"
        v-if="authenticationDialog"
        width="350"
      >
        <autenticar-smart-lock
          @fechar="authenticationDialog = false"
          @autenticado="pagarProposta(), authenticationDialog = false"
        />
      </v-dialog>
    </v-card-text>
    <v-card-actions style="display: flex; flex-direction: column;">
      <v-btn
        v-if="proposta.solicitacao.finalizado == false && !isEmpresa"
        block
        @click="responderProposta(false)"
        color="success"
        class="mb-2"
        :disabled="disabled"
        :loading="loading"
      >
        {{ (isCliente || !isEmpresa) && proposta.solicitacao.status == null ? 'Confirmar' : 'Confirmar Entrega do Veículo' }}
      </v-btn>
      <v-btn
        v-if="
          proposta.solicitacao.finalizado != true
          && proposta.solicitacao.status == true
          && !isEmpresa && !exibir_dados && !proposta.solicitacao.pagamento_fila
        "
        block
        @click="responderProposta(true)"
        color="error"
        class="mb-2"
        :loading="loading"
      >
        Cancelar Proposta
      </v-btn>
      <v-btn block @click="$emit('fechar')">Fechar</v-btn>
    </v-card-actions>
  </v-card>
  <v-card v-else>
    <v-card-text>
      <v-layout justify-center>
        <v-progress-circular indeterminate size="150" color="primary"></v-progress-circular>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import { VMoney } from 'v-money'
import AutenticarSmartLock from '@/components/dialogs/AutenticarSmartLock'
export default {
  directives: { money: VMoney },
  props: ['propostaSelecionada'],
  components: { AutenticarSmartLock },
  data: () => ({
    resposta: undefined,
    step: 1,
    temError: false,
    mensagemError: '',
    posicao: undefined,
    fila: false,
    numero_propostas: undefined,
    carteira: {
      saldo: 0
    },
    mostrar: false,
    authenticationDialog: false,
    configuracoes_gerais: {
      taxa_por_negociacao_cliente: null,
      taxa_por_negociacao_empresa: null,
      taxa_por_negociacao_empresa_para_empresa: null,
      taxa_por_finalizacao_cliente: null,
      taxa_por_finalizacao_empresa: null,
      taxa_por_finalizacao_empresa_para_empresa: null,
      taxa_por_fila_empresa_para_cliente: null,
      taxa_por_fila_empresa_para_empresa: null,
      taxa_por_fila_cliente_para_empresa: null
    },
    money: {
      decimal: ',',
      thousands: '.',
      precision: 2,
      masked: false
    },
    proposta: undefined
  }),
  computed: {
    empresa () {
      return this.$store.getters.empresa
    },
    loading () {
      return this.$store.getters.loading
    },
    isCliente () {
      return !!this.proposta.solicitacao.cliente // Informa se foi um cliente que enviou uma solicitação
    },
    isEmpresa () {
      // Informa se foi a empresa logada que enviou uma solicitação
      return !!this.proposta.solicitacao.empresa && this.empresa && this.proposta.solicitacao.empresa.cnpj === this.empresa.cnpj
    },
    pagamento_taxa_por_negociacao_empresa () { // vendendo
      if (!this.proposta.empresa || !this.proposta.solicitacao.cliente) return true
      return !this.configuracoes_gerais.taxa_por_negociacao_empresa || (this.proposta && this.proposta.solicitacao.pagamento_taxa_por_negociacao_empresa)
    },
    pagamento_taxa_por_negociacao_empresa_para_empresa () { // vendendo
      if (!this.proposta.solicitacao.empresa || !this.proposta.empresa) return true
      return !this.configuracoes_gerais.taxa_por_negociacao_empresa_para_empresa || (this.proposta && this.proposta.solicitacao.pagamento_taxa_por_negociacao_empresa_para_empresa)
    },
    pagamento_taxa_por_finalizacao_empresa () { // comprando
      if (!this.proposta.solicitacao.empresa || !this.proposta.cliente) return true
      return !this.configuracoes_gerais.taxa_por_finalizacao_empresa || (this.proposta && this.proposta.solicitacao.pagamento_taxa_por_finalizacao_empresa)
    },
    pagamento_taxa_por_finalizacao_empresa_para_empresa () { // comprando
      if (!this.proposta.solicitacao.empresa || !this.proposta.empresa) return true
      return !this.configuracoes_gerais.taxa_por_finalizacao_empresa_para_empresa || (this.proposta && this.proposta.solicitacao.pagamento_taxa_por_finalizacao_empresa_para_empresa)
    },
    pagamento_taxa_por_negociacao_cliente () { // vendendo
      if (!this.proposta.cliente) return true
      return !this.configuracoes_gerais.taxa_por_negociacao_cliente || (this.proposta && this.proposta.solicitacao.pagamento_taxa_por_negociacao_cliente)
    },
    pagamento_taxa_por_finalizacao_cliente () { // comprando
      if (!this.proposta.solicitacao.cliente) return true
      return !this.configuracoes_gerais.taxa_por_finalizacao_cliente || (this.proposta && this.proposta.solicitacao.pagamento_taxa_por_finalizacao_cliente)
    },
    exibir_alerta () {
      if (this.isEmpresa) {
        return this.pagamento_taxa_por_finalizacao_empresa && this.pagamento_taxa_por_finalizacao_empresa_para_empresa
      } else {
        return this.pagamento_taxa_por_negociacao_empresa && this.pagamento_taxa_por_negociacao_empresa_para_empresa
      }
    },
    exibir_dados () {
      return this.pagamento_taxa_por_negociacao_cliente && this.pagamento_taxa_por_negociacao_empresa && this.pagamento_taxa_por_finalizacao_cliente && this.pagamento_taxa_por_finalizacao_empresa && this.pagamento_taxa_por_finalizacao_empresa_para_empresa && this.pagamento_taxa_por_negociacao_empresa_para_empresa
    },
    disabled () {
      if ((!this.resposta && this.proposta.solicitacao.status == null) || this.isEmpresa) return true
      else if ((this.posicao !== 1 && this.proposta.solicitacao.status == null) || this.isEmpresa) return true
      else if (this.proposta.solicitacao.status === true && !this.exibir_dados) return true
      return false
    }
  },
  async beforeMount () {
    await this.pegarConfirguracoes()
    await this.pegarCarteiraVirtual()
    this.proposta = { ...this.propostaSelecionada }
    if (this.proposta && this.proposta.veiculo) {
      if (this.isEmpresa) await this.pegarFilaDeEspera({ cnpj: this.empresa.cnpj, mostrar_posicao: true, id_veiculo: this.proposta.veiculo.id_veiculo })
      else if (!this.isEmpresa && !this.isCliente) await this.pegarFilaDeEspera({ cnpj: this.proposta.solicitacao.empresa.cnpj, mostrar_posicao: true, id_veiculo: this.proposta.veiculo.id_veiculo })
      else this.pegarFilaDeEspera({ id_cliente: this.proposta.solicitacao.cliente.id_cliente, mostrar_posicao: true, id_veiculo: this.proposta.veiculo.id_veiculo })
      const interval = setInterval(() => {
        this.mostrar = true
        clearInterval(interval)
      }, 300)
    }
  },
  methods: {
    formatarHora (hora) {
      if (!hora) return ''
      return hora.slice(0, 5)
    },
    pegarConfirguracoes () {
      this.$store.dispatch('getConfigApetrus')
        .then(json => {
          this.configuracoes_gerais = json
        })
    },
    pegarFilaDeEspera (data) {
      this.$store.dispatch('pegarFilaDeEspera', data).then(json => {
        this.posicao = json.posicao
        this.numero_propostas = json.numero_negociacoes
      })
    },
    getIcon (tipo) {
      if (tipo && typeof tipo === 'object') tipo = tipo.denominacao
      if (tipo === 'Passageiro' || tipo === 'Automóvel' || tipo === 'Outros') return 'airport_shuttle'
      else if (tipo === 'Caminhão') return 'local_shipping'
      else if (tipo === 'Caminhão trator') return 'agriculture'
      else if (tipo === 'Caminhonete') return 'agriculture'
      else if (tipo === 'Ciclomotor' || tipo === 'Motoneta' || tipo === 'Motocicleta' || tipo === 'Triciclo') return 'two_wheeler'
      else if (tipo === 'Micro-ônibus' || tipo === 'Ônibus') return 'directions_bus'
      return 'airport_shuttle'
    },
    formatarCor (cor) {
      if (cor === null) return ''
      if (cor === undefined) return ''
      if (cor.length === 6) return `#${cor}`
      if (cor.length === 7) return cor
      if (cor.length === 9) return cor.slice(0, 7)
      else {
        let teste = parseInt(cor)
        teste = teste.toString(16)
        return `#${teste.slice(2, 8)}`
      }
    },
    showError (msg) {
      this.temError = true
      this.mensagemError = msg
      setTimeout(() => {
        this.temError = false
        this.mensagemError = ''
      }, 5000)
    },
    responderProposta (cancelar) {
      const data = {
        method: 'PUT',
        id: this.proposta.id
      }
      if (cancelar) {
        data.cancelar = true
      } else if (this.proposta.solicitacao.status == null) {
        data.resposta = this.resposta === 'Aceitar'
      } else {
        data.finalizar = true
      }
      this.$store.dispatch('solicitacaoVeiculo', data).then(json => {
        this.$emit('carregar')
        if (json) this.proposta = json
        else this.$emit('fechar')
      })
    },
    pagarProposta () {
      const data = {
        method: 'PATCH',
        id: this.proposta.id,
        cnpj_empresa: this.empresa.cnpj,
        is_fila: this.fila
      }
      if (this.proposta.solicitacao.status == null && !this.fila) {
        this.$store.dispatch('setError', 'Para realizar o pagamento, primeiro a proposta deve ser aceita.')
        return
      }
      this.$store.dispatch('solicitacaoVeiculo', data).then(json => {
        this.$emit('carregar')
        this.proposta = json
        this.fila = false
      })
    },
    pegarCarteiraVirtual () {
      this.$store.dispatch('pegarCarteiraVirtual', { cnpj: this.empresa.cnpj }).then((json) => {
        this.carteira = json
      })
    }
  }
}
</script>
