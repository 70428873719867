var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-1",attrs:{"color":"transparent"}},[(_vm.funcionarios)?_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.funcionarios),callback:function ($$v) {_vm.funcionarios=$$v},expression:"funcionarios"}},_vm._l((_vm.funcionarios),function(funcionario,index){return _c('v-list-item',{key:index},[_c('v-list-item-icon',[(funcionario.confirmado == true)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"teal","size":"30"}},on),[_vm._v("done")])]}}],null,true)},[_vm._v(" Confirmado ")]):_vm._e(),(funcionario.confirmado == null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"grey lighten-1","size":"30"}},on),[_vm._v("watch_later")])]}}],null,true)},[_vm._v(" Pendente ")]):_vm._e(),(funcionario.confirmado == false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"grey lighten-1","size":"30"}},on),[_vm._v("close")])]}}],null,true)},[_vm._v(" Colaborador não aceitou! ")]):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title font-weight-regular grey--text text--darken-2",domProps:{"textContent":_vm._s(funcionario.nome_email)}}),(funcionario.cpf)?_c('v-list-item-subtitle',{staticClass:"caption",domProps:{"textContent":_vm._s(funcionario.cpf)}}):_c('v-list-item-subtitle',{staticClass:"caption red--text",domProps:{"textContent":_vm._s(_vm.text)}}),_c('v-list-item-subtitle',{staticClass:"caption",domProps:{"textContent":_vm._s(funcionario.email)}})],1),(funcionario.bloqueado)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("block")]):_vm._e(),_c('v-list-item-action',[(funcionario.ativo)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"text":"","icon":"","small":""},on:{"click":function($event){return _vm.dialogRemover(funcionario)}}},[_c('v-icon',_vm._g({attrs:{"color":"red","size":"30"}},on),[_vm._v("close")])],1)]}}],null,true)},[_vm._v(" Excluir ")]):_vm._e(),(funcionario.ativo)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"text":"","icon":"","small":""},on:{"click":function($event){return _vm.editarFuncionario(funcionario)}}},[_c('v-icon',_vm._g({attrs:{"color":"green","size":"30"}},on),[_vm._v("edit")])],1)]}}],null,true)},[_vm._v(" Editar ")]):_vm._e()],1)],1)}),1),(!_vm.funcionarios.length)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-xs-center title font-weight-light grey--text text--darken-2"},[_vm._v(" Sem funcionarios cadastrados ")])],1)],1):_vm._e()],1):_vm._e(),_c('div',{staticClass:"text-xs-center"},[_c('v-pagination',{attrs:{"length":_vm.pages,"circle":""},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1),(_vm.dialogEditarFuncionario)?_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.dialogEditarFuncionario),callback:function ($$v) {_vm.dialogEditarFuncionario=$$v},expression:"dialogEditarFuncionario"}},[_c('dialog-editar-funcionario',{attrs:{"funcionario":_vm.funcionarioSelecionado},on:{"fechar":function($event){_vm.dialogEditarFuncionario = false},"carregar":_vm.carregarFuncionarios}})],1):_vm._e(),(_vm.dialogExcluir)?_c('v-dialog',{attrs:{"width":"450"},model:{value:(_vm.dialogExcluir),callback:function ($$v) {_vm.dialogExcluir=$$v},expression:"dialogExcluir"}},[_c('excluir-funcionario',{attrs:{"funcionario":_vm.funcionarioSelecionado},on:{"excluir":_vm.removerFuncionario,"fechar":function($event){_vm.dialogExcluir = false}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }