<template>
  <v-card>
    <v-layout>
      <v-flex xs3>
        <v-btn icon v-if="step===2" @click="voltarStep">
          <v-icon>close</v-icon>
        </v-btn>
      </v-flex>
      <v-flex xs9>
        <v-card-title class="headline font-weight-medium secondary--text">
          CÓDIGOS PROMOCIONAIS
        </v-card-title>
      </v-flex>
    </v-layout>
    <v-stepper v-model="step" style="box-shadow: none;">
    <v-stepper-items>
      <v-stepper-content step="1" class="pa-0">
        <v-card class="pa-2">
          <v-expand-transition>
              <v-card-text
                class="text-center headline font-weight-light grey--text text--darken-1 pl-0"
                v-show="!cadastrando"
                >
                <template v-if="loadingCodigos">
                    <v-progress-circular indeterminate color="grey lighten-1" size="80"></v-progress-circular>
                </template>
                <template v-else-if="!codigosPromocionais.length">Sem códigos promocionais no momento</template>
                <template v-else>
                    <v-list dense two-line>
                      <template v-for="(codigo, index) in codigosPromocionais">
                          <v-divider :key="-index" v-if="index > 0" inset></v-divider>
                          <v-list-item :key="codigo.id"  :style="{'color': !codigo.disponivel || (codigo.data_fim != null && new Date(`${codigo.data_fim}T23:59:59`) < new Date()) ? 'red' : 'gray', 'height' : '3em'}">
                            <v-list-item-avatar>
                                <svg-filler
                                  :path="require(`@/assets/combs/gas.svg`)"
                                  width="50px"
                                  height="50px"
                                  :fill="codigo.combustivel? codigo.combustivel.color : ''"
                                  />
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title
                                  v-if="codigo.desconto"
                                  class="font-weight-light body-2"
                                  >{{codigo.desconto}}% de desconto
                                </v-list-item-title>
                                <v-list-item-subtitle
                                  :style="{'color': !codigo.disponivel ||
                                  (codigo.data_fim != null && new Date(`${codigo.data_fim}T23:59:59`) < new Date()) ? 'red' : 'gray'}">
                                  {{codigo.codigo}}
                                  <span v-if="codigo.possui_valor_limite && codigo.valor_limite <= codigo.valor_usado">(Volume esgotago)</span>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-content>
                                <v-list-item-subtitle
                                  class="font-weight-light caption text--darken-2 text-xs-right"
                                  :style="{'color': !codigo.disponivel || (codigo.data_fim != null && new Date(`${codigo.data_fim}T23:59:59`) < new Date()) ? 'red' : 'gray'}"
                                  >{{new Date(`${codigo.data_inicio}T00:00:00`) >= new Date()? 'Válido a partir de':'Ficou válido a partir de'}} {{codigo.data_inicio | formatDate('DD/MM/YYYY')}}</v-list-item-subtitle>
                                <v-list-item-subtitle
                                  v-if="codigo.tem_data_fim"
                                  class="font-weight-light caption text--darken-2 text-xs-right"
                                  :style="{'color': !codigo.disponivel || (codigo.data_fim != null && new Date(`${codigo.data_fim}T23:59:59`) < new Date()) ? 'red' : 'gray'}"
                                  >
                                  <span v-if="codigo.data_fim != null && new Date(`${codigo.data_fim}T23:59:59`) < new Date()">
                                  Data expirada
                                  </span>
                                  <span v-else>Até {{codigo.data_fim | formatDate('DD/MM/YYYY')}}</span>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-if="codigo.combustivel" :style="{'color': codigo.combustivel.color}">
                                  {{codigo.combustivel.nome}}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-menu
                                  offset-x
                                  max-width="230"
                                  min-width="230"
                                  right
                                  transition="slide-x-transition"
                                  >
                                  <template v-slot:activator="{ on }">
                                      <v-btn icon v-on="on">
                                        <v-icon color="grey darken-2">more_vert</v-icon>
                                      </v-btn>
                                  </template>
                                  <v-list class="pa-0">
                                      <v-list-item ripple @click="carregarBanner(codigo)">
                                        <v-list-item-content>
                                            <v-list-item-title
                                              class="font-weight-light body-1 success--text text-xs-center"
                                              >CARREGAR BANNER</v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-icon color="success">image</v-icon>
                                        </v-list-item-action>
                                      </v-list-item>
                                      <v-list-item ripple @click="cancelarCodigo(codigo)">
                                        <v-list-item-content>
                                            <v-list-item-title
                                              class="font-weight-light body-1 error--text text-xs-center"
                                              >APAGAR CÓDIGO</v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-icon color="error">delete</v-icon>
                                        </v-list-item-action>
                                      </v-list-item>
                                      <v-list-item ripple @click="visualizarDetalhes(codigo)">
                                        <v-list-item-content>
                                            <v-list-item-title
                                              class="font-weight-light body-1 primary--text text-xs-center"
                                              >DETALHES</v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-icon color="primary">visibility</v-icon>
                                        </v-list-item-action>
                                      </v-list-item>
                                  </v-list>
                                </v-menu>
                            </v-list-item-action>
                          </v-list-item>
                      </template>
                    </v-list>
                </template>
              </v-card-text>
          </v-expand-transition>
          <v-expand-transition>
              <v-container v-show="cadastrando" grid-list-md>
                <v-layout row wrap justify-end>
                    <v-btn icon @click="cadastrando = false">
                      <v-icon class="grey--text lighten-1">close</v-icon>
                    </v-btn>
                </v-layout>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-layout row wrap justify-start>
                      <v-flex xs12 class="pb-0">
                          <v-text-field
                            v-model.lazy="codigo_model.codigo"
                            outline
                            label="Código da promoção"
                            counter="20"
                            v-mask="'NNNNNNNNNNNNNNNNNNNN'"
                            @input="codigo_model.codigo = codigo_model.codigo.toUpperCase()"
                            required
                            :rules="[
                            v => !!v || 'O campo é obrigatório'
                            ]"
                            ></v-text-field>
                      </v-flex>
                      <v-flex xs6 class="pb-0">
                          <v-text-field
                            v-model.lazy="codigo_model.desconto"
                            outline
                            label="Percentual do desconto"
                            suffix="%"
                            type="number"
                            required
                            :rules="[
                            () => parseFloat(codigo_model.desconto) >= 0 || 'Desconto inválido',
                            () => parseFloat(codigo_model.desconto) <= 100 || 'O desconto deve ser menor que 100%'
                            ]"
                            ></v-text-field>
                      </v-flex>
                      <v-flex xs6 class="pb-0">
                          <v-menu
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                            :close-on-content-click="false"
                            v-model="menuDataInicial"
                            >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="codigo_model.data_inicio"
                                  outline
                                  v-on="on"
                                  label="Data inicial"
                                  required
                                  :rules="[
                                  v => !!v || 'O campo é obrigatório'
                                  ]"
                                  readonly
                                  ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="date_inicio"
                                no-title
                                locale="pt-br"
                                :allowed-dates="(date) => {
                                return Date.parse(date) >= today
                                }"
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="green darken-1"
                                  class="caption font-weight-medium"
                                  @click="menuDataInicial = false"
                                  >FECHAR</v-btn>
                            </v-date-picker>
                          </v-menu>
                      </v-flex>
                      <v-flex xs6 class="pb-0 pt-0">
                          <v-checkbox
                            v-model="codigo_model.tem_data_fim"
                            color="primary"
                            class="mt-0"
                            :hide-details="codigo_model.tem_data_fim"
                            >
                            <template slot="label">
                                <span class="body-2 grey--text">Possui data de expiração?</span>
                            </template>
                          </v-checkbox>
                          <v-menu
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                            :close-on-content-click="false"
                            v-model="menuDataFinal"
                            >
                            <template v-slot:activator="{ on }">
                                <v-slide-x-transition>
                                  <v-text-field
                                      v-model="codigo_model.data_fim"
                                      outline
                                      hide-details
                                      v-on="on"
                                      v-show="codigo_model.tem_data_fim"
                                      class="pt-2"
                                      readonly
                                      label="Data de Expiração"
                                      ></v-text-field>
                                </v-slide-x-transition>
                            </template>
                            <v-date-picker
                                v-model="date_fim"
                                no-title
                                locale="pt-br"
                                :allowed-dates="(date) => {
                                return Date.parse(date) >= today
                                }"
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="green darken-1"
                                  class="caption font-weight-medium"
                                  @click="menuDataFinal = false"
                                  >FECHAR</v-btn>
                            </v-date-picker>
                          </v-menu>
                      </v-flex>
                      <v-flex xs6>
                          <v-checkbox
                            v-model="codigo_model.possui_valor_limite"
                            color="primary"
                            class="mt-0"
                            :hide-details="codigo_model.possui_valor_limite"
                            >
                            <template slot="label">
                                <span class="body-2 grey--text">Limitar valor de uso</span>
                            </template>
                          </v-checkbox>
                          <v-slide-x-transition>
                            <v-text-field
                                outline
                                v-if="codigo_model.possui_valor_limite"
                                v-money="money"
                                v-model.lazy="codigo_model.valor_limite"
                                class="pt-2"
                                label="Valor limite"
                                :rules="[
                                  () => valor_limite_teste > 0 || 'Valor inválido'
                                ]"
                                ></v-text-field>
                          </v-slide-x-transition>
                      </v-flex>
                      <v-flex xs6>
                          <v-checkbox
                            v-model="possui_limete_por_cliente"
                            color="primary"
                            class="mt-0"
                            :hide-details="possui_limete_por_cliente"
                            >
                            <template slot="label">
                                <span class="body-2 grey--text">Limitar valor de uso por cliente</span>
                            </template>
                          </v-checkbox>
                          <v-slide-x-transition>
                            <v-text-field
                                outline
                                v-if="possui_limete_por_cliente"
                                v-money="money"
                                v-model.lazy="codigo_model.limite_por_cliente"
                                class="pt-2"
                                label="Valor limite por cliente"
                                :rules="[
                                  () => valor_limite_teste > 0 || 'Valor inválido'
                                ]"
                                ></v-text-field>
                          </v-slide-x-transition>
                      </v-flex>
                      <v-flex xs6>
                          <v-checkbox
                            v-model="possuiLink"
                            color="primary"
                            class="mt-0"
                            :hide-details="possuiLink"
                            >
                            <template slot="label">
                                <span class="body-2 grey--text">Possui link para site?</span>
                            </template>
                          </v-checkbox>
                          <v-slide-x-transition>
                            <v-text-field
                                v-show="possuiLink"
                                v-model="codigo_model.link_site"
                                type="text"
                                placeholder="https://www.dominio.com.br/"
                                outline
                                class="pt-2"
                                label="Digite o link do site"
                                :rules="[
                                  () => url_teste == true || 'Link inválido'
                                ]"
                                ></v-text-field>
                          </v-slide-x-transition>
                      </v-flex>
                      <v-flex xs6>
                          <v-checkbox
                            v-model="codigo_model.qtd_codigos_limitado"
                            color="primary"
                            class="mt-0"
                            :hide-details="codigo_model.qtd_codigos_limitado"
                            >
                            <template slot="label">
                                <span class="body-2 grey--text">Limitar quantidade de códigos</span>
                            </template>
                          </v-checkbox>
                          <v-slide-x-transition>
                            <v-text-field
                                outline
                                v-model="codigo_model.qtd_codigos"
                                v-show="codigo_model.qtd_codigos_limitado"
                                hide-details
                                class="pt-2"
                                type="number"
                                label="Códigos disponíveis"
                                ></v-text-field>
                          </v-slide-x-transition>
                      </v-flex>
                      <v-flex xs6>
                          <v-checkbox
                            v-model="codigo_model.usos_limitados"
                            color="primary"
                            class="mt-0"
                            :hide-details="codigo_model.usos_limitados"
                            >
                            <template slot="label">
                                <span class="body-2 grey--text">Limitar usos para cada usuário</span>
                            </template>
                          </v-checkbox>
                          <v-slide-x-transition>
                            <v-text-field
                                v-show="codigo_model.usos_limitados"
                                v-model="codigo_model.qtd_usos_usuario"
                                type="number"
                                outline
                                hide-details
                                class="pt-2"
                                label="Usos por usuário"
                                ></v-text-field>
                          </v-slide-x-transition>
                      </v-flex>
                      <v-flex xs6>
                          <v-checkbox
                            v-model="possui_limete_item"
                            color="primary"
                            class="mt-0"
                            :hide-details="possui_limete_item"
                            >
                            <template slot="label">
                                <span class="body-2 grey--text">Vincular Item</span>
                            </template>
                          </v-checkbox>
                          <v-slide-x-transition>
                            <v-select
                                single-line
                                v-show="possui_limete_item"
                                :items="todos_itens"
                                item-text="nome"
                                item-value="id"
                                v-model="codigo_model.item"
                                outline
                                placeholder="Selecione um item"
                                />
                          </v-slide-x-transition>
                      </v-flex>
                      <v-flex xs6>
                          <v-checkbox
                            v-model="possui_limete_categoria"
                            color="primary"
                            class="mt-0"
                            :hide-details="possui_limete_categoria"
                            >
                            <template slot="label">
                                <span class="body-2 grey--text">Vincular Categoria</span>
                            </template>
                          </v-checkbox>
                          <v-slide-x-transition>
                            <v-select
                                single-line
                                v-show="possui_limete_categoria"
                                :items="categorias"
                                item-text="nome"
                                item-value="id"
                                v-model="codigo_model.categoria"
                                outline
                                placeholder="Selecione uma categoria"
                                />
                          </v-slide-x-transition>
                      </v-flex>
                      <v-flex xs12 class="pb-0 pt-0">
                          <v-checkbox
                            color="primary"
                            class="mt-0"
                            :hide-details="codigo_model.possui_mensagem"
                            v-model="codigo_model.possui_mensagem"
                            >
                            <template slot="label">
                                <span class="body-2 grey--text">Deseja adicionar uma mensagem à promoção?</span>
                            </template>
                          </v-checkbox>
                          <v-slide-x-transition>
                            <v-text-field
                                v-show="codigo_model.possui_mensagem"
                                v-model="codigo_model.mensagem_cupom"
                                outline
                                class="pt-2"
                                label="Mensagem"
                                counter="30"
                                :required="codigo_model.possui_mensagem"
                                :rules="codigo_model.possui_mensagem ? [
                                v => !!v || 'Insira uma mensagem válida',
                                v => v && v.length < 31 || 'A mensagem só pode ter 31 dígitos no máximo'
                                ] : []"
                                ></v-text-field>
                          </v-slide-x-transition>
                      </v-flex>
                    </v-layout>
                </v-form>
              </v-container>
          </v-expand-transition>
          <v-card-actions>
              <v-btn
                block
                text
                color="success"
                @click="cadastrando = true"
                v-if="!cadastrando"
                >NOVO CÓDIGO PROMOCIONAL</v-btn>
              <v-btn v-else block text color="success" @click="inserirCodigo()" :loading="loading">CONFIRMAR</v-btn>
          </v-card-actions>
          <v-dialog persistent v-model="dialogBanner" :value="!!codigoSelecionado" width="600">
              <banner-codigo-promocional
                :codigo_promocional="codigoSelecionado"
                @close="codigoSelecionado = null; dialogBanner = false;"
                @success="carregarCodigos()"
              ></banner-codigo-promocional>
          </v-dialog>
        </v-card>
      </v-stepper-content>
      <v-stepper-content step="2" v-if="mostrarDetalhes" v-model="mostrarDetalhes"  class="pa-0" max-width="600">
        <v-layout v-if="codigoSelecionado" column style="text-align: start;" class="subheading font-weight-regula pa-3">
          <v-flex align-self-center>
            <img v-if="codigoSelecionado.url_imagem_codigo" :src="codigoSelecionado.url_imagem_codigo" alt="Imagem do código" width="200">
            <v-card v-else style="width: 200px; height: 200px; background-color: #DCDCDC;" class="elevation-0 mb-3">
              <v-layout justify-center align-center fill-height>
                <v-icon size="100">image</v-icon>
              </v-layout>
            </v-card>
          </v-flex>
          <span v-if="codigoSelecionado.link_site">Link para site: <a :href="codigoSelecionado.link_site" target="_blank" rel="noopener noreferrer">{{codigoSelecionado.link_site}}</a>
          </span>
          <span>Código: {{codigoSelecionado.codigo}}</span>
          <span>{{new Date(`${codigoSelecionado.data_inicio}T00:00:00`) >= new Date()? 'Válido a partir de':'Ficou válido a partir de'}}: {{codigoSelecionado.data_inicio | formatDate('DD/MM/YYYY')}}</span>
          <span v-if="codigoSelecionado.tem_data_fim">{{new Date(`${codigoSelecionado.data_fim}T00:00:00`) >= new Date()? 'Válido até':'Ficou válido até'}}: {{codigoSelecionado.data_fim | formatDate('DD/MM/YYYY')}}</span>
          <span>Ganha: {{codigoSelecionado.desconto}}% de desconto</span>
          <span>{{codigoSelecionado.combustivel ? `Viculado a ${codigoSelecionado.combustivel.nome}` : 'Vinculado a todos os combustíveis'}}</span>
          <span v-if="codigoSelecionado.possui_valor_limite">Valor limite definido: R$ {{codigoSelecionado.valor_limite}}</span>
          <span v-if="codigoSelecionado.possui_valor_limite">Restam: R$ {{(codigoSelecionado.valor_limite - codigoSelecionado.valor_usado).toFixed(2)}} a serem vendidos</span>
          <span v-if="codigoSelecionado.qtd_codigos_limitado">Quantidade de códigos definida: {{codigoSelecionado.qtd_codigos}} códigos</span>
          <span v-if="codigoSelecionado.qtd_codigos_limitado">Restam: {{codigoSelecionado.qtd_codigos - codigoSelecionado.qtd_codigos_usados}} códigos a serem vendidos</span>
          <span v-if="codigoSelecionado.usos_limitados">Usuário pode usar: {{codigoSelecionado.qtd_usos_usuario}} {{codigoSelecionado.qtd_usos_usuario == 1? 'vez' : 'vezes'}}</span>
        </v-layout>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
  </v-card>
</template>

<script>

import { VMoney } from 'v-money'

const BannerCodigoPromocional = () =>
  import('../CarregarBanner')

export default {
  components: {
    BannerCodigoPromocional
  },
  data () {
    const todayDate = new Date().toISOString().substr(0, 10)
    return {
      menuDataInicial: false,
      codigoSelecionado: null,
      step: 1,
      mostrarDetalhes: false,
      dialogBanner: false,
      menuDataFinal: false,
      cadastrando: false,
      inserindoBanner: false,
      valid: false,
      consumo: [0, 200],
      codigos_promocionais: [],
      loadingCodigos: false,
      date_inicio: null,
      date_fim: null,
      today: Date.parse(todayDate),
      codigosPromocionais: [],
      todos_itens: [],
      categorias: [],
      possuiLink: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      possui_limete_combustivel: false,
      possui_limete_item: false,
      possui_limete_categoria: false,
      possui_limete_por_cliente: false,
      codigo_model: {
        desconto: 0,
        codigo: null,
        ativo: true,
        possui_mensagem: false,
        mensagem_cupom: null,
        combustivel: null,
        data_inicio: null,
        tem_data_fim: false,
        data_fim: null,
        tipo_pagamento_limitado: false,
        qtd_codigos_limitado: false,
        usos_limitados: false,
        qtd_codigos: 500,
        qtd_usos_usuario: 1,
        qtd_litros: null,
        consumo_limitado: false,
        consumo_minimo: null,
        consumo_maximo: null,
        formas_pagamento: [],
        mostrar: true,
        possui_valor_limite: false,
        valor_limite: null,
        is_apetrus: false,
        link_site: null,
        limite_por_cliente: null,
        novo: true,
        categoria: null,
        item: null,
        rede: null,
        posto: null
      }
    }
  },
  computed: {
    empresa () {
      return this.$store.getters.empresa
    },
    loading () {
      return this.$store.getters.loading
    },
    url_teste () {
      if (this.codigo_model.link_site && this.codigo_model.link_site.length > 7) {
        const valor = this.codigo_model.link_site.startsWith('https://') || this.codigo_model.link_site.startsWith('http://')
        return valor
      }
      return true
    },
    valor_limite_teste () {
      if (this.codigo_model.valor_limite) {
        const valor = this.codigo_model.valor_limite.split(' ')[1].split(',')
        return Number(`${valor[0].split('.').join('')}.${valor[1]}`)
      }
      return 0
    },
    limite_por_cliente_teste () {
      if (this.codigo_model.limite_por_cliente) {
        const valor = this.codigo_model.limite_por_cliente.split(' ')[1].split(',')
        return Number(`${valor[0].split('.').join('')}.${valor[1]}`)
      }
      return 0
    }
  },
  methods: {
    pegarCategorias () {
      this.$store.dispatch('pegarCategorias').then(json => {
        this.categorias = json.filter(c => c.referencia !== 'combustivel')
      })
    },
    pegarTodosItens () {
      const data = {
        method: 'GET',
        parameters: {
          empresa: this.empresa.cnpj,
          empresa__cnpj: this.empresa.cnpj
        }
      }
      this.$store.dispatch('itens', data).then(json => {
        this.todos_itens = json
      })
    },
    carregarBanner (codigo) {
      this.codigoSelecionado = codigo
      this.dialogBanner = true
    },
    carregarCodigos () {
      this.loadingCodigos = true
      this.$store.dispatch('codigoPromocional', {
        method: 'GET',
        parameters: {
          empresa: this.empresa.cnpj,
          empresa__cnpj: this.empresa.cnpj
        }
      }).then(json => {
        this.loadingCodigos = false
        this.codigosPromocionais = json
        this.$emit('notifications')
      })
    },
    cancelarCodigo (codigo) {
      this.$store.dispatch('codigoPromocional', {
        method: 'DELETE',
        body: {
          empresa: this.empresa.cnpj
        },
        id: codigo.id
      }).then(() => {
        this.carregarCodigos()
      })
    },
    inserirCodigo () {
      if (this.$refs.form.validate()) {
        if (this.codigo_model.valor_limite) {
          const valor = this.codigo_model.valor_limite.split(' ')[1].split(',')
          this.codigo_model.valor_limite = Number(`${valor[0].split('.').join('')}.${valor[1]}`)
          if (this.codigo_model.valor_limite <= 0) {
            return
          }
        }
        if (this.codigo_model.limite_por_cliente) {
          const valor = this.codigo_model.limite_por_cliente.split(' ')[1].split(',')
          this.codigo_model.limite_por_cliente = Number(`${valor[0].split('.').join('')}.${valor[1]}`)
          if (this.codigo_model.limite_por_cliente <= 0) {
            return
          }
        }

        this.$store.dispatch('codigoPromocional', {
          method: 'POST',
          body: {
            ...this.codigo_model,
            desconto: this.codigo_model.desconto,
            categoria: this.codigo_model.item ? null : this.codigo_model.categoria,
            empresa: this.empresa.cnpj
          }
        }).then(() => {
          this.cadastrando = false
          this.loadData()
          this.carregarCodigos()
          this.$emit('notifications')
        }).catch(() => {
          this.$emit(
            'error',
            'Não foi possível inserir o código promocional, por favor, verifique os dados e tente novamente.'
          )
        })
      }
    },
    visualizarDetalhes (codigo) {
      this.codigoSelecionado = codigo
      this.step = 2
      this.mostrarDetalhes = true
    },
    voltarStep () {
      this.codigoSelecionado = null
      this.step = 1
    },
    voltar () {
      this.codigo_model = {
        desconto: 0,
        codigo: null,
        possui_mensagem: false,
        mensagem_cupom: null,
        combustivel: null,
        data_inicio: null,
        tem_data_fim: false,
        data_fim: null,
        tipo_pagamento_limitado: false,
        qtd_codigos_limitado: false,
        usos_limitados: false,
        qtd_codigos: 500,
        qtd_usos_usuario: 1,
        qtd_litros: null,
        consumo_limitado: false,
        consumo_minimo: 0,
        consumo_maximo: 0,
        formas_pagamento: [],
        mostrar: true,
        possui_valor_limite: false,
        valor_limite: null,
        is_apetrus: true,
        limite_por_cliente: null,
        novo: true,
        categoria: null,
        item: null
      }
    },
    loadData () {
      this.codigo_model.consumo_minimo = this.consumo[0]
      this.codigo_model.consumo_maximo = this.consumo[1]
      this.codigo_model.empresa = this.empresa.cnpj
    }
  },
  beforeMount () {
    this.pegarCategorias()
    this.loadData()
    this.carregarCodigos()
    this.pegarTodosItens()
  },
  watch: {
    'codigo_model.possui_mensagem' (val) {
      if (!val) {
        this.codigo_model.mensagem_cupom = null
      }
    },
    'codigo_model.possui_valor_limite' (val) {
      if (!val) {
        this.codigo_model.valor_limite = null
      }
    },
    possuiLink (val) {
      if (!val) {
        this.codigo_model.link_site = null
      }
    },
    possui_limete_combustivel (val) {
      this.codigo_model.combustivel = null
    },
    possui_limete_categoria (val) {
      this.codigo_model.categoria = null
      if (val) {
        this.possui_limete_item = false
      }
    },
    possui_limete_item (val) {
      this.codigo_model.item = null
      if (val) {
        this.possui_limete_categoria = false
      }
    },
    possui_limete_por_cliente () {
      this.codigo_model.limite_por_cliente = null
    },
    consumo (val) {
      this.codigo_model.consumo_minimo = val[0]
      this.codigo_model.consumo_maximo = val[1]
    },
    date_inicio (val) {
      this.menuDataInicial = false
      if (val) {
        this.codigo_model.data_inicio = val
          .split('-')
          .reverse()
          .join('/')
      } else {
        this.codigo_model.data_inicio = null
      }
    },
    date_fim (val) {
      this.menuDataFinal = false
      if (val) {
        this.codigo_model.data_fim = val
          .split('-')
          .reverse()
          .join('/')
      } else {
        this.codigo_model.data_fim = null
      }
    },
    empresa (val) {
      if (val) {
        this.codigo_model.empresa = val.cnpj
      } else {
        this.codigo_model.empresa = null
      }
      this.loadData()
    }
  },
  directives: { money: VMoney }
}
</script>
