<template>
  <v-card>
    <v-card-text class="text-xs-center headline pa-3">
      <h3 style="text-align: center;">Mostrando detalhes da fatura</h3>
      <div
        style="text-align: center;"
        class="subheading grey--text text--darken-2"
        v-if="fatura"
      ><h6>Dia {{fatura.data_fatura.split('-').reverse().join('/')}}</h6></div>
      <v-layout justify-end>
        <v-btn @click="irParaRelatorio" small rounded color="primary" class="mt-4"><v-icon>visibility</v-icon> Visualizar Relatório</v-btn>
      </v-layout>
    </v-card-text>
    <v-card-text class="pa-5 text-xs-center" v-if="loading">
      <v-progress-circular indeterminate size="150" color="primary"></v-progress-circular>
    </v-card-text>
    <v-card-text v-else>
      <v-list>
        <template v-for="(tipo_faturamento, index) in detalhes.tipos_faturamento">
          <v-divider :key="`divider-${index}`" v-if="index > 0"></v-divider>
          <v-list-item :key="index">
            <v-list-item-icon>
              <v-icon color="green">attach_money</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="`Valor: R$ ${tipo_faturamento.valor}`"
                class="font-weight-light"
              ></v-list-item-title>
              <v-list-item-subtitle v-text="tipo_faturamento.descricao"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <v-flex v-if="this.fatura.proposta" class="mt-4">
        <h4 v-if="valor_total">Valor Total: {{valor_total.toFixed(2).replaceAll(',', '').replace('.', ',')}}</h4>
        <h4 v-if="fatura.proposta.desconto">Desconto: {{fatura.proposta.desconto}}%</h4>
        <h4 v-if="fatura.valor_faturamento">Valor a pagar: {{fatura.valor_faturamento.replaceAll(',', '').replace('.', ',')}}</h4>
      </v-flex>
    </v-card-text>
    <v-footer
      color="primary"
      height="auto"
      v-if="apetrus"
    >
      <v-layout justify-center class="pa-3 white--text">
        <strong>Banco do Brasil</strong>&nbsp;- Ag.<strong>1668-3</strong>, C.C. <strong>56373-0</strong>
      </v-layout>
    </v-footer>
  </v-card>
</template>

<script>
export default {
  props: ['fatura', 'apetrus'],
  data () {
    return {
      detalhes: {}
    }
  },
  computed: {
    loading () {
      return this.$store.getters.loading
    },
    valor_total () {
      if (!this.fatura.proposta) return 0
      return this.fatura.valor_faturamento / (1 - this.fatura.proposta.desconto / 100)
    }
  },
  watch: {
    fatura (val) {
      if (val) {
        this.carregarDetalhes()
      }
    }
  },
  mounted () {
    if (this.fatura) {
      this.carregarDetalhes()
    }
  },
  methods: {
    carregarDetalhes () {
      this.$store.dispatch('detalhesFatura', this.fatura.id).then(json => {
        this.detalhes = json
      })
    },
    irParaRelatorio () {
      this.$router.push('/relatorio/vouchers')
    }
  }
}
</script>
