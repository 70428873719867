<template>
  <v-card class="mx-auto pa-2"  max-width="600">
    <v-card-title class="justify-center">
      <h2>
        NOVA
        {{
          cadOferta.promocao ?
          'PROMOÇÃO'
          :
          'OFERTA'
        }}
      </h2>
    </v-card-title>
    <v-alert v-show="alerta" :type="tipo" style="width: 100%;" class="pa-2" :value="true">
        <h4>{{alerta}}</h4>
    </v-alert>
    <v-divider v-show="alerta"></v-divider>
    <v-stepper v-model="step" class="elevation-0">
      <v-stepper-items>
        <v-stepper-content class="pa-0 ma-0" step="1">
          <v-container grid-list-md>
            <v-form v-model="valid1" ref="formFirstStep">
              <v-layout row wrap>
                <v-flex xs4 class="ma-0 pa-0">
                  <v-text-field
                    v-model="desconto"
                    label="Percentual do desconto"
                    required
                    suffix="%"
                    :rules="[
                      () => desconto <= 100 || 'Desconto inválido',
                      () => desconto >= 1 || `O desconto mímino deve ser de 1%`
                    ]"
                  ></v-text-field>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs7 class="ma-0 pa-0">
                  <v-select
                    label="Selecione a categoria"
                    :items="categorias"
                    item-text="nome"
                    item-value="id"
                    v-model="cadOferta.categoria"
                  />
                </v-flex>
                <v-flex xs12 class="ma-0 pa-0">
                  <v-select
                    label="Para"
                    :items="[{ nome: 'Todos os itens da categoria', id: 0 }, ...todos_itens]"
                    item-text="nome"
                    item-value="id"
                    v-model="cadOferta.item"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-flex xs12 class="ma-0 pa-0" v-on="on">
                        <v-switch
                            slot="activator"
                            class="ml-4"
                            v-model="cadOferta.promocao"
                            label="É uma promoção"
                            color="info"
                          />
                        </v-flex>
                      </template>
                      <span>
                        {{
                          cadOferta.promocao ?
                          'Ao desativar esse recurso você terá um desconto válido por tempo indeterminado.'
                          :
                          'Ao ativar esse recurso você terá um desconto válido por um tempo determinado.'
                        }}
                      </span>
                  </v-tooltip>
                </v-flex>
                <v-flex
                  aling-self-center
                  style="
                      justify-content: center;
                      display: flex;
                  "
                  class="ma-0 pa-0"
                  v-if="planos.length > 0"
                >
                  <v-tooltip bottom>
                    <v-switch class="mr-4" slot="activator" v-model="cadOferta.para_assinantes" color="secondary">
                      <template v-slot:label>
                          Para Assinantes
                      </template>
                    </v-switch>
                    <span style="font-size: 1.2em;">
                      {{
                        !cadOferta.para_assinantes ?
                        'Ativando essa função, A promoção estará disponível para os assinantes do programa de assinatura.' :
                        'Ao Desativar essa função a promoção estará disponível para todos os clientes que comprarem em seu posto'
                      }}
                    </span>
                  </v-tooltip>
                </v-flex>
                <template v-if="cadOferta.item === 0">
                  <v-flex xs12 class="mt-2">
                    <h4 class="text-center secondary--text mb-2" style="font-weight: normal;">
                      Selecione os itens que não farão parte dessa promoção
                    </h4>
                  </v-flex>
                  <v-flex xs12 class="ma-0 pa-0" style="flex-direction: row; display: flex;">
                    <v-flex xs10 class="pa-0 ma-0">
                        <v-select
                          label="Selecione um Item"
                          :items="todos_itens"
                          item-text="nome"
                          v-model="item_list"
                          return-object
                          clearable
                        />
                    </v-flex>
                    <v-flex xs2 style="justify-content: center; display: flex;" class="pa-0 ma-0">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn :disabled="item_list === undefined" @click="ListAdd()" v-on="on" icon color="primary" style="margin-top:2.5em;"><v-icon small>add</v-icon></v-btn>
                        </template>
                        Adicionar combustível a lista
                      </v-tooltip>
                    </v-flex>
                  </v-flex>
                  <v-flex xs12 class="ma-0 pa-0">
                    <v-layout justify-center wrap>
                      <v-flex xs12 class="mt-3">
                        <h3 style="text-align: center;" class="grey--text" v-if="itens_nao_ofertados.length > 0">
                          Itens que não incluídos
                        </h3>
                      </v-flex>
                      <v-flex xs12 v-if="itens_nao_ofertados.length > 0">
                        <v-list style="background-color:#F5F5F5">
                          <v-list-item v-for="(ino, index) in itens_nao_ofertados" :key="index">
                            <v-list-item-avatar>
                              <svg-filler
                                v-if="ino.categoria.referencia === 'combustivel'"
                                :path="ino.combustivel.icone ? require(`@/assets/combs/${ino.combustivel.icone}.svg`) : require(`@/assets/combs/gas.svg`)"
                                width="30px"
                                height="30px"
                                :fill="ino.preco ? ino.combustivel.color : 'grey'"
                              />
                              <v-icon v-else :color="ino.categoria.cor">
                                {{ ino.categoria.icone }}
                              </v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>{{ino.nome}}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn icon @click="removerDalista(index)">
                                <v-icon>close</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </template>
              </v-layout>
            </v-form>
          </v-container>
        </v-stepper-content>
        <v-stepper-content step="2" class="pa-0">
          <v-container grid-list-md>
            <v-form v-model="valid2" ref="formSecondStep">
              <v-layout row wrap>
                <v-flex xs6>
                  <v-menu
                    ref="menuInicio"
                    v-model="menuInicio"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="cadOferta.data_inicio"
                        label="Início"
                        hint="Início da promoção"
                        persistent-hint
                        prepend-icon="event"
                        readonly
                        @blur="date_inicio = parseDate(cadOferta.data_inicio)"
                        v-on="on"
                        required
                        :rules="[
                          (v) => !!v || 'A data inicial é obrigatória'
                        ]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date_inicio"
                      no-title
                      @input="menuInicio = false"
                      locale="pt-br"
                      :allowed-dates="(date) => {
                        return Date.parse(date) >= today
                      }"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs6>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="cadOferta.data_fim"
                        label="Fim"
                        hint="Fim da promoção"
                        persistent-hint
                        prepend-icon="event"
                        readonly
                        @blur="date = parseDate(cadOferta.data_fim)"
                        v-on="on"
                        required
                        :rules="[
                          (v) => !!v || 'A data final é obrigatória'
                        ]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="date"
                    no-title
                    @input="menu = false"
                    locale="pt-br"
                    :allowed-dates="(date) => {
                        return Date.parse(date) >= data_inicio_teste
                    }"
                    />
                  </v-menu>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-stepper-content>
        <v-stepper-content step="3" class="pa-0">
          <v-container grid-list-md>
            <v-form v-model="valid4" ref="formFourthStep">
              <v-layout row wrap>
                <v-slide-y-transition>
                  <v-flex
                    xs12
                    class="text-xs-center subheading font-weight-medium red--text"
                    v-show="!limitar_pagamento"
                  >
                    Deseja limitar a forma de pagamento?
                    <v-card-actions class="pt-4">
                      <v-btn text block class="green--text" @click="limitar_pagamento = true">SIM</v-btn>
                      <v-btn text block class="red--text" @click="continuarTela()">NÃO</v-btn>
                    </v-card-actions>
                  </v-flex>
                </v-slide-y-transition>
                <v-slide-y-transition>
                  <v-flex xs12>
                    <v-radio-group
                      v-model="tipo_pagamento"
                      row
                      class="mt-0"
                      v-show="limitar_pagamento"
                      :required="limitar_pagamento"
                      :rules="[
                        (v) => !!v || 'Escolha uma forma de pagamento'
                      ]"
                      hide-details
                    >
                      <template slot="label">
                        <div
                          class="text-center pb-3 subheading font-weight-medium secondary--text"
                        >Selecione uma das formas para prosseguir</div>
                      </template>
                      <v-radio
                        color="primary"
                        v-for="(item, index) in tiposPagamento"
                        :key="index"
                        :label="item.descricao"
                        :value="item"
                      ></v-radio>
                    </v-radio-group>
                  </v-flex>
                </v-slide-y-transition>
              </v-layout>
            </v-form>
          </v-container>
        </v-stepper-content>
      </v-stepper-items>
      <v-card-actions>
        <v-flex xs6>
          <v-btn color="grey" text @click="voltarTela()" block>
            {{ step === 1 ? 'CANCELAR' : 'VOLTAR' }}
          </v-btn>
        </v-flex>
        <v-divider vertical></v-divider>
        <v-flex xs6>
          <v-btn color="green darken-1" text @click="continuarTela()" block>
            {{ step === 4 || !this.cadOferta.promocao ? 'CONFIRMAR' : 'CONTINUAR' }}
          </v-btn>
        </v-flex>
      </v-card-actions>
    </v-stepper>
  </v-card>
</template>

<script>
import { VMoney } from 'v-money'

export default {
  data () {
    return {
      step: 1,
      opcao: 'Posto',
      valid1: false,
      valid2: false,
      valid4: false,
      renderComponent: true,
      date: null,
      limitar_pagamento: false,
      tipo_pagamento: null,
      date_inicio: null,
      menu: false,
      menuInicio: false,
      desconto: 0,
      planos: [],
      itens_nao_ofertados: [],
      todos_itens: [],
      categorias: [],
      item_list: undefined,
      alerta: undefined,
      tipo: 'error',
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      moneyFinal: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      cadOferta: {
        percentual_desconto: 0,
        promocao: false,
        disponivel: true,
        ativo: true,
        novo: true,
        data_inicio: null,
        data_fim: null,
        today: null,
        data_inicio_teste: null,
        para_assinantes: false,
        combustiveis_nao_ofertados: [],
        itens_nao_ofertados: [],
        categoria: 0,
        item: 0
      }
    }
  },
  watch: {
    limitar_pagamento (val) {
      if (!val) {
        this.tipo_pagamento = null
      }
    },
    date (val) {
      this.cadOferta.data_fim = this.formatDate(this.date)
    },
    date_inicio (val) {
      this.cadOferta.data_inicio = this.formatDate(this.date_inicio)
      this.data_inicio_teste = Date.parse(val)
    },
    desconto (val) {
      if (val) {
        this.cadOferta.percentual_desconto = val
      }
    },
    tipo_pagamento (val) {
      this.cadOferta.tipos_pagamento = []
      if (val) {
        this.cadOferta.tipos_pagamento.push(val)
      }
    },
    'cadOferta.item' (val) {
      if (val !== 0) {
        this.itens_nao_ofertados = []
      }
    },
    'cadOferta.categoria' () {
      this.pegarTodosItens()
    }
  },
  computed: {
    empresa () {
      return this.$store.getters.empresa
    },
    tiposPagamento () {
      return this.$store.getters.tiposPagamento
    }
  },
  beforeMount () {
    this.pegarCategorias()
    this.today = new Date().toISOString().substr(0, 10)
    this.today = Date.parse(this.today)
    // this.pegarPlanos()
    this.pegarTodosItens()
  },
  methods: {
    activeAlert (text, type) {
      this.alerta = text
      this.tipo = type
      const interval = setInterval(() => {
        this.alerta = undefined
        clearInterval(interval)
      }, 5000)
    },
    fechar () {
      this.$refs.formFirstStep.reset()
      this.$refs.formSecondStep.reset()
      this.$refs.formFourthStep.reset()
      this.limitar_pagamento = false
      this.step = 1
      this.$emit('fechar')
    },
    ListAdd () {
      let itemExiste = false
      for (const i in this.itens_nao_ofertados) {
        if (this.itens_nao_ofertados[i].id === this.item_list.id) {
          itemExiste = true
        }
      }
      if (itemExiste) {
        this.activeAlert('Esse item já foi inserido.', 'error')
      } else {
        this.itens_nao_ofertados.push(this.item_list)
      }
    },
    removerDalista (index) {
      this.itens_nao_ofertados.splice(index, 1)
    },
    pegarPlanos () {
      const data = { ativo: true }
      data.empresa = this.empresa.cnpj
      this.$store.dispatch('pegarPlanos', data).then(json => {
        this.planos = json
      })
    },
    continuarTela () {
      switch (this.step) {
        case 1:
          if (this.$refs.formFirstStep.validate()) {
            if (this.cadOferta.promocao) {
              this.step++
            } else {
              this.confirmar()
            }
          }
          break
        case 2:
          if (this.$refs.formSecondStep.validate()) {
            this.step++
          }
          break
        case 3:
          if (this.limitar_pagamento) {
            if (this.$refs.formFourthStep.validate()) {
              this.confirmar()
            }
          } else {
            this.confirmar()
          }
      }
    },
    voltarTela () {
      switch (this.step) {
        case 1:
          this.fechar()
          break
        default:
          this.step--
      }
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    confirmar () {
      if (this.cadOferta.item === 0 && this.itens_nao_ofertados.length > 0) {
        this.cadOferta.itens_nao_ofertados = []
        this.itens_nao_ofertados.map(
          (ino) => this.cadOferta.itens_nao_ofertados.push(
            ino.id
          )
        )
      }

      if (this.cadOferta.categoria === 0) {
        this.activeAlert('Você precisa selecionar um categoria', 'error')
        return
      }

      if (!this.cadOferta.percentual_desconto || !this.cadOferta.percentual_desconto === 0) {
        this.activeAlert('O percentual de precisa ser superior a zero', 'error')
        return
      }

      const texto = this.cadOferta.promocao ? 'Promoção' : 'Oferta'

      const data = {
        method: 'POST',
        body: {
          ...this.cadOferta,
          data_fim: this.parseDate(this.cadOferta.data_fim),
          data_inicio: this.parseDate(this.cadOferta.data_inicio),
          item: this.cadOferta.item === 0 ? null : this.cadOferta.item,
          categoria: this.cadOferta.categoria,
          empresa: this.empresa.cnpj
        }
      }

      this.$store
        .dispatch('oferta', data)
        .then(() => {
          this.activeAlert(`${texto} cadastrada com sucesso`, 'success')
          this.$store.dispatch('setSuccess', `${texto} cadastrada com sucesso`)
          this.$emit('carregar')
          this.fechar()
        })
        .catch(() => {
          this.activeAlert(`Não foi possível inserir a ${texto}`, 'error')
          // this.fechar()
        })
    },
    pegarCategorias () {
      this.$store.dispatch('pegarCategorias').then(json => {
        this.categorias = json.filter(c => c.referencia !== 'combustivel')
        if (this.categorias.length > 0) this.cadOferta.categoria = this.categorias[0].id
      })
    },
    pegarTodosItens () {
      const data = {
        method: 'GET',
        parameters: {
          empresa: this.empresa.cnpj,
          empresa__cnpj: this.empresa.cnpj
        }
      }
      if (this.cadOferta.categoria) {
        data.parameters.categoria = this.cadOferta.categoria
      }
      this.$store.dispatch('itens', data).then(json => {
        this.todos_itens = json
      })
    }
  },
  directives: { money: VMoney }
}
</script>
