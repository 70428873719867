var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{staticStyle:{"position":"relative"}},[_c('monaco-editor',{attrs:{"language":"json","height":"300px","theme":"vs-dark","options":{
        readOnly: true,
        automaticLayout: true,
        formatOnType: true,
        formatOnPaste: true
      }},model:{value:(_vm.dados),callback:function ($$v) {_vm.dados=$$v},expression:"dados"}}),_c('v-tooltip',{attrs:{"top":"","color":_vm.copiando ? 'green' : ''},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"position":"absolute","bottom":"1em","right":"1em"},attrs:{"icon":"","color":"white"},on:{"click":_vm.copiarRetorno}},on),[_c('v-icon',[_vm._v("content_copy")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.copiando ? 'Copiado' : 'Copiar'))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }