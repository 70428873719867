import settings from '@/settings'

const getCombustiveis = (headers) => {
  return fetch(`${settings.apetrusApi}/combustiveis/unpaginated/`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const getPrecoatual = (cnpj, idCombustivel, headers) => {
  return fetch(`${settings.apetrusApi}/precoscombustivel/getprecoatual/?cnpj=${cnpj}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

export default {
  getCombustiveis,
  getPrecoatual
}
