<template>
  <v-container fluid fill-height grid-list-xs pa-0>
    <v-layout row wrap align-stretch>
      <v-form v-model="valid" style="width: 100%;">
        <v-layout row wrap justify-center>
          <v-flex xs4 md2>
            <v-select
              :disabled="rede !== null"
              :items="[
                { text: 'Pré-Paga', value: 'prepago' },
                // { text: 'Pós-Paga', value: 'pospago' },
                { text: 'Presencial', value: 'presencial' }
              ]"
              item-text="text"
              label="Forma de pagamento"
              item-value="value"
              v-model="filtros.modelo_faturamento"
            >
            </v-select>
          </v-flex>
          <v-flex xs4 md2>
            <v-select
              :disabled="rede !== null"
              :items="postos"
              item-text="nome_fantasia"
              label="Posto"
              item-value="cnpj"
              v-model="posto"
              clearable
            >
            </v-select>
          </v-flex>
          <v-flex xs4 md2>
            <v-select
              :disabled="posto !== null"
              :items="redes"
              item-text="nome"
              label="Rede"
              item-value="id_rede"
              v-model="rede"
              clearable
            >
            </v-select>
          </v-flex>
          <v-flex xs4 md3>
            <v-select
              :items="[
                { 'text': 'Todas', value: undefined },
                { 'text': 'Aceitas', value: true },
                { 'text': 'Rejeitadas', value: false },
                { 'text': 'Aguardando Responta', value: null }
              ]"
              item-text="text"
              label="Status"
              item-value="value"
              v-model="filtros.status"
            >
            </v-select>
          </v-flex>
          <v-flex shrink>
            <v-btn fab dark small color="primary" @click="carregarPropostas">
              <v-icon>search</v-icon>
            </v-btn>
          </v-flex>
          <v-flex shrink>
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  @click="print()"
                >
                  <v-list-item-action>
                    <v-icon>print</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>Imprimir relatório</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-flex>
        </v-layout>
      </v-form>
      <v-flex xs12 class="print">
        <div class="pb-3 pt-5">
          <v-layout row>
            <h1> Relatório de propostas</h1>
            <v-spacer></v-spacer>
            <span class="display-2">Total: {{ valor_total_gasto() | currency({ fractionCount: 2 }) }}</span>
          </v-layout>
          <hr/>
        </div>
         <v-card>
          <v-data-table
            :headers="headers"
            :items="propostas"
            hide-default-footer
            :options.sync="pagination"
            :loading="loading"
            :hide-default-header="!propostas || !propostas.length"
            no-data-text="Não foi encontrado proposta"
          >
          <template v-slot:item.icone="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">{{ item.posto ? 'local_gas_station' : 'ac_unit' }}</v-icon>
              </template>
              <span>{{ item.posto ? 'Posto' : 'Rede' }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.status="{ item }">
            <v-tooltip v-if="item.status === true" top>
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="teal">done_all</v-icon>
                </template>
                <span>Confirmada</span>
            </v-tooltip>
            <v-tooltip v-else-if="item.status === null" top>
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="indo">query_builder</v-icon>
                </template>
                <span>Aguardando resposta</span>
            </v-tooltip>
            <v-tooltip v-else-if="item.status === false" top>
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="red">close</v-icon>
                </template>
                <span>Rejeitada</span>
            </v-tooltip>
          </template>
          <template v-slot:item.valor_usado="{ item }">
            {{ item.valor_usado | currency({ fractionCount: 2 }) }}
          </template>
          <template v-slot:item.valor_inicial="{ item }">
            {{ item.valor_gasto | currency({ fractionCount: 2 }) }}
          </template>
          <template v-slot:item.data_resposta="{ item }">
            {{ item.data_abertura | formatDate('DD/MM/YYYY') }}
          </template>
          <template v-slot:item.nome="{ item }">
            {{ item.posto ? `${item.posto.nome_fantasia}` : `${item.rede.nome}` }}
          </template>
          <template v-slot:item.desconto="{ item }">
            {{ item.desconto ? item.desconto : 0 }}%
          </template>
          </v-data-table>
        </v-card>
        <div class="text-xs-center hidden-print-only">
          <v-pagination v-model="pagination.page" :length="pages" circle></v-pagination>
        </div>
      </v-flex>
    </v-layout>
    <v-footer
      absolute
      dense
      style="background: transparent"
    >
    </v-footer>
  </v-container>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      valid: false,
      combustivel: '',
      filtro: 'confirmadas',
      editando: false,
      menuDataInicial: false,
      date: moment().format('YYYY-MM-DD'),
      month: new Date().toISOString().substr(0, 10),
      dates: [],
      headersVisible: {
        combustivel: true,
        data_compra: true,
        pagamento: true,
        confirmada: true,
        tid: true,
        cpf: true,
        litrosTotal: true,
        litrosUsados: true,
        litrosRestante: true,
        valor: true,
        avatar: true
      },
      today: null,
      selectOpcoes: 'dia',
      filtros: {
        status: undefined,
        modelo_faturamento: 'prepago'
      },
      posto: null,
      rede: null,
      options: [
        {
          title: 'Dia',
          value: 'dia'
        },
        {
          title: 'Mês',
          value: 'mes'
        },
        {
          title: 'Intervalo',
          value: 'custom'
        }
      ],
      opcoes: {
        dia: true,
        custom: false,
        mes: false,
        data_relatorio: moment().format('DD/MM/YYYY'),
        mes_relatorio: null,
        intervalo_relatorio: [],
        confirmadas: true,
        posto_select: null
      },
      rules: {
        cpf: value => {
          const pattern = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/
          return !value || pattern.test(value) || 'CPF inválido.'
        }
      },
      headers: [
        {
          align: 'start',
          sortable: false,
          value: 'icone'
        },
        { text: 'Identificação', value: 'identificacao' },
        { text: 'Fornecedor', value: 'nome' },
        { text: 'Data', value: 'data_resposta' },
        { text: 'Valor (R$)', value: 'valor_inicial' },
        { text: 'Valor usado (R$)', value: 'valor_usado' },
        { text: 'Acréscimo (%)', value: 'desconto' },
        { text: 'Status', value: 'status' }
      ],
      pagination: {
        page: 1,
        rowsPerPage: 20
      },
      propostas: [],
      valor_total: 0,
      total_itens: 0,
      postos: [],
      redes: []
    }
  },
  beforeMount () {
    if (this.ready) {
      this.carregarPostos()
      this.carregarRedes()
      this.carregarPropostas()
    }
  },
  computed: {
    loading () {
      return this.$store.getters.loading
    },
    empresa () {
      return this.$store.getters.empresa
    },
    ready () {
      return !!this.headers && !!this.empresa
    },
    pages () {
      if (this.pagination.rowsPerPage == null ||
        this.total_itens == null
      ) return 0

      return Math.ceil(this.total_itens / this.pagination.rowsPerPage)
    }
  },
  watch: {
    date (val) {
      if (val) {
        this.opcoes.data_relatorio = this.formatDate(this.date)
      }
    },
    month (val) {
      if (val) {
        this.opcoes.mes_relatorio = this.formatDate(this.month)
      }
    },
    pagination: {
      handler (val, oldval) {
        if (this.empresa) this.carregarPropostas()
      },
      deep: true
    },
    empresa (val, oldval) {
      if (val !== null && val !== undefined && val !== oldval) {
        this.carregarPostos()
        this.carregarRedes()
        this.carregarPropostas()
      }
    },
    proposta (val, oldval) {
      if (val !== null && val !== undefined && val !== oldval) {
        this.selectPostoRede()
      }
    }
  },
  methods: {
    print () {
      window.print()
    },
    formatDate (date) {
      if (!date) return null

      if (this.opcoes.dia || this.opcoes.custom) {
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      } else {
        const [year, month] = date.split('-')
        return `${month}/${year}`
      }
    },
    orderDates () {
      this.dates.sort((a, b) => {
        a = new Date(a.split('-'))
        b = new Date(b.split('-'))
        return a < b ? -1 : a > b ? 1 : 0
      })
    },
    parseDate (date) {
      if (!date) return null

      if (this.opcoes.dia || this.opcoes.custom) {
        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      } else {
        const [month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}`
      }
    },
    limparOpcoes () {
      this.opcoes.dia = false
      this.opcoes.custom = false
      this.opcoes.mes = false
      this.search = ''
    },
    dataSelecionada () {
      if (this.opcoes.custom) {
        if (this.dates.length === 3) {
          const day1 = this.dates[0]
          const day2 = this.dates[2]
          this.dates = []
          this.dates = [day1, day2]
        }
        if (this.dates.length) {
          this.orderDates()
        }
        this.opcoes.intervalo_relatorio = this.dates.map(this.formatDate)
      } else {
        this.menuDataInicial = false
      }
    },
    carregarPostos () {
      this.$store.dispatch('postosComProposta').then(json => {
        this.postos = json
      })
    },
    carregarRedes () {
      this.$store.dispatch('redesComProposta').then(json => {
        this.redes = json
      })
    },
    carregarPropostas () {
      const filtro = {}
      if (this.posto !== null && this.posto !== undefined) {
        filtro.posto = this.posto
      }
      if (this.rede !== null && this.rede !== undefined) {
        filtro.rede = this.rede
      }
      if (this.filtros.status !== 'Todas' && this.filtros.status !== undefined) {
        filtro.status = this.filtros.status
      }
      filtro.modelo_faturamento = this.filtros.modelo_faturamento
      this.$store.dispatch('relatorioPropostas', {
        pagination: this.pagination,
        filter: filtro
      }).then(json => {
        this.propostas = json.results.propostas
        this.valor_total = json.results.valor_total
        this.total_itens = json.count
      })
    },
    valor_total_gasto () {
      let total = 0
      this.propostas.map((proposta) => {
        total += parseInt(proposta.valor_gasto)
      })
      return total
    }
  }
}
</script>
