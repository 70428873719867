<template>
  <v-card>
    <v-card-title>
      <v-flex xs2></v-flex>
      <v-flex xs8>
        <h3 class="text-center">
          {{
            imagem === '' ?
            'INSERIR IMAGEM' :
            imagem ?
            'PRÉ-VISUALIZAÇÃO DO BANNER':
            'VISUALIZAR BANNER'
          }}
        </h3>
      </v-flex>
      <v-flex xs2>
        <v-btn
          absolute
          style="right: 10px; top: 10px"
          text
          icon
          @click="$emit('close')"
          color="secondary"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-flex>
    </v-card-title>
    <v-alert v-show="alerta" :type="tipo" style="width: 100%;" class="pa-2" :value="true">
      <h4>{{alerta}}</h4>
    </v-alert>
    <v-divider v-show="alerta"></v-divider>
    <v-card-text>
      <v-layout
        justify-center
        column
        style="justify-content: center; align-items: center;"
      >
      <input
        type="file"
        style="display:none"
        accept="image/*"
        ref="imagemInput"
        @input="carregarImagem"
    />
    <v-flex xs12 v-show="cropping">
      <vue-croppie
        ref="croppie"
        :enableExif="true"
        :enforceBoundary="true"
        :enableResize="false"
        :enableOrientation="false"
        :mouseWheelZoom="true"
        :showZoomer="false"
        :boundary="{ width: croppie_width + 100, height: croppie_height + 100 }"
        :viewport="{ width: croppie_width, height: croppie_height }"
      ></vue-croppie>
      <v-btn
        text
        @click="recortarImagem()"
        block
        class="mt-3"
      >
        Recortar imagem
        </v-btn>
    </v-flex>
        <img
          v-if="imagem"
          :src="imagem"
          class="ml-3 mr-3 mt-2 clickable"
          width="300"
          slot="activator"
          @click="removerImagem()"
        />
        <v-progress-circular
          v-else-if="loading && !imagem"
          indeterminate
          :size="50"
          color="info"
        ></v-progress-circular>
        <v-tooltip
          bottom
          v-else-if="!cropping"
        >
          <template v-slot:activator="{ on }">
            <v-card
              hover
              class="ml-3 mr-3 mt-2 mb-2"
              width="400"
              v-on="on"
              @click="$refs.imagemInput.click()"
              :loading="loading"
            >
              <v-layout justify-center column>
                <v-icon size="15em">image</v-icon>
              </v-layout>
            </v-card>
          </template>
          <h4>Clique para inserir uma imagem</h4>
        </v-tooltip>
        <v-flex
          v-if="!cropping"
          xs12
          style="
            justify-content: center;
            align-items: center;
            display: flex;
          "
        >
          <v-btn
            class="mt-3 mb-3"
            color="primary"
            @click="$refs.imagemInput.click(), indexSelecionado = index"
            large
          >
            Inserir imagem
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions class="justify-space-around">
      <v-btn
        text
        block
        @click="fechar"
        class="grey--text text--darken-1"
      >
        CANCELAR
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
window.EXIF = require('exif-js')

export default {
  props: ['codigo_promocional', 'promocao'],
  data () {
    return {
      cupom_model: {
        url_imagem_codigo: ''
      },
      cropping: false,
      imagem: null,
      alerta: undefined,
      tipo: 'error',
      croppie_width: 400,
      croppie_height: 400
    }
  },
  computed: {
    empresa () {
      return this.$store.getters.empresa
    },
    loading () {
      return this.$store.getters.loading
    }
  },
  beforeMount () {
    if (this.codigo_promocional) {
      this.imagem = this.codigo_promocional.url_imagem_codigo
    } else if (this.promocao) {
      this.imagem = this.promocao.url_imagem_promocao
    }
  },
  watch: {
    codigo_promocional (val) {
      if (val) {
        this.imagem = val.url_imagem_codigo
      }
    },
    promocao (val) {
      if (val) {
        this.imagem = this.promocao.url_imagem_promocao
      }
    }
  },
  methods: {
    activeAlert (text, type) {
      this.alerta = text
      this.tipo = type
      const interval = setInterval(() => {
        this.alerta = undefined
        clearInterval(interval)
      }, 5000)
    },
    salvarBanner () {
      // Inserir código de seleção de função
      this.$store
        .dispatch(this.codigo_promocional ? 'codigoPromocional' : 'oferta',
          {
            method: 'PATCH',
            id: this.codigo_promocional ? this.codigo_promocional.id : this.promocao.id,
            body: {
              empresa: this.empresa.cnpj,
              url_imagem_codigo: this.imagem,
              url_imagem_promocao: this.imagem
            }
          }
        )
        .then(() => {
          this.$emit('success')
          this.fechar()
        })
    },
    fechar () {
      this.imagem = ''
      this.$emit('close')
    },
    carregarImagem (event) {
      this.imagem = null
      if (event.target.files && event.target.files[0]) {
        const imagem = event.target.files[0]
        const reader = new FileReader()
        reader.onloadend = e => {
          this.cropping = true
          this.$refs.croppie.bind({
            url: reader.result
          })
          this.$nextTick(() => {
            this.$refs.croppie.setZoom(0.1)
          })
        }
        reader.readAsDataURL(imagem)
      }
    },
    recortarImagem () {
      const options = {
        type: 'base64',
        format: 'png'
      }
      options.circle = false
      this.$refs.croppie.result(options, output => {
        this.inserirArquivoFirebase(output)
        this.cropping = false
      })
    },
    inserirArquivoFirebase (imagem) {
      this.$store.dispatch('inserirFirebase', {
        arquivo: imagem,
        id: Date.parse(new Date()).toString(),
        local: this.codigo_promocional ? this.codigo_promocional.id : this.promocao.id_oferta,
        tipo: 'png',
        rota: this.codigo_promocional ? 'codigo_promocional' : 'oferta'
      }).then(url => {
        this.imagem = url
        this.activeAlert('Imagem inserida com sucesso.', 'success')
        this.salvarBanner()
      }).catch(() => {
        this.activeAlert('Não foi possível inserir imagem.', 'error')
      })
    },
    removerImagem () {
      this.$store.dispatch('deletarArquivoFirebase', this.imagem).then(() => {
        this.imagem = null
        this.salvarBanner()
      })
      this.activeAlert('Imagem removida com sucesso.', 'success')
    }
  }
}
</script>
