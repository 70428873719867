<template>
  <v-dialog
    width="40em"
    persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        block
        large
        class="mb-3 mt-4"
        :loading="loading"
        v-on="on"
        v-bind="attrs"
        rounded
      >
        <v-icon class="mr-2">autorenew</v-icon>
        Integração Automática
      </v-btn>
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-card-title class="justify-center text-h5" v-if="step !== 1">
          Integração Automatica
        </v-card-title>
        <v-alert :value="true" v-if="alerta" :type="tipo">
          <h3>{{ alerta }}</h3>
        </v-alert>
        <v-divider v-else></v-divider>
        <v-card-text v-if="empresa">
          <v-stepper v-model="step" flat class="pa-0 ma-0" style="box-shadow: none;">
            <v-stepper-items>
              <v-stepper-content step="1" class="pa-0 ma-0">
                <v-layout justify-center>
                  <v-icon class="mt-4" size="15em" color="info">autorenew</v-icon>
                </v-layout>
                <p class="text-h5">
                  A Integração Automatica permite que os seus anúncios cadastrados em outro sistema sejam portados para o sistema da Apetrus.
                </p>
              </v-stepper-content>
              <v-stepper-content step="2" class="pa-0 ma-0">
                <v-form ref="formulario" v-model="valido" lazy-validation>
                  <v-select
                    class="mt-4"
                    label="Nome do Integrador"
                    outlined
                    v-model="integracao.tipo_integrador"
                    :rules="[v => !!v || 'Esse campo é obrigatório']"
                    :items="[
                      { text: 'Auto Nitro', value: 1 }
                    ]"
                  ></v-select>
                  <v-text-field
                    label="E-mail"
                    placeholder="Digite seu email da Auto Nitro"
                    outlined
                    v-model="integracao.email"
                    :rules="[v => !!v || 'Esse campo é obrigatório']"
                  ></v-text-field>
                  <v-text-field
                    label="Senha"
                    placeholder="Digite sua senha da Auto Nitro"
                    outlined
                    :type="mostrar ? 'text' : 'password'"
                    v-model="integracao.senha"
                    :append-icon="mostrar ? 'visibility' : 'visibility_off'"
                    :rules="[v => !!v || 'Esse campo é obrigatório']"
                    required
                    @click:append="mostrar = !mostrar"
                  ></v-text-field>
                </v-form>
                <v-layout justify-center v-if="integracao.id">
                  <v-btn
                    color="error"
                    small
                    rounded
                    @click="deletarIntegracao()"
                    :disabled="loading"
                  >Remover Integração Automática</v-btn>
                </v-layout>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-around">
          <v-flex xs6>
            <v-btn block text @click="dialog.value = false" :disabled="loading">
              Fechar
            </v-btn>
          </v-flex>
          <v-divider vertical></v-divider>
          <v-flex xs6>
            <v-btn block text @click="btnAvancar" color="info" :loading="loading" :disabled="loading">
              {{
                step === 1 ? 'Avançar' :
                step === 2 && integracao.id ? 'Editar' : 'Salvar'
              }}
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { aesKey, iv } from '@/keys.json'
const CryptoJS = require('crypto-js')
export default {
  data: () => ({
    step: 1,
    mostrar: false,
    valido: false,
    alerta: '',
    tipo: 'error',
    integracao: {
      tipo_integrador: 1
    }
  }),
  computed: {
    empresa () {
      return this.$store.getters.empresa
    },
    loading () {
      return this.$store.getters.loading
    }
  },
  beforeMount () {
    if (this.empresa && this.empresa.tipo_empresa === 2) {
      this.pegarIntegracao()
    }
  },
  watch: {
    empresa (val) {
      if (val) {
        this.pegarIntegracao()
      }
    },
    integracao (val) {
      if (val && val.id) {
        const interval = setInterval(() => {
          this.ativarAlerta('Você possui uma integração automática cadastrada', 'info', true)
          clearInterval(interval)
        }, 3000)
      }
    }
  },
  methods: {
    btnAvancar () {
      switch (this.step) {
        case 1:
          this.step++
          break
        case 2:
          this.cadastrarIntegracao()
      }
    },
    ativarAlerta (texto, tipo, permanente) {
      this.alerta = texto
      this.tipo = tipo
      if (!permanente) {
        const interval = setInterval(() => {
          this.alerta = undefined
          clearInterval(interval)
        }, 5000)
      }
    },
    cadastrarIntegracao () {
      if (this.$refs.formulario.validate()) {
        const ciphertext = CryptoJS.AES.encrypt(
          JSON.stringify({
            senha: this.integracao.senha
          }),
          CryptoJS.enc.Utf8.parse(aesKey),
          {
            iv: CryptoJS.enc.Utf8.parse(iv),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
          }
        ).toString()

        this.ativarAlerta('Puxando suas informações da Auto Nitro', 'info', true)
        this.$store.dispatch('integracaoAutomatica', {
          method: this.integracao.id ? 'PATCH' : 'POST',
          body: {
            ...this.integracao,
            empresa: this.empresa.cnpj,
            senha: ciphertext
          }
        }).then(json => {
          this.ativarAlerta('As informações foram salvas com sucesso.', 'success', false)
          const interval = setInterval(() => {
            this.integracao = {
              ...json,
              senha: undefined
            }
            clearInterval(interval)
          }, 1000)
        })
      } else {
        this.ativarAlerta('Você precisa preencher coerretamente o formulário.', 'error', false)
      }
    },
    deletarIntegracao () {
      this.$store.dispatch('integracaoAutomatica', {
        method: 'DELETE',
        body: {
          empresa: this.empresa.cnpj
        }
      }).then(() => {
        this.$refs.formulario.reset()
        this.integracao.id = undefined
        this.ativarAlerta('A Integração Automática foi apagada com sucesso.', 'success', false)
      })
    },
    pegarIntegracao () {
      this.$store.dispatch('integracaoAutomatica', {
        method: 'GET',
        parametros: {
          empresa: this.empresa.cnpj
        }
      }).then(json => {
        this.integracao = {
          ...json,
          senha: undefined
        }
        this.step = 2
      })
    }
  }
}
</script>
