<template>
  <aviso-bloqueio v-if="empresa && (!empresa.ativo || empresa.tipo_empresa !== 3)"/>
  <v-layout align-space-around justify-start fill-height column class="pt-5" v-else>
    <v-toolbar
      color="transparent"
      class="elevation-0 secondary--text"
      style="background: transparent">
      <v-toolbar-title>Postos Integrados</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" small fab color="info" @click="showFilter = !showFilter">
            <v-icon>{{ !showFilter ? 'filter_list' : 'filter_list_off' }}</v-icon>
          </v-btn>
        </template>
        <span>{{!showFilter ? 'Mostrar filtros' : 'Recolher filtros'}}</span>
      </v-tooltip>
    </v-toolbar>
    <v-slide-x-transition>
      <v-card v-show="showFilter" class="ml-4 mr-4">
        <v-card-text>
          <v-layout wrap justify-space-between>
            <v-flex xs10 md11>
              <v-layout wrap justify-space-around>
                <v-flex xs11 sm5 md3 lg2 xl2>
                  <v-text-field
                    label="CNPJ"
                    v-model="cnpj_posto"
                  ></v-text-field>
                </v-flex>
                <v-flex xs11 sm5 md3 lg2 xl2>
                  <v-text-field
                    label="Nome fantasia"
                    v-model="nome_fantasia"
                  ></v-text-field>
                </v-flex>
                <v-flex xs11 sm5 md3 lg2 xl2>
                  <v-text-field
                    label="Cidade"
                    v-model="cidade"
                  ></v-text-field>
                </v-flex>
                <v-flex xs11 sm5 md1 lg2 xl2>
                  <v-select
                    label="Estado"
                    v-model="estado"
                    :items="estados"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs2 md1 align-self-center>
              <v-btn fab small color="info" @click="carregarPostos(true)">
                <v-icon>search</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-slide-x-transition>
      <v-data-table
        hide-default-footer
        :headers="postoHeaders"
        :items="postos"
        :options.sync="pagination"
        no-data-text="Você ainda não possui postos cadastrados"
        class="elevation-1"
      >
        <template v-slot:item="props">
          <tr>
            <td><v-icon color="info">local_gas_station</v-icon></td>
            <td>{{ props.item.cnpj }}</td>
            <td>{{ props.item.nome_fantasia }}</td>
            <td>{{ props.item.endereco ? props.item.endereco.cidade : '' }}{{props.item.endereco ? `/${props.item.endereco.estado}` : ''}}</td>
            <td class="text-xs-right">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    color="primary"
                    @click="
                      dialogVisualizar = true,
                      postoSelecionado = props.item
                    "
                  >
                      <v-icon>visibility</v-icon>
                  </v-btn>
                </template>
                <span>Visualizar dados do posto</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    <div class="text-xs-center">
      <v-pagination v-model="pagination.page" :length="pages" circle></v-pagination>
    </div>
    <v-dialog v-model="dialogVisualizar" v-if="dialogVisualizar" scrollable width="650">
      <visualizar-posto-integrado
        :posto="postoSelecionado"
        @fechar="dialogVisualizar = false, postoSelecionado = undefined"
      />
    </v-dialog>
  </v-layout>
</template>
<script>
import VisualizarPostoIntegrado from '@/components/dialogs/VisualizarPostoIntegrado'
import AvisoBloqueio from '@/components/AvisoBloqueio'
export default {
  components: { VisualizarPostoIntegrado, AvisoBloqueio },
  data () {
    return {
      dialogVisualizar: false,
      postos: [],
      postoSelecionado: undefined,
      totalItens: 0,
      cnpj_posto: undefined,
      nome_fantasia: undefined,
      cidade: undefined,
      estado: undefined,
      showFilter: false,
      pagination: {
        page: 1,
        rowsPerPage: 5
      },
      postoHeaders: [
        {
          align: 'right',
          sortable: false
        },
        {
          text: 'CNPJ',
          value: 'cnpj',
          sortable: false,
          align: 'left'
        },
        {
          text: 'Nome',
          align: 'left',
          sortable: false,
          value: 'nome_fantasia'
        },
        {
          text: 'Local',
          value: 'estado.cidade',
          sortable: false,
          align: 'left'
        },
        {
          align: 'right',
          sortable: false
        }
      ]
    }
  },
  watch: {
    pagination: {
      handler (val, oldval) {
        if (this.empresa) this.carregarPostos(false)
      },
      deep: true
    },
    empresa (val) {
      if (val !== undefined && val !== null) {
        this.carregarPostos(false)
      }
    },
    showFilter (val) {
      if (!val) {
        this.cnpj_posto = undefined
        this.cidade = undefined
        this.estado = undefined
        this.nome_fantasia = undefined
      }
    }
  },
  computed: {
    pages () {
      const count = this.totalItens
      if (this.pagination.rowsPerPage == null || count == null) return 0

      return Math.ceil(count / this.pagination.rowsPerPage)
    },
    estados () {
      return this.$store.getters.estados
    },
    empresa () {
      return this.$store.getters.empresa
    }
  },
  beforeMount () {
    if (this.empresa) {
      this.carregarPostos(false)
    }
  },
  methods: {
    carregarPostos (isBtn) {
      const data = {
        cnpj_empresa: this.empresa.cnpj,
        automacao: true
      }
      data.page = isBtn ? 1 : this.pagination.page
      data.page_size = this.pagination.rowsPerPage
      if (this.cnpj_posto) data.cnpj = this.cnpj_posto
      if (this.nome_fantasia) data.nome_fantasia__iexact = this.nome_fantasia
      if (this.cidade) data.endereco__cidade__icontains = this.cidade
      if (this.estado) data.endereco__estado__iexact = this.estado
      this.$store.dispatch('pegarPostos', data).then(json => {
        this.postos = json.results
        this.totalItens = json.count
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
