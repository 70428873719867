var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","fill-height":"","grid-list-xs":"","pa-0":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","align-stretch":""}},[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-layout',{attrs:{"row":"","wrap":"","justify-end":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.print()}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("print")])],1),_c('v-list-item-title',[_vm._v("Imprimir relatório")])],1)],1)],1)],1)],1)],1),_c('v-flex',{staticClass:"print",attrs:{"xs12":""}},[_c('div',{staticClass:"pb-3 pt-5 hide-display"},[_c('v-layout',{attrs:{"row":""}},[_c('h1',[_vm._v(" Relatório de Recargas")]),_c('v-spacer'),_c('span',{staticClass:"display-2"},[_vm._v("Total: "+_vm._s(_vm._f("currency")(_vm.valor_total(),{ fractionCount: 2 })))])],1),_c('hr')],1),_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.recargassolicitadas,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.icone",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(_vm._s(item.proposta.posto ? 'local_gas_station' : 'ac_unit'))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.proposta.posto ? 'Posto' : 'Rede'))])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"teal"}},on),[_vm._v("done_all")])]}}],null,true)},[_c('span',[_vm._v("Confirmada")])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red"}},on),[_vm._v("close")])]}}],null,true)},[_c('span',[_vm._v("Compra não finalizada")])])]}},{key:"item.valor_recarga",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.valor_recarga,{ fractionCount: 2 }))+" ")]}},{key:"item.data_confirmacao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data_confirmacao,'DD/MM/YYYY'))+" ")]}},{key:"item.nome",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.proposta.posto ? ("" + (item.proposta.posto.nome_fantasia)) : ("" + (item.proposta.rede.nome)))+" ")]}},{key:"item.desconto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.proposta.desconto)+"% ")]}}])})],1),_c('div',{staticClass:"text-xs-center"},[_c('v-pagination',{attrs:{"length":_vm.pages,"circle":""},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1)],1),_c('v-footer',{staticStyle:{"background":"transparent"},attrs:{"absolute":"","dense":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }