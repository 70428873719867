<template>
  <aviso-bloqueio v-if="empresa && (!empresa.ativo || empresa.tipo_empresa === 3)"/>
  <v-container grid-list-md fluid class="pt-5" v-else>
    <v-toolbar color="transparent" class="elevation-0 secondary--text">
      <v-toolbar-title>{{apetrus ? 'Minhas Faturas com a Apetrus' : 'Minhas Faturas com Postos'}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-flex xs2>
        <v-select
          v-if="empresa"
          label="Filtrar Faturas"
          :items="[
            { text: 'Apetrus', value: true, tipo: 0 },
            { text: 'Postos', value: false, tipo: 1 },
          ].filter((item) => item.tipo == 0 || item.tipo === empresa.tipo_empresa)"
          v-model="apetrus"
        />
      </v-flex>
    </v-toolbar>
    <template v-for="(filter, index) in filters">
      <v-btn
        class="mr-3"
        :key="index"
        outlined
        rounded
        :color="filter.color"
        :disabled="selected_filter === filter.value"
        @click="selected_filter = filter.value"
      >{{filter.text}}</v-btn>
    </template>
    <v-list class="mt-3">
      <v-list-item v-if="!loading && !faturas.length">
        <v-list-item-content class="headline font-weight-light">
          <v-list-item-title class="text-xs-center">Nenhuma fatura para mostrar</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-subheader v-if="!loading && faturas.length">
        Clique para mostrar detalhes
      </v-subheader>
      <template v-if="!loading && faturas.length">
        <template v-for="(fatura, index) in faturas">
          <v-list-item :key="index" ripple class="mb-2">
            <v-list-item-icon  @click="detalhar(fatura)" style="cursor: pointer;">
              <v-icon color="green">assignment</v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="detalhar(fatura)" style="cursor: pointer;">
              <v-list-item-title>{{fatura.posto ? `Posto ${fatura.posto}` : fatura.rede? fatura.rede.nome : 'Fatura Apetrus'}}</v-list-item-title>
              <v-list-item-subtitle>Fatura do dia {{fatura.data_fatura.split('-').reverse().join('/')}}</v-list-item-subtitle>
              <v-list-item-subtitle>Valor: {{fatura.valor_faturamento | currency}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-action-text
                v-if="!fatura.fatura_paga"
                class="mb-2"
                style="width: 100%; justify-content: center; display: flex;"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon color="primary"  @click="dialogInserirComprovante(fatura)">
                      <v-icon>receipt</v-icon>
                    </v-btn>
                  </template>
                  <span>Anexar comprovante</span>
                </v-tooltip>
              </v-list-item-action-text>
              <v-list-item-action-text
                class="red--text font-weight-bold"
                v-if="!fatura.fatura_paga"
              >Pagamento Pendente</v-list-item-action-text>
              <template v-else>
                <v-list-item-action-text class="green--text font-weight-bold">Pagamento Efetuado</v-list-item-action-text>
                <v-list-item-action-text
                  class="font-weight-bold"
                >Pago em {{fatura.data_hora_pagamento.substr(0, 10).split('-').reverse().join('/')}}</v-list-item-action-text>
              </template>
            </v-list-item-action>
          </v-list-item>
        </template>
      </template>
    </v-list>
    <v-dialog width="550" v-model="dialogDetahes" v-if="dialogDetahes">
        <detalhes-fatura :fatura="fatura_selecionada" :apetrus="apetrus"></detalhes-fatura>
    </v-dialog>
    <v-dialog width="500" v-if="dialogAnexar" v-model="dialogAnexar">
      <dialog-anexar-comprovante
        :fatura="fatura_selecionada"
        :apetrus="apetrus"
        @fechar="dialogAnexar=false"
        @carregarFaturas="pegarFaturas"
      />
    </v-dialog>
  </v-container>
</template>

<script>

import DialogAnexarComprovante from '@/components/dialogs/DialogAnexarComprovante'
import DetalhesFatura from '@/components/dialogs/DetalhesFatura'
import AvisoBloqueio from '@/components/AvisoBloqueio'

import { faturamentoDao } from '@/api'
export default {
  components: { DialogAnexarComprovante, DetalhesFatura, AvisoBloqueio },
  data: () => ({
    faturas: [],
    dialogAnexar: false,
    dialogDetahes: false,
    selected_filter: null,
    selected_date: null,
    fatura_selecionada: null,
    apetrus: true,
    filters: [
      {
        text: 'Pendentes',
        value: 'pendentes',
        color: 'success'
      },
      {
        text: 'Pagas',
        value: 'pagas',
        color: 'primary'
      },
      {
        text: 'Todas',
        value: 'todas',
        color: 'error'
      }
    ]
  }),
  computed: {
    loading () {
      return this.$store.getters.loading
    },
    empresa () {
      return this.$store.getters.empresa
    },
    headers () {
      return this.$store.getters.headers
    },
    filtros () {
      const tempFiltros = {}
      tempFiltros.apetrus = this.apetrus
      switch (this.selected_filter) {
        case 'pendentes':
          tempFiltros.fatura_paga = false
          break
        case 'pagas':
          tempFiltros.fatura_paga = true
          break
      }
      return tempFiltros
    }
  },
  beforeMount () {
    if (this.empresa !== undefined && this.empresa !== null) {
      this.pegarFaturas()
    }
  },
  watch: {
    empresa (val) {
      if (val !== undefined && val !== null) {
        this.pegarFaturas()
      }
    },
    selected_filter (val) {
      if (val && this.picker) {
        this.pegarFaturas()
      }
    },
    filtros (val) {
      if (val) {
        this.pegarFaturas()
      }
    },
    apetrus () {
      this.pegarFaturas()
    }
  },
  methods: {
    pegarFaturas () {
      faturamentoDao.pegarFaturas(this.headers, this.empresa.cnpj, this.filtros).then(response => {
        if (!response.ok) {
          this.$store.dispatch('setError', 'Não foi possívei pegar faturas')
          return
        }
        return response.json()
      }).then(json => {
        this.faturas = json
      }).catch(() => {
        this.$store.dispatch('setError', 'Não foi possívei pegar faturas')
      })
    },
    detalhar (fatura) {
      this.fatura_selecionada = fatura
      this.dialogDetahes = true
    },
    dialogInserirComprovante (fatura) {
      this.fatura_selecionada = fatura
      this.dialogAnexar = true
    }
  }
}
</script>
