<template>
  <v-container fluid fill-height grid-list-xs pa-0>
    <v-layout row wrap align-stretch>
      <v-form v-model="valid">
        <v-layout row wrap justify-end>
          <v-flex shrink>
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="print()"
                >
                  <v-list-item-action>
                    <v-icon>print</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>Imprimir relatório</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-flex>
        </v-layout>
      </v-form>
      <v-flex xs12 class="print">
        <div class="pb-3 pt-5 hide-display">
          <v-layout row>
            <h1> Relatório de Recargas</h1>
            <v-spacer></v-spacer>
            <span class="display-2">Total: {{ valor_total() | currency({ fractionCount: 2 }) }}</span>
          </v-layout>
          <hr/>
        </div>
         <v-card>
          <v-data-table
            :headers="headers"
            :items="recargassolicitadas"
            hide-default-footer
          >
          <template v-slot:item.icone="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">{{ item.proposta.posto ? 'local_gas_station' : 'ac_unit' }}</v-icon>
              </template>
              <span>{{ item.proposta.posto ? 'Posto' : 'Rede' }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.status="{ item }">
            <v-tooltip v-if="item.status" top>
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="teal">done_all</v-icon>
                </template>
                <span>Confirmada</span>
            </v-tooltip>
            <v-tooltip v-else top>
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="red">close</v-icon>
                </template>
                <span>Compra não finalizada</span>
            </v-tooltip>
          </template>
          <template v-slot:item.valor_recarga="{ item }">
            {{ item.valor_recarga | currency({ fractionCount: 2 }) }}
          </template>
          <template v-slot:item.data_confirmacao="{ item }">
            {{ item.data_confirmacao | formatDate('DD/MM/YYYY') }}
          </template>
          <template v-slot:item.nome="{ item }">
            {{ item.proposta.posto ? `${item.proposta.posto.nome_fantasia}` : `${item.proposta.rede.nome}` }}
          </template>
          <template v-slot:item.desconto="{ item }">
            {{ item.proposta.desconto }}%
          </template>

          </v-data-table>
        </v-card>
        <div class="text-xs-center">
          <v-pagination v-model="pagination.page" :length="pages" circle></v-pagination>
        </div>
      </v-flex>
    </v-layout>
    <v-footer
      absolute
      dense
      style="background: transparent"
    >
    </v-footer>
  </v-container>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      valid: false,
      combustivel: '',
      filtro: 'confirmadas',
      editando: false,
      menuDataInicial: false,
      date: moment().format('YYYY-MM-DD'),
      month: new Date().toISOString().substr(0, 10),
      dates: [],
      headersVisible: {
        combustivel: true,
        data_compra: true,
        pagamento: true,
        confirmada: true,
        tid: true,
        cpf: true,
        litrosTotal: true,
        litrosUsados: true,
        litrosRestante: true,
        valor: true,
        avatar: true
      },
      today: null,
      selectOpcoes: 'dia',
      filtros: [
        {
          title: 'Confirmadas',
          value: 'confirmadas'
        },
        {
          title: 'Todas',
          value: 'todas'
        }
      ],
      options: [
        {
          title: 'Dia',
          value: 'dia'
        },
        {
          title: 'Mês',
          value: 'mes'
        },
        {
          title: 'Intervalo',
          value: 'custom'
        }
      ],
      opcoes: {
        dia: true,
        custom: false,
        mes: false,
        data_relatorio: moment().format('DD/MM/YYYY'),
        mes_relatorio: null,
        intervalo_relatorio: [],
        confirmadas: true,
        posto_select: null
      },
      rules: {
        cpf: value => {
          const pattern = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/
          return !value || pattern.test(value) || 'CPF inválido.'
        }
      },
      total_relatorio_vouchers: 0,
      Compras: [],
      search: '',
      headers: [
        {
          align: 'start',
          sortable: false,
          value: 'icone'
        },
        { text: 'Fornecedor', value: 'nome' },
        { text: 'Data aprovada', value: 'data_confirmacao' },
        { text: 'Valor (R$)', value: 'valor_recarga' },
        { text: 'Desconto (%)', value: 'desconto' },
        { text: 'Status', value: 'status' }
      ],
      pagination: {
        page: 1,
        rowsPerPage: 5
      },
      creditos: [],
      postosredes: []
    }
  },
  beforeMount () {
    if (this.ready) {
      this.$store.dispatch('carregarCreditosSolicitados', this.pagination)
    }
  },
  computed: {
    compras () {
      return this.$store.getters.compras
    },
    loading () {
      return this.$store.getters.loading
    },
    total_relatorio_compras () {
      return this.$store.getters.total_relatorio_compras
    },
    tempOpcoes () {
      const tempOpcoes = {
        run: true
      }
      tempOpcoes.ativo = true
      if (this.filtro !== 'todas') {
        tempOpcoes.confirmado = true
        tempOpcoes.recompra = false
      }
      if (this.filtro === 'confirmadas') {
        tempOpcoes.em_especie = false
      }
      this.valid && this.opcoes.masked_cpf && this.opcoes.masked_cpf !== '' && (tempOpcoes.cliente__pessoa__cpf = this.opcoes.masked_cpf.replace(/[^\d]+/g, ''))

      // Validando as datas
      if (this.opcoes.custom && this.opcoes.intervalo_relatorio && this.opcoes.intervalo_relatorio.length === 2) {
        tempOpcoes.data_compra__gte = this.opcoes.intervalo_relatorio[0]
        tempOpcoes.data_compra__lte = this.opcoes.intervalo_relatorio[1]
      } else if (this.opcoes.dia && this.date) {
        tempOpcoes.data_compra = this.date.split('-').reverse().join('/')
      } else if (this.opcoes.mes && this.month && this.month.length) {
        const initialDate = new Date(`${this.month.split('-')[1]}-01-${this.month.split('-')[0]}`.split('-').join('/'))
        const finalDate = new Date(initialDate.getFullYear(), initialDate.getMonth() + 1, 0)

        tempOpcoes.data_compra__gte = initialDate.toISOString().substr(0, 10).split('-').reverse().join('/')
        tempOpcoes.data_compra__lte = finalDate.toISOString().substr(0, 10).split('-').reverse().join('/')
      } else {
        tempOpcoes.run = false
      }
      tempOpcoes.posto = this.opcoes.posto_select
      return tempOpcoes
    },
    // propostas () {
    //   return this.$store.getters.propostas
    // },
    empresa () {
      return this.$store.getters.empresa
    },
    ready () {
      return !!this.headers && !!this.empresa
    },
    recargassolicitadas () {
      return this.$store.getters.recargassolicitadas
    },
    totalItemsPage () {
      return this.$store.getters.totalItemsPage
    },
    pages () {
      if (this.pagination.rowsPerPage == null ||
        this.totalItemsPage == null
      ) return 0

      return Math.ceil(this.totalItemsPage / this.pagination.rowsPerPage)
    }
  },
  watch: {
    tempOpcoes (val) {
      if (val && val.run) {

      }
    },
    filtro (val) {
      if (val === 'confirmadas') {
        this.opcoes.confirmadas = true
      } else {
        this.opcoes.confirmadas = false
      }
    },
    date (val) {
      if (val) {
        this.opcoes.data_relatorio = this.formatDate(this.date)
      }
    },
    month (val) {
      if (val) {
        this.opcoes.mes_relatorio = this.formatDate(this.month)
      }
    },
    selectOpcoes (val) {
      this.limparOpcoes()
      if (val === 'dia') {
        this.opcoes.dia = true
        this.opcoes.data_relatorio = this.formatDate(this.date)
      } else if (val === 'custom') {
        this.opcoes.custom = true
        this.opcoes.data_relatorio = this.formatDate(this.date)
      } else if (val === 'mes') {
        this.opcoes.mes = true
        this.opcoes.mes_relatorio = this.formatDate(this.month)
      }
    },
    pagination: {
      handler (val, oldval) {
        this.carregarRecargas()
      },
      deep: true
    },
    empresa (val, oldval) {
      if (val !== null && val !== undefined && val !== oldval) {
        this.carregarRecargas()
      }
    },
    proposta (val, oldval) {
      if (val !== null && val !== undefined && val !== oldval) {
        this.selectPostoRede()
      }
    }
  },
  mounted () {
    if (this.empresa) {
      this.carregarRecargas()
    }
  },
  methods: {
    print () {
      window.print()
    },
    formatDate (date) {
      if (!date) return null

      if (this.opcoes.dia || this.opcoes.custom) {
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      } else {
        const [year, month] = date.split('-')
        return `${month}/${year}`
      }
    },
    orderDates () {
      this.dates.sort((a, b) => {
        a = new Date(a.split('-'))
        b = new Date(b.split('-'))
        return a < b ? -1 : a > b ? 1 : 0
      })
    },
    parseDate (date) {
      if (!date) return null

      if (this.opcoes.dia || this.opcoes.custom) {
        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      } else {
        const [month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}`
      }
    },
    limparOpcoes () {
      this.opcoes.dia = false
      this.opcoes.custom = false
      this.opcoes.mes = false
      this.search = ''
    },
    dataSelecionada () {
      if (this.opcoes.custom) {
        if (this.dates.length === 3) {
          const day1 = this.dates[0]
          const day2 = this.dates[2]
          this.dates = []
          this.dates = [day1, day2]
        }
        if (this.dates.length) {
          this.orderDates()
        }
        this.opcoes.intervalo_relatorio = this.dates.map(this.formatDate)
      } else {
        this.menuDataInicial = false
      }
    },
    carregarRecargas () {
      var data = {
        pagination: this.pagination,
        empresa: this.empresa.cnpj
      }
      this.$store.dispatch('carregarCreditosSolicitados', data)
    },
    selectPostoRede () {
      let nomespostosrede = ''
      let cnpjpostosrede = ''
      const result = []
      for (const i in this.proposta) {
        if (this.proposta[i].posto !== null) {
          nomespostosrede = this.proposta[i].posto.nome_fantasia
          cnpjpostosrede = this.proposta[i].posto.cnpj
        } else {
          nomespostosrede = this.proposta[i].rede.matriz.nome_fantasia
          cnpjpostosrede = this.proposta[i].rede.matriz.cnpj
        }
        const test = { nome_fantasia: nomespostosrede, cnpj: cnpjpostosrede }
        result.push(test)
      }
      this.postosredes = result
    },
    valor_total () {
      let total = 0
      this.creditos = this.recargassolicitadas.results
      for (const i in this.creditos) {
        total += parseInt(this.creditos[i].valor_recarga)
      }
      return total
    }
  }
}
</script>
