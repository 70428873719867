var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"justify-center"},[_c('h2',[_vm._v("INSERIR MEIO DE RECEBIMENTO")])]),_c('v-card-text',[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-items',[_c('v-stepper-content',{staticClass:"pa-0",attrs:{"step":"1"}},[_c('v-container',{attrs:{"flex":"","grid-list-lg":""}},[_c('v-form',{ref:"firstForm",model:{value:(_vm.valid1),callback:function ($$v) {_vm.valid1=$$v},expression:"valid1"}},[_c('v-layout',{attrs:{"justify-center":"","row":"","wrap":""}},[_c('v-flex',{staticClass:"text-center",attrs:{"xs12":""}},[_vm._v("Selecione uma das formas abaixo")]),_c('v-flex',{staticClass:"pa-0 ma-0",attrs:{"xs12":"","md8":""}},[_c('v-select',{attrs:{"outlined":"","placeholder":"Clique para selecionar","single-line":"","items":_vm.formasPag,"item-text":"descricao","required":"","return-object":"","hide-details":"","rules":[
                        function (v) { return !!v || ''; }
                    ]},model:{value:(_vm.pagamento),callback:function ($$v) {_vm.pagamento=$$v},expression:"pagamento"}})],1)],1)],1)],1)],1),_c('v-stepper-content',{staticClass:"pa-0",attrs:{"step":"2"}},[_c('v-container',{attrs:{"flex":"","grid-list-lg":""}},[_c('v-form',{ref:"secondForm",model:{value:(_vm.valid2),callback:function ($$v) {_vm.valid2=$$v},expression:"valid2"}},[(!_vm.loading)?_c('v-layout',{attrs:{"justify-center":"","row":"","wrap":""}},[_c('v-flex',{staticClass:"title primary--text text-xs-center",attrs:{"xs12":""}},[_vm._v("Insira os dados para os seguintes campos")]),(_vm.pagamento_secundario)?_c('v-flex',{staticClass:"secondary--text text-xs-center",attrs:{"xs11":""}},[_vm._v("ATENÇÃO! Esta forma de pagamento é secundária, usada para recebimento pix e carteira digital pela empresa.")]):_vm._e(),_vm._l((_vm.fields),function(field,index){return [(field === 'chave_pix')?_c('v-flex',{key:index,attrs:{"xs12":"","md10":""}},[_c('v-select',{attrs:{"label":"Tipo de chave","items":[
                      'CNPJ',
                      'Número de Telefone',
                      'Chave Aleatória'
                    ]},model:{value:(_vm.tipo_chave),callback:function ($$v) {_vm.tipo_chave=$$v},expression:"tipo_chave"}}),_c('v-text-field',{attrs:{"label":field,"hide-details":"","required":"","mask":_vm.tipo_chave === 'CNPJ' ? '##.###.###/####-##' : (_vm.tipo_chave === 'Número de Telefone' ? '(##) # ####-####' : ''),"rules":[
                        function (v) { return !!v || ''; }
                      ]},model:{value:(_vm.pagamento_model.formulario[field]),callback:function ($$v) {_vm.$set(_vm.pagamento_model.formulario, field, $$v)},expression:"pagamento_model.formulario[field]"}})],1):_c('v-flex',{key:index,attrs:{"xs12":"","md10":""}},[_c('v-text-field',{attrs:{"label":field,"hide-details":"","required":"","rules":[
                        function (v) { return !!v || ''; }
                      ]},model:{value:(_vm.pagamento_model.formulario[field]),callback:function ($$v) {_vm.$set(_vm.pagamento_model.formulario, field, $$v)},expression:"pagamento_model.formulario[field]"}})],1)]})],2):_c('v-layout',{staticClass:"ma-4",attrs:{"justify-center":""}},[_c('v-progress-circular',{attrs:{"size":"100","color":"info","indeterminate":""}})],1)],1)],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"justify-space-around"},[_c('v-flex',{attrs:{"xs6":""}},[_c('v-btn',{attrs:{"block":"","text":"","color":"grey darken-3"},on:{"click":function($event){return _vm.cancelar()}}},[_vm._v(" CANCELAR ")])],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-flex',{attrs:{"xs6":""}},[_c('v-btn',{attrs:{"block":"","text":"","color":"green","disabled":_vm.disabledBtn,"loading":_vm.loading},on:{"click":function($event){return _vm.continuar()}}},[(_vm.step === 1)?[_vm._v("CONTINUAR")]:(_vm.step === 2)?[_vm._v("CONFIRMAR")]:_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }