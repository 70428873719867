import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/app/Home.vue'
import AuthHandle from './views/auth/AuthHandle'
import Login from './views/auth/Login.vue'
import Colaborador from './views/app/Colaborador'
import Frota from './views/app/Frota'
import Ofertas from './views/app/Ofertas'
import Vouchers from './views/app/Vouchers'
import Negociacoes from './views/app/Negociacoes'
import Propostas from './views/app/Propostas'
import Empresas from './views/app/Empresas'
import Permissoes from './views/app/Permissoes'
import MeusCreditos from './views/app/MeusCreditos'
// import Dashboard from './views/app/Dashboard'
import Relatorio from './views/app/Relatorio'
import RelatorioCompras from './views/app/relatorios/RelatorioCompras'
import RelatorioPropostas from './views/app/relatorios/RelatorioPropostas'
import RelatorioRecargas from './views/app/relatorios/RelatorioRecargas'
import relatorioVouchers from './views/app/relatorios/RelatorioVouchers'
import Cartoes from './views/app/Cartoes'
import Faturas from './views/app/Faturas'
import Configuracoes from './views/app/Configuracoes'
import EditarPerfil from './views/app/EditarPerfil'
import CarteiraVirtual from './views/app/CarteiraVirtual'
import VeiculosAVenda from './views/app/VeiculosAVenda'
import Veiculos from './views/app/Veiculos'
import PropostaVeiculos from './views/app/PropostaVeiculos'
import EditarEmpresa from './views/app/EditarEmpresa'
import PostosIntegrados from './views/app/PostosIntegrados'
import Integracao from './views/app/Integracao'
import MeiosDeRecebimento from './views/app/MeiosDeRecebimento'
import MeusProdutos from './views/app/MeusProdutos'
import MeusServicos from './views/app/MeusServicos'
import MeusClientes from './views/app/MeusClientes'
import MeusFuncionarios from './views/app/MeusFuncionarios'
import Repasse from './views/app/Repasse'
import Leiloes from './views/app/Leiloes'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login,
      props: (route) => ({
        mode: route.query.mode
      })
    },
    {
      path: '/auth',
      component: AuthHandle,
      props: (route) => ({
        mode: route.query.mode,
        oobCode: route.query.oobCode,
        apiKey: route.query.apiKey,
        lang: route.query.lang
      })
    },
    {
      path: '/home',
      component: Home,
      children: [
        {
          path: '',
          component: Empresas,
          props: { fullscreen: true }
        },
        {
          path: '/veiculosparavender',
          component: VeiculosAVenda
        },
        {
          path: '/repasse',
          component: Repasse
        },
        {
          path: '/leiloes',
          component: Leiloes
        },
        {
          path: '/postos-integrados',
          component: PostosIntegrados
        },
        {
          path: '/integracao',
          component: Integracao
        },
        {
          path: '/veiculos',
          component: Veiculos
        },
        {
          path: '/colaborador',
          component: Colaborador
        },
        {
          path: '/frota',
          component: Frota
        },
        {
          path: '/negociacoes',
          component: Negociacoes
        },
        {
          path: '/propostas-veiculos',
          component: PropostaVeiculos
        },
        {
          path: '/vouchers',
          component: Vouchers
        },
        {
          path: '/propostas',
          component: Propostas
        },
        {
          path: '/ofertas',
          component: Ofertas
        },
        {
          path: '/carteiravirtual',
          component: CarteiraVirtual
        },
        {
          path: '/permissoes',
          component: Permissoes
        },
        {
          path: '/meuscreditos',
          component: MeusCreditos
        },
        // {
        //   path: '/Dashboard',
        //   component: Dashboard
        // },
        {
          path: '/Cartoes',
          component: Cartoes
        },
        {
          path: '/meusservicos',
          component: MeusServicos
        },
        {
          path: '/meusprodutos',
          component: MeusProdutos
        },
        {
          path: '/meusclientes',
          component: MeusClientes
        },
        {
          path: '/meusfuncionarios',
          component: MeusFuncionarios
        },
        {
          path: '/minhasfaturas',
          component: Faturas
        },
        {
          path: '/configuracoes',
          component: Configuracoes
        },
        {
          path: '/editarperfil',
          component: EditarPerfil
        },
        {
          path: '/editarempresa',
          component: EditarEmpresa
        },
        {
          path: '/meios-de-recebimento',
          component: MeiosDeRecebimento
        },
        {
          path: '/relatorio',
          component: Relatorio,
          children: [
            {
              path: '/relatorio/propostas',
              component: RelatorioPropostas
            },
            {
              path: '/relatorio/compras',
              component: RelatorioCompras
            },
            {
              path: '/relatorio/recargas',
              component: RelatorioRecargas
            },
            {
              path: '/relatorio/vouchers',
              component: relatorioVouchers
            }
          ]
        }
      ]
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    }
  ]
})

export default router
