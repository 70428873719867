<template>
  <v-container fluid fill-height grid-list-xs pa-0>
    <v-layout row wrap align-stretch>
      <v-form v-model="valid">
        <v-layout row wrap justify-center>
          <v-flex xs4 md3>
            <v-select
              :items="filtros"
              item-text="title"
              label="Filtros"
              item-value="value"
              v-model="data.disponivel"
            >
            </v-select>
          </v-flex>
          <v-flex xs4 md3>
            <v-select
              :items="funcionarios_empresa"
              item-text="nome_email"
              label="Funcionário"
              item-value="id_funcionario"
              v-model="data.funcionario"
              clearable
            >
            </v-select>
          </v-flex>
          <v-flex xs4 md3>
            <v-select
              :items="veiculos"
              item-text="identificacao"
              label="Veículo"
              item-value="identificacao"
              v-model="data.veiculo"
              clearable
            >
            </v-select>
          </v-flex>
          <v-flex xs4 md2>
            <v-select
              :disabled="data.rede !== null"
              :items="postos"
              item-text="nome_fantasia"
              label="Posto"
              item-value="cnpj"
              v-model="data.posto"
              clearable
            >
            </v-select>
          </v-flex>
          <v-flex xs4 md2>
            <v-select
              :disabled="data.posto !== null"
              :items="redes"
              item-text="nome"
              label="Rede"
              item-value="id_rede"
              v-model="data.rede"
              clearable
            >
            </v-select>
          </v-flex>
          <v-flex xs4 md2>
            <v-select
              :items="[
                // {text: 'Pós-paga', value: 'pospago'},
                {text: 'Pré-paga', value: 'prepago'},
                {text: 'Presencial', value: 'presencial'}
              ]"
              item-text="text"
              label="Tipo de proposta"
              item-value="value"
              v-model="data.tipo_proposta"
              clearable
            >
            </v-select>
          </v-flex>
          <v-flex xs3 m1>
           <v-select :items="propostas" label="Proposta" item-text="identificacao" item-value="id" v-model="proposta" clearable></v-select>
          </v-flex>
          <v-flex xs3 md1>
            <v-select
              :items="options"
              item-text="title"
              item-value="value"
              v-model="selectOpcoes"
            >
            </v-select>
          </v-flex>
          <v-flex xs4 :md3="opcoes.custom" :md2="!opcoes.custom">
            <v-menu
              ref="menuDataInicial"
              v-model="menuDataInicial"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-combobox
                  v-if="opcoes.custom"
                  v-model="opcoes.intervalo_relatorio"
                  label="Dias"
                  multiple
                  persistent-hint
                  chips
                  small-chips
                  readonly
                  flat
                  prepend-icon="event"
                  required
                  v-on="on"
                  clearable
                ></v-combobox>
                <v-combobox
                  v-else-if="opcoes.dia"
                  v-model="opcoes.data_relatorio"
                  label="Dia"
                  persistent-hint
                  chips
                  small-chips
                  readonly
                  flat
                  @blur="date = parseDate(opcoes.data_relatorio)"
                  prepend-icon="event"
                  required
                  v-on="on"
                  clearable
                ></v-combobox>
                <v-combobox
                  v-else
                  v-model="opcoes.mes_relatorio"
                  label="Mes"
                  persistent-hint
                  chips
                  small-chips
                  readonly
                  flat
                  @blur="month = parseDate(opcoes.mes_relatorio)"
                  prepend-icon="event"
                  required
                  v-on="on"
                  clearable
                ></v-combobox>
              </template>
              <v-date-picker v-show="opcoes.custom" multiple v-model="dates" type="date" no-title @input="dataSelecionada" locale="pt-br"></v-date-picker>
              <v-date-picker v-show="opcoes.dia" v-model="date" type="date" no-title @input="dataSelecionada" locale="pt-br"></v-date-picker>
              <v-date-picker v-show="opcoes.mes" v-model="month" type="month" no-title @input="dataSelecionada" locale="pt-br"></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex shrink>
            <v-btn fab dark small color="primary" @click="carregarRelatorio">
              <v-icon>search</v-icon>
            </v-btn>
          </v-flex>
          <v-flex shrink>
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="print()"
                >
                  <v-list-item-action>
                    <v-icon>print</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>Imprimir relatório</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-flex>
        </v-layout>
      </v-form>
      <v-flex xs12 class="print">
        <div class="pb-3 pt-5">
          <v-layout row>
            <h1> Relatório de Vouchers</h1>
            <v-spacer></v-spacer>
            <span class="display-2">Total: {{ total_relatorio_vouchers | currency({ fractionCount: 2 }) }}</span>
          </v-layout>
          <hr/>
        </div>
         <v-card>
          <v-data-table
            :headers="headers"
            :items="Vouchers"
            hide-default-footer
            :options.sync="paginationVoucher"
          >
          <template v-slot:item.disponivel="{ item }">
                <v-tooltip v-if="item.disponivel" top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="teal">done_all</v-icon>
                  </template>
                  <span>Ativo</span>
                </v-tooltip>
                <v-tooltip v-else top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="red">close</v-icon>
                  </template>
                  <span>Usado</span>
                </v-tooltip>
          </template>
          <template v-slot:item.valor="{ item }">
            {{ item.valor | currency({ fractionCount: 2 }) }}
          </template>
          <template v-slot:item.usuario_utilizador="{ item }">
            <div v-if="item.usuario_utilizador !== null && item.usuario_utilizador !== undefined">
            {{`${item.usuario_utilizador.nome}`}}
            </div>
          </template>
          <template v-slot:item.data_gerado="{ item }">
            {{ item.data_gerado | formatDate('DD/MM/YYYY') }}
          </template>
          <template v-slot:item.data_inativado="{ item }">
            {{ item.data_inativado | formatDate('DD/MM/YYYY') }}
          </template>
          <template v-slot:item.posto="{ item }">
            {{ item.rede ? `Rede ${item.rede.nome}` : `Posto ${item.compra.posto.nome_fantasia}`  }}
          </template>
          <template  v-slot:item.compra="{ item }">
            <div v-if="item.compra.kms_odometro !== null">
              {{ item.compra.kms_odometro }} Km
            </div>
             <div v-else>
                Não informado
            </div>
          </template>
          </v-data-table>
        </v-card>
        <div class="text-xs-center">
          <v-pagination v-model="paginationVoucher.page" :length="pages" circle></v-pagination>
        </div>
      </v-flex>
    </v-layout>
    <v-footer
      absolute
      dense
      style="background: transparent"
    >
    </v-footer>
  </v-container>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      pagination: {
        page: 1,
        rowsPerPage: 20
      },
      paginationVoucher: {
        page: 1,
        rowsPerPage: 10
      },
      proposta: undefined,
      valid: false,
      combustivel: '',
      filtro: 'todos',
      editando: false,
      menuDataInicial: false,
      date: moment().format('YYYY-MM-DD'),
      month: new Date().toISOString().substr(0, 10),
      dates: [],
      headersVisible: {
        combustivel: true,
        data_compra: true,
        pagamento: true,
        confirmada: true,
        tid: true,
        cpf: true,
        litrosTotal: true,
        litrosUsados: true,
        litrosRestante: true,
        valor: true,
        avatar: true
      },
      funcionarios_empresa: [],
      today: null,
      selectOpcoes: 'dia',
      filtros: [
        {
          title: 'Todos',
          value: null
        },
        {
          title: 'Consumidos',
          value: false
        },
        {
          title: 'Pendentes',
          value: true
        }
      ],
      data: {
        disponivel: null,
        funcionario: null,
        rede: null,
        posto: null,
        veiculo: null,
        tipo_proposta: null
      },
      options: [
        {
          title: 'Dia',
          value: 'dia'
        },
        {
          title: 'Mês',
          value: 'mes'
        },
        {
          title: 'Intervalo',
          value: 'custom'
        }
      ],
      postos: [],
      redes: [],
      opcoes: {
        dia: true,
        custom: false,
        mes: false,
        data_relatorio: moment().format('DD/MM/YYYY'),
        mes_relatorio: null,
        intervalo_relatorio: [],
        recebidos: false
      },
      rules: {
        cpf: value => {
          const pattern = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/
          return !value || pattern.test(value) || 'CPF inválido.'
        }
      },
      total_relatorio_vouchers: 0,
      Vouchers: [],
      search: '',
      total_itens: 0,
      headers: [
        {
          text: 'Código',
          align: 'start',
          sortable: false,
          value: 'numero'
        },
        { text: 'local de uso', value: 'posto' },
        { text: 'Funcionário', value: 'voucher_funcionario.funcionario.nome_email' },
        { text: 'Gerado em (R$)', value: 'data_gerado' },
        { text: 'Usado em', value: 'data_inativado' },
        { text: 'Odómetro (Km)', value: 'compra' },
        { text: 'Valor (R$)', value: 'valor' },
        { text: 'Status', value: 'disponivel' }
      ]
    }
  },
  computed: {
    vouchers () {
      return this.$store.getters.vouchers
    },
    loading () {
      return this.$store.getters.loading
    },
    total_relatorio_compras () {
      return this.$store.getters.total_relatorio_compras
    },
    ready () {
      return !!this.headers && !!this.empresa
    },
    empresa () {
      return this.$store.getters.empresa
    },
    funcionarios () {
      return this.$store.getters.funcionarios
    },
    veiculos () {
      return this.$store.getters.veiculos
    },
    pages () {
      if (this.paginationVoucher.rowsPerPage == null ||
        this.total_itens == null
      ) return 0

      return Math.ceil(this.total_itens / this.paginationVoucher.rowsPerPage)
    },
    propostas () {
      return this.$store.getters.propostas
    },
    filtro_proposta () {
      return {
        rede: this.data.rede,
        posto: this.data.posto,
        modelo_faturamento: this.data.tipo_proposta,
        not_page: true
      }
    }
  },
  watch: {
    empresa (val) {
      if (val !== null && val !== undefined) {
        this.carregarPostos()
        this.carregarRedes()
        this.carregarFuncionarios()
        this.carregarVeiculos()
        this.carregarPropostas()
      }
    },
    filtro (val) {
      this.opcoes.recebidos = false
      if (val === 'recebidos') {
        this.opcoes.recebidos = true
      }
    },
    date (val) {
      if (val) {
        this.opcoes.data_relatorio = this.formatDate(this.date)
      }
    },
    month (val) {
      if (val) {
        this.opcoes.mes_relatorio = this.formatDate(this.month)
      }
    },
    filtro_proposta () {
      this.carregarPropostas()
    },
    selectOpcoes (val) {
      this.limparOpcoes()
      if (val === 'dia') {
        this.opcoes.dia = true
        this.opcoes.data_relatorio = this.formatDate(this.date)
      } else if (val === 'custom') {
        this.opcoes.custom = true
        this.opcoes.data_relatorio = this.formatDate(this.date)
      } else if (val === 'mes') {
        this.opcoes.mes = true
        this.opcoes.mes_relatorio = this.formatDate(this.month)
      }
    },
    paginationVoucher: {
      handler (val, oldval) {
        this.carregarRelatorio()
      },
      deep: true
    }
  },
  mounted () {
    this.today = new Date().setHours(0, 0, 0, 0)
  },
  beforeMount () {
    if (this.ready) {
      this.carregarPostos()
      this.carregarRedes()
      this.carregarFuncionarios()
      this.carregarVeiculos()
      this.carregarPropostas()
    }
  },
  methods: {
    print () {
      window.print()
    },
    formatDate (date) {
      if (!date) return null

      if (this.opcoes.dia || this.opcoes.custom) {
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      } else {
        const [year, month] = date.split('-')
        return `${month}/${year}`
      }
    },
    orderDates () {
      this.dates.sort((a, b) => {
        a = new Date(a.split('-'))
        b = new Date(b.split('-'))
        return a < b ? -1 : a > b ? 1 : 0
      })
    },
    parseDate (date) {
      if (!date) return null

      if (this.opcoes.dia || this.opcoes.custom) {
        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      } else {
        const [month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}`
      }
    },
    limparOpcoes () {
      this.opcoes.dia = false
      this.opcoes.custom = false
      this.opcoes.mes = false
    },
    carregarRelatorio () {
      const filtro = {}
      if (this.data.posto !== null && this.data.posto !== undefined) {
        filtro.posto = this.data.posto
      }
      if (this.data.rede !== null && this.data.rede !== undefined) {
        filtro.rede = this.data.rede
      }
      if (this.data.funcionario !== null && this.data.funcionario !== undefined) {
        filtro.vouchers_funcionario__funcionario = this.data.funcionario
      }
      if (this.data.veiculo !== null && this.data.veiculo !== undefined) {
        filtro.veiculo__identificacao = this.data.veiculo
      }
      if (this.data.disponivel !== null && this.data.disponivel !== undefined) {
        filtro.disponivel = this.data.disponivel
      }
      if (this.data.disponivel === false) {
        if (this.opcoes.custom && this.opcoes.intervalo_relatorio && this.opcoes.intervalo_relatorio.length === 2) {
          filtro.data_inativado__gte = this.opcoes.intervalo_relatorio[0]
          filtro.data_inativado__lte = this.opcoes.intervalo_relatorio[1]
        } else if (this.opcoes.dia && this.date) {
          filtro.data_inativado = this.date.split('-').reverse().join('/')
        } else if (this.opcoes.mes && this.month) {
          const initialDate = new Date(`${this.month.split('-')[1]}-01-${this.month.split('-')[0]}`.split('-').join('/'))
          const finalDate = new Date(initialDate.getFullYear(), initialDate.getMonth() + 1, 0)

          filtro.data_inativado__gte = initialDate.toISOString().substr(0, 10).split('-').reverse().join('/')
          filtro.data_inativado__lt = finalDate.toISOString().substr(0, 10).split('-').reverse().join('/')
        }
      } else {
        if (this.opcoes.custom && this.opcoes.intervalo_relatorio && this.opcoes.intervalo_relatorio.length === 2) {
          filtro.data_gerado__gte = this.opcoes.intervalo_relatorio[0]
          filtro.data_gerado__lte = this.opcoes.intervalo_relatorio[1]
        } else if (this.opcoes.dia && this.date) {
          filtro.data_gerado = this.date.split('-').reverse().join('/')
        } else if (this.opcoes.mes && this.month) {
          const initialDate = new Date(`${this.month.split('-')[1]}-01-${this.month.split('-')[0]}`.split('-').join('/'))
          const finalDate = new Date(initialDate.getFullYear(), initialDate.getMonth() + 1, 0)

          filtro.data_gerado__gte = initialDate.toISOString().substr(0, 10).split('-').reverse().join('/')
          filtro.data_gerado__lt = finalDate.toISOString().substr(0, 10).split('-').reverse().join('/')
        }
      }
      if (this.data.tipo_proposta !== null && this.data.tipo_proposta !== undefined) {
        filtro.proposta__modelo_faturamento = this.data.tipo_proposta
      }
      if (this.proposta) filtro.proposta = this.proposta
      const data = {
        filtros: filtro,
        pagination: this.paginationVoucher
      }
      this.$store.dispatch('relatorioVouchers', data).then(relatorio => {
        this.Vouchers = relatorio.results.vouchers
        this.total_relatorio_vouchers = relatorio.results.valor_total
        this.total_itens = relatorio.count
      })
    },
    carregarPostos () {
      this.$store.dispatch('postosComProposta').then(json => {
        this.postos = json
      })
    },
    carregarRedes () {
      this.$store.dispatch('redesComProposta').then(json => {
        this.redes = json
      })
    },
    dataSelecionada () {
      if (this.opcoes.custom) {
        if (this.dates.length === 3) {
          const day1 = this.dates[0]
          const day2 = this.dates[2]
          this.dates = []
          this.dates = [day1, day2]
        }
        if (this.dates.length) {
          this.orderDates()
        }
        this.opcoes.intervalo_relatorio = this.dates.map(this.formatDate)
      } else {
        this.menuDataInicial = false
      }
    },
    carregarFuncionarios () {
      this.$store.dispatch('pegarFuncionarios').then(json => {
        this.funcionarios_empresa = json
      })
    },
    carregarPropostas () {
      this.$store.dispatch('buscarPropostas', { pagination: this.pagination, filtros: this.filtro_proposta })
    },
    carregarVeiculos () {
      var data = {
        paginationVeiculo: this.pagination,
        cnpj: this.empresa.cnpj
      }
      this.$store.dispatch('carregarVeiculos', data)
    }
  }
}
</script>
