<template>
    <v-card>
        <v-card-title class="justify-center" style="font-size: 1.8em">
            Detalhes do Cliente
        </v-card-title>
        <v-card-text style="font-size: 1.2em;">
            <v-layout justify-spacer-between wrap>
                <v-flex xs5>
                    <b>Identificador:</b> {{ cliente.pessoa.id_pessoa || 'Não informado' }}
                </v-flex>
                <v-flex xs6>
                    <b>Nome:</b> {{ cliente.pessoa.nome_completo || 'Não informado' }}
                </v-flex>
                <v-flex xs5 class="mt-3">
                    <b>Data cadastro:</b> {{ cliente.pessoa.data_cadastro | formatDate('DD/MM/YYYY') }}
                </v-flex>
                <v-flex xs6 class="mt-3">
                    <b>Local:</b> {{ cliente.pessoa.local || 'Não informado' }}
                </v-flex>
                <v-flex xs12 class="mt-4">
                  <v-card flat>
                    <v-card-text>
                        <v-layout justify-space-between wrap v-if="inscricao_empresa">
                            <v-flex xs5>
                                <b>Recebe e-mail:</b> {{ inscricao_empresa.inscrito_email ? 'SIM' : 'NÃO' }}
                            </v-flex>
                            <v-flex xs5>
                                <b>Recebe Notificações:</b> {{ inscricao_empresa.inscrito_push ? 'SIM' : 'NÃO' }}
                            </v-flex>
                            <v-flex xs5>
                                <b>Número de compras:</b> {{ inscricao_empresa.numero_compras }}
                            </v-flex>
                            <v-flex xs5>
                                <b>Assinante:</b> {{ inscricao_empresa.tem_assinatura ? 'SIM' : 'NÃO' }}
                            </v-flex>
                            <v-flex xs5>
                                <b>Cliente Plus:</b> {{ inscricao_empresa.cliente_plus ? 'SIM' : 'NÃO' }}
                            </v-flex>
                            <v-flex xs5>
                                <b>Pontuação:</b> {{ inscricao_empresa.pontuacao }} {{ inscricao_empresa.pontuacao === 1 ? 'pt' : 'pts' }}
                            </v-flex>
                            <v-flex xs12 class="mt-3">
                                <v-btn round small block color="primary" @click="() => tooglePlusCustomer()">
                                    <v-icon class="mr-1">{{ inscricao_empresa.cliente_plus ? 'star' : 'star_outline' }}</v-icon> {{ inscricao_empresa.cliente_plus ? 'Retira Plus' : 'Tornar Plus' }}
                                </v-btn>
                            </v-flex>
                            <template v-if="inscricao_empresa.assinatura">
                                <v-flex xs12 class="mt-3">
                                    <h3
                                        class="text-xs-center"
                                        style="font-weight:400;"
                                    >
                                        Dados da Assinatura
                                    </h3>
                                </v-flex>
                                <v-flex xs5 class="mt-3">
                                    <b>Plano:</b> {{ inscricao_empresa.assinatura.plano ? inscricao_empresa.assinatura.plano.descricao : 'Não informado' }}
                                </v-flex>
                                <v-flex xs5 class="mt-3">
                                    <b>Valor:</b> {{ inscricao_empresa.assinatura.plano.valor | currency({fractionCount: 2}) }}
                                </v-flex>
                                <v-flex xs5 class="mt-3">
                                    <b>Data de assinatura:</b> {{ inscricao_empresa.assinatura.data | formatDate('DD/MM/YYYY') }}
                                </v-flex>
                                <v-flex xs5 class="mt-3">
                                    <b>Data de cobrança:</b> {{ inscricao_empresa.assinatura.data_cobranca | formatDate('DD/MM/YYYY') }}
                                </v-flex>
                                <v-flex xs5 class="mt-3">
                                    <b>Data de encerramento:</b> {{ inscricao_empresa.assinatura.data_encerramento | formatDate('DD/MM/YYYY') }}
                                </v-flex>
                            </template>
                        </v-layout>
                    </v-card-text>
                  </v-card>
                </v-flex>
                <template v-if="cliente.assinatura">
                    <v-flex xs12 class="mt-4">
                        <h4 class="text-xs-center">Dados da Assinatura</h4>
                    </v-flex>
                    <v-flex xs5 class="mt-3">
                        <b>Plano:</b> {{ cliente.assinatura.plano ? cliente.assinatura.plano.descricao : 'Não informado' }}
                    </v-flex>
                    <v-flex xs5 class="mt-3">
                        <b>Valor:</b> {{ cliente.assinatura.plano.valor | currency({fractionCount: 2}) }}
                    </v-flex>
                    <v-flex xs5 class="mt-3">
                        <b>Data de assinatura:</b> {{ cliente.assinatura.data | formatDate('DD/MM/YYYY') }}
                    </v-flex>
                    <v-flex xs5 class="mt-3">
                        <b>Data de cobrança:</b> {{ cliente.assinatura.data_cobranca | formatDate('DD/MM/YYYY') }}
                    </v-flex>
                    <v-flex xs5 class="mt-3">
                        <b>Data de encerramento:</b> {{ cliente.assinatura.data_encerramento | formatDate('DD/MM/YYYY') }}
                    </v-flex>
                </template>
            </v-layout>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
  props: ['cliente'],
  beforeMount () {
    if (this.cliente.inscricao_posto) {
      const inscricaoEmpresa = this.cliente.inscricao_posto.filter((ip) => !!ip.empresa)
      if (inscricaoEmpresa.length > 0) {
        this.inscricao_empresa = inscricaoEmpresa[0]
      }
    }
  },
  data: () => ({
    active: null,
    inscricao_empresa: undefined
  }),
  computed: {
    empresa () {
      return this.$store.getters.empresa
    }
  },
  methods: {
    isSubscriber (listaInscricoes) {
      if (!listaInscricoes || listaInscricoes.length === 0) return false
      const lista = listaInscricoes.filter((obj) => {
        return obj.cliente_plus
      })
      return lista.length > 0
    },
    isPlus (listaInscricoes) {
      if (!listaInscricoes || listaInscricoes.length === 0) return false
      const lista = listaInscricoes.filter((obj) => {
        return !!obj.assinatura
      })
      return lista.length > 0
    },
    tooglePlusCustomer () {
      const data = {
        id_cliente: this.cliente.id_cliente,
        empresa: this.empresa.cnpj
      }
      this.$store.dispatch('tooglePlus', data).then(() => {
        this.inscricao_empresa.cliente_plus = !this.inscricao_empresa.cliente_plus
        this.$emit('carregar')
      }).catch((e) => {
        alert(e.message)
      })
    }
  }
}
</script>
