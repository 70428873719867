<template>
  <v-card class="pa-2" style="border-radius: 1em;">
    <v-card-title>Tem certeza que deseja excluir {{funcionario.nome_email}}?</v-card-title>
    <v-card-actions>
      <v-btn @click="$emit('fechar')">NÃO</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="error" @click="$emit('excluir', funcionario)">SIM</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['funcionario']
}
</script>
